<div [hidden]="subjectCacheIsLoaded < 2">
   <div>
      <mat-card class="mat-card-center-95P">
         <mat-card-header class="mat-card-header-color">
            <mat-card-title>
               <span class="material-icons matcard-title-icon"
                  style="margin-left:auto">volunteer_activism</span>{{titleDescriptionDisplay}}
            </mat-card-title>
         </mat-card-header>
         <mat-card-content>

            <div class="matcard matcardFloat">
               <app-panel>
                  <span panel-title></span>
                  <div panel-content>
                     <div>
                        Comme souvent, une des préoccupations principales d'un club sportif est de trouver les
                        ressources
                        budgétaires pour mener à bien son projet associatif.
                        C'est dans cette optique que nous sommes continuellement à la recherche de sponsors.
                        Si l'aventure vous tente et que vous souhaitez plus d'informations à ce sujet, n'hésitez pas à
                        nous
                        en faire part.
                     </div>
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat" *ngIf="sponsorResponsible != null">
               <app-panel [widthPanel]="'420px'" [isBodyContentCenter]="true">
                  <span panel-title>Contact</span>
                  <div panel-content>
                     <div>{{sponsorResponsible.firstName}}&nbsp;{{sponsorResponsible.lastName}}</div>

                     <div class="br1" *ngIf="sponsorResponsible.phone1PrivacyLevel ==  EnumPrivacyLevel.Public"></div>
                     <app-buttonhref *ngIf="sponsorResponsible.phone1PrivacyLevel == EnumPrivacyLevel.Public"
                        [isBackgroundOpaque]="true" [icon]="'phone'" [text]="getPhoneFormat(sponsorResponsible.phone1)"
                        [type]="EnumHRefType.Phone" [href]="sponsorResponsible.phone1" alt="Phone">
                     </app-buttonhref>

                     <div class="br1" *ngIf="sponsorResponsible.emailPrivacyLevel == EnumPrivacyLevel.Public"></div>
                     <app-buttonhref *ngIf="sponsorResponsible.emailPrivacyLevel == EnumPrivacyLevel.Public"
                        [isBackgroundOpaque]="true" [icon]="'email'" [text]="sponsorResponsible.email"
                        [type]="EnumHRefType.Email" [href]="sponsorResponsible.email" alt="Mailto"></app-buttonhref>
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel [isBodyContentCenter]="true" [widthPanel]="'420px'" [height]="'400px'" *ngFor="let sponsor of allSponsors">
                  <span panel-title>{{sponsor.name}}</span>
                  <div panel-content>
                     {{getAddress(sponsor)}}<br>

                     <app-buttonhref *ngIf="sponsor.phone != ''" [isBackgroundOpaque]="true" [icon]="'phone'"
                        [text]="getPhoneFormat(sponsor.phone)" [type]="EnumHRefType.Phone" [href]="sponsor.phone"
                        alt="Phone">
                     </app-buttonhref>

                     <app-buttonhref *ngIf="sponsor.email != ''" [isBackgroundOpaque]="true" [icon]="'email'"
                        [text]="sponsor.email" [type]="EnumHRefType.Email" [href]="sponsor.email" alt="Mailto">
                     </app-buttonhref>

                     <div *ngIf="sponsor.description != ''">{{sponsor.description}}</div>
                     <div class="br1"></div>

                     <!-- Image avec lien -->
                     <div class="sponsorImage" [style.backgroundImage]="getImgBackgroundURL(sponsor.picture)"
                        [hidden]="sponsor.website != null && sponsor.website != ''"></div>

                     <!-- Image sans lien -->
                     <a [hidden]="sponsor.website == null || sponsor.website == ''" [href]="sponsor.website"
                        target="_blank" rel="noopener">
                        <div class="sponsorImage" [style.backgroundImage]="getImgBackgroundURL(sponsor.picture)">
                        </div>
                     </a>
                     <div class="br1"></div>
                  </div>
               </app-panel>
            </div>

         </mat-card-content>
      </mat-card>
   </div>
</div>