import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';

@Component({
   selector: 'app-buttonPlusMinus',
   templateUrl: './buttonPlusMinus.component.html',
   styleUrls: ['./buttonPlusMinus.component.scss']
})
export class ButtonPlusMinusComponent implements OnInit {
   @Input() isPlusButton: boolean;
   @Input() visible: boolean = true;
   @Input() isAlwaysEnabled: boolean = false;
   @Input() isEventClickNotEmitted: boolean = false;

   @Output() onClick = new EventEmitter<string>();

   isNetworkStatusOnline: boolean = false;
   isNetworkLoading: boolean = false;

   constructor(private subjectService: SubjectService,
      private frameworkService: FrameworkService) {  
   }

   ngOnInit() {
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
      this.subjectService.IsButtonInLoadingSubject.subscribe(value => { this.isNetworkLoading = value; }); 
   }

   onButtonClick(disabled: boolean) {
      if (!disabled && !this.isNetworkLoading && !this.isEventClickNotEmitted)
      {
         this.isNetworkLoading = true;
         this.subjectService.IsButtonInLoadingSubject.next(true);
         this.onClick.emit(); 
      }
   }

   getClass(disabled: boolean) {
      if (disabled) return "disabled";
      return "";
   }
}
