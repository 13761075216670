import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { Observable, forkJoin, timer } from 'rxjs';
import { enumDatagridHeaderOrder, enumQueryFilterOp } from 'src/sites/core/enum/Enum';
import { datagridFilterDetail } from 'src/sites/core/models/system/datagridFilter';
import { datagridParam } from 'src/sites/core/models/system/datagridParam';
import { AlertShowService } from 'src/sites/core/services/alertShow.service';
import { DatagridService } from 'src/sites/core/services/datagrid.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { buildingTraining } from 'src/sites/vabourlettis/models/buildingTraining';
import { BuildingTrainingService } from 'src/sites/vabourlettis/services/buildingTraining.service';
import * as XLSX from 'xlsx';
import { queryFilter } from '../../models/queryFilter';
import { CacheService } from '../../services/cache.service';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { encryptedValue } from '../../authentication/encryptedValue';
import { SubjectService } from 'src/sites/core/services/subject.service';

type AOA = any[][];

@Component({
   selector: 'app-adminBuildingTraining-list',
   templateUrl: './adminBuildingTraining-list.component.html',
   styleUrls: ['./adminBuildingTraining-list.component.scss']
})
export class AdminBuildingTrainingListComponent implements OnInit {
  public allBuildingTrainingsCurrentCS: buildingTraining[];
  public allBuildingTrainingsPreviousCS: buildingTraining[];
  public championshipNameDisplay: string = "";
  public data_salles: AOA;
  public datagridParam: datagridParam<buildingTraining>;
  public dialog: any;
  public exportFilesFromFvwbPortal: string = "";
  public importChampionshipDisplay: string = "";
  public importDataDescriptionDisplay: string = "";
  public importDataTitleDisplay: string = "";
  public isLoading: boolean = false;
  public previousChampionshipSysId = 0;
  public subjectCacheIsLoaded: number = null;

  constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private alertShowService: AlertShowService,
      private datagridService: DatagridService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      private encryptionService: EncryptionService,
      private buildingTrainingService: BuildingTrainingService) {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.subscribe(r => {
         if (this.isLoading) this.refresh();
      });
   }

  public getDatagridFilters($event) {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.next(true);
   }

  public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

  public ngOnInit() {
      //Souscription à l'event lorsque la cache globale a terminé son chargement
      this.subjectService.IsGlobalCacheLoadedSubject.subscribe(value => { this.subjectCacheIsLoaded = value; if (value == 2) this.initialize(); });
   }

  public onFileChange(evt: any) {
      const target: DataTransfer = <DataTransfer>(evt.target);
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');

      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
         const wb: XLSX.WorkBook = XLSX.read(e.target.result, { type: 'binary' });

         const ws_salles: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[5]];

         //TODO (Excel) : Check if excel has a good structure

         //5 : salles+et+terrains
         /*
         00 Nom	
         01 Adresse	
         02 Code postal	
         03 Ville	
         04 Homologation	
         05 Terrain	
         06 Clé	
         07 Code	
         08 DocumentId
         */
         this.data_salles = <AOA>(XLSX.utils.sheet_to_json(ws_salles, { header: 1 }));

         let newBuildingTrainingToInsert = [];
         let newBuildingTraining = new buildingTraining();
         newBuildingTraining.championshipSysId = this.cacheService.getCurrentChampionship().sysId;

         this.data_salles.forEach(item => {
            if ((item[0] + "").trim() != "Nom" &&
               !(item[0] + "").trim().startsWith('(') &&
               !(item[0] + "").trim().startsWith('*') &&
               (item[0] + "").trim() != "" &&
               (item[1] + "").trim() + (item[2] + "").trim() + (item[3] + "").trim() != "") {
               let allPartCode = item[7].split('-');
               let code = item[7]?.toUpperCase();
               if (allPartCode.length > 0) {
                  allPartCode.pop();
                  code = allPartCode.join('-');
               }

               code = this.frameworkService.removeBlank(code).toUpperCase();

               let buildingTrainingFound = newBuildingTrainingToInsert.FirstOrDefault(i => i.code == code);
               let buildingTrainingAlreadyInDatabase = this.allBuildingTrainingsCurrentCS.FirstOrDefault(i => i.code == code);
               //No update during the championship.One shoot at the begining of each championship
               if (buildingTrainingFound == null && buildingTrainingAlreadyInDatabase == null) {
                  let newbuildingTraining = new buildingTraining();
                  newbuildingTraining.code = code;
                  newbuildingTraining.buildingName = this.frameworkService.uppercaseFirstLetterAndLowerOther(item[0] ?? "");
                  newbuildingTraining.address = this.frameworkService.uppercaseFirstLetterAndLowerOther(item[1] ?? "");
                  newbuildingTraining.zipCode = item[2] ?? "";
                  newbuildingTraining.locality = this.frameworkService.uppercaseFirstLetterAndLowerOther(item[3] ?? "");

                  newbuildingTraining.championshipSysId = this.cacheService.getCurrentChampionship().sysId;
                  newBuildingTrainingToInsert.push(newbuildingTraining);
               }
            }
         }
         );

         this.buildingTrainingService.insertAll(newBuildingTrainingToInsert).subscribe({
            next: data => {
               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

               this.alertShowService.success(this.translateService.instant('MessageImportFinishedSuccessfully'));
               this.subjectService.IsDatagridShouldbeRefreshedSubject.next(true);
               timer(this.frameworkService.saveStatusResetTimeOnSuccess()).subscribe(x => { this.alertShowService.clear() });
               this.subjectService.IsButtonInLoadingSubject.next(false);
            },
            error: err => {
               this.alertShowService.clear();
               this.alertShowService.error(this.translateService.instant('MessageImportUnfinishedDueToAnError'));
               timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
         });

      };
      reader.readAsBinaryString(target.files[0]);
   }

  public refresh() {
      this.isLoading = false;

      this.importChampionshipDisplay = this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", this.cacheService.getCurrentChampionship());

      //-> Get all championships
      let filterDetail = new datagridFilterDetail();
      filterDetail.dropdownLists = this.cacheService.getAllChampionships().map(x => (
         {
            value: x.sysId,
            viewValue: this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", x)
         }));

      filterDetail.dropdownSelectedValueOfLists = this.cacheService.getCurrentChampionship()?.sysId;

      //-> Get previous championship
      let previousChampionship = this.cacheService.getAllChampionships().FirstOrDefault(i => i.yearStarting == (Number(this.cacheService.getCurrentChampionship().yearStarting) - 1).toString());
      if (previousChampionship != null)
         this.previousChampionshipSysId = previousChampionship?.sysId;
      else
         this.previousChampionshipSysId = 0;

      let appServiceArray: Observable<any>[] = [];

      //-> Get all trainingBuildings of current CS
      let queryFilters = [];
      let queryFiltersOnCurrentCS = [];
      queryFiltersOnCurrentCS.push(new queryFilter({ propertyName: "ChampionshipSysId", operation: enumQueryFilterOp.Equals, value: this.cacheService.getCurrentChampionship()?.sysId }));
      appServiceArray.push(this.buildingTrainingService.getAllWithIncluding(queryFilters));
      //<- Get all trainingBuildings of current CS

      //-> Get all trainingBuildings of previous CS
      queryFilters = [];
      let queryFiltersOnPreviousCS = [];
      queryFiltersOnPreviousCS.push(new queryFilter({ propertyName: "ChampionshipSysId", operation: enumQueryFilterOp.Equals, value: previousChampionship?.sysId }));
      appServiceArray.push(this.buildingTrainingService.getAllWithIncluding(queryFilters));
      //<- Get all trainingBuildings of previous CS

      forkJoin(appServiceArray).subscribe(res => {

         var res0Decrypted = this.encryptionService.decryptObject(false, res[0].value);
         var res1Decrypted = this.encryptionService.decryptObject(false, res[1].value);

         this.allBuildingTrainingsCurrentCS = res0Decrypted;
         this.allBuildingTrainingsPreviousCS = res1Decrypted;

         this.allBuildingTrainingsCurrentCS.forEach(i => i.championship = this.cacheService.getAllChampionships().FirstOrDefault(j => j.sysId == i.championshipSysId));
         this.allBuildingTrainingsPreviousCS.forEach(i => i.championship = this.cacheService.getAllChampionships().FirstOrDefault(j => j.sysId == i.championshipSysId));

         filterDetail.labelText = this.championshipNameDisplay + " : ";
         filterDetail.name = "championship";
         filterDetail.idName = "buildingTraining.championship.sysId";

         let filters = new Map<string, datagridFilterDetail>();
         filters.set(filterDetail.name, filterDetail);
         //<- Set filter(s)

         // Display the datagrid
         this.datagridParam = new datagridParam(
            {
               hidden: !this.isLoading,
               dataSource: this.allBuildingTrainingsCurrentCS,
               filters: null, //filters
               mainTitle: "AdminBuildingTrainingsListTitle",
               headerOrder: enumDatagridHeaderOrder.ApiByDefault,
               entity: new buildingTraining(),
               urlApi: "C1007",
               paginatorItemsCount: this.frameworkService.getPaginatorItemsCount(),
               formTitle: "BuildingTrainingTitle"
            });
         this.isLoading = true;
      });
   }

  public translatePage() {
      //ExportFiles2FromFvwbPortal
      this.translateService.get(['ExportFiles2FromFvwbPortal'])
         .subscribe(translations => {
            this.exportFilesFromFvwbPortal = translations['ExportFiles2FromFvwbPortal'];
         });

      //Championship
      this.translateService.get(['Championship'])
         .subscribe(translations => {
            this.championshipNameDisplay = translations['Championship'];
         });

      //ImportDataTitleDisplay
      this.translateService.get(['ImportDataTitleDisplay'])
         .subscribe(translations => {
            this.importDataTitleDisplay = translations['ImportDataTitleDisplay'];
         });

      //ImportData2DescriptionDisplay
      this.translateService.get(['ImportData2DescriptionDisplay'])
         .subscribe(translations => {
            this.importDataDescriptionDisplay = translations['ImportData2DescriptionDisplay'];
         });
   }
}
