import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, ObservableInput, forkJoin } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/sites/core/components/confirmDialog/confirmDialog.component';
import { enumCacheTypeLoad } from 'src/sites/core/enum/Enum';
import { user } from 'src/sites/core/models/user/user';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { AccountService } from 'src/sites/core/services/account.service';
import { AppConfigService } from 'src/sites/core/services/appconfig.service';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { LastUpdatesService } from 'src/sites/core/services/lastUpdates.service';
import { NotificationService } from 'src/sites/core/services/notification.service';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { SystemService } from 'src/sites/core/services/system.service';
import { encryptedValue } from 'src/sites/vabourlettis/authentication/encryptedValue';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';

@Component({
   selector: 'app-navbar',
   templateUrl: './navbar.component.html',
   styleUrls: ['./navbar.component.scss'],
   providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
   public isCacheInLoading: boolean = true;
   public headerSiteTitleDisplay: string = "";
   public headerSiteUnderTitleDisplay: string = "";
   public iconOnlyToggled = false;
   public isDisplayOnAMobile = false;
   public isNetworkStatusOnline: boolean = false;
   public isTurnAdminButtonsOff: boolean = true;
   public searchTitleDisplay: string = "";
   public sidebarToggled = false;
   public subjectCacheIsLoaded: number = null;
   public userInitialDisplay: string = "";
   public userNameDisplay: string = "";
   public userConnectedSubject?: user = undefined;
   private subjectHubConnectionStatus: number = 0;
   public isSynchronisationRunning: boolean = false;

   constructor(
      private router: Router,
      private subjectService: SubjectService,
      private accessRightsService: AccessRightsService,
      private accountService: AccountService,
      private translateService: TranslateService,
      private cacheService: CacheService,
      private appConfigService: AppConfigService,
      private systemService: SystemService,
      private frameworkService: FrameworkService,
      private lastUpdatesService: LastUpdatesService,
      private storageService: StorageService,
      public dialog: MatDialog,
      private encryptionService: EncryptionService,
      private notificationService: NotificationService,
      config: NgbDropdownConfig) {
      config.placement = 'bottom-right';
   }

   @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.subjectService.IsWindowResizingSubject.next(window.innerWidth <= 960);
   }

   public backColorEnv() {
      if (this.appConfigService.getEnvironment() == "development")
         return "navbarMiddle pageBodyEnvDev";
      else {
         if (this.appConfigService.getEnvironment() == "test")
            return "navbarMiddle pageBodyEnvTest";
         else
            return "navbarMiddle";
      }
   }

   public fvwbSynchronize() {
      this.isSynchronisationRunning = true;

      const message1: string = "Voulez-vous effectuer la mise à jour ?";
      const message2: string = "";

      const dialogData = new ConfirmDialogModel("Synchronisation FVWB", message1, message2);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
         maxWidth: "100%",
         width: "100%",
         data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
         if (dialogResult) {
            let appServiceArray: Observable<any>[] = [];
            appServiceArray.push(this.systemService.fvwbSynchronize(false));

            forkJoin(appServiceArray).subscribe(res => {
               if (res[0]) {
                  this.frameworkService.displayAlert(true, "La synchronisation a bien été effectuée.");
               }
               else {
                  this.frameworkService.displayAlert(false, "La synchronisation n'a pas été effectuée.");
               }
               this.subjectService.IsButtonInLoadingSubject.next(false);
               this.isSynchronisationRunning = false;
            });
         }
         else {
            this.subjectService.IsButtonInLoadingSubject.next(false);
            this.isSynchronisationRunning = false;
         }
      });
   }

   public isComiteeAuthenticated() {
      if (this.storageService.getUserConnected() == null)
         return false;
      else
         return this.frameworkService.isUserInComitee(this.storageService.getUserConnected());
   }

   public getCurrentChampionshipName() {
      return this.cacheService.getCurrentChampionship().yearStarting + " - " + this.cacheService.getCurrentChampionship().yearEnding
   }

   public getWorkerServiceClass() {
      return "material-icons status" + this.subjectHubConnectionStatus.toString();
   }

   public getNavbarClass() {
      if (this.isCacheInLoading)
         return "logoVABNavbar logoVABBase64 at-item animate-flicker";
      else
         return "logoVABNavbar logoVABBase64";
   }
   public initialize() {
      this.subjectService.UserConnectedSubject.subscribe(x => { this.userConnectedSubject = x; this.translatePage(); });

      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });
   }

   public isBackgroundOpaque() {
      return (this.appConfigService.getEnvironment() == "development");
   }

   public isInformationEditable() {
      return this.accessRightsService.isInformationEditable();
   }

   public isUserAuthenticated(): boolean {
      let result = this.userConnectedSubject != null;
      if (result) {
         let user = this.userConnectedSubject as user | undefined

         if (user) {
            this.translateService.use(user.language);
         }
         this.accessRightsService.refresh();
      }
      return result;
   }

   public isUserInTeam(): boolean {
      return (this.storageService.getUserConnected().team?.teamCategory != "")
   }

   public isUserSupervisor(): boolean {
      return (this.isUserAuthenticated() && this.accessRightsService.isUserSupervisor());
   }

   public login() {
      this.router.navigate(['login']);
   }

   public logout() {
      const message1: string = "Voulez-vous vous déconnecter ?";
      const message2: string = "";

      const dialogData = new ConfirmDialogModel("", message1, message2);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
         maxWidth: "100%",
         width: "100%",
         data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
         if (dialogResult) {
            this.accountService.logout();
            //Remove collection not used in cache on public mode
            this.cacheService.resetUnloggedData();
         }
         this.subjectService.IsButtonInLoadingSubject.next(false);
      });
   }

   public ngOnInit() {
      this.getScreenSize();
      //Souscription à l'event lorsque la cache globale a terminé son chargement
      this.subjectService.IsGlobalCacheLoadedSubject.subscribe(value => {
         this.subjectCacheIsLoaded = value ?? 0;
         if (value >= 1) this.initialize();
      });
      this.subjectService.IsWindowResizingSubject.subscribe(value => { this.isDisplayOnAMobile = value; this.translatePage(); });
      this.subjectService.IsTurnOffAdminButtonSubject.next(this.isTurnAdminButtonsOff);
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
      this.subjectService.IsGlobalCacheLoadingSubject.subscribe(value => {
         this.isCacheInLoading = value;
      });
      this.subjectService.HubConnectionStatusSubject.subscribe(value => { this.subjectHubConnectionStatus = value; });
   }

   public reloadWebsite() {
      if (this.subjectCacheIsLoaded != 3) {
         //this.frameworkService.logInfo("CALL cacheLoadStart","reloadWebsite");
         this.cacheService.setIsAutomaticCheckCalled(true);
         this.cacheService.cacheLoadStart(enumCacheTypeLoad.fromWebAPI);
         //this.frameworkService.logInfo("IsGlobalCacheLoadedSubject 3","reloadWebsite");
         this.subjectService.IsGlobalCacheLoadedSubject.next(3);
      }
   }

   public setReset() {
      this.lastUpdatesService.setReset().subscribe(data => {
         //this.frameworkService.logInfo(data, "setReset return");
         if (data) {
            this.frameworkService.displayAlert(true, "Le flag \"reset\" a bien été mis à jour.");
         }
         else {
            this.frameworkService.displayAlert(false, "Le flag \"reset\" n'a pas été mis à jour.");
         }
      });
   }

   public site() {
      return this.appConfigService.getSiteName();
   }

   // toggle sidebar in small devices
   public toggleOffcanvas() {
      document.querySelector('.sidebar-offcanvas')?.classList.toggle('active');
   }

   // toggle sidebar
   public toggleSidebar() {
      let body = document.querySelector('body');

      this.iconOnlyToggled = !this.iconOnlyToggled;
      if (this.iconOnlyToggled) {
         body?.classList.add('sidebar-icon-only');
      } else {
         body?.classList.remove('sidebar-icon-only');
      }
   }

   public translatePage() {
      //SearchTitle
      this.translateService.get(['SearchTitle'])
         .subscribe(translations => {
            this.searchTitleDisplay = translations['SearchTitle'];
         });

      //HeaderSiteTitle
      this.translateService.get(['HeaderSiteTitle'])
         .subscribe(translations => {
            this.headerSiteTitleDisplay = translations['HeaderSiteTitle'];
         });

      //UsernameUnknown
      this.translateService.get('UsernameUnknown').subscribe((res: string) => {
         if (this.userConnectedSubject == null) {
            this.userNameDisplay = res;
            this.userInitialDisplay = "";
         }
         else {
            this.userNameDisplay = this.userConnectedSubject.firstName;
            this.userInitialDisplay = this.userConnectedSubject.firstName;
         }
      });
   }

   public turnOnOffAdminButtons() {
      this.isTurnAdminButtonsOff = !this.isTurnAdminButtonsOff;
      this.subjectService.IsTurnOffAdminButtonSubject.next(this.isTurnAdminButtonsOff);
   }


  

   // toggle right sidebar
   // toggleRightSidebar() {
   //   document.querySelector('#right-sidebar').classList.toggle('open');
   // }
}
