import { jsonIgnore } from "json-ignore";
import { metadataModelBase } from "./metadataModelBase";
import { enumWorkgroup } from "../../enum/Enum";

export class auditFields
{
    @Reflect.metadata('sysId',
    <metadataModelBase> <unknown> {
      editColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
      gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None]
    })
    sysId?: number = 0;

    // sysCreatedBy: string;
    sysCreatedOn: Date;
    //sysUpdatedBy: string;
    sysUpdatedOn: Date;
    // sysDeletedBy: string;
    sysDeletedOn: Date;
    sysIsActive: boolean;
}