import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';
import { Md5 } from 'ts-md5';
import { AccountService } from "../../../core/services/account.service";
import { AlertShowService } from "../../../core/services/alertShow.service";
import { enumCacheTypeLoad } from '../../enum/Enum';
import { AccessRightsService } from '../../services/accessRights.service';
import { AppConfigService } from '../../services/appconfig.service';
import { FrameworkService } from '../../services/framework.service';
import { AuthenticationService } from 'src/sites/vabourlettis/authentication/authentication.service';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { EncryptionService } from '../../services/encryption.service';
import { SubjectService } from '../../services/subject.service';

@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public form: FormGroup;
  public hidePassword: boolean = true;
  public isNetworkStatusOnline: boolean = false;
  public submitted: boolean = false;

  constructor(
      private appConfigService : AppConfigService,   
      private translateService: TranslateService,
      private formBuilder: FormBuilder,
      private router: Router,
      private accountService: AccountService,
      private alertShowService: AlertShowService,
      private frameworkService: FrameworkService,
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private storageService: StorageService,
      private encryptionService: EncryptionService
   ) { }

  public ngOnInit() {
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });

      this.form = this.formBuilder.group({
         username: ['', Validators.required],
         password: ['', Validators.required]
      });

      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });
   }

   public onLoginForgotPasswordCommand() {
      this.router.navigate(['recoverPassword']);
   }
  public onSignInCommand() {
      this.submitted = true;

      // reset alerts on submit
      this.alertShowService.clear();

      // stop here if form is invalid
      if (this.form.invalid) {
         this.subjectService.IsButtonInLoadingSubject.next(false);
         return;
      }

      const md5 = new Md5();

      
      this.accountService.isAuthenticated(this.form.controls.username.value, md5.appendStr(this.form.controls.password.value).end().toString()).subscribe(
      {
         next: encryptedValue => {
            //this.frameworkService.logInfo("-","LOGIN / isAuthenticated");
            var userAuthToken = this.encryptionService.decryptObject(true, encryptedValue.value);

            if (userAuthToken == null || userAuthToken.user == null) {
               //this.frameworkService.logInfo("IncorrectPasswordOrUsername","LOGIN / isAuthenticated");
               this.alertShowService.error(this.translateService.instant('IncorrectPasswordOrUsername'));
            }
            else {
               if (userAuthToken.user.accountLoginTries > 0 && userAuthToken.user.accountLoginTries <= this.frameworkService.getMaxAccountLoginTries() - 1) {
               
                     //this.frameworkService.logInfo("IncorrectPasswordOrUsername","LOGIN / isAuthenticated");
                     this.alertShowService.error(this.translateService.instant('IncorrectPasswordOrUsername'));
               }
               else {
                  if (userAuthToken.user.accountLoginTries > this.frameworkService.getMaxAccountLoginTries() - 1) {
                     //this.frameworkService.logInfo("AccountIsLocked","LOGIN / isAuthenticated");
                     this.alertShowService.error(this.translateService.instant('AccountIsLocked'));
                  }
                  else {
                     this.storeTokenAndRefresh(userAuthToken.user, encryptedValue);
                  }
               }
            }
            this.subjectService.IsButtonInLoadingSubject.next(false);
         },
         error: err => {
            this.alertShowService.error(err.mes);
            this.subjectService.IsButtonInLoadingSubject.next(false);
         }
      });
   }

   public storeTokenAndRefresh(user, encryptedValue) {
      if (user.accountLoginTries == 0) {
         //this.frameworkService.logInfo("UserConnectedSubject","LOGIN / isAuthenticated / storeTokenAndRefresh");
         //-> Set Userconnected subject
         this.subjectService.UserConnectedSubject.next(user);
         //this.frameworkService.logInfo("saveToken","LOGIN / isAuthenticated / storeTokenAndRefresh");
         //-> Store the token
         this.storageService.saveToken(encryptedValue);
         //-> REFRESH the cacheService
         //this.frameworkService.logInfo("LOGIN", ">>> RELOAD ALL");
         //this.frameworkService.logInfo("CALL cacheLoadStart","storeTokenAndRefresh (login)");
         this.cacheService.cacheLoadStart(enumCacheTypeLoad.fromWebAPI);
         //<- REFRESH the cacheService
         //this.frameworkService.logInfo("home","LOGIN / isAuthenticated / storeTokenAndRefresh");

         this.router.navigate(['home']);
      }
   }

  public site() {
      return this.appConfigService.getSiteName();
   }

  public translatePage() {
   }
}