import { Injectable } from '@angular/core';
import { enumFormAction, enumRoles, enumWorkgroup } from '../enum/Enum';
import { AccessRights } from '../models/system/accessRights';
import { TranslateService } from '@ngx-translate/core';
import { user } from '../models/user/user';
import { EncryptionService } from './encryption.service';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';

@Injectable({ providedIn: 'root' })
//-- AccessRights service
export class AccessRightsService {

   private workgroupOfUserConnected = enumWorkgroup.None;

   constructor(private encryptionService: EncryptionService,
      private translateService: TranslateService,
      private storageService: StorageService) {
      this.refresh();
   }

   public refresh() {
      this.workgroupOfUserConnected = this.getWorkGroupOfUserConnected();
   }

   public isUserAdministratorOrSupervisor() {
      if (this.storageService.getUserConnected() == null)
         return false;
      else
         return this.storageService.getUserConnected().workgroupSysId <= 2; //Only admin or supervisor
   }

   public isUserSupervisor() {
      return this.workgroupOfUserConnected == enumWorkgroup.Supervisor;
   }

   public isInformationEditable() {
      return this.storageService.getUserConnected() != null && this.workgroupOfUserConnected != enumWorkgroup.None &&
         this.workgroupOfUserConnected != enumWorkgroup.UserReadOnly;
   }

   //-> Local storage   ------------------------------------------------------------------------------------------------




   public getWorkGroupOfUserConnected() {
      const user = this.storageService.getUserConnected();
      if (user == null)
         return enumWorkgroup.None;
      else
         return user.workgroupSysId;
   }
   public isUserConnectedInWorkGroups(workGroups: string) {
      const user = this.storageService.getUserConnected();
      return workGroups.includes(user.workgroupSysId.toString());
   }

   //<- Local storage   ------------------------------------------------------------------------------------------------

   public getUserIcon(userConnectedSubject): string {
      switch (this.workgroupOfUserConnected) {
         case enumWorkgroup.Supervisor:
            return "supervisor.png";
         case enumWorkgroup.Administrator:
            return "admin.png";
         case enumWorkgroup.UserFullEditSoft:
         case enumWorkgroup.UserFullEdit:
         case enumWorkgroup.UserAddEdit:
         case enumWorkgroup.UserEditOnly:
         case enumWorkgroup.UserReadOnly:
            if (userConnectedSubject.gender != null) {
               if (userConnectedSubject.gender)
                  return "user-male.png";
               else
                  return "user-female.png";
            }
            return "user-male.png";
         case enumWorkgroup.UserReadOnly:
            return "user-male.png"; //TODO (User) : get image for user unknown
      }
   }

   public getUserIconFromMember(user): string {
      switch (user.workgroup.sysId) {
         case enumWorkgroup.Supervisor:
            return "supervisor.png";
         case enumWorkgroup.Administrator:
            return "admin.png";
         case enumWorkgroup.UserFullEditSoft:
         case enumWorkgroup.UserFullEdit:
         case enumWorkgroup.UserAddEdit:
         case enumWorkgroup.UserEditOnly:
         case enumWorkgroup.UserReadOnly:
            if (user.gender != null) {
               if (user.gender)
                  return "user-male.png";
               else
                  return "user-female.png";
            }
            return "user-male.png";
         case enumWorkgroup.UserReadOnly:
            return "user-male.png"; //TODO (User) :  get image for user unknown
      }
   }

   getVisibilityOfColumn(isVisibleProperty: string) {
      const isVisiblePropertyEnum = this.getWorkgroupEnumFromName(isVisibleProperty);
      if (isVisiblePropertyEnum == enumWorkgroup.None)
         return false;
      else
         return (this.workgroupOfUserConnected <= isVisiblePropertyEnum)
   }

   getWorkgroupEnumFromName(value: string): number {
      switch (value) {
         case enumWorkgroup[enumWorkgroup.Supervisor]:
            return enumWorkgroup.Supervisor;
         case enumWorkgroup[enumWorkgroup.Administrator]:
            return enumWorkgroup.Administrator;
         case enumWorkgroup[enumWorkgroup.UserFullEditSoft]:
            return enumWorkgroup.UserFullEditSoft;
         case enumWorkgroup[enumWorkgroup.UserFullEdit]:
            return enumWorkgroup.UserFullEdit;
         case enumWorkgroup[enumWorkgroup.UserAddEdit]:
            return enumWorkgroup.UserAddEdit;
         case enumWorkgroup[enumWorkgroup.UserEditOnly]:
            return enumWorkgroup.UserEditOnly;
         case enumWorkgroup[enumWorkgroup.UserReadOnly]:
            return enumWorkgroup.UserReadOnly;
         case enumWorkgroup[enumWorkgroup.None]:
            return enumWorkgroup.None;
      }
   }

   getLastColumnBasedOnAccessRights(columnSelected: string, rowItems: any, listOfColumnHeader: any, isOnMobile: boolean): boolean {
      //Search last column name visible (visible or not hidden and not null)
      let column: string = "";
      for (const [key, value] of Object.entries(listOfColumnHeader)) {
         for (const [keyR, valueR] of Object.entries(rowItems)) {
            if (valueR !== null && valueR !== "") {
               let resume = true;
               if (value[0] != keyR && isOnMobile) {
                  resume = false;
               }

               if (value[1].grisIsInDetailExpanded && !isOnMobile)
                  resume = false;

               if (resume) {
                  let gridColumnIsVisible = (value[1].gridColumnIsVisible != undefined);
                  if (gridColumnIsVisible) {
                     gridColumnIsVisible = this.getVisibilityOfColumn(value[1].gridColumnIsVisible);
                  }
                  if (gridColumnIsVisible) {
                     column = value[0];
                  }
               }
            }
         }
      }
      return columnSelected == column;
   }

   //Get visibility of CRUD edition command of modal form
   getVisibilityFromAccessRights(menu: enumFormAction): boolean {
      const accessRights = this.getAccessRights();
      switch (menu) {
         case enumFormAction.All: //Used for CRUD menu edition
            return accessRights.newCommand ||
               accessRights.editCommand ||
               accessRights.deleteCommand ||
               accessRights.archiveCommand ||
               accessRights.unarchiveCommand;
         case enumFormAction.Add:
            return accessRights.newCommand;
         case enumFormAction.Edit:
            return accessRights.editCommand;
         case enumFormAction.Delete:
            return accessRights.deleteCommand;
         case enumFormAction.Archive:
            return accessRights.archiveCommand;
         case enumFormAction.Unarchive:
            return accessRights.unarchiveCommand;
      }
   }

   public getAccessRights(): AccessRights {
      switch (this.workgroupOfUserConnected) {
         case enumWorkgroup.Supervisor:
            return new AccessRights({
               newCommand: true,
               editCommand: true,
               deleteCommand: true,
               archiveCommand: true,
               unarchiveCommand: true
            });
         case enumWorkgroup.Administrator:
            return new AccessRights({
               newCommand: true,
               editCommand: true,
               deleteCommand: true,
               archiveCommand: true,
               unarchiveCommand: true
            });
         case enumWorkgroup.UserFullEditSoft:
            return new AccessRights({
               newCommand: true,
               editCommand: true,
               deleteCommand: true,
               archiveCommand: true,
               unarchiveCommand: true
            });
         case enumWorkgroup.UserFullEdit:
            return new AccessRights({
               newCommand: true,
               editCommand: true,
               deleteCommand: true,
               archiveCommand: false,
               unarchiveCommand: false
            });
         case enumWorkgroup.UserAddEdit:
            return new AccessRights({
               newCommand: true,
               editCommand: true,
               deleteCommand: false,
               archiveCommand: false,
               unarchiveCommand: false
            });
         case enumWorkgroup.UserEditOnly:
            return new AccessRights({
               newCommand: false,
               editCommand: true,
               deleteCommand: false,
               archiveCommand: false,
               unarchiveCommand: false
            });
         case enumWorkgroup.UserReadOnly:
            return new AccessRights({
               newCommand: false,
               editCommand: false,
               deleteCommand: false,
               archiveCommand: false,
               unarchiveCommand: false
            });
         case enumWorkgroup.None:
            return new AccessRights({
               newCommand: false,
               editCommand: false,
               deleteCommand: false,
               archiveCommand: false,
               unarchiveCommand: false
            });
      }
   }
}