import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { activityByMember } from "src/sites/vabourlettis/models/activityByMember";
import { user } from "../models/user/user";

@Injectable()
export class SubjectService implements OnDestroy {
   public MessageCacheSubject: BehaviorSubject<string> = new BehaviorSubject("");
   public StepNumberCacheSubject: BehaviorSubject<number> = new BehaviorSubject(0);

   public IsVersionCacheLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
   public IsQueryOnTimeoutSubject: BehaviorSubject<number> = new BehaviorSubject(0);

   public IsWindowResizingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
   public IsTurnOffAdminButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
   public IsButtonInLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

   //0 : not on timeoout
   //1 : on timeout
   //3 : on restart of the hubconnection
   public HubConnectionStatusSubject: BehaviorSubject<number> = new BehaviorSubject(0);
   public IsNetworkStatusOnlineSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
   public IsDatagridShouldbeRefreshedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

   public ActivityByMemberInsertedOrUpdatedSubject: BehaviorSubject<activityByMember> = new BehaviorSubject(null);
   public UserConnectedSubject: BehaviorSubject<user> = new BehaviorSubject(null);

   public IsComiteeMeetingCacheLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
   //TODELETE public IsMatchCacheLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
   public IsTrainingPlayerCacheLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

   public IsGlobalCacheLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true); //TODO replace it by !IsGlobalCacheLoadedSubject
   public IsGlobalCacheLoadedSubject: BehaviorSubject<number> = new BehaviorSubject(null);
   public IsPart1CacheLoadSubject: BehaviorSubject<number> = new BehaviorSubject(null);
   public IsPart2CacheLoadSubject: BehaviorSubject<number> = new BehaviorSubject(null);
   public IsPart3CacheLoadSubject: BehaviorSubject<number> = new BehaviorSubject(null);
   public IsPartTokenCacheLoadSubject: BehaviorSubject<number> = new BehaviorSubject(null);

   constructor() {
   }

   public ngOnDestroy(): void {
      this.IsButtonInLoadingSubject.unsubscribe();
      this.IsComiteeMeetingCacheLoadedSubject.unsubscribe();
      this.IsGlobalCacheLoadedSubject.unsubscribe();
      this.IsPart1CacheLoadSubject.unsubscribe();
      this.IsPart2CacheLoadSubject.unsubscribe();
      this.IsPart3CacheLoadSubject.unsubscribe();
      this.IsPartTokenCacheLoadSubject.unsubscribe();
      //TODELETE this.IsMatchCacheLoadedSubject.unsubscribe();
      this.MessageCacheSubject.unsubscribe();
      this.IsTrainingPlayerCacheLoadedSubject.unsubscribe();
      this.IsVersionCacheLoadedSubject.unsubscribe();
      this.IsGlobalCacheLoadingSubject.unsubscribe();
      this.IsTurnOffAdminButtonSubject.unsubscribe();
      this.ActivityByMemberInsertedOrUpdatedSubject.unsubscribe();
      this.IsWindowResizingSubject.unsubscribe();
      this.IsQueryOnTimeoutSubject.unsubscribe();
      this.HubConnectionStatusSubject.unsubscribe();
      this.IsNetworkStatusOnlineSubject.unsubscribe();
      this.IsDatagridShouldbeRefreshedSubject.unsubscribe();
      this.StepNumberCacheSubject.unsubscribe();
      this.UserConnectedSubject.unsubscribe();
   }
}