<mat-card>
   <mat-card-header>
      <mat-card-title>
         {{importDataTitleDisplay}} {{importChampionshipDisplay}}
      </mat-card-title>
   </mat-card-header>


   <mat-card>
      <mat-card-header>
         <mat-card-title>
            Les données de la Fvwb (export Excel)
         </mat-card-title>
      </mat-card-header>
      <mat-card-content>
         <div class="matcard matcardFloat">
            <app-panel>
               <span panel-title>Se loguer et sélectionner la saison</span>
               <div panel-content>
                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'" [text]="'Portail'"
                     [type]="EnumHRefType.HttpNewWindow" [href]="'https://www.portailfvwb.be'" alt="">
                  </app-buttonhref>

               </div>
            </app-panel>
         </div>
         <div class="matcard matcardFloat">
            <div>Cliquer sur les liens suivants et transférer les fichiers vià le sftp</div>
         </div>
         <div class="matcard matcardFloat">
            <app-panel>
               <span panel-title>Membres et entités</span>
               <div panel-content>
                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'"
                     [text]="'1.Membres (Liste+des+adhérents)'" [type]="EnumHRefType.HttpNewWindow"
                     [href]="'https://www.portailfvwb.be/Member/Export?sort=LastName-asc~FirstName-asc&filter=&group=&showInactive=false&isInDebtfalse&isSuspended=false'"
                     alt="">
                  </app-buttonhref>
                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'"
                     [text]="'2. Salles et terrains (Salles+et+terrains)'" [type]="EnumHRefType.HttpNewWindow"
                     [href]="'https://www.portailfvwb.be/TrainingPlace/Export?sort=Name-asc&filter=&group='" alt="">
                  </app-buttonhref>
               </div>
            </app-panel>
         </div>
         <div class="matcard matcardFloat">
            <app-panel>
               <span panel-title>Gestion admnistrative</span>
               <div panel-content>
                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'"
                     [text]="'3.Licences (Liste+des+licences)'" [type]="EnumHRefType.HttpNewWindow"
                     [href]="'https://www.portailfvwb.be/SportLicense/Export?sort=MemberName-asc~MemberFirstName-asc&filter=&group='"
                     alt="">
                  </app-buttonhref>


                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'"
                     [text]="'4.Affiliations'" [type]="EnumHRefType.HttpNewWindow"
                     [href]="' https://www.portailfvwb.be/Affiliates/Index'" alt="">
                  </app-buttonhref>

                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'"
                     [text]="'4.Affiliations (Liste+des+affiliations)'" [type]="EnumHRefType.HttpNewWindow"
                     [href]="'https://www.portailfvwb.be/Affiliates/Export?status=0&filter=&group='" alt="">
                  </app-buttonhref>
               </div>
            </app-panel>
         </div>
         <div class="matcard matcardFloat">
            <app-panel>
               <span panel-title>Gestion sportive</span>
               <div panel-content>
                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'"
                     [text]="'5.Equipes (Liste+des+équipes)'" [type]="EnumHRefType.HttpNewWindow"
                     [href]="'https://www.portailfvwb.be/Team/Export?sort=Name-asc~SeasonSport-asc&filter=&group='"
                     alt="">
                  </app-buttonhref>
                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'"
                     [text]="'6.Joueurs par équipe (Liste+des+joueurs+par+équipe)'" [type]="EnumHRefType.HttpNewWindow"
                     [href]="'https://www.portailfvwb.be/Player/Export?sort=LastName-asc~FirstName-asc~Club-asc&filter=&group=&inErrorOnly=false&searchTerm='"
                     alt="">
                  </app-buttonhref>
               </div>
            </app-panel>
         </div>
         <div class="matcard matcardFloat">
            <app-panel>
               <span panel-title>Fonctions</span>
               <div panel-content>
                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'"
                     [text]="'7.Encadrants'" [type]="EnumHRefType.HttpNewWindow"
                     [href]="'https://www.portailfvwb.be/TechnicalStaff/Index'" alt="">
                  </app-buttonhref>

                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'"
                     [text]="'7.Encadrants (Liste+des+encadrants)'" [type]="EnumHRefType.HttpNewWindow"
                     [href]="'https://www.portailfvwb.be/TechnicalStaff/Export?sort=LastName-asc~FirstName-asc~ClubName-asc&filter=&group='"
                     alt="">
                  </app-buttonhref>

                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'"
                     [text]="'8.Dirigeants de clubs'" [type]="EnumHRefType.HttpNewWindow"
                     [href]="'https://www.portailfvwb.be/AdminStaff/Index'" alt="">
                  </app-buttonhref>

                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'"
                     [text]="'8.Dirigeants de clubs (Liste+des+dirigeants+de+club)'" [type]="EnumHRefType.HttpNewWindow"
                     [href]="'https://www.portailfvwb.be/AdminStaff/Export?sort=LastName-asc~FirstName-asc~ClubName-asc&filter=&group='"
                     alt="">
                  </app-buttonhref>
               </div>
            </app-panel>
         </div>
         <div class="matcard matcardFloat">
            <app-panel>
               <span panel-title>Arbitrage</span>
               <div panel-content>
                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'"
                     [text]="'9.Frais d\'arbitrage (Liste+des+frais+d\'arbitrage)'" [type]="EnumHRefType.HttpNewWindow"
                     [href]="'https://www.portailfvwb.be/RefereeExpense/Export?isPaid=false&sort=&filter=&group='"
                     alt="">
                  </app-buttonhref>
               </div>
            </app-panel>
         </div>

         <div class="matcard matcardFloat">
            <app-panel>
               <span panel-title>Publication sur serveur</span>
               <div panel-content>
                  <input style="display:none" id="input-file-id" multiple type="file"
                     (change)="onFilesSelected($event)" />
                  <span class="buttons"><label class="nav-link navbarLink buttonize" for="input-file-id">
                        Choisir ...
                     </label>
                     &nbsp;{{selectedFiles.length}} fichier(s) sélectionné(s)&nbsp;
                     <button class="nav-link navbarLink buttonize" (click)="uploadFiles()">Upload</button>
                  </span>
               </div>
            </app-panel>
         </div>

         <div class="matcard matcardFloat">
            <app-panel>
               <span panel-title>Les jeunes</span>
               <div panel-content>
                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'"
                     [text]="exportFilesFromAchvbPortal" [type]="EnumHRefType.HttpNewWindow"
                     [href]="'https://www.achvb.be/'" alt="">
                  </app-buttonhref>
                  <!-- <app-button icon="input" [class]="'spanButtonSeparator'" [text]="'ImportYoung' | translate"
                     [isBackgroundOpaque]="true" (onClick)="fileInputYoung.click()"></app-button>
                  <input hidden (change)="onFileChange($event, true)" #fileInputYoung type="file" multiple="false"> -->
               </div>
            </app-panel>
         </div>

      </mat-card-content>
   </mat-card>



   <div class="alertShow">
      <app-alertShow></app-alertShow>
   </div>
</mat-card>