import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { AccountService } from 'src/sites/core/services/account.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';

@Component({
   selector: 'app-sidebar',
   templateUrl: './sidebar.component.html',
   styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {
   public SportsclubTitleDisplay: string = "";
   public activityTitleDisplay: string = "";
   public adminActivitiesListTitleDisplay: string = "";
   public adminChampionshipsListTitleDisplay: string = "";
   public adminMemberListTitleDisplay: string = "";
   public adminDevelopmentTitleDisplay: string = "";
   public adminHomeNewsListTitleDisplay: string = "";
   public adminBuildingTrainingClosingDayListTitleDisplay: string = "";
   public adminImportDataTitleDisplay: string = "";
   public adminListTitleDisplay: string = "";
   public managementListTitleDisplay: string = "";
   public adminMatchsListTitleDisplay: string = "";
   public adminSponsorsListTitleDisplay: string = "";
   public adminTeamsListTitleDisplay: string = "";
   public adminTrainingBuildingsListTitleDisplay: string = "";
   public adminUsersListTitleDisplay: string = "";
   public buildingTrainingTitleDisplay: string = "";
   public comiteeMeetingTitleDisplay: string = "";
   public comiteeMemberTitleDisplay: string = "";
   public comiteeTitleDisplay: string = "";
   public comiteeTodoTitleDisplay: string = "";
   public contactusTitleDisplay: string = "";
   public diaryTitleDisplay: string = "";
   public financesTitleDisplay: string = "";
   public homeTitleDisplay: string = "";
   public loginTitleDisplay: string = "";
   public matchTitleDisplay: string = "";
   public newsPaperTitleDisplay: string = "";
   public profileTitleDisplay: string = "";
   public rankingTitleDisplay: string = "";
   public samplePagesCollapsed = false;
   public sponsorTitleDisplay: string = "";
   public subjectCacheIsLoaded: number = null;
   public teamCompositionTitleDisplay: string = "";
   public teamTitleDisplay: string = "";
   public trainingInfoTitleDisplay: string = "";
   public trainingPlayerTitleDisplay: string = "";
   public trainingReportTitleDisplay: string = "";
   public trainingTitleDisplay: string = "";
   public uiAdminCollapsed = false;
   public uiManagementCollapsed = false;
   public uiComiteeCollapsed = false;
   public uiTeamCollapsed = false;
   public uiTrainingCollapsed = false;
   public userConnectedSubject = null;

   constructor(
      private renderer: Renderer2,
      private accountService: AccountService,
      private translateService: TranslateService,
      private frameworkService: FrameworkService,
      private accessRightsService: AccessRightsService,
      private storageService: StorageService,
      private subjectService: SubjectService
   ) {
      this.renderer.listen('window', 'click', (e: Event) => {
         if ((e.target as HTMLElement).id != "toggleMenu" && (e.target as HTMLElement).id != "subMenu") {
            if (document.querySelector('.sidebar-offcanvas') != null)
               document.querySelector('.sidebar-offcanvas').classList.remove('active');
         }
      });
   }

   public initialize() {
      this.subjectService.UserConnectedSubject.subscribe(x => { this.userConnectedSubject = x; this.translatePage(); });

      const body = document.querySelector('body');

      // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
      document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
         el.addEventListener('mouseover', function () {
            if (body.classList.contains('sidebar-icon-only')) {
               el.classList.add('hover-open');
            }
         });
         el.addEventListener('mouseout', function () {
            if (body.classList.contains('sidebar-icon-only')) {
               el.classList.remove('hover-open');
            }
         });
      });

      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });
   }

   public isComiteeAuthenticated() {
      if (this.storageService.getUserConnected() == null)
         return false;
      else
         return this.frameworkService.isUserInComitee(this.storageService.getUserConnected());
   }

   public isTodoComiteeMeetingAvailable() {
      if (this.storageService.getUserConnected() == null)
         return false;
      else
         return this.frameworkService.getMemberIsPresident(this.storageService.getUserConnected()) ||
            this.frameworkService.getMemberIsVicePresident(this.storageService.getUserConnected()) ||
            this.frameworkService.getMemberIsSecretary(this.storageService.getUserConnected()) ||
            this.frameworkService.getMemberIsTreasurer(this.storageService.getUserConnected()) ||
            this.frameworkService.getMemberIsManager(this.storageService.getUserConnected()) ||
            this.frameworkService.getMemberIsTeamDelegateManager(this.storageService.getUserConnected());
   }

   public isTrainingAvailable() {
      //Training only available if user is in a team
      if (this.storageService.getUserConnected() == null)
         return false;
      else {
         return this.storageService.getUserConnected().team?.teamCategory != "" ||
            this.frameworkService.isMemberCoachOnly(this.storageService.getUserConnected()) ||
            this.frameworkService.getMemberIsAssistantCoach(this.storageService.getUserConnected());
      }
   }

   public isUserAdministratorOrSupervisor() {
      return this.accessRightsService.isUserAdministratorOrSupervisor();
   }

   public isUserAuthenticated(): boolean {
      return this.userConnectedSubject != null;
   }

   public isSecretaryAuthenticated() {
      if (this.storageService.getUserConnected() == null)
         return false;
      else
         return this.frameworkService.isUserSecretary(this.storageService.getUserConnected());
   }

   public isUserCanManageMeeting() {
      if (this.storageService.getUserConnected() == null)
         return false;
      else
         return this.frameworkService.isUserCanManageMeeting(this.storageService.getUserConnected());
   }

   public ngAfterViewInit(): void {
   }

   public ngOnInit() {
      //Souscription à l'event lorsque la cache globale a terminé son chargement
      this.subjectService.IsGlobalCacheLoadedSubject.subscribe(value => { this.subjectCacheIsLoaded = value; if (value == 2) this.initialize(); });
   }

   public toggleMenu() {
      document.querySelector('.sidebar-offcanvas').classList.toggle('active');
   }

   public translatePage() {
      //LoginTitle
      this.translateService.get(['MenuLoginTitle'])
         .subscribe(translations => {
            this.loginTitleDisplay = translations['MenuLoginTitle'];
         });

      //HomeTitle
      this.translateService.get(['MenuHomeTitle'])
         .subscribe(translations => {
            this.homeTitleDisplay = translations['MenuHomeTitle'];
         });

      //TeamTitle
      this.translateService.get(['MenuTeamTitle'])
         .subscribe(translations => {
            this.teamTitleDisplay = translations['MenuTeamTitle'];
         });

      //TeamCompositionTitle
      this.translateService.get(['MenuTeamCompositionTitle'])
         .subscribe(translations => {
            this.teamCompositionTitleDisplay = translations['MenuTeamCompositionTitle'];
         });

      //ProfileTitle
      this.translateService.get(['MenuProfileTitle'])
         .subscribe(translations => {
            this.profileTitleDisplay = translations['MenuProfileTitle'];
         });

      //TrainingInfoTitle
      this.translateService.get(['MenuTrainingInfoTitle'])
         .subscribe(translations => {
            this.trainingInfoTitleDisplay = translations['MenuTrainingInfoTitle'];
         });

      //DiaryTitle
      this.translateService.get(['MenuDiaryTitle'])
         .subscribe(translations => {
            this.diaryTitleDisplay = translations['MenuDiaryTitle'];
         });

      //ActivityTitle
      this.translateService.get(['MenuActivityTitle'])
         .subscribe(translations => {
            this.activityTitleDisplay = translations['MenuActivityTitle'];
         });

      //SportsclubTitle
      this.translateService.get(['MenuSportsclubTitle'])
         .subscribe(translations => {
            this.SportsclubTitleDisplay = translations['MenuSportsclubTitle'];
         });

      //SponsorTitle
      this.translateService.get(['MenuSponsorTitle'])
         .subscribe(translations => {
            this.sponsorTitleDisplay = translations['MenuSponsorTitle'];
         });

      //ContactusTitle
      this.translateService.get(['MenuContactusTitle'])
         .subscribe(translations => {
            this.contactusTitleDisplay = translations['MenuContactusTitle'];
         });

      //Administration
      this.translateService.get(['MenuAdminTitle'])
         .subscribe(translations => {
            this.adminListTitleDisplay = translations['MenuAdminTitle'];
         });

      //Management
      this.translateService.get(['MenuManagementTitle'])
      .subscribe(translations => {
         this.managementListTitleDisplay = translations['MenuManagementTitle'];
      });

      //Activity
      this.translateService.get(['MenuActivityTitle'])
         .subscribe(translations => {
            this.activityTitleDisplay = translations['MenuActivityTitle'];
         });

      //BuildingTraining
      this.translateService.get(['MenuBuildingTrainingTitle'])
         .subscribe(translations => {
            this.buildingTrainingTitleDisplay = translations['MenuBuildingTrainingTitle'];
         });

      //ComiteeMeeting
      this.translateService.get(['MenuComiteeMeetingTitle'])
         .subscribe(translations => {
            this.comiteeMeetingTitleDisplay = translations['MenuComiteeMeetingTitle'];
         });

      //Comitee
      this.translateService.get(['MenuComiteeTitle'])
         .subscribe(translations => {
            this.comiteeTitleDisplay = translations['MenuComiteeTitle'];
         });

      //ComiteeMember
      this.translateService.get(['MenuComiteeMemberTitle'])
         .subscribe(translations => {
            this.comiteeMemberTitleDisplay = translations['MenuComiteeMemberTitle'];
         });

      //ComiteeTodo
      this.translateService.get(['MenuComiteeTodoTitle'])
         .subscribe(translations => {
            this.comiteeTodoTitleDisplay = translations['MenuComiteeTodoTitle'];
         });

      //Finances
      this.translateService.get(['MenuFinancesTitle'])
         .subscribe(translations => {
            this.financesTitleDisplay = translations['MenuFinancesTitle'];
         });

      //Match
      this.translateService.get(['MenuMatchTitle'])
         .subscribe(translations => {
            this.matchTitleDisplay = translations['MenuMatchTitle'];
         });

      //NewsPaper
      this.translateService.get(['MenuNewsPaperTitle'])
         .subscribe(translations => {
            this.newsPaperTitleDisplay = translations['MenuNewsPaperTitle'];
         });

      //Ranking
      this.translateService.get(['MenuRankingTitle'])
         .subscribe(translations => {
            this.rankingTitleDisplay = translations['MenuRankingTitle'];
         });

      //Training
      this.translateService.get(['MenuTrainingTitle'])
         .subscribe(translations => {
            this.trainingTitleDisplay = translations['MenuTrainingTitle']
         });

      //Training
      this.translateService.get(['MenuTrainingReportTitle'])
         .subscribe(translations => {
            this.trainingReportTitleDisplay = translations['MenuTrainingReportTitle'];
         });

      //TrainingPlayer
      this.translateService.get(['MenuTrainingPlayerTitle'])
         .subscribe(translations => {
            this.trainingPlayerTitleDisplay = translations['MenuTrainingPlayerTitle'];
         });

      //AdminDevelopmentTitle
      this.translateService.get(['MenuAdminDevelopmentTitle'])
         .subscribe(translations => {
            this.adminDevelopmentTitleDisplay = translations['MenuAdminDevelopmentTitle'];
         });

      //AdminChampionshipListTitle
      this.translateService.get(['MenuAdminChampionshipsListTitle'])
         .subscribe(translations => {
            this.adminChampionshipsListTitleDisplay = translations['MenuAdminChampionshipsListTitle'];
         });

      this.adminMemberListTitleDisplay = "Joueurs";

      //AdminHomeNewsListTitle
      this.translateService.get(['MenuAdminHomeNewsListTitle'])
         .subscribe(translations => {
            this.adminHomeNewsListTitleDisplay = translations['MenuAdminHomeNewsListTitle'];
         });

      //AdminBuildingTrainingClosingDayListTitle
      this.translateService.get(['MenuAdminBuildingTrainingClosingDayListTitle'])
         .subscribe(translations => {
            this.adminBuildingTrainingClosingDayListTitleDisplay = translations['MenuAdminBuildingTrainingClosingDayListTitle'];
         });

      //AdminTeamsListTitle
      this.translateService.get(['MenuAdminTeamsListTitle'])
         .subscribe(translations => {
            this.adminTeamsListTitleDisplay = translations['MenuAdminTeamsListTitle'];
         });

      //AdminActivitiesListTitle
      this.translateService.get(['MenuAdminActivitiesListTitle'])
         .subscribe(translations => {
            this.adminActivitiesListTitleDisplay = translations['MenuAdminActivitiesListTitle'];
         });

      //AdminSponsorsListTitle
      this.translateService.get(['MenuAdminSponsorsListTitle'])
         .subscribe(translations => {
            this.adminSponsorsListTitleDisplay = translations['MenuAdminSponsorsListTitle'];
         });

      //AdminUsersListTitle
      this.translateService.get(['MenuAdminUsersListTitle'])
         .subscribe(translations => {
            this.adminUsersListTitleDisplay = translations['MenuAdminUsersListTitle'];
         });

      //AdminTrainingBuildingsListTitle
      this.translateService.get(['MenuAdminBuildingTrainingListTitle'])
         .subscribe(translations => {
            this.adminTrainingBuildingsListTitleDisplay = translations['MenuAdminBuildingTrainingListTitle'];
         });

      //AdminMatchsListTitle
      this.translateService.get(['MenuAdminMatchsListTitle'])
         .subscribe(translations => {
            this.adminMatchsListTitleDisplay = translations['MenuAdminMatchsListTitle'];
         });

      //AdminImportDataTitle
      this.translateService.get(['MenuAdminImportDataTitle'])
         .subscribe(translations => {
            this.adminImportDataTitleDisplay = translations['MenuAdminImportDataTitle'];
         });
   }
}
