import { Component, HostListener, Inject, LOCALE_ID, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/sites/core/components/confirmDialog/confirmDialog.component';
import { enumActivityType, enumMatchDisplayStatus } from 'src/sites/core/enum/Enum';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { PrintService } from 'src/sites/core/services/print.service';
import { match } from '../../models/match';
import { AppointmentService } from '../../services/appointmentService';
import { CacheService } from '../../services/cache.service';
import { StorageService } from '../../authentication/storage.service';
import { encryptedValue } from '../../authentication/encryptedValue';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-diary',
   templateUrl: './diary.component.html',
   styleUrls: ['./diary.component.scss'],
   encapsulation: ViewEncapsulation.None
})

export class DiaryComponent implements OnInit {
  public allMatchs: match[] = [];
  public currentTeam = 99999;
  public displayedMatchColumns = [];
  public displayedMatchHeaderColumns = [];
  public filter = [];
  public form: FormGroup = new FormGroup({});
  public isDisplayOnAMobile: boolean = false;
  public isLoading: boolean = false;
  public isNetworkStatusOnline: boolean = false;
  public subjectCacheIsLoaded: number = null;
  public titleDescriptionDisplay: string = "";

  constructor(
      @Inject(LOCALE_ID) private locale: string,
      public sanitizer: DomSanitizer,
      private frameworkService: FrameworkService,
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private appointmentService: AppointmentService,
      private translateService: TranslateService,
      private storageService: StorageService,
      private printService: PrintService,
      public dialog: MatDialog
   ) {
   }

  @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

  public classForMatchOfClub(match: match, column: string) {
      if (column == "fromTeam")
         return "matCell matCellDiary matCell100Percent";
      else
         return "matCell matCellDiary matCellNoWrap";
   }

  public displayColumn(column: string, match: match) {
      let label = "";
      switch (column) {
         case "time":
            label = match.time == "00:00" ? "N.C" : match.time.replace(":", "H");
            if (match.fromTeam.includes(this.frameworkService.getByeStatus()) || match.awayTeam.includes(this.frameworkService.getByeStatus()))
               return "<span class='BYE'>" + label + "</span>";
            else
               return label;
         case "timeReserve":
            label = match.type == enumActivityType[enumActivityType.HAINAUT_CUP] || match.type == enumActivityType[enumActivityType.YOUNG] ? "" : (match.time == "00:00" ? "N.C" : this.frameworkService.getMatchTimeOfReserve(match));
            if (match.fromTeam.includes(this.frameworkService.getByeStatus()) || match.awayTeam.includes(this.frameworkService.getByeStatus()))
               return "<span class='BYE'>" + label + "</span>";
            else
               return label;
         case "timeOnSite":
            label = match.type == enumActivityType[enumActivityType.YOUNG] ? "" : (match.time == "00:00" ? "N.C" : this.frameworkService.getMatchTimeOnSite(match));
            if (match.fromTeam.includes(this.frameworkService.getByeStatus()) || match.awayTeam.includes(this.frameworkService.getByeStatus()))
               return "<span class='BYE'>" + label + "</span>";
            else
               return label;
         case "teamSysId":
            label = match.team.teamCategory;
            if (match.fromTeam.includes(this.frameworkService.getByeStatus()) || match.awayTeam.includes(this.frameworkService.getByeStatus()))
               return "<span class='BYE'>" + label + "</span>";
            else
               return label;
         case "day":
            if (match.fromTeam.includes(this.frameworkService.getByeStatus()) || match.awayTeam.includes(this.frameworkService.getByeStatus()))
               label = "<span class='BYE'>" + this.frameworkService.getDateWithDayFormatForScreen(match.day.toString()) + "</span>";
            else
               label = this.frameworkService.getDateWithDayFormatForScreen(match.day.toString());
            if (match.fromTeam.includes(this.frameworkService.getByeStatus()) || match.awayTeam.includes(this.frameworkService.getByeStatus()))
               return "<span class='BYE'>" + label + "</span>";
            else
               return label;
         case "fromTeam":
            if (match.fromTeam.includes(this.frameworkService.getByeStatus()) || match.awayTeam.includes(this.frameworkService.getByeStatus()))
               return "<span class='BYE'>BYE</span>";

            let temp: string = "";
            if (match.type != enumActivityType[enumActivityType.YOUNG]) {
               if (match.type == enumActivityType[enumActivityType.CHAMPIONSHIP] && match.number.startsWith("CL"))
                  temp += this.translateService.instant(match.type) + " " + this.translateService.instant("CHAMPIONSHIP_FINAL") + "<br>";
            }
            if (match.type == enumActivityType[enumActivityType.HAINAUT_CUP]) {
               temp += this.translateService.instant("HAINAUT_CUP") + "<br>";
            }

            let icon = match.isAtHome ? "<span class='material-icons matIconColorForPrint matIconVAB'>house</span> " : "";
            if (match.isAtHome != null) {
               if (match.isAtHome)
                  temp += "<span class='VAB'>" + icon + " <span style='white-space:normal'>" + match.awayTeam + "</span></span>";
               else
                  temp += " <span style='white-space:normal'>" + match.fromTeam + "</span>";
            }
            else
               temp += " <span style='white-space:normal'>" + match.fromTeam + " - " + match.awayTeam + "</span>";
            return temp;
         default:
            label = match[column];
            if (match.fromTeam.includes(this.frameworkService.getByeStatus()) || match.awayTeam.includes(this.frameworkService.getByeStatus()))
               return "<span class='BYE'>" + label + "</span>";
            else
               return label;
      }
   }

  public getColumnClass(column: string, match: match) {
      if (column ==  "teamSysId")
         return "fontSize " + match.team.class??"";
      else
         return "";
   }

  public getPreviousMatchDay(i) {
      if (i - 1 < 0)
         return "";
      else {
         return this.allMatchs[i - 1]?.day;
      }
   }

  public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

  public isUserAuthenticated(): boolean {
      return this.storageService.getUserConnected() != null;
   }

  public loadAllMatchOfTeams() {
      this.allMatchs = this.cacheService.getAllMatchs().filter(m => m.status == enumMatchDisplayStatus[enumMatchDisplayStatus.AJO] &&
         (m.fromTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()) ||
            m.awayTeam.toLowerCase().includes(this.frameworkService.getSportsClubName())) && this.frameworkService.getDateFormatYyyyMmDd(m.day) >= this.frameworkService.getDateFormatYyyyMmDd(this.frameworkService.getToday()))
         .sort((a, b) => this.frameworkService.getDateFormatYyyyMmDd(a.day) + a.team + a.type < this.frameworkService.getDateFormatYyyyMmDd(b.day) + b.team + b.type ? -1 : 1);
   }

  public ngOnInit() {
      this.form.addControl("allTeam", new FormControl(''));

      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
      //Souscription à l'event lorsque la cache globale a terminé son chargement
      this.subjectService.IsGlobalCacheLoadedSubject.subscribe(value => { this.subjectCacheIsLoaded = value; if (value == 2) this.initialize(); });

      this.getScreenSize();

      this.displayedMatchColumns = ['day', 'teamSysId', 'timeOnSite', 'timeReserve', 'time', 'fromTeam'];
      this.displayedMatchHeaderColumns = ['Date', 'Equipe', 'Sur p.', 'Rés.', '1ère', 'Rencontre'];
   }

  public onFilterChange(): void {
      this.currentTeam = this.form.get("allTeam").value;

      if (this.currentTeam == 99999)
         this.loadAllMatchOfTeams();
      else      
         this.allMatchs = this.cacheService.getAllMatchs().filter(m => m.teamSysId == this.currentTeam && m.status == enumMatchDisplayStatus[enumMatchDisplayStatus.AJO] &&
            (m.fromTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()) ||
               m.awayTeam.toLowerCase().includes(this.frameworkService.getSportsClubName())))
            .sort((a, b) => this.frameworkService.getDateFormatYyyyMmDd(a.day) + a.team + a.type < this.frameworkService.getDateFormatYyyyMmDd(b.day) + b.team + b.type ? -1 : 1);
   }

  public onPrintDiary() {
      this.printService.onDataReady();
      this.printService.printDocument('diary');
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

  public onSendToMail() {
      this.subjectService.IsButtonInLoadingSubject.next(false);

      let message1 = "Les rencontres sélectionnées vous seront envoyées par mail. Vous pourrez ensuite les importer dans votre agenda personnel. Si vous ne désirez que les rencontres de votre équipe, sélectionnez la préalablement.";
      if (this.currentTeam != 99999)
         message1 = "Les rencontres de l'équipe \"" + this.cacheService.getAllTeamsWithoutPicture().FirstOrDefault(i => i.sysId == this.currentTeam).teamDisplay + "\" vous seront enoyées par mail. Vous pourrez ensuite les importer dans votre agenda personnel. Si vous ne désirez que les rencontres de votre équipe, sélectionnez la préalablement.";

      const message2 = "Voulez-vous recevoir le mail ?";

      const dialogData = new ConfirmDialogModel("Calendrier des rencontres", message1, message2);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
         position: { top: '0px' },
         maxWidth: "100%",
         width: "100%",
         data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
         if (dialogResult) {
            let appServiceArray: Observable<any>[] = [];

            appServiceArray.push(this.appointmentService.sendMatchAppointment([this.currentTeam], this.storageService.getUserConnected().email));

            forkJoin(appServiceArray).subscribe(res => {
               this.subjectService.IsButtonInLoadingSubject.next(false);
               if (res[0])
                  this.frameworkService.displayAlert(true, (this.currentTeam != 99999) ? "Les rencontres de l'équipe " + this.cacheService.getAllTeamsWithoutPicture().FirstOrDefault(i => i.sysId == this.currentTeam).teamCategory + " ont été envoyées sur votre email " + this.storageService.getUserConnected().email :
                     "Les rencontres ont été envoyées sur votre email " + this.storageService.getUserConnected().email);
               else
                  this.frameworkService.displayAlert(false, (this.currentTeam != 99999) ? "Les rencontres de l'équipe n'ont pas pu être envoyées sur votre email " + this.storageService.getUserConnected().email + " en raison d'une erreur technique." :
                     "Les rencontres n'ont pas pu être envoyées sur votre email " + this.storageService.getUserConnected().email + " en raison d'une erreur technique.");
            });
         }
         else
            this.subjectService.IsButtonInLoadingSubject.next(false);
      });
   }

  public refresh() {
      this.isLoading = true;

      this.loadAllMatchOfTeams();

      this.filter = this.cacheService.getAllTeamsWithoutPicture().filter(i => i.division != "" && i.sysId > 1 && i.isRankingAvailable).OrderBy(i => i.teamDisplay).ToArray().map(x => (
         {
            value: x.sysId,
            viewValue: x.teamDisplay
         })
      );
      let all = {
         value: 99999,
         viewValue: "Tout"
      };
      this.filter.push(all);

      this.form.get("allTeam").setValue(99999);
      this.isLoading = false;
   }

  public translatePage() {
   }
}