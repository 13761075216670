<div>
   <div class="matcard">
      <mat-card>

         <mat-card-header>
            <mat-card-title>
               <div class="flexSpaceBetween" style="width: 98%">
                  <div>
                     <span class="material-icons matcard-title-icon" style="margin-left:auto">more_horiz</span>
                     {{mainTitle | translate}}
                  </div>
                  &nbsp;
                  <div>
                     <app-button [icon]="getIconHtml('Add')" [class]="'spanButtonSeparator'" [isBackgroundOpaque]="true" [isAlwaysEnabled]="true" isIconShowOnlyOnMobile="true" [text]="formAction[formAction.Add] |
                     translate" [visible]="getVisibilityFromAccessRights(formAction.Add)" (click)="menuClick($event, formAction.Add, null)"></app-button>
                  </div>
               </div>
            </mat-card-title>
         </mat-card-header>

         <mat-card-content>
            <div [hidden]="isLoaded" style="display: flex;justify-content: center;">
               <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
            </div>

            <div [hidden]="!isLoaded" class="h-100">
               <div class="mat-elevation-z8">

                  <form [formGroup]="form">
                     <mat-card [hidden]="allFilters == null" style="margin-left:0px;margin-right:0px">
                        <mat-card-header>
                           <mat-card-title>{{databaseFiltersNameDisplay}}</mat-card-title>
                           <mat-card-subtitle></mat-card-subtitle>
                        </mat-card-header>

                        <mat-card-content>
                           <span *ngFor="let key of allFilters">
                              {{datagridParam.filters.get(key).labelText}}
                              <mat-select (selectionChange)="onFilterChange()" formControlName="all{{key}}"
                                 style="width:90px">
                                 <mat-option *ngFor="let item of datagridParam.filters.get(key).dropdownLists"
                                    [value]="item.value">
                                    <ng-container>{{item.viewValue}}</ng-container>
                                 </mat-option>
                              </mat-select>
                           </span>
                        </mat-card-content>
                     </mat-card>
                  </form>


                  <table mat-table [dataSource]="mainDataSource" matSort multiTemplateDataRows
                     [hidden]="mainDataSourceData.length == 0">

                     <!-- Expanded Content -->
                     <ng-container matColumnDef="expandedDetail">
                        <ng-container *matCellDef="let elementRowItems">
                           <ng-container *ngIf="elementRowItems == expandedElement">
                              <td mat-cell class='m-card-sub-title' [attr.colspan]="listOfColumnHeaderName.length"
                                 style="padding-left: 0px !important; padding-right: 0px !important">
                                 <div class="element-detail-group"
                                    [@detailExpand]="elementRowItems == expandedElement ? 'expanded' : 'collapsed'">
                                    <span *ngFor="let columnDetail of listOfColumnMetadataForDetailExpanded"
                                       [class]="getElementDetailClass(elementRowItems[columnDetail[0]], elementRowItems[columnDetail[0]], getControlType(columnDetail[1]))">

                                       <span *ngIf="elementRowItems[columnDetail[0]] != null">{{ columnDetail[0] |
                                          translate
                                          }} : </span>

                                       <span
                                          *ngIf="getControlType(columnDetail[1]) == 'Text'">{{elementRowItems[columnDetail[0]]}}</span>


                                       <span
                                          *ngIf="(columnDetail[0] != 'gender' && (getControlType(columnDetail[1]) == 'Bool2States' || getControlType(columnDetail[1]) == 'Bool3States')) && elementRowItems[columnDetail[0]]"><img
                                             src="../../../../sites/core/images/system/checked.png"
                                             class="iconImageDisplaySize" alt="Checked"></span>
                                       <span
                                          *ngIf="(columnDetail[0] != 'gender' && (getControlType(columnDetail[1]) == 'Bool2States' || getControlType(columnDetail[1]) == 'Bool3States')) && !elementRowItems[columnDetail[0]]"><img
                                             src="../../../../sites/core/images/system/nochecked.png"
                                             class="iconImageDisplaySize" alt="Unchecked"></span>

                                       <span
                                          *ngIf="(columnDetail[0] == 'gender' && getControlType(columnDetail[1]) == 'Bool3States') && elementRowItems[columnDetail[0]]"><img
                                             src="../../../../sites/{{site()}}/images/account/malesex.png"
                                             class="iconImageDisplaySize" alt="Male"></span>
                                       <span
                                          *ngIf="(columnDetail[0] == 'gender' && getControlType(columnDetail[1]) == 'Bool3States') && !elementRowItems[columnDetail[0]]"><img
                                             src="../../../../sites/{{site()}}/images/account/femalesex.png"
                                             class="iconImageDisplaySize" alt="Female"></span>

                                       <span
                                          *ngIf="getControlType(columnDetail[1]) == 'DateOnly'">{{elementRowItems[columnDetail[0]]
                                          |
                                          date:'dd/MM/yyyy'}}</span> <!-- todo change date format for en -->
                                       <!-- TODO (Datagrid) : DateTime -->
                                       <span
                                          *ngIf="getControlType(columnDetail[1]) == 'Decimal'">{{elementRowItems[columnDetail[0]]}}</span>
                                       <span
                                          *ngIf="getControlType(columnDetail[1]) == 'Dropdown'">{{getDropdownValue(elementRowItems,
                                          columnDetail[0], columnDetail[1])}}</span>
                                       <span
                                          *ngIf="getControlType(columnDetail[1]) == 'Email'">{{elementRowItems[columnDetail[0]]}}</span>
                                       <span
                                          *ngIf="getControlType(columnDetail[1]) == 'Integer'">{{elementRowItems[columnDetail[0]]}}</span>
                                       <span
                                          *ngIf="getControlType(columnDetail[1]) == 'LetterLowerCaseOnly'">{{elementRowItems[columnDetail[0]]}}</span>
                                       <span
                                          *ngIf="getControlType(columnDetail[1]) == 'LetterUpperCaseOnly'">{{elementRowItems[columnDetail[0]]}}</span>
                                       <span
                                          *ngIf="getControlType(columnDetail[1]) == 'Letter1CharUpperCase'">{{elementRowItems[columnDetail[0]]}}</span>
                                       <span *ngIf="getControlType(columnDetail[1]) == 'Password'">*****</span>
                                       <!-- todo if admin show password ??? -->
                                       <span
                                          *ngIf="getControlType(columnDetail[1]) == 'PhoneOffice'">{{elementRowItems[columnDetail[0]]}}</span>
                                       <span
                                          *ngIf="getControlType(columnDetail[1]) == 'PhoneMobile'">{{elementRowItems[columnDetail[0]]}}</span>
                                       <img
                                          *ngIf="getControlType(columnDetail[1]) == 'Picture' && elementRowItems[columnDetail[0]]"
                                          alt="Picture" [src]="getImgURL(elementRowItems[columnDetail[0]])"
                                          loading="lazy" class="iconImageDisplaySizeForDetail">
                                       <span
                                          *ngIf="getControlType(columnDetail[1]) == 'Textarea'">{{elementRowItems[columnDetail[0]]}}</span>
                                       <span
                                          *ngIf="getControlType(columnDetail[1]) == 'TextAllToUpperCase'">{{elementRowItems[columnDetail[0]]}}</span>
                                       <span
                                          *ngIf="getControlType(columnDetail[1]) == 'TextAllToLowerCase'">{{elementRowItems[columnDetail[0]]}}</span>
                                       <span
                                          *ngIf="getControlType(columnDetail[1]) == 'Text1CharUpperCase'">{{elementRowItems[columnDetail[0]]}}</span>
                                       <span
                                          *ngIf="getControlType(columnDetail[1]) == 'TimeOnly'">{{elementRowItems[columnDetail[0]]}}</span>
                                    </span>
                                 </div>
                              </td>
                           </ng-container>
                        </ng-container>
                     </ng-container>
                     <!-- Expanded Content -->

                     <ng-container *ngFor="let column of listOfColumnHeader; let currentRow = index;">
                        <ng-container matColumnDef="{{column[0]}}">

                           <!-- if NOT ON MOBILE ==> Display header -->
                           <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column[0] | translate}}</th>

                           <!-- ROWS (if MOBILE OR NOT)-->
                           <ng-container *matCellDef="let rowItems">
                              <!-- if ON MOBILE ==> Display values NOT null -->
                              <td mat-cell [class]='getMainHeaderClass(currentRow)' data-label='Form type'
                                 *ngIf="ifRow(rowItems[column[0]], getControlType(column[1]))">
                                 <ng-container>
                                    <div class="divsOnAEndOfRowOnMobile">
                                       <div [class]="getHeaderClass()" *ngIf="isDisplayOnAMobile">{{column[0] |
                                          translate}} :&nbsp;</div>

                                       <div
                                          *ngIf="(column[0] != 'gender' && (getControlType(column[1]) == 'Bool2States' || getControlType(column[1]) == 'Bool3States')) && rowItems[column[0]]">
                                          <img src="../../../../sites/core/images/system/checked.png"
                                             class="iconImageDisplaySize" alt="Checked">
                                       </div>
                                       <div
                                          *ngIf="(column[0] != 'gender' && (getControlType(column[1]) == 'Bool2States' || getControlType(column[1]) == 'Bool3States')) && !rowItems[column[0]]">
                                          <img src="../../../../sites/core/images/system/nochecked.png"
                                             class="iconImageDisplaySize" alt="Unchecked">
                                       </div>

                                       <div
                                          *ngIf="(column[0] == 'gender' && getControlType(column[1]) == 'Bool3States') && rowItems[column[0]]">
                                          <img src="../../../../sites/{{site()}}/images/account/malesex.png"
                                             class="iconImageDisplaySize" alt="Male">
                                       </div>
                                       <div
                                          *ngIf="(column[0] == 'gender' && getControlType(column[1]) == 'Bool3States') && !rowItems[column[0]]">
                                          <img src="../../../../sites/{{site()}}/images/account/femalesex.png"
                                             class="iconImageDisplaySize" alt="Female">
                                       </div>

                                       <div *ngIf="getControlType(column[1]) == 'DateOnly'">{{rowItems[column[0]] |
                                          date:'dd/MM/yyyy'}}</div> <!-- todo change date format for en -->
                                       <!-- TODO (Datagrid) : DateTime -->
                                       <div *ngIf="getControlType(column[1]) == 'Decimal'">{{rowItems[column[0]]}}</div>
                                       <div *ngIf="getControlType(column[1]) == 'Dropdown'">{{getDropdownValue(rowItems,
                                          column[0], column[1])}}</div>
                                       <div *ngIf="getControlType(column[1]) == 'Email'">{{rowItems[column[0]]}}</div>
                                       <div *ngIf="getControlType(column[1]) == 'Integer'">{{rowItems[column[0]]}}</div>
                                       <div *ngIf="getControlType(column[1]) == 'LetterLowerCaseOnly'">
                                          {{rowItems[column[0]]}}
                                       </div>
                                       <div *ngIf="getControlType(column[1]) == 'LetterUpperCaseOnly'">
                                          {{rowItems[column[0]]}}
                                       </div>
                                       <div *ngIf="getControlType(column[1]) == 'Letter1CharUpperCase'">
                                          {{rowItems[column[0]]}}</div>
                                       <div *ngIf="getControlType(column[1]) == 'Password'">*****</div>
                                       <!-- todo if admin show password ??? -->
                                       <div *ngIf="getControlType(column[1]) == 'PhoneOffice'">{{rowItems[column[0]]}}
                                       </div>
                                       <div *ngIf="getControlType(column[1]) == 'PhoneMobile'">{{rowItems[column[0]]}}
                                       </div>

                                       <div *ngIf="getControlType(column[1]) == 'Picture' && rowItems[column[0]] != null && rowItems[column[0]] != ''">
                                          <img alt="Picture"
                                             [src]="getImgURL(rowItems[column[0]])" loading="lazy" width="15"
                                             height="15" >
                                       </div>
                                       <div *ngIf="getControlType(column[1]) == 'Picture' && (rowItems[column[0]] == null || rowItems[column[0]] == '')">
                                          &nbsp;
                                       </div>

                                       <div *ngIf="getControlType(column[1]) == 'Text'  && rowItems[column[0]] != null && rowItems[column[0]] != ''">{{rowItems[column[0]]}}</div>
                                       <div *ngIf="getControlType(column[1]) == 'Text' && (rowItems[column[0]] == null || rowItems[column[0]] == '')"></div>

                                       <div *ngIf="getControlType(column[1]) == 'Html'"
                                          [innerHTML]="getHtml(rowItems[column[0]])"></div>

                                       <div *ngIf="getControlType(column[1]) == 'Textarea'">{{rowItems[column[0]]}}
                                       </div>
                                       <div *ngIf="getControlType(column[1]) == 'TextAllToUpperCase'">
                                          {{rowItems[column[0]]}}
                                       </div>
                                       <div *ngIf="getControlType(column[1]) == 'TextAllToLowerCase'">
                                          {{rowItems[column[0]]}}
                                       </div>
                                       <div *ngIf="getControlType(column[1]) == 'Text1CharUpperCase'">
                                          {{rowItems[column[0]]}}
                                       </div>
                                       <div *ngIf="getControlType(column[1]) == 'TimeOnly' && rowItems[column[0]] != null">{{rowItems[column[0]].replace(":","H")}}
                                       </div>
                                       <div *ngIf="getControlType(column[1]) == 'TimeOnly' && rowItems[column[0]] == null">
                                       </div>

                                       <div class="divMenuPushRightOnMobile"
                                          *ngIf="getLastColumnBasedOnAccessRights(column[0], rowItems, listOfColumnHeader, isDisplayOnAMobile) && isDisplayOnAMobile">
                                          <!-- Display menu icon if on mobile -->
                                          <mat-icon class="icon-xxsmall" [matMenuTriggerFor]="subMenu"
                                             *ngIf="getVisibilityFromAccessRights(formAction.All)">menu</mat-icon>
                                          <!-- Display expand icon if on mobile -->
                                          <mat-icon class="icon-xxsmall"
                                             (click)="expandedElement = expandedElement === rowItems ? null : rowItems;"
                                             *ngIf="getIsExpandedRowExist()">unfold_more</mat-icon>
                                          <!-- TODO (Datagrid) : Display expanded info on grid if on mobile DOESN'T SHOW DETAIL (seems that all table is not display -> css rule ?)-->

                                          <mat-menu #subMenu="matMenu">
                                             <button mat-menu-item
                                                *ngIf="getVisibilityFromAccessRights(formAction.Edit)"
                                                (click)="menuClick($event, formAction.Edit, rowItems)">
                                                <mat-icon>{{getIconHtml("Edit")}}</mat-icon>
                                                <span>{{formAction[formAction.Edit] | translate}}</span>
                                             </button>
                                             <button mat-menu-item
                                                *ngIf="getVisibilityFromAccessRights(formAction.Delete)"
                                                (click)="menuClick($event, formAction.Delete, rowItems)">
                                                <mat-icon>{{getIconHtml("Delete")}}</mat-icon>
                                                <span>{{formAction[formAction.Delete] | translate}}</span>
                                             </button>
                                             <button mat-menu-item
                                                *ngIf="getVisibilityFromAccessRights(formAction.Archive) && rowItems['sysIsActive']"
                                                (click)="menuClick($event, formAction.Archive, rowItems)">
                                                <mat-icon>{{getIconHtml("Archive")}}</mat-icon>
                                                <span>{{formAction[formAction.Archive] | translate}}</span>
                                             </button>
                                             <button mat-menu-item
                                                *ngIf="getVisibilityFromAccessRights(formAction.Unarchive) && !rowItems['sysIsActive']"
                                                (click)="menuClick($event, formAction.Unarchive, rowItems)">
                                                <mat-icon>{{getIconHtml("Unarchive")}}</mat-icon>
                                                <span>{{formAction[formAction.Unarchive] | translate}}</span>
                                             </button>
                                          </mat-menu>
                                       </div>

                                       <!-- Display menu on last column if NOT on mobile -->
                                       <div
                                          [class]="(getLastColumnBasedOnAccessRights(column[0], rowItems, listOfColumnHeader, isDisplayOnAMobile)) ? 'divMenuPushRight' : 'jdax-datagrid-cellWithoutValue divMenuPushRight'"
                                          *ngIf="getLastColumnBasedOnAccessRights(column[0], rowItems, listOfColumnHeader, isDisplayOnAMobile) && !isDisplayOnAMobile">
                                          <!-- Display menu icon if NOT on mobile -->
                                          <mat-icon class="icon-xxsmall" [matMenuTriggerFor]="subMenu"
                                             *ngIf="getVisibilityFromAccessRights(formAction.All)">menu</mat-icon>
                                          <!-- Display expand icon if NOT on mobile -->
                                          <mat-icon class="icon-xxsmall"
                                             (click)="expandedElement = expandedElement === rowItems ? null : rowItems;"
                                             *ngIf="getIsExpandedRowExist() && getVisibilityFromAccessRights(formAction.All)">
                                             unfold_more</mat-icon>

                                          <mat-menu #subMenu="matMenu">
                                             <button mat-menu-item
                                                *ngIf="getVisibilityFromAccessRights(formAction.Edit)"
                                                (click)="menuClick($event, formAction.Edit, rowItems)">
                                                <mat-icon>{{getIconHtml("Edit")}}</mat-icon>
                                                <span>{{formAction[formAction.Edit] | translate}}</span>
                                             </button>
                                             <button mat-menu-item
                                                *ngIf="getVisibilityFromAccessRights(formAction.Delete)"
                                                (click)="menuClick($event, formAction.Delete, rowItems)">
                                                <mat-icon>{{getIconHtml("Delete")}}</mat-icon>
                                                <span>{{formAction[formAction.Delete] | translate}}</span>
                                             </button>
                                             <button mat-menu-item
                                                *ngIf="getVisibilityFromAccessRights(formAction.Archive) && rowItems['sysIsActive']"
                                                (click)="menuClick($event, formAction.Archive, rowItems)">
                                                <mat-icon>{{getIconHtml("Archive")}}</mat-icon>
                                                <span>{{formAction[formAction.Archive] | translate}}</span>
                                             </button>
                                             <button mat-menu-item
                                                *ngIf="getVisibilityFromAccessRights(formAction.Unarchive) && !rowItems['sysIsActive']"
                                                (click)="menuClick($event, formAction.Unarchive, rowItems)">
                                                <mat-icon>{{getIconHtml("Unarchive")}}</mat-icon>
                                                <span>{{formAction[formAction.Unarchive] | translate}}</span>
                                             </button>
                                          </mat-menu>
                                       </div>
                                    </div>
                                 </ng-container>
                              </td>
                              <!-- if ON MOBILE <== -->

                              <!-- if ON MOBILE ==> Display values null -->
                              <td class="jdax-datagrid-highlight-row" style="padding:0px; margin: 0px;"
                                 *ngIf="rowItems[column[0]] == null && isDisplayOnAMobile">
                              </td>
                              <!-- if ON MOBILE <== -->

                              <!-- if NOT ON MOBILE ==> Display MENU on last column -->
                              
                           </ng-container>
                        </ng-container>
                     </ng-container>

                     <!-- Display the header if NOT ON MOBILE -->
                     <tr mat-header-row class="jdax-datagrid-header-row" *matHeaderRowDef="listOfColumnHeaderName"></tr>

                     <tr mat-row class="jdax-datagrid-header-row"
                        *matRowDef="let element; columns: listOfColumnHeaderName;"
                        [ngClass]="{ 'selected': element === expandedElement}"
                        (dblclick)="expandedElement = expandedElement === element ? null : element;">
                     </tr>

                     <!-- Display the expand row -->
                     <ng-container>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
                           [class]="getDetailRowClass(expandedElement)"></tr>
                     </ng-container>

                  </table>
               </div>
            </div>
            <mat-paginator [hidden]="mainDataSourceData.length <= 1" style="background-color: transparent;" [pageSize]="pageSize"
               [pageSizeOptions]="paginatorItemsCount"></mat-paginator>
         </mat-card-content>

      </mat-card>
   </div>
</div>