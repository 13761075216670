//This file is autogenerated by C# GeneratedModels

import { auditFields } from "src/sites/core/models/system/auditFields";
import { metadataModelBase } from "src/sites/core/models/system/metadataModelBase";

@Reflect.metadata('activityByMemberBase', 'activityByMemberBase')
export class activityByMemberBase extends auditFields {
   @Reflect.metadata('userSysId',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   userSysId: number = 0;

   @Reflect.metadata('activitySysId',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   activitySysId: number = 0;

   @Reflect.metadata('personSeniorCount',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   personSeniorCount: number = 0;

   @Reflect.metadata('personChildCount',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   personChildCount: number = 0;

   @Reflect.metadata('isPresent',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   isPresent?: number = null;

   @Reflect.metadata('particularDemand',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   particularDemand: string = "";
}