import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { Observable, forkJoin, timer } from 'rxjs';
import { enumActivityType, enumHRefType, enumMatchDisplayStatus } from 'src/sites/core/enum/Enum';
import { datagridFilterDetail } from 'src/sites/core/models/system/datagridFilter';
import { datagridParam } from 'src/sites/core/models/system/datagridParam';
import { workgroup } from 'src/sites/core/models/workgroup/workgroup';
import { AlertShowService } from 'src/sites/core/services/alertShow.service';
import { DatagridService } from 'src/sites/core/services/datagrid.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { MatchService } from 'src/sites/vabourlettis/services/match.service';
import * as XLSX from 'xlsx';
import { match } from '../../models/match';
import { team } from '../../models/team';
import { CacheService } from '../../services/cache.service';
import { RankingService } from '../../services/ranking.service';
import { encryptedValue } from '../../authentication/encryptedValue';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { SystemService } from 'src/sites/core/services/system.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@microsoft/signalr';
import { HttpHeaders } from '@angular/common/http';

type AOA = any[][];

@Component({
   selector: 'app-adminImportData',
   templateUrl: './adminImportData.component.html',
   styleUrls: ['./adminImportData.component.scss'],
   providers: [DatePipe]
})

export class AdminImportDataComponent implements OnInit {
   @ViewChild('fileInputChampionship') public fileInputChampionship: ElementRef;
   @ViewChild('fileInputMainRanking') public fileInputMainRanking: ElementRef;
   @ViewChild('fileInputNotChampionship') public fileInputNotChampionship: ElementRef;
   @ViewChild('fileInputReferee') public fileInputReferee: ElementRef;
   @ViewChild('fileInputReserveRanking') public fileInputReserveRanking: ElementRef;
   @ViewChild('fileInputYoung') public fileInputYoung: ElementRef;

   public allMatchsCurrent: match[];
   public allMatchsCurrentCS;
   public allMatchsPreviousCS;
   public allTeamsCurrent: team[];
   public allWorkgroups: workgroup[];
   public championshipNameDisplay: string = "";
   public currentChampionshipSysId = 0;
   public data_import: AOA;
   public datagridParam: datagridParam<match>;
   public dialog: any;
   public exportFilesFromAchvbPortal: string = "";
   public exportFilesFromFvwbPortal: string = "";
   public importChampionshipDisplay: string = "";
   public importDataDescriptionDisplay: string = "";
   public importDataTitleDisplay: string = "";
   public isLoading: boolean = false;
   public previousChampionshipSysId = 0;
   public subjectCacheIsLoaded: number = null;
   public EnumHRefType = enumHRefType;
   public selectedFiles: File[] = [];

   constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private alertShowService: AlertShowService,
      private matchService: MatchService,
      private systemService: SystemService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      private encryptionService: EncryptionService) {

      this.subjectService.IsDatagridShouldbeRefreshedSubject.subscribe(r => {
         if (this.isLoading) this.refresh(true);
      });
   }

   public getDateFromYoungExcel(date: string, turn: string): Date {
      if (date != null) {
         let temp1 = date.split(' ');
         if (temp1.length == 2) {
            let temp2 = temp1[1].split('/');
            return new Date(+ temp2[1] + "/" + temp2[0] + "/" + ((turn == "1") ? this.cacheService.getCurrentChampionship().yearStarting : this.cacheService.getCurrentChampionship().yearEnding));
         }
         else
            return null;
      }
      else
         return null;
   }

   public getTeamCategoryFromExcelFieldForCoupe(team1: string, team2: string): number {
      let found = 0;
      let teams = team1 + team2;
      for (let cpt = 0; cpt < this.allTeamsCurrent.length; cpt++) {
         if (teams.trim() != "" && this.allTeamsCurrent[cpt].teamCategory != "" && teams.toUpperCase().includes("(" + this.getTeamCategoryWithoutCategory(this.allTeamsCurrent[cpt].teamCategory.toUpperCase()) + ")")) {
            found = this.allTeamsCurrent[cpt].sysId;
            return found;
         }
      }
      return found;
   }

   public getTeamCategoryFromExcelFieldForYoung(number: string): number {
      let found = 0;
      for (let cpt = 0; cpt < this.allTeamsCurrent.length; cpt++) {
         if (number.trim() != "" && this.allTeamsCurrent[cpt].teamCategory != "" && number.toUpperCase().includes(this.allTeamsCurrent[cpt].teamCategory.toUpperCase())) {
            found = this.allTeamsCurrent[cpt].sysId;
         }
      }
      return found;
   }

   public getTeamCategoryWithoutCategory(category: string): string {
      //P3BM -> P3M
      return this.frameworkService.left(category, 2) + this.frameworkService.right(category, 1);
   }

   public uploadFiles(): void {
      if (this.selectedFiles.length > 0) {
         const formData: FormData = new FormData();
   
         for (const file of this.selectedFiles) {
           formData.append('files', file, file.name);
         }   

         let appServiceArray: Observable<any>[] = [];
         appServiceArray.push(this.systemService.upload(formData));
         forkJoin(appServiceArray).subscribe(res => {
            if (res[0]) {
               this.frameworkService.displayAlert(true, "Les fichiers ont bien été transférés.");
            }
            else {
               this.frameworkService.displayAlert(false, "Les fichiers n'ont pas pu être transférés.");
            }
         });
      }
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh(false);
   }

   public ngOnInit() {
      //Souscription à l'event lorsque la cache globale a terminé son chargement
      this.subjectService.IsGlobalCacheLoadedSubject.subscribe(value => { this.subjectCacheIsLoaded = value; if (value == 2) this.initialize(); });
   }

   public onFilesSelected(event: any): void {
      this.selectedFiles = event.target.files as File[];
   }

   public onExportAll() {
      window.addEventListener('message', function (event) {
         // Check the origin of the message
         if (event.origin === "https://www.portailfvwb.be/Member/Export?sort=LastName-asc~FirstName-asc&filter=&group=&showInactive=false&isInDebtfalse&isSuspended=false") {
            // Do something with the received message
            console.log('Message received in the second window:', event.data);

            // Close the window
            window.close();
         }
      });

      const win = window.open("https://www.portailfvwb.be/Member/Export?sort=LastName-asc~FirstName-asc&filter=&group=&showInactive=false&isInDebtfalse&isSuspended=false", '1');


      setTimeout(() => {
         const win = window.open("https://www.portailfvwb.be/TrainingPlace/Export?sort=Name-asc&filter=&group=", '2');

      }, 1000);

      //  setTimeout(() => {
      //    alert("2");
      //    window.open("https://www.portailfvwb.be/SportLicense/Export?sort=MemberName-asc~MemberFirstName-asc&filter=&group=", '3');
      //  }, 1000);

      //  setTimeout(() => {
      //    alert("2");
      //    window.open("https://www.portailfvwb.be/Affiliates/Export?status=0&filter=&group=", '4');
      //  }, 1000);

      //  setTimeout(() => {
      //    alert("2");
      //    window.open("https://www.portailfvwb.be/Team/Export?sort=Name-asc~SeasonSport-asc&filter=&group=", '5');
      //  }, 1000);

      //  setTimeout(() => {
      //    alert("2");
      //    window.open("https://www.portailfvwb.be/Player/Export?sort=LastName-asc~FirstName-asc~Club-asc&filter=&group=&inErrorOnly=false&searchTerm=", '6');
      //  }, 1000);

      //  setTimeout(() => {
      //    alert("2");
      //    window.open("https://www.portailfvwb.be/TechnicalStaff/Export?sort=LastName-asc~FirstName-asc~ClubName-asc&filter=&group=", '7');
      //  }, 1000);

      //  setTimeout(() => {
      //    alert("2");
      //    window.open("https://www.portailfvwb.be/AdminStaff/Export?sort=LastName-asc~FirstName-asc~ClubName-asc&filter=&group=", '8');
      //  }, 1000);

      //  setTimeout(() => {
      //    alert("2");
      //    window.open("https://www.portailfvwb.be/RefereeExpense/Export?isPaid=false&sort=&filter=&group=", '9');
      //  }, 1000);
   }


   // public onFileChange(evt: any, importType: boolean) {
   //    const target: DataTransfer = <DataTransfer>(evt.target);
   //    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

   //    const reader: FileReader = new FileReader();

   //    //-> Import des matchs
   //    reader.onload = (e: any) => {
   //       const wb: XLSX.WorkBook = XLSX.read(e.target.result, { type: 'binary', cellDates: true, cellNF: false, cellText: false });

   //       const ws_data: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[0]];

   //       this.data_import = <AOA>(XLSX.utils.sheet_to_json(ws_data, { header: 1, raw: false, dateNF: 'dd-mm-yyyy hh:mm' }));

   //       let newMatchToInsert = [];
   //       let teamSysId = 0;
   //       let matchName: string = "";
   //       let turn = "1";

   //       let type = "HAINAUT_CUP";
   //       if (this.data_import[1][2] == "JEUNE") {
   //          type = enumActivityType[enumActivityType.YOUNG];  // Jeunes
   //          matchName = this.data_import[1][3] ?? ""; //CAD-G-HT
   //          teamSysId = this.getTeamCategoryFromExcelFieldForYoung(matchName);
   //          turn = this.data_import[1][1] ?? "";
   //       }

   //       this.data_import.forEach(item => {
   //          if ((item[0] + "").trim() != "Province" && (item[0] + "").trim() != "Date1" && (item[0] + "").trim() != "INFO") {
   //             let newMatch1 = new match();
   //             let newMatch2 = new match();
   //             let isMatch1MustBeAdded = false;
   //             let isMatch2MustBeAdded = false;

   //             if (type == enumActivityType[enumActivityType.HAINAUT_CUP])
   //                newMatch1.teamSysId = this.getTeamCategoryFromExcelFieldForCoupe(item[9] ?? "", item[10] ?? "");
   //             if (type == enumActivityType[enumActivityType.YOUNG])
   //                newMatch1.teamSysId = teamSysId;

   //             if (newMatch1.teamSysId != 0) {
   //                switch (type) {
   //                   case enumActivityType[enumActivityType.HAINAUT_CUP]:
   //                      {
   //                         newMatch1.name = item[1] ?? "";

   //                         newMatch1.day = this.frameworkService.getDateOnlyFormatDatabaseFromJJMMAAAAADate(item[7]);
   //                         newMatch1.time = this.frameworkService.getTimeFormatExcel(item[8]);
   //                         newMatch1.number = this.frameworkService.getHainautCupShortcut(item[1]) + "/" + item[6] ?? "";
   //                         newMatch1.fromTeam = this.frameworkService.removeChars(item[9] ?? "", ["."]).toUpperCase();
   //                         newMatch1.awayTeam = this.frameworkService.removeChars(item[10] ?? "", ["."]).toUpperCase();
   //                         newMatch1.score = this.frameworkService.removeBlank(item[11] ?? "").toUpperCase();
   //                         newMatch1.scoreReserve = this.frameworkService.removeBlank("").toUpperCase();
   //                         newMatch1.scoreDetail = item[12] ?? "";
   //                         newMatch1.status = item[19] ?? "";
   //                         newMatch1.statusReserve = "";
   //                         newMatch1.buildingName = this.frameworkService.uppercaseFirstLetterAndLowerOther(item[13] ?? "");
   //                         newMatch1.address = this.frameworkService.uppercaseFirstLetterAndLowerOther(item[14] ?? "");
   //                         newMatch1.zipCode = item[15] ?? "";
   //                         newMatch1.locality = this.frameworkService.uppercaseFirstLetterAndLowerOther(item[16] ?? "");
   //                         newMatch1.turn = item[2] ?? "";
   //                         newMatch1.type = type;
   //                         newMatch1.referee1 = "";
   //                         newMatch1.transportCost1 = 0;
   //                         newMatch1.indemnity1 = 0;
   //                         newMatch1.referee2 = "";
   //                         newMatch1.transportCost2 = 0;
   //                         newMatch1.indemnity2 = 0;
   //                         break;
   //                      }
   //                   case enumActivityType[enumActivityType.YOUNG]:
   //                      {
   //                         if (item[0].trim() != "" && item[0].trim() != "x") {
   //                            newMatch1.name = matchName;
   //                            newMatch1.number = "";
   //                            newMatch1.statusReserve = "";
   //                            newMatch1.scoreReserve = "";
   //                            newMatch1.scoreDetail = "";
   //                            newMatch1.buildingName = "";
   //                            newMatch1.address = "";
   //                            newMatch1.zipCode = "";
   //                            newMatch1.locality = "";
   //                            newMatch1.turn = turn;
   //                            newMatch1.type = type;
   //                            newMatch1.referee1 = "";
   //                            newMatch1.transportCost1 = 0;
   //                            newMatch1.indemnity1 = 0;
   //                            newMatch1.referee2 = "";
   //                            newMatch1.transportCost2 = 0;
   //                            newMatch1.indemnity2 = 0;
   //                            //AtHome

   //                            newMatch1.day = this.getDateFromYoungExcel(item[0], turn);
   //                            newMatch1.time = item[1]?.toUpperCase().includes("H") ? item[1]?.toUpperCase().replace("H", ":") : "";
   //                            newMatch1.fromTeam = this.frameworkService.removeChars(item[3] ?? "", ["."]).toUpperCase();
   //                            newMatch1.awayTeam = this.frameworkService.removeChars(item[4] ?? "", ["."]).toUpperCase();

   //                            newMatch1.score = this.frameworkService.removeBlank(item[2] ?? "").toUpperCase().replace("*", "").replace("*", "").replace("FFT", "FT");
   //                            newMatch1.status = newMatch1.score == "" ? enumMatchDisplayStatus[enumMatchDisplayStatus.AJO] : enumMatchDisplayStatus[enumMatchDisplayStatus.JOU];

   //                            isMatch1MustBeAdded = true;
   //                         }
   //                         //Return
   //                         if (item[6]?.trim() != "" && item[6]?.trim() != "x") {
   //                            isMatch2MustBeAdded = true;

   //                            newMatch2.name = matchName;
   //                            newMatch2.number = "";
   //                            newMatch2.statusReserve = "";
   //                            newMatch2.scoreReserve = "";
   //                            newMatch2.scoreDetail = "";
   //                            newMatch2.buildingName = "";
   //                            newMatch2.address = "";
   //                            newMatch2.zipCode = "";
   //                            newMatch2.locality = "";
   //                            newMatch2.turn = turn;
   //                            newMatch2.type = type;
   //                            newMatch2.referee1 = "";
   //                            newMatch2.transportCost1 = 0;
   //                            newMatch2.indemnity1 = 0;
   //                            newMatch2.referee2 = "";
   //                            newMatch2.transportCost2 = 0;
   //                            newMatch2.indemnity2 = 0;
   //                            newMatch2.day = this.getDateFromYoungExcel(item[6], turn);
   //                            newMatch2.time = item[7]?.toUpperCase().includes("H") ? item[7]?.toUpperCase().replace("H", ":") : "";
   //                            newMatch2.fromTeam = this.frameworkService.removeChars(item[4] ?? "", ["."]).toUpperCase();
   //                            newMatch2.awayTeam = this.frameworkService.removeChars(item[3] ?? "", ["."]).toUpperCase();
   //                            newMatch2.score = this.frameworkService.removeBlank(item[5] ?? "").toUpperCase().replace("*", "").replace("*", "").replace("FFT", "FT");
   //                            newMatch2.status = newMatch2.score == "" ? enumMatchDisplayStatus[enumMatchDisplayStatus.AJO] : enumMatchDisplayStatus[enumMatchDisplayStatus.JOU];

   //                            newMatch2.teamSysId = teamSysId;

   //                            if (((newMatch2.fromTeam).toLowerCase().includes(this.frameworkService.getSportsClubName()) || (newMatch2.fromTeam).toLowerCase().includes(this.frameworkService.getSportsClubNameOld())))
   //                               newMatch2.fromTeam = this.frameworkService.getSportsClubNameToUse();
   //                            if (((newMatch2.awayTeam).toLowerCase().includes(this.frameworkService.getSportsClubName()) || (newMatch2.awayTeam).toLowerCase().includes(this.frameworkService.getSportsClubNameOld())))
   //                               newMatch2.awayTeam = this.frameworkService.getSportsClubNameToUse();

   //                            newMatch2.championshipSysId = this.currentChampionshipSysId;
   //                            if (newMatch2.fromTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()) || newMatch2.awayTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()))
   //                               newMatch2.isAtHome = newMatch2.fromTeam.toLowerCase().includes(this.frameworkService.getSportsClubName());
   //                            else
   //                               newMatch2.isAtHome = null;
   //                         }
   //                         break;
   //                      }
   //                }

   //                if (((newMatch1.fromTeam).toLowerCase().includes(this.frameworkService.getSportsClubName()) || (newMatch1.fromTeam).toLowerCase().includes(this.frameworkService.getSportsClubNameOld())))
   //                   newMatch1.fromTeam = this.frameworkService.getSportsClubNameToUse();
   //                if (((newMatch1.awayTeam).toLowerCase().includes(this.frameworkService.getSportsClubName()) || (newMatch1.awayTeam).toLowerCase().includes(this.frameworkService.getSportsClubNameOld())))
   //                   newMatch1.awayTeam = this.frameworkService.getSportsClubNameToUse();

   //                newMatch1.championshipSysId = this.currentChampionshipSysId;

   //                if (newMatch1.fromTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()) || newMatch1.awayTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()))
   //                   newMatch1.isAtHome = newMatch1.fromTeam.toLowerCase().includes(this.frameworkService.getSportsClubName());
   //                else
   //                   newMatch1.isAtHome = null;

   //                if (type == enumActivityType[enumActivityType.HAINAUT_CUP]) {
   //                   if ((newMatch1.fromTeam + newMatch1.awayTeam).includes(this.frameworkService.getSportsClubNameToUse())) {
   //                      newMatchToInsert.push(newMatch1);
   //                   }
   //                }
   //                else {
   //                   if (isMatch1MustBeAdded && (newMatch1.fromTeam + newMatch1.awayTeam).includes(this.frameworkService.getSportsClubNameToUse())) {
   //                      newMatchToInsert.push(newMatch1);
   //                   }
   //                   if (isMatch2MustBeAdded && (newMatch2.fromTeam + newMatch2.awayTeam).includes(this.frameworkService.getSportsClubNameToUse())) {
   //                      newMatchToInsert.push(newMatch2);
   //                   }
   //                }
   //             }
   //          }
   //       }
   //       );

   //       if (newMatchToInsert.length > 0) {
   //          this.matchService.deleteAllOfChampionship(this.currentChampionshipSysId, type, (type == enumActivityType[enumActivityType.YOUNG]) ? turn : "").subscribe({
   //             next: data => {
   //                //let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

   //                this.matchService.insertAll(newMatchToInsert).subscribe({
   //                   next: data => {
   //                      let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

   //                      if (dataDecrypted == 0) {
   //                         this.alertShowService.clear();
   //                         this.alertShowService.error(this.translateService.instant('MessageImportUnfinishedDueToNoReturn'));
   //                         timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
   //                      }
   //                      else {
   //                         this.alertShowService.success(this.translateService.instant('MessageImportFinishedSuccessfully'));
   //                         this.subjectService.IsDatagridShouldbeRefreshedSubject.next(true);
   //                         timer(this.frameworkService.saveStatusResetTimeOnSuccess()).subscribe(x => { this.alertShowService.clear() });
   //                      }
   //                      this.subjectService.IsButtonInLoadingSubject.next(false);
   //                   },
   //                   error: err => {
   //                      this.alertShowService.clear();
   //                      this.alertShowService.error(this.translateService.instant('MessageImportUnfinishedDueToAnError'));
   //                      timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
   //                      this.subjectService.IsButtonInLoadingSubject.next(false);
   //                   }
   //                });
   //             },
   //             error: err => {
   //                this.alertShowService.clear();
   //                this.alertShowService.error(this.translateService.instant('MessageImportUnfinishedDueToAnError'));
   //                timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
   //                this.subjectService.IsButtonInLoadingSubject.next(false);
   //             }
   //          });
   //       }
   //       else {
   //          this.alertShowService.success(this.translateService.instant('MessageImportNoDataFinishedSuccessfully'));
   //          this.subjectService.IsDatagridShouldbeRefreshedSubject.next(true);
   //          timer(this.frameworkService.saveStatusResetTimeOnSuccess()).subscribe(x => { this.alertShowService.clear() });
   //          this.subjectService.IsButtonInLoadingSubject.next(false);
   //       }
   //       e.target.value = null;
   //       this.fileInputChampionship.nativeElement.value = '';
   //       this.fileInputNotChampionship.nativeElement.value = '';
   //       this.fileInputYoung.nativeElement.value = '';
   //    };

   //    reader.readAsBinaryString(target.files[0]);
   // }

   public refresh(reload: boolean) {
      this.isLoading = false;

      if (reload) {
         //TODO JD replace this.cacheService.reloadMatch();
      }

      let currentChampionship = this.cacheService.getAllChampionships().FirstOrDefault(j => j.isCurrent);

      //-> Set filter(s)
      let filterDetail = new datagridFilterDetail();
      filterDetail.dropdownLists = this.cacheService.getAllChampionships().map(x => (
         {
            value: x.sysId,
            viewValue: this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", x)
         }));

      filterDetail.dropdownSelectedValueOfLists = currentChampionship?.sysId;
      this.currentChampionshipSysId = currentChampionship?.sysId;
      let previousChampionship = this.cacheService.getAllChampionships().FirstOrDefault(i => i.yearStarting == (Number(currentChampionship.yearStarting) - 1).toString());
      if (previousChampionship != null)
         this.previousChampionshipSysId = previousChampionship?.sysId;
      else
         this.previousChampionshipSysId = 0;
      this.importChampionshipDisplay = this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", currentChampionship);

      // List of all matchs of the current championship
      let self = this;
      this.allMatchsCurrentCS = this.cacheService.getAllMatchs().filter(
         function (record) {
            let value = record;

            const props = "match.championship.sysId".split('.');
            for (let index = 0; index < props.length; index += 1) {
               if (props[index] === undefined) break;
               if (value[props[index]] != null)
                  value = value[props[index]];
            }
            return Number(value) == self.currentChampionshipSysId;
         }
      );

      // List of all matchs of the previous championship
      this.allMatchsPreviousCS = this.cacheService.getAllMatchs().filter(
         function (record) {
            let value = record;

            const props = "match.championship.sysId".split('.');
            for (let index = 0; index < props.length; index += 1) {
               if (props[index] === undefined) break;
               if (value[props[index]] != null)
                  value = value[props[index]];
            }
            return Number(value) == self.previousChampionshipSysId;
         }
      );

      // List of all teams of the current championship
      this.allTeamsCurrent = this.cacheService.getAllTeamsWithoutPicture();

      filterDetail.labelText = this.championshipNameDisplay + " : ";
      filterDetail.name = "championship";
      filterDetail.idName = "match.championship.sysId";

      let filters = new Map<string, datagridFilterDetail>();
      filters.set(filterDetail.name, filterDetail);
      //<- Set filter(s)

      this.isLoading = true;
   }

   public translatePage() {
      //ExportFiles1FromAchvbPortal
      this.translateService.get(['ExportFiles1FromAchvbPortal'])
         .subscribe(translations => {
            this.exportFilesFromAchvbPortal = translations['ExportFiles1FromAchvbPortal'];
         });

      //Championship
      this.translateService.get(['Championship'])
         .subscribe(translations => {
            this.championshipNameDisplay = translations['Championship'];
         });

      //ImportDataTitleDisplay
      this.translateService.get(['ImportDataTitleDisplay'])
         .subscribe(translations => {
            this.importDataTitleDisplay = translations['ImportDataTitleDisplay'];
         });

      //ImportData2DescriptionDisplay
      this.translateService.get(['ImportData3DescriptionDisplay'])
         .subscribe(translations => {
            this.importDataDescriptionDisplay = translations['ImportData3DescriptionDisplay'];
         });
   }
}