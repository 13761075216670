//This file is autogenerated by C# GeneratedServices/Angular/ServicesAngularGenerationTool

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { LogService } from 'src/sites/core/services/log.service';
import { AppConfigService } from 'src/sites/core/services/appconfig.service';
import { UserBaseService } from './userBase.service';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { encryptedValue } from 'src/sites/vabourlettis/authentication/encryptedValue';
import { jsonIgnoreReplacer } from 'json-ignore';

@Injectable()
export class UserService extends UserBaseService {
   private _logger: LogService;
   private _appConfigService: AppConfigService;
   private _httpClient: HttpClient;
   private _frameworkService: FrameworkService;
   private _accessRightsService: AccessRightsService;
   private _encryptionService: EncryptionService;
   private _tokenStorageService: StorageService;

   username = "UNKNOWN";

   constructor(
      appConfigService : AppConfigService,
      logger: LogService,
      httpClient: HttpClient,
      frameworkService: FrameworkService,
      accessRightsService: AccessRightsService,
      encryptionService : EncryptionService,
      storageService: StorageService
   ) {
      super(appConfigService, logger, httpClient, frameworkService, accessRightsService, encryptionService, storageService);
      this._appConfigService = appConfigService;
      this._logger = logger;
      this._httpClient = httpClient;
      this._frameworkService = frameworkService;
      this._accessRightsService = accessRightsService;
      this._encryptionService = encryptionService;
      this._tokenStorageService = storageService;
      this.username = this._tokenStorageService.getUserConnected() == null ? "UNKNOWN" : this._tokenStorageService.getUserConnected().username;
   }

      
   public getByEmail(email: string, withIncluding = true) {
      let encryptedData = new encryptedValue();
      encryptedData.value = this._encryptionService.encryptObject(true, false, email);

      let url = "/Q102800001?withIncluding=" + withIncluding;
      url += "&isActive=true";

      return this._httpClient.post<encryptedValue>(this._appConfigService.getApiUrl() + 'api/C1028' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(retry(1), catchError(this.handleError));
   }

   public getByUsername(username: string, withIncluding = true) {
      let encryptedData = new encryptedValue();
      encryptedData.value = this._encryptionService.encryptObject(true, false, username);

      let url = "/Q102800002?withIncluding=" + withIncluding;
      url += "&isActive=true";

      return this._httpClient.post<encryptedValue>(this._appConfigService.getApiUrl() + 'api/C1028' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(retry(1), catchError(this.handleError));
   }

   public changeUserActivityStatus(userSysId: number, status: boolean) {
      //console.log("TRACE -> changeUserActivityStatus");
      
      let encryptedData = new encryptedValue();
      encryptedData.value = this._encryptionService.encryptObject(true, false, userSysId);

      let url = "/Q102800004?status=" + status;
      return this._httpClient.post<encryptedValue>(this._appConfigService.getApiUrl() + 'api/C1028' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(retry(1), catchError(this.handleError));
   }

   public changeUserLoginStatus(userSysId: number, status: boolean) {
      //console.log("TRACE -> changeUserLoginStatus");
      
      let encryptedData = new encryptedValue();
      encryptedData.value = this._encryptionService.encryptObject(true, false, userSysId);

      let url = "/Q102800005?status=" + status;
      return this._httpClient.post<encryptedValue>(this._appConfigService.getApiUrl() + 'api/C1028' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(retry(1), catchError(this.handleError));
   }

   public resetLoginTries(userSysId: number) {
      //console.log("TRACE -> resetLoginTries");
      
      let encryptedData = new encryptedValue();
      encryptedData.value = this._encryptionService.encryptObject(true, false, userSysId);
        
      let url = "/Q102800006";
      return this._httpClient.post<encryptedValue>(this._appConfigService.getApiUrl() + 'api/C1028' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(retry(1), catchError(this.handleError));
   }

   public updateRoleOfMember(userSysId: number, role: string, isToBeAdded: boolean) {
      //console.log("TRACE -> updateRoleOfMember");
   
      let encryptedData = new encryptedValue();
      encryptedData.value = this._encryptionService.encryptObject(true, false, userSysId);
      
      let url = "/Q102800007?role=" + encodeURI(role) + "&isToBeAdded=" + isToBeAdded;
      return this._httpClient.post<encryptedValue>(this._appConfigService.getApiUrl() + 'api/C1028' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(retry(1), catchError(this.handleError));
   }

   public assignPlayersToTeam(usersList : number[], teamSysId: number) {
      //console.log("TRACE -> updateRoleOfMember");
   
      let encryptedData = new encryptedValue();
      encryptedData.value = this._encryptionService.encryptObject(true, false, usersList);
      
      let url = "/Q102800011?teamSysId=" + encodeURI(teamSysId.toString());
      return this._httpClient.post<encryptedValue>(this._appConfigService.getApiUrl() + 'api/C1028' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(retry(1), catchError(this.handleError));
   }
}