//This file is autogenerated by C# GeneratedModels

import { jsonIgnore } from "json-ignore";
import { auditFields } from "src/sites/core/models/system/auditFields";
import { metadataModelBase } from "src/sites/core/models/system/metadataModelBase";
import { enumClubActivityType, enumControlType, enumWorkgroup } from "../../core/enum/Enum";
import { championship } from "./championship";
import { int } from "./int";

@Reflect.metadata('activityBase', 'activityBase')
export class activityBase extends auditFields {

   @Reflect.metadata('day',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.DateOnly,
         editVariableValidator: {
         }
      })
   day?: Date = null;

   @Reflect.metadata('time',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.TimeOnly,
         editVariableValidator: {
            "MaxLength": "5"
         }
      })
   time: string = "";

   @Reflect.metadata('deadline',
   <metadataModelBase><unknown>{
      editControlType: enumControlType.DateOnly,
      editVariableValidator: {
      }
   })
deadline: Date = null;

@Reflect.metadata('type',
<metadataModelBase><unknown>{
   editControlType: enumControlType.Dropdown,
   editDropdownStickyValues: Object.keys(enumClubActivityType)
      .filter(key => isNaN(Number(key)))
      .map(key => ({ value: enumClubActivityType[key], viewValue: key })),

   editDropdownIsTranslateForDisplay: true
})
type: number = enumClubActivityType.NONE;

   @Reflect.metadata('name',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "50"
         }
      })
   name: string = "";

   @Reflect.metadata('description',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Textarea,
         editVariableValidator: {
         }
      })
   description: string = "";

   


   @Reflect.metadata('isAloneActivity',
   <metadataModelBase><unknown>{
      editVariableValidator: {
      },
      editControlType: enumControlType.Bool2States,
      grisIsInDetailExpanded: true
   })
isAloneActivity: boolean = false;

@Reflect.metadata('withReservation',
<metadataModelBase><unknown>{
   editVariableValidator: {
   },
   editControlType: enumControlType.Bool2States
})
withReservation: boolean = false;

@Reflect.metadata('personSeniorPrice',
<metadataModelBase><unknown>{
   editControlType: enumControlType.Decimal,
   editVariableValidator: {
   },
   grisIsInDetailExpanded: true
})
personSeniorPrice: number = 0;

@Reflect.metadata('personChildPrice',
<metadataModelBase><unknown>{
   editControlType: enumControlType.Decimal,
   editVariableValidator: {
   },
   grisIsInDetailExpanded: true
})
personChildPrice: number = 0;

   @Reflect.metadata('picture',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Picture,
         editVariableValidator: {
         }
      })
   picture: string = "";

   @Reflect.metadata('forTeams',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   forTeams: int[];

   @Reflect.metadata('championshipSysId',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Dropdown,
         editDropdownEntityMapping: "championship",
         editDropdownPropertiesForDisplay: "yearStarting;yearEnding",
         editDropdownFormatForDisplay: "{0}-{1}",
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.UserEditOnly],
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None]
      })
   championshipSysId: number = 0;

   @Reflect.metadata('championship',
      <metadataModelBase><unknown>{
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None]
      })
   @jsonIgnore()
   championship: championship;
}