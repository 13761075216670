import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { enumDatagridHeaderOrder, enumQueryFilterOp } from 'src/sites/core/enum/Enum';
import { datagridParam } from 'src/sites/core/models/system/datagridParam';
import { DatagridService } from 'src/sites/core/services/datagrid.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { queryFilter } from '../../models/queryFilter';
import { BuildingTrainingClosingDayService } from '../../services/buildingTrainingClosingDay.service';
import { CacheService } from '../../services/cache.service';
import { encryptedValue } from '../../authentication/encryptedValue';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { buildingTrainingClosingDay } from '../../models/buildingTrainingClosingDay';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-adminBuildingTrainingClosingDay-list',
   templateUrl: './adminBuildingTrainingClosingDay-list.component.html',
   styleUrls: ['./adminBuildingTrainingClosingDay-list.component.scss']
})
export class AdminBuildingTrainingClosingDayListComponent implements OnInit {
  public championshipNameDisplay: string = "";
  public datagridParam: datagridParam<buildingTrainingClosingDay>;
  public importDataDescriptionDisplay: string = "";
  public importDataTitleDisplay: string = "";
  public isLoading: boolean = false;
  public subjectCacheIsLoaded: number = null;

  constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private datagridService: DatagridService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      private encryptionService: EncryptionService,
      private buildingTrainingClosingDayService: BuildingTrainingClosingDayService) {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.subscribe(value => {
      }
      );
   }

  public getDatagridFilters($event) {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.next(true);
   }

  public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

  public ngOnInit() {
      //Souscription à l'event lorsque la cache globale a terminé son chargement
      this.subjectService.IsGlobalCacheLoadedSubject.subscribe(value => { this.subjectCacheIsLoaded = value; if (value == 2) this.initialize(); });
   }

  public refresh() {
      this.isLoading = false;

      const queryFilters = [];
      queryFilters.push(new queryFilter({ propertyName: "ChampionshipSysId", operation: enumQueryFilterOp.Equals, value: this.cacheService.getCurrentChampionship()?.sysId }));

      this.buildingTrainingClosingDayService.getAllWithIncluding(queryFilters).subscribe(
         (data: encryptedValue) => {
            this.datagridParam = new datagridParam(
               {
                  hidden: !this.isLoading,
                  dataSource: this.encryptionService.decryptObject(false, data.value),
                  filters: null,
                  mainTitle: "AdminBuildingTrainingClosingDayListTitle",
                  headerOrder: enumDatagridHeaderOrder.ApiByDefault,
                  entity: new buildingTrainingClosingDay(),
                  urlApi: "C1006",
                  paginatorItemsCount: this.frameworkService.getPaginatorItemsCount(),
                  formTitle: "BuildingTrainingClosingDayListTitle"
               });
            this.isLoading = true;
         }
      );
   }

  public translatePage() {
      //Championship
      this.translateService.get(['Championship'])
         .subscribe(translations => {
            this.championshipNameDisplay = translations['Championship'];
         });
   }
}
