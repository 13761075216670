import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/sites/core/components/confirmDialog/confirmDialog.component';
import { user } from 'src/sites/core/models/user/user';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { AccountService } from 'src/sites/core/services/account.service';
import { AppConfigService } from 'src/sites/core/services/appconfig.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { CacheService } from '../../services/cache.service';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { encryptedValue } from '../../authentication/encryptedValue';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { MailService } from 'src/sites/core/services/mail.service';
import { mailRequest } from 'src/sites/core/models/mail/mailRequest';
import { enumDocumentType } from 'src/sites/core/enum/Enum';
import { Observable, forkJoin } from 'rxjs';
import { StorageService } from '../../authentication/storage.service';

@Component({
   selector: 'app-member',
   templateUrl: './member.component.html',
   styleUrls: ['./member.component.scss']
})

export class MemberComponent implements OnInit {
   public allMembersByAllTeam: user[] = [];
   public allMembersByFirstname: user[] = [];
   public allMembersByLastname: user[] = [];
   public allMembersByRole;
   public allMembersByTeam: user[] = [];
   public allMembersSelected: user[] = [];
   public allRolesOfMembers = [];
   public form: FormGroup = new FormGroup({});
   public isLoading: boolean = false;
   public isNetworkStatusOnline: boolean = false;
   public isNotFirstTime: boolean = false;
   public queryParams: any;
   public subjectCacheIsLoaded: number = null;
   public tabIndex: number;
   public titleDescriptionDisplay: string = "";
   public allTeamActiveCount = [];
   public allTeamInactiveCount = [];
   public allTeamInactiveLoginCount = [];
   public inactivesMembersCount: number = 0;
   public inactivesLoginMembersCount: number = 0;
   public activesMembersCount: number = 0;
   public isDisplayOnAMobile = false;

   constructor(
      private appConfigService: AppConfigService,
      public dialog: MatDialog,
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      private accessRightsService: AccessRightsService,
      private accountService: AccountService,
      private formBuilder: FormBuilder,
      private activeRouter: ActivatedRoute,
      private encryptionService: EncryptionService,
      private mailService: MailService,
      private storageService: StorageService,
      private router: Router) {
      this.subjectService.IsWindowResizingSubject.subscribe(value => { this.isDisplayOnAMobile = value; });
   }

   public changeProfile(member, tabType) {
      this.router.navigate(['profile'], { queryParams: { id: this.encryptionService.encrypt(true, member.sysId), tabType: tabType } });
   }


   public getAllTeamsCount() {
      this.allTeamActiveCount = [];
      this.allTeamInactiveCount = [];
      this.allTeamInactiveLoginCount = [];
      let i = 0;
      let backupi = 0;
      let activeCount = 0;
      let inactiveCount = 0;
      let inactiveLoginCount = 0;
      let backupActiveCount = 0;
      let backupInactiveCount = 0;
      let backupInactiveLoginCount = 0;
      this.cacheService.getAllMembersByTeam().forEach(m => {
         if (this.getPreviousMemberByTeam(i) != m.team.teamCategory) {
            backupActiveCount = activeCount;
            backupInactiveCount = inactiveCount;
            backupInactiveLoginCount = inactiveLoginCount;
            if (!m.isUserLoginInactive) {
               this.allTeamActiveCount[backupi] = backupActiveCount;
               this.allTeamInactiveLoginCount[backupi] = backupInactiveLoginCount;
               this.allTeamInactiveCount[backupi] = backupInactiveCount - backupInactiveLoginCount;

               activeCount = 1;
               inactiveCount = 0;
               if (!m.sysIsActive)
                  inactiveLoginCount = 1;
               else
                  inactiveLoginCount = 0;
            }
            else {
               this.allTeamActiveCount[backupi] = backupActiveCount;
               this.allTeamInactiveCount[backupi] = backupInactiveCount;
               this.allTeamInactiveLoginCount[backupi] = backupInactiveLoginCount;
               activeCount = 0;
               inactiveCount = 1;
               if (!m.sysIsActive)
                  inactiveLoginCount = 1;
               else
                  inactiveLoginCount = 0;
            }
            backupi = i;
         }
         else {
            this.allTeamActiveCount.push(activeCount);
            this.allTeamInactiveCount.push(inactiveCount);
            this.allTeamInactiveLoginCount.push(inactiveLoginCount);
            if (!m.isUserLoginInactive)
               activeCount++;
            else
               inactiveCount++;
            if (!m.sysIsActive)
               inactiveLoginCount++;
         }
         i++;
      });
      this.allTeamActiveCount[backupi] = activeCount;
      this.allTeamInactiveLoginCount[backupi] = inactiveLoginCount;
      this.allTeamInactiveCount[backupi] = inactiveCount - inactiveLoginCount;

   }


   public getAllUsersOfRole(role): [] {
      if (this.allMembersByRole != null) {
         return this.allMembersByRole.get(role);
      }
      return [];
   }

   public getIsInactiveClass(profile, alignRight): string {
      var result = profile.isUserLoginInactive ? "memberLoginInactive" : "";
      if (alignRight)
         result += !profile.sysIsActive ? " memberInactive fontSmaller alignmentRight" : " fontSmaller alignmentRight";
      else
         result += !profile.sysIsActive ? " memberInactive fontSmaller" : " fontSmaller";
      return result;
   }

   public getPreviousMember(i) {
      if (i - 1 < 0)
         return "";
      else {
         return this.cacheService.getAllMembersByLastname()[i - 1].lastName[0];
      }
   }

   public getPreviousMemberByAllTeam(i) {
      if (i - 1 < 0)
         return "";
      else {
         return this.cacheService.getAllMembersByAllTeam()[i - 1]?.team?.teamCategory;
      }
   }

   public getPreviousMemberByFirstname(i) {
      if (i - 1 < 0)
         return "";
      else {
         return this.cacheService.getAllMembersByFirstname()[i - 1].firstName[0];
      }
   }

   public getPreviousMemberByTeam(i) {
      if (i - 1 < 0)
         return "";
      else {
         return this.cacheService.getAllMembersByTeam()[i - 1]?.team?.teamCategory;
      }
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

   public isMemberCoachOnly(profile) {
      return this.frameworkService.isMemberCoachOnly(profile);
   }

   public isRoleAssistantCoach(role) {
      return this.frameworkService.isRoleAssistantCoach(role);
   }

   public isRoleCoach(role) {
      return this.frameworkService.isRoleCoach(role);
   }

   public isRoleCoachOrAssistant(role) {
      return this.frameworkService.isRoleCoachOrAssistant(role);
   }

   public isRoleTeamContact(role) {
      return this.frameworkService.isRoleTeamContact(role);
   }

   public isRoleTeamDelegate(role) {
      return this.frameworkService.isRoleTeamDelegate(role);
   }

   public isSupervisorAuthenticated() {
      return this.accessRightsService.isUserSupervisor();
   }

   public isPresidentAuthenticated() {
      return this.frameworkService.getMemberIsPresident(this.storageService.getUserConnected())
   }

   public isSecretaryAuthenticated() {
      return this.frameworkService.getMemberIsSecretary(this.storageService.getUserConnected())
   }

   public ngOnInit() {
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });

      this.activeRouter.queryParams.subscribe(params => {
         this.queryParams = params;
         if (this.queryParams["tabType"] == undefined) {
         }
         else {
            this.tabIndex = Number(this.queryParams["tabType"]);
         }
      });

      this.form = this.formBuilder.group({
         allMembersSelectedArray: this.formBuilder.array([]),
         allMembersSelectedCheckbox: [false],
         withActiveMembersSelectedCheckbox: [false],
         subject: [""],
         message: [""]
      });

      //Souscription à l'event lorsque la cache globale a terminé son chargement
      this.subjectService.IsGlobalCacheLoadedSubject.subscribe(value => { this.subjectCacheIsLoaded = value; if (value == 2) this.initialize(); });
   }

   public onChange_MemberSelected(event, member: user) {
      if (event.checked) {
         this.allMembersSelected.push(member);
         if (this.allMembersSelected.length == this.allMembersByTeam.length)
            this.form.get("allMembersSelectedCheckbox").setValue(true);
      } else {
         this.allMembersSelected = this.removeAt(this.allMembersSelected, member.sysId);
         this.form.get("allMembersSelectedCheckbox").setValue(false);
      }
      let withActiveMembersSelectedCheckbox = false;
      this.allMembersSelected.forEach(i => {
         if (!i.sysIsActive || i.isUserLoginInactive)
         {
            withActiveMembersSelectedCheckbox = true;
         }
      });

      this.form.get("withActiveMembersSelectedCheckbox").setValue(withActiveMembersSelectedCheckbox);
   }

   public onChange_AllMembersSelected(event) {
      this.form.get("withActiveMembersSelectedCheckbox").setValue(true);
      this.setMembersSelected();
   }

   public onChange_WithActiveMembersSelected(event) {
      this.setMembersSelected();
   }

   public setMembersSelected() {
      const allMembersSelectedArray = this.form.get('allMembersSelectedArray') as FormArray;

      this.allMembersSelected = [];
      if (this.form.get("allMembersSelectedCheckbox").value) {
         allMembersSelectedArray.controls.forEach(control => {
            control.setValue(true);
         });
         if (this.form.get('withActiveMembersSelectedCheckbox').value)
            this.allMembersSelected = this.allMembersByTeam.map(x => x);
         else
            this.allMembersSelected = this.allMembersByTeam.filter(i=>i.sysIsActive && !i.isUserLoginInactive).map(x => x);
      }
      else {
         allMembersSelectedArray.controls.forEach(control => {
            control.setValue(false);
         });
         this.allMembersSelected = [];
      }
   }
   

   public refresh() {
      this.isLoading = !this.isNotFirstTime;

      this.allMembersByRole = this.cacheService.getAllMembersByRole();
      this.allMembersByLastname = this.cacheService.getAllMembersByLastname();
      this.allMembersByFirstname = this.cacheService.getAllMembersByFirstname();
      this.allMembersByTeam = this.cacheService.getAllMembersByTeam();
      this.allMembersByAllTeam = this.cacheService.getAllMembersByAllTeam();

      if (this.storageService.getUserConnected() != null && (this.storageService.getUserConnected().username.toLowerCase() == "vab.root" || this.storageService.getUserConnected().username.toLowerCase() == "vab.root"))
      {
         this.activesMembersCount = 0;
         this.inactivesLoginMembersCount = 0;
         this.inactivesMembersCount = 0;
      }
      else
      {
         this.activesMembersCount = this.cacheService.getAllMembersByAllTeam().filter(i => !i.isUserLoginInactive).length;
         this.inactivesLoginMembersCount = this.cacheService.getAllMembersByAllTeam().filter(i => !i.sysIsActive).length;
         this.inactivesMembersCount = this.cacheService.getAllMembersByAllTeam().filter(i => i.isUserLoginInactive).length - this.inactivesLoginMembersCount;
      }

      this.allRolesOfMembers = this.frameworkService.getAllRolesOfMembers();

      const formArray = this.form.get('allMembersSelectedArray') as FormArray;
      this.allMembersByTeam.forEach(x => formArray.push(new FormControl(false)));

      this.allMembersByTeam = this.allMembersByTeam.sort(function (a, b) {
         if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName < (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
            return -1;
         }
         if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName > (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
            return 1;
         }
         return 0;
      });

      this.getAllTeamsCount();

      this.isLoading = false;
   }

   public removeAt(objectArray, sysId: number) {
      objectArray.forEach((value, index) => {
         if (value.sysId == sysId) objectArray.splice(index, 1);
      });
      return objectArray;
   }

   public sendLoginProcedure() {
      this.accountService.sendLoginProcedureToUser(this.allMembersSelected, this.siteMail()).subscribe(
         {
            next: data => {
               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

               if (dataDecrypted != null) {
                  this.frameworkService.displayAlert(true, this.translateService.instant('SendLoginProcedureSuccessfull'));
                  this.subjectService.IsButtonInLoadingSubject.next(false);
               }
               else {
                  this.frameworkService.displayAlert(false, this.translateService.instant('SendLoginProcedureFailed'));
                  this.subjectService.IsButtonInLoadingSubject.next(false);
               }
            },
            error: err => {
               this.frameworkService.displayAlert(false, err.mes);
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
         });
   }

   public onClick_LoginProcedure(): void {
      this.subjectService.IsButtonInLoadingSubject.next(false);

      const message1 = "Vous êtes sur le point d'envoyer la procédure à tous les membres sélectionnés.";
      const message2 = "Voulez-vous continuer ?";

      const dialogData = new ConfirmDialogModel("Procédure de login", message1, message2);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
         position: { top: '0px' },
         maxWidth: "100%",
         width: "100%",
         data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
         if (dialogResult) {
            this.sendLoginProcedure();
         }
         this.subjectService.IsButtonInLoadingSubject.next(false);
      });
   }

   public onClick_Mailing(): void {
      let request = new mailRequest();
      request.toEmail = this.allMembersSelected.join(';');
      request.subject = this.form.get('subject').value;
      request.title = "SAISON " + this.cacheService.getCurrentChampionship().yearStarting + "-"  + this.cacheService.getCurrentChampionship().yearEnding + " : " + this.form.get('subject').value;
      request.body = this.form.get('message').value;
      request.type = enumDocumentType.SimpleMailing;

      let appServiceArray: Observable<any>[] = [];

      appServiceArray.push(this.mailService.sendMail(request));

      forkJoin(appServiceArray).subscribe(res => {
         this.subjectService.IsButtonInLoadingSubject.next(false);
         if (res[0])
            this.frameworkService.displayAlert(true, "Le mail a été envoyé aux destinataires.");
         else
            this.frameworkService.displayAlert(false, "Le mail n'a pas pu être envoyées aux destinataires.");
      });
   }


   public siteMail() {
      return this.appConfigService.getMail();
   }

   public translatePage() {
      //ProfileTitle
      this.translateService.get(['MemberTitle'])
         .subscribe(translations => {
            this.titleDescriptionDisplay = translations['MemberTitle'];
         });
   }
}