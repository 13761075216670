<div class="logoVABBase64 logoVABSetting">
   <div class="logoVABBase64Size"></div>
</div>
<div class="loadingError" *ngIf="isCannotBeLoaded"> 
   <span class="material-icons-white color-red">wifi_off</span>&nbsp;&nbsp;Veuillez activer votre connexion internet.<br/><br/>
   Si l'application ne redémarre pas après une minute alors que la connexion est réactivée, veuillez redémarrer l'application manuellement, svp ...
</div>
<div class="loadingError" *ngIf="isQueryOnTimeoutSubject"> 
   <span class="material-icons-white color-red">hourglass_top</span>&nbsp;&nbsp;
   Désolé, mais votre connexion actuelle n'est pas suffisante pour obtenir toutes les données nécessaires.
   Veuillez vérifier votre connexion. Si la connexion s'améliore, l'application redémarrera automatiquement.
</div>
<div class="logoVABSpin logoVABSpinner"></div>
<div class="version" *ngIf="currentApplicationVersionNumber != ''">v. {{currentApplicationVersionNumber}}<br>Dernière
   mise à jour : {{currentApplicationLastVersionUpdate}}

   <br>
   {{message}}
   <mat-progress-bar mode="determinate" value="{{stepNumber}}"></mat-progress-bar>
</div>