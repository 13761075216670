import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { timer } from 'rxjs';
import { MatchCalendarComponent } from 'src/sites/core/components/matchCalendar/matchCalendar.component';
import { enumActivityType, enumMatchDisplayType } from 'src/sites/core/enum/Enum';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { AlertShowService } from 'src/sites/core/services/alertShow.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { eventClub } from '../../models/eventClub';
import { trainingPlayer } from '../../models/trainingPlayer';
import { CacheService } from '../../services/cache.service';
import { TrainingPlayerService } from '../../services/trainingPlayer.service';
import { TrainingPlayerEditionComponent, TrainingPlayerEditionModel } from '../trainingPlayerEdition/trainingPlayerEdition.component';
import { TrainingPlayersManagerComponent, TrainingPlayersManagerModel } from '../trainingPlayersManager/trainingPlayersManager.component';
import { TrainingPlayersStatisticsComponent, TrainingPlayersStatisticsModel } from '../trainingPlayersStatistics/trainingPlayersStatistics.component';
import { StorageService } from '../../authentication/storage.service';
import { encryptedValue } from '../../authentication/encryptedValue';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-trainingPlayer',
   templateUrl: './trainingPlayer.component.html',
   styleUrls: ['./trainingPlayer.component.scss'],
   encapsulation: ViewEncapsulation.None,
   providers: [DatePipe]
})

export class TrainingPlayerComponent implements OnInit {
  @ViewChild('calendar', { static: false }) public calendar: MatCalendar<Date>;
  @ViewChild(MatchCalendarComponent) public matchCalendar: MatchCalendarComponent;

  public EnumActivityType = enumActivityType;
  public EnumMatchDisplayType = enumMatchDisplayType;
  //TODELETE public IsMatchCacheLoadedSubject: boolean = false;
  public activityType = enumActivityType.NONE;
  public allActivities: any[] = [];
  public allClubEventsOfUserAuthenticated = new Map<string, eventClub>();
  public allClubEventsOfUserAuthenticatedForCalendar = new Map<string, eventClub>();
  public allReasons: any[] = [];
  public form: FormGroup = new FormGroup({});
  public isCoachOrAssistantOrSupervisorConnected: boolean = false;
  public isDisplayOnAMobile: boolean = false;
  public isLoading: boolean = false;
  public isNetworkStatusOnline: boolean = false;
  public isNotFirstTime: boolean = false;
  public maxDate = this.frameworkService.getToday();
  public minDate = this.frameworkService.getToday();
  public selectedCalendarDate: Date;
  public subjectCacheIsLoaded: number = null;
  public titleDescriptionDisplay: string = "";

  constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private dateAdapter: DateAdapter<any>,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      private accessRightsService: AccessRightsService,
      private trainingPlayerService: TrainingPlayerService,
      private router: Router,
      private alertShowService: AlertShowService,
      private storageService: StorageService,
      private encryptionService: EncryptionService,
      public dialog: MatDialog
   ) {
      this.getScreenSize();
   }

  @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

  public delete(trainingPlayerToDelete: trainingPlayer) {
      this.trainingPlayerService.deleteWithServerDateCheck(trainingPlayerToDelete.sysId, trainingPlayerToDelete.day, trainingPlayerToDelete.extraTrainingTimeStarting, !this.isCoachOrAssistantOrSupervisorConnected).subscribe({
         next: data => {
            let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

            if (dataDecrypted != null) {
               if (dataDecrypted.reason == "OUTOFDATE")
                  this.frameworkService.displayAlert(false, "Un événement passé ne peut plus être modifié !");
               else {
                  //this.frameworkService.logInfo("CALL loadUpdatedData","TP (Delete)");
                  this.cacheService.loadUpdatedData(false, false);
                  this.frameworkService.displayAlert(true, this.translateService.instant('MessageDataDeletedSuccessfully'));
               }
            }
            else {
               this.alertShowService.error(this.translateService.instant('MessageDataDeletedSuccessfully'));
               timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
            }
            this.subjectService.IsButtonInLoadingSubject.next(false);
         },
         error: err => {
            this.alertShowService.error(err.mes);
            timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
            this.subjectService.IsButtonInLoadingSubject.next(false);
         }
      });
   }

  public deleteAll(date: Date) {
      let allTrainingPlayerToDelete: trainingPlayer[] = [];

      for (let cpt = 0; cpt < this.cacheService.getAllUsersOfTeamOfCurrentUser().length; cpt++) {
         let trainingFound = this.cacheService.getAllTrainingPlayers().FirstOrDefault(i => i.playerSysId == this.cacheService.getAllUsersOfTeamOfCurrentUser()[cpt].sysId && this.frameworkService.getDateFormatYyyyMmDd(i.day) == this.frameworkService.getDateFormatYyyyMmDd(date));
         if (trainingFound != null)
            allTrainingPlayerToDelete.push(trainingFound);
      }
      this.trainingPlayerService.deleteAll(allTrainingPlayerToDelete).subscribe({
         next: data => {
            let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

            if (dataDecrypted != null) {
               //this.frameworkService.logInfo("CALL loadUpdatedData","TP (Delete All)");
               this.cacheService.loadUpdatedData(false, false);

               this.frameworkService.displayAlert(true, this.translateService.instant('MessageDataDeletedSuccessfully'));
            }
            else {
               this.alertShowService.error(this.translateService.instant('MessageDataDeletedSuccessfully'));
               timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
            }
            this.subjectService.IsButtonInLoadingSubject.next(false);
         },
         error: err => {
            this.alertShowService.error(err.mes);
            timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
            this.subjectService.IsButtonInLoadingSubject.next(false);
         }
      });
   }

  public initialize() {
      this.allClubEventsOfUserAuthenticatedForCalendar = this.cacheService.getAllClubEventsOfUserAuthenticated();
      this.allClubEventsOfUserAuthenticated = this.frameworkService.findAllEventClubsOverToday(this.cacheService.getAllClubEventsOfUserAuthenticated());
      this.refresh(false);

      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });
   }

  public insert(trainingPlayerToInsert: trainingPlayer): void {
      this.trainingPlayerService.insertWithServerDateCheck(trainingPlayerToInsert, !this.isCoachOrAssistantOrSupervisorConnected).subscribe({
         next: data => {
            let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

            if (dataDecrypted != null) {
               if (dataDecrypted.reason == "OUTOFDATE")
                  this.frameworkService.displayAlert(false, "Un événement passé ne peut plus être modifié !");
               else {
                  //this.frameworkService.logInfo("CALL loadUpdatedData","TP (Insert)");
                  this.cacheService.loadUpdatedData(false, false);
                  this.frameworkService.displayAlert(true, this.translateService.instant("MessageDataSavedSuccessfully"));
               }
            }
            else {
               this.alertShowService.error(this.translateService.instant("MessageDataNotSavedDueToAnError"));
               timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
            }
            this.subjectService.IsButtonInLoadingSubject.next(false);
         },
         error: err => {
            this.alertShowService.error(err.mes);
            timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
            this.subjectService.IsButtonInLoadingSubject.next(false);
         }
      });
   }

  public ngOnInit() {
      this.form.addControl("allReasons", new FormControl(''));
      this.form.addControl("allActivities", new FormControl(''));
      this.form.addControl("team", new FormControl(''));
      this.form.addControl("isAtHome", new FormControl(''));
      this.form.addControl("time", new FormControl(''));
      this.form.addControl("trainingBuilding", new FormControl(''));
      this.form.addControl("isPresenceNotCounted", new FormControl(''));

      this.isCoachOrAssistantOrSupervisorConnected = this.frameworkService.IsMemberCoachs(this.storageService.getUserConnected()) || this.accessRightsService.isUserSupervisor();

      this.setMinMaxDateOfCalendar();
      this.dateAdapter.setLocale(this.translateService.currentLang);

      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
      //Souscription à l'event lorsque la cache globale a terminé son chargement
      this.subjectService.IsGlobalCacheLoadedSubject.subscribe(value => { this.subjectCacheIsLoaded = value; if (value == 2) this.initialize(); });
      //Souscription à l'event lorsque la cache sur les présences a terminé son chargement
      //TODELETE this.subjectService.IsMatchCacheLoadedSubject.subscribe(bool => { if (bool) this.refresh(true); });
   }

  //<-> Event when click on the date of calendar
  public onCalendarDateSelect(date: Date) {
      this.selectedCalendarDate = date;
   }

  public onCancelDialog(): void {
      this.dialog.closeAll();
   }

  public onMatchClick(date: Date) {
      this.selectedCalendarDate = date;
      this.matchCalendar.changeSelectedDate(this.selectedCalendarDate);
   }

  public onRefreshTrainingPlayerInformation() {
      //this.frameworkService.logInfo("CALL loadUpdatedData","TP (onRefreshTrainingPlayerInformation)");
      this.cacheService.loadUpdatedData(false, false);
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

  public onReturnToTraining(): void {
      this.subjectService.IsButtonInLoadingSubject.next(false);
      this.router.navigate(['training']);
   }

  public onTrainingPlayerEdition() {
      if (this.cacheService.getCoachOfCurrentLogged() == null) {
         this.frameworkService.displayAlert(false, "Impossible d'effectuer l'opération. Il n'existe aucun coach pour votre équipe !");
         this.subjectService.IsButtonInLoadingSubject.next(false);
      }
      else {
         const dialogRefData = new TrainingPlayerEditionModel(this.selectedCalendarDate);

         const dialogRef = this.dialog.open(TrainingPlayerEditionComponent, {
            position: { top: '0px' },
            maxWidth: "100%",
            width: "100%",
            data: dialogRefData
         });

         dialogRef.afterClosed().subscribe(dialogResult => {
            this.subjectService.IsButtonInLoadingSubject.next(false);
         });
      }
   }

  public onTrainingPlayersManager() {
      const dialogRefData = new TrainingPlayersManagerModel(this.selectedCalendarDate);

      const dialogRef = this.dialog.open(TrainingPlayersManagerComponent, {
         position: { top: '0px' },
         maxWidth: "100%",
         width: "100%",
         data: dialogRefData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
         this.subjectService.IsButtonInLoadingSubject.next(false);
      });
   }

  public onTrainingPlayersStatistics() {
      const dialogRefData = new TrainingPlayersStatisticsModel(this.selectedCalendarDate);

      const dialogRef = this.dialog.open(TrainingPlayersStatisticsComponent, {
         position: { top: '0px' },
         maxWidth: "100%",
         width: "100%",
         data: dialogRefData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
         this.subjectService.IsButtonInLoadingSubject.next(false);
      });
   }

  public refresh(reload: boolean) {
      this.isLoading = !this.isNotFirstTime;
      if (reload && this.calendar != null) {
         this.calendar.updateTodaysDate();
         this.isLoading = false;
      }
      if (!this.isNotFirstTime) {
         this.allReasons = this.frameworkService.getNonPresenceReason();
         this.allActivities = this.frameworkService.getMatchTrainingActivitiesType();
         this.isNotFirstTime = true;
      }
      this.setMinMaxDateOfCalendar();
      this.isLoading = false;
   }

  public setMinMaxDateOfCalendar() {
      if (this.cacheService.getTeamOfUserConnected() != null) {
         if (this.isCoachOrAssistantOrSupervisorConnected) {
            this.minDate = new Date(this.cacheService.getTeamOfUserConnected().startOfTrainingDate);
            this.maxDate = new Date(this.cacheService.getTeamOfUserConnected().endOfTrainingDate);
         }
         else {
            this.minDate = new Date(this.frameworkService.getToday());
            this.maxDate = new Date(this.cacheService.getTeamOfUserConnected().endOfTrainingDate);
         }
      }
   }

  public translatePage() {
      //TrainingPlayerTitle
      this.translateService.get(['TrainingPlayerTitle'])
         .subscribe(translations => {
            this.titleDescriptionDisplay = translations['TrainingPlayerTitle'];
         });
   }

//   public update(trainingPlayerToUpdate: trainingPlayer): void {
//       this.trainingPlayerService.updateWithServerDateCheck(trainingPlayerToUpdate, !this.isCoachOrAssistantOrSupervisorConnected).subscribe({
//          next: data => {
//             let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);
//             if (dataDecrypted != null) {
//                if (dataDecrypted.reason == "OUTOFDATE")
//                   this.frameworkService.displayAlert(false, "Un événement passé ne peut plus être modifié !");
//                else {
//                   //this.frameworkService.logInfo("CALL loadUpdatedData","TP (Update)");
//                   this.cacheService.loadUpdatedData(false, false);
//                   this.frameworkService.displayAlert(true, this.translateService.instant("MessageDataSavedSuccessfully"));
//                }
//             }
//             else {
//                this.alertShowService.error(this.translateService.instant("MessageDataNotSavedDueToAnError"));
//                timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
//             }
//             this.subjectService.IsButtonInLoadingSubject.next(false);
//          },
//          error: err => {
//             this.alertShowService.error(err.mes);
//             timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
//             this.subjectService.IsButtonInLoadingSubject.next(false);
//          }
//       });
//    }

//   public updateExtraTrainingAll(date: Date): void {
//       let trainingPlayerToUpdate: trainingPlayer[] = [];

//       for (let cpt = 0; cpt < this.cacheService.getAllUsersOfTeamOfCurrentUser().length; cpt++) {
//          let trainingFound = this.cacheService.getAllTrainingPlayers().FirstOrDefault(i => i.playerSysId == this.cacheService.getAllUsersOfTeamOfCurrentUser()[cpt].sysId && this.frameworkService.getDateFormatYyyyMmDd(i.day) == this.frameworkService.getDateFormatYyyyMmDd(date));
//          if (trainingFound != null) {
//             trainingFound.type = this.form.get("allActivities").value;
//             if (trainingFound.type == enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY] || trainingFound.type == enumActivityType[enumActivityType.TOURNAMENT_DAY] || trainingFound.type == enumActivityType[enumActivityType.EXTRA_TRAINING_DAY]) {
//                trainingFound.extraTrainingTeam = this.form.get("team").value.toString().toUpperCase();
//                trainingFound.extraTrainingTimeStarting = this.form.get("time").value;
//                trainingFound.extraTrainingIsAtHome = this.form.get("isAtHome").value == "" ? false : this.form.get("isAtHome").value;
//                trainingFound.extraTrainingBuilding = this.form.get("trainingBuilding").value.toString().toUpperCase();
//                trainingFound.isPresenceNotCounted = this.form.get("isPresenceNotCounted").value == "" ? false : this.form.get("isPresenceNotCounted").value;
//             }
//             else {
//                trainingFound.extraTrainingTeam = "";
//                trainingFound.extraTrainingTimeStarting = "";
//                trainingFound.extraTrainingIsAtHome = false;
//                trainingFound.extraTrainingBuilding = "";
//                trainingFound.isPresenceNotCounted = false;
//             }

//             trainingPlayerToUpdate.push(trainingFound);
//          }
//       }

//       this.trainingPlayerService.updateAll(trainingPlayerToUpdate).subscribe({
//          next: data => {
//             let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

//             if (dataDecrypted != null) {
//                //this.frameworkService.logInfo("CALL loadUpdatedData","TP (Update All)");
//                this.cacheService.loadUpdatedData(false, false);

//                this.frameworkService.displayAlert(true, this.translateService.instant("MessageDataSavedSuccessfully"));
//             }
//             else {
//                this.alertShowService.error(this.translateService.instant("MessageDataNotSavedDueToAnError"));
//                timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
//             }
//             this.subjectService.IsButtonInLoadingSubject.next(false);
//          },
//          error: err => {
//             this.alertShowService.error(err.mes);
//             timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
//             this.subjectService.IsButtonInLoadingSubject.next(false);
//          }
//       });
//    }
}