import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, fromEvent, map, merge, ObservableInput, of, Subscription } from 'rxjs';

import { user } from 'src/sites/core/models/user/user';
import { workgroup } from 'src/sites/core/models/workgroup/workgroup';
import { championship } from '../models/championship';
import { comiteeMeeting } from '../models/comiteeMeeting';
import { match } from '../models/match';
import { team } from '../models/team';
import { trainingPlayer } from '../models/trainingPlayer';

// import * as signalR from '@microsoft/signalr';
import { TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { enumActivityType, enumAvailability, enumAvailabilityReason, enumCacheTypeLoad, enumClubActivityType, enumIndexDB0, enumIndexDB1, enumIndexDB2, enumIndexDB3, enumQueryFilterOp } from 'src/sites/core/enum/Enum';
import { lastUpdates } from 'src/sites/core/models/lastupdates/lastUpdates';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { LastUpdatesService } from 'src/sites/core/services/lastUpdates.service';
import { UserService } from 'src/sites/core/services/user.service';
import { WorkgroupService } from 'src/sites/core/services/workgroup.service';
import { AuthenticationService } from 'src/sites/vabourlettis/authentication/authentication.service';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { activity } from '../models/activity';
import { buildingTrainingClosingDay } from '../models/buildingTrainingClosingDay';
import { eventClub } from '../models/eventClub';
import { homeNews } from '../models/homeNews';
import { queryFilter } from '../models/queryFilter';
import { ranking } from '../models/ranking';
import { setting } from '../models/setting';
import { sponsor } from '../models/sponsor';
import { sportsClub } from '../models/sportsClub';
import { strings } from '../models/strings';
import { ActivityService } from './activity.service';
import { db } from './appDB';
import { BuildingTrainingClosingDayService } from './buildingTrainingClosingDay.service';
import { ChampionshipService } from './championship.service';
import { ComiteeMeetingService } from './comiteeMeeting.service';
import { HomeNewsService } from './homeNews.service';
import { MatchService } from './match.service';
import { RankingService } from './ranking.service';
import { SettingService } from './setting.service';
import { SponsorService } from './sponsor.service';
import { SportsClubService } from './sportsClub.service';
import { TeamService } from './team.service';
import { TodoService } from './todoService';
import { TrainingPlayerService } from './trainingPlayer.service';
import { VersionningService } from './versionningService';
import { entityUpdatedRequest } from '../models/entityUpdateRequest';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { SignalRService } from 'src/sites/core/services/signalR.service';
import * as signalR from '@microsoft/signalr';
import { DataPrivacyDialogComponent, DataPrivacyDialogModel } from 'src/app/common/footer/dataPrivacyDialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { buildingTraining } from '../models/buildingTraining';

@Injectable({
   providedIn: 'root'
})
export class CacheService implements OnInit, OnDestroy {
   private allActivities: activity[] = [];
   private allBuildingTrainingClosingDay: buildingTrainingClosingDay[] = [];
   private allChampionships: championship[] = [];
   private allClubEvents = new Map<string, eventClub>();
   private allClubEventsOfUserAuthenticated = new Map<string, eventClub>();
   private allCoachsOfCurrentLogged: user[] = [];
   private allComiteeMeetings: comiteeMeeting[] = [];
   private allEntitiesToUpdate: lastUpdates[] = [];
   private allHomeNews: homeNews[] = [];
   private allLastUpdates: lastUpdates[] = [];
   private allMatchs: match[] = [];
   private allMatchsOfTeamAndClub: match[] = [];
   private allMatchsOfUserTeamAndClub: match[] = [];
   private allMatchsOfUserTeamAndClubAndOverCurrentDayCS: match[] = [];
   private allRanking: ranking[] = [];
   private allSettings: setting[] = [];
   private allSponsors: sponsor[] = [];
   private allTeams: team[] = [];
   private allTeamsWithoutPicture: team[] = [];
   private allTodos: strings[] = [];
   private allTrainingDay: any[] = [];
   private allTrainingPlayers: trainingPlayer[] = [];
   private allTrainingPlayersOfCoachs: trainingPlayer[] = [];
   private allTrainingPlayersOfCurrentLogged: trainingPlayer[] = [];
   private allTrainingPlayersOfUserCoachConnected: trainingPlayer[] = [];
   private allUsers: user[] = [];
   private allUsersOfTeamOfCurrentUser: user[] = [];
   private allVersions: strings[] = [];
   private allWorkgroups: workgroup[] = [];
   private coachOfCurrentLogged: user = null;
   private currentChampionship: championship;
   private isInternalCacheLoadInProgress: boolean = false;
   private isWebApiShouldBeCalled: boolean = false;
   private isCacheIsInLoading: boolean = false;
   private networkStatus$: Subscription = Subscription.EMPTY;
   private previousChampionship: championship;
   private queryFiltersOnChampionship = [];
   private queryFiltersOnTeam = [];
   private sportsClub: sportsClub = null;
   private teamOfCurrentUser: team = null;
   private isQueryOnTimeoutSubject: number = 0;
   private isAutomaticCheckCalled: boolean = false;
   public isNetworkStatusOnline: boolean = false;
   public hubConnection: signalR.HubConnection;

   private _allMembersByAllTeam;
   private _allMembersByFirstname;
   private _allMembersByLastname;
   private _allMembersByRole;
   private _allMembersByTeam;

   constructor(
      private frameworkService: FrameworkService,
      private accessRightsService: AccessRightsService,
      private lastUpdatesService: LastUpdatesService,
      private userService: UserService,
      private championshipService: ChampionshipService,
      private buildingTrainingClosingDayService: BuildingTrainingClosingDayService,
      private homeNewsService: HomeNewsService,
      private teamService: TeamService,
      private matchService: MatchService,
      private trainingPlayerService: TrainingPlayerService,
      private comiteeMeetingService: ComiteeMeetingService,
      private workgroupService: WorkgroupService,
      private activityService: ActivityService,
      private sponsorService: SponsorService,
      private settingService: SettingService,
      private versionningService: VersionningService,
      private todoService: TodoService,
      private sportsClubService: SportsClubService,
      private rankingService: RankingService,
      private authenticationService: AuthenticationService,
      private storageService: StorageService,
      private encryptionService: EncryptionService,
      private signalRService: SignalRService,
      private subjectService: SubjectService,
      public dialog: MatDialog
   ) {
      var isResume = true;

      this.checkNetworkStatus();

      this.isCacheIsInLoading = false;
      this.isInternalCacheLoadInProgress = false;

      this.subjectService.IsPartTokenCacheLoadSubject.subscribe(cacheType => { if (cacheType != null) this.cacheLoadPartToken(cacheType) });
      this.subjectService.IsPart1CacheLoadSubject.subscribe(cacheType => { if (cacheType != null) this.cacheLoadPart1(cacheType) });
      this.subjectService.IsPart2CacheLoadSubject.subscribe(cacheType => { if (cacheType != null) this.cacheLoadPart2(cacheType) });
      this.subjectService.IsPart3CacheLoadSubject.subscribe(cacheType => { if (cacheType != null) this.cacheLoadPart3(cacheType) });
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => {
         if (!this.storageService.isDataPrivacyIsNull() && !this.storageService.isDataPrivacyAccepted() && !this.isNetworkStatusOnline && value) {
            this.isNetworkStatusOnline = value;
            isResume = false;
            //this.frameworkService.logInfo("CALL cacheLoadStart", "constructor1 (cache)");
            this.cacheLoadStart(enumCacheTypeLoad.fromWebAPI);
         }
         else
            this.isNetworkStatusOnline = value;
      });
      //BOOKMARK : IsQueryOnTimeoutSubject subscribe
      // this.subjectService.IsQueryOnTimeoutSubject.subscribe(value => {
      //    if (value == 3) this.resetIsCacheIsInLoading();
      //    this.isQueryOnTimeoutSubject = value;
      // });

      if (isResume) {
         if (!this.isInternalCacheLoadInProgress) {
            this.isInternalCacheLoadInProgress = true;

            if (!this.storageService.isDataPrivacyIsNull() && this.storageService.isDataPrivacyAccepted()) {
               //this.frameworkService.logInfo("CALL cacheLoadStart", "constructor2 (cache)");
               this.cacheLoadStart(enumCacheTypeLoad.fromIndexDB);
            }
            else {
               if (!this.storageService.isDataPrivacyIsNull() && !this.storageService.isDataPrivacyAccepted()) {
                  //this.frameworkService.logInfo("CALL cacheLoadStart", "constructor3 (cache)");
                  this.cacheLoadStart(enumCacheTypeLoad.fromWebAPI);
               }
            }
         }
      }

      this.signalRService.startConnection(false, this.subjectService);
   }

   public ngOnInit() {
   }

   public ngOnDestroy(): void {
      this.networkStatus$.unsubscribe();
   }

   public async cacheEnd(cacheType: number) {
      this._allMembersByAllTeam = this._getAllMembersByAllTeam(true);
      this._allMembersByFirstname = this._getAllMembersByFirstname();
      this._allMembersByLastname = this._getAllMembersByLastname();
      this._allMembersByRole = this._getAllMembersByRole();
      this._allMembersByTeam = this._getAllMembersByTeam();

      //-> Load foreign object (reload it if indexDB is used too)
      if (this.allTrainingPlayers != null) {
         this.loadAllTrainingPlayersForeignKeys();
      }

      if (cacheType == enumCacheTypeLoad.fromWebAPI) {
         //this.frameworkService.logInfo("setLastUpdateCookie", "CACHE / cacheEnd");
         this.storageService.setLastUpdateCookie(this.frameworkService.getTodayString());
         if (this.allEntitiesToUpdate != null) {
            if (this.allEntitiesToUpdate.map(i => i.entityName.toLowerCase()).Contains("reset")) {
               //this.frameworkService.logInfo("Reset called / SetLastResetCookie", "CACHE / cacheEnd");
               this.storageService.setLastResetCookie(this.frameworkService.getTodayString());
            }
         }
      }

      //this.frameworkService.logInfo("IsGlobalCacheLoadedSubject 2", "cacheEnd");
      this.subjectService.IsGlobalCacheLoadedSubject.next(2);
      this.subjectService.IsGlobalCacheLoadingSubject.next(false);
      this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true); //TODO JD launch event only if trainingplayer has changed

      this.isWebApiShouldBeCalled = false;
      this.subjectService.IsButtonInLoadingSubject.next(false);
      this.isInternalCacheLoadInProgress = false;
      this.isCacheIsInLoading = false;

      this.showPrivacyPopup();
   }

   public async cacheLoadStart(cacheType: enumCacheTypeLoad) {
      try {
         if (!this.isCacheIsInLoading) {
            this.isCacheIsInLoading = true;
            let isShouldBeStopped = cacheType == enumCacheTypeLoad.fromWebAPI && !this.storageService.isDataPrivacyAccepted() && !this.isNetworkStatusOnline;
            if (!isShouldBeStopped) {
               var type = "";
               switch (cacheType) {
                  case enumCacheTypeLoad.fromIndexDB:
                     type = "fromIndexDB";
                     break;
                  case enumCacheTypeLoad.fromWebAPI:
                     type = "fromWebAPI";
                     break;
                  case enumCacheTypeLoad.fromUpdate:
                     type = "fromUpdate";
                     break;
               }

               //this.frameworkService.logInfo("Type = " + type, "CACHE / cacheLoadStart");

               this.subjectService.MessageCacheSubject.next("Chargement ...");
               this.subjectService.StepNumberCacheSubject.next(0);

               this.subjectService.IsGlobalCacheLoadingSubject.next(true);

               //Check if reset should be done. If it is, call web api cachetype
               if (this.allEntitiesToUpdate != null) {
                  if (this.allEntitiesToUpdate.map(i => i.entityName.toLowerCase()).Contains("reset") && navigator.onLine) {
                     //this.frameworkService.logInfo("Reset", "CACHE / cacheLoadStart");
                     cacheType = enumCacheTypeLoad.fromWebAPI;
                  }
               }

               //Remove all data from the browser (reset)
               if (cacheType == enumCacheTypeLoad.fromWebAPI && navigator.onLine) {
                  //this.frameworkService.logInfo("Close IndexDB", "CACHE / cacheLoadStart");
                  db.close();
                  //this.frameworkService.logInfo("Delete IndexDB", "CACHE / cacheLoadStart");
                  db.delete().then(() => {
                     //this.frameworkService.logInfo("IndexDB successfully deleted", "CACHE / cacheLoadStart");
                  }).catch((err) => {
                     //this.frameworkService.logInfo("IndexDB could not deleted", "CACHE / cacheLoadStart");
                  }).finally(() => {
                     if (this.storageService.isDataPrivacyAccepted()) {
                        //this.frameworkService.logInfo("initialize IndexDB", "CACHE / cacheLoadStart");
                        db.initializeDB();
                     }
                     this.resumeCacheLoadStart(cacheType);
                  });
               }
               else
                  this.resumeCacheLoadStart(cacheType);
            }
         }
      }
      catch (error) {
         //this.frameworkService.logInfo("error = " + error, "CACHE ERROR / cacheLoadStart");
         this.setCacheEndOnError();
      }
   }

   public async resumeCacheLoadStart(cacheType: enumCacheTypeLoad) {
      try {

         if (this.storageService.isDataPrivacyAccepted()) {
            //this.frameworkService.logInfo("Open IndexDB", "CACHE / cacheLoadStart");
            db.open();
            this.isWebApiShouldBeCalled = await db.sportsClubDB.count() == 0;
         }

         if (this.isWebApiShouldBeCalled) {
            cacheType = enumCacheTypeLoad.fromWebAPI;
         }

         if (cacheType == enumCacheTypeLoad.fromWebAPI || cacheType == enumCacheTypeLoad.fromUpdate) {
            //this.frameworkService.logInfo("Chargement en ligne ...", "CACHE / cacheLoadStart");
            this.subjectService.MessageCacheSubject.next("Chargement en ligne ...");
         }

         else {
            //this.frameworkService.logInfo("Chargement hors ligne ...", "CACHE / cacheLoadStart");
            this.subjectService.MessageCacheSubject.next("Chargement hors ligne ...");
         }

         if (this.isWebApiShouldBeCalled) {
            if (!navigator.onLine) {
               this.isInternalCacheLoadInProgress = false;
               return;
            }
         }

         let forkJoinSource: Record<string, ObservableInput<any>> = {};
         forkJoinSource["None"] = of(1);

         await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB0[enumIndexDB0.versionning], null, null);

         forkJoin(forkJoinSource).subscribe({
            next: async data => {
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB0[enumIndexDB0.versionning], data);
               this.cacheSaveToIndexDB(cacheType, enumIndexDB0[enumIndexDB0.versionning]);
               this.subjectService.IsVersionCacheLoadedSubject.next(true);

               this.subjectService.IsPartTokenCacheLoadSubject.next(cacheType);
            },
            error: err => {
               //this.frameworkService.logInfo(err, "CACHE ERROR FJ / resumeCacheLoadStart");
               this.setCacheEndOnError();
            }
         });
      }
      catch (error) {
         //this.frameworkService.logInfo("error = " + error, "CACHE ERROR / resumeCacheLoadStart");
         this.setCacheEndOnError();
      }
   }

   public showPrivacyPopup() {
      const dialogData = new DataPrivacyDialogModel("Protection de vos données");

      if (this.storageService.getDataPrivacyCookie() == null) {
         //this.frameworkService.logInfo("dialog open ", "DataPrivacy (app)");
         const dialogRef = this.dialog.open(DataPrivacyDialogComponent, {
            maxWidth: "100%",
            width: "100%",
            maxHeight: "100%",
            height: "100%",
            data: dialogData
         });

         dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult) {
               //Set privacy cookie
               this.storageService.setDataPrivacyCookie(true);
               //this.frameworkService.logInfo("showPrivacyPopup", "Popup data privacy (cache)");
               this.cacheLoadStart(enumCacheTypeLoad.fromWebAPI);
            }
            else {
               this.resetDataForPrivacyOff();
            }
         });
      }
   }

   public async loadUpdatedData(isForced: boolean, isAutomaticCheckCalled: boolean) {
      try {
         //this.frameworkService.logInfo(isAutomaticCheckCalled, "CACHE loadUpdatedData / isAutomaticCheckCalled");
         //this.frameworkService.logInfo(this.isQueryOnTimeoutSubject, "CACHE loadUpdatedData / isQueryOnTimeoutSubject");

         this.isAutomaticCheckCalled = isAutomaticCheckCalled;

         let isResume = true;
         if (isAutomaticCheckCalled && this.isQueryOnTimeoutSubject > 1) isResume = false;

         this.frameworkService.logInfo(isResume, "CACHE loadUpdatedData / isResume");


         if (!this.storageService.isDataPrivacyIsNull() && isResume) {
            if (!this.storageService.isDataPrivacyAccepted() && isForced)
               this.isWebApiShouldBeCalled = true;


            if (this.isWebApiShouldBeCalled && this.isNetworkStatusOnline) {
               //this.frameworkService.logInfo("CHECK IF DATA SHOULD BE UPDATED / isWebApiShouldBeCalled", "CACHE");
               if (!this.isInternalCacheLoadInProgress) {
                  this.isInternalCacheLoadInProgress = true;
                  //this.frameworkService.logInfo("CALL cacheLoadStart", "loadUpdatedData1 (cache)");
                  this.cacheLoadStart(enumCacheTypeLoad.fromWebAPI);
               }
            }
            else {
               //this.frameworkService.logInfo("CHECK IF DATA SHOULD BE UPDATED / NOT isWebApiShouldBeCalled", "CACHE");
               let forkJoinSource: Record<string, ObservableInput<any>> = {};

               forkJoinSource = {};

               var request = new entityUpdatedRequest();

               request.lastClientUpdate = new Date(1970, 1, 1);
               request.lastClientReset = new Date(1970, 1, 1);

               if (this.storageService.getLastUpdateCookie() != null)
                  request.lastClientUpdate = this.frameworkService.getDateTimeFromDateTimeString(this.storageService.getLastUpdateCookie());

               if (this.storageService.getLastResetCookie() != null)
                  request.lastClientReset = this.frameworkService.getDateTimeFromDateTimeString(this.storageService.getLastResetCookie());

               forkJoinSource[enumIndexDB1[enumIndexDB1.lastupdates]] = this.lastUpdatesService.getEntityUpdated(request);

               forkJoin(forkJoinSource).subscribe({
                  next: data => {
                     //Retrieve all entities to update

                     //console.log("DECRYPT 4");
                     this.allEntitiesToUpdate = this.encryptionService.decryptObject(false, data[enumIndexDB1[enumIndexDB1.lastupdates]].value);
                     //this.frameworkService.logInfo("CHECK DATA TO UPDATED", "CACHE");
                     //this.frameworkService.logInfo(this.allEntitiesToUpdate, "allEntitiesToUpdate");

                     if (this.allEntitiesToUpdate != null) {
                        if (this.allEntitiesToUpdate.length > 0) {
                           this.frameworkService.logInfo("DATA TO UPDATED : " + this.allEntitiesToUpdate.map(i => i.entityName.toLowerCase()), "CACHE");
                           this.isInternalCacheLoadInProgress = true;
                           //this.frameworkService.logInfo("CALL cacheLoadStart", "loadUpdatedData2 (cache)");
                           this.cacheLoadStart(enumCacheTypeLoad.fromUpdate);
                        }
                        else
                           this.frameworkService.logInfo("NO DATA TO UPDATED", "CACHE");
                     }

                     this.subjectService.IsButtonInLoadingSubject.next(false);
                     //this.frameworkService.logInfo("SetLastUpdateCookie", "CACHE / loadUpdatedData");
                     this.storageService.setLastUpdateCookie(this.frameworkService.getTodayString());
                     //this.frameworkService.logInfo("SetLastResetCookie", "CACHE / loadUpdatedData");
                     this.storageService.setLastResetCookie(this.frameworkService.getTodayString());
                  },
                  error: err => {
                     //this.frameworkService.logInfo(err, "CACHE ERROR FJ / loadUpdatedData");
                     this.setCacheEndOnError();
                  }
               });
            }
         }
      }
      catch (error) {
         //this.frameworkService.logInfo("error = " + error, "CACHE ERROR / loadUpdatedData");
         this.setCacheEndOnError();
      }
   }

   private setCacheEndOnError() {
      //if (this.signalRService != undefined) this.signalRService.closeHubConnection();
      this.subjectService.IsQueryOnTimeoutSubject.next(1);

      if (this.isAutomaticCheckCalled && (this.storageService.isDataPrivacyIsNull || !this.storageService.isDataPrivacyAccepted)) {
         //Show the spinner
         //this.frameworkService.logInfo("IsGlobalCacheLoadedSubject 0", "setCacheEndOnError");
         //this.subjectService.IsGlobalCacheLoadedSubject.next(0);
      }
      else {
         if (this.isAutomaticCheckCalled) {
            //this.frameworkService.logInfo("IsGlobalCacheLoadedSubject 2", "setCacheEndOnError");
            this.subjectService.IsGlobalCacheLoadedSubject.next(2);
         }
         else {
            //Show the spinner
            //this.frameworkService.logInfo("IsGlobalCacheLoadedSubject 0", "setCacheEndOnError");
            //this.subjectService.IsGlobalCacheLoadedSubject.next(0);
         }
         this.subjectService.IsGlobalCacheLoadingSubject.next(false);
         this.subjectService.IsButtonInLoadingSubject.next(false);
         this.isInternalCacheLoadInProgress = false;
      }
      this.isCacheIsInLoading = false;
   }

   public resetIsCacheIsInLoading() {
      this.isCacheIsInLoading = false;
      this.subjectService.IsGlobalCacheLoadingSubject.next(false);
      //this.frameworkService.logInfo("IsGlobalCacheLoadedSubject 2","resetIsCacheIsInLoading");
   }

   public cacheAddToForkJoinSource(cacheType: enumCacheTypeLoad, forkJoinSource: Record<string, ObservableInput<any>>, entityName: string, queryFiltersOnChampionship: any[], queryFiltersOnTeam: any[]) {
      if (cacheType != enumCacheTypeLoad.fromIndexDB) {
         //Load token if indexDB is used and navigator is online (for future use of WEB API)
         if (navigator.onLine) {
            switch (entityName) {
               case enumIndexDB0[enumIndexDB0.token]:
                  //TOKEN MANAGEMENT
                  //Check if token is not expired
                  var isTokenExpired = ((Date.now() - new Date(this.storageService.getTokenFromStorage()?.refreshTokenExpiryTime).getTime()) > 0);
                  //this.frameworkService.logInfo("Is token epxired = " + isTokenExpired, "CACHE / cacheAddToForkJoinSource");
                  //Below: If Access Token Expired and no refresh of it currently running
                  if (isTokenExpired) {
                     if (this.isQueryOnTimeoutSubject == null || this.isQueryOnTimeoutSubject == 0)
                        forkJoinSource[entityName] = this.authenticationService.refreshToken(this.storageService.getTokenFromStorage());
                     else
                        forkJoinSource[entityName] = of(1);
                  }
                  else
                     forkJoinSource[entityName] = of(1);
                  return;
            }
         }
      }
      else
         forkJoinSource[entityName] = of(1);

      let resume = true;
      if (this.allEntitiesToUpdate != null)
         resume = this.allEntitiesToUpdate.map(i => i.entityName.toLowerCase()).includes(entityName.toLowerCase());

      if ((cacheType == enumCacheTypeLoad.fromWebAPI && navigator.onLine) ||
         (cacheType == enumCacheTypeLoad.fromUpdate && navigator.onLine && resume)) {
         switch (entityName) {
            case enumIndexDB0[enumIndexDB0.token]:
               //TOKEN MANAGEMENT
               //Check if token is not expired
               var isTokenExpired = ((Date.now() - new Date(this.storageService.getTokenFromStorage()?.refreshTokenExpiryTime).getTime()) > 0);
               //this.frameworkService.logInfo("Is token epxired = " + isTokenExpired, "CACHE / cacheAddToForkJoinSource 1");
               //Below: If Access Token Expired
               if (isTokenExpired) {
                  forkJoinSource[entityName] = this.authenticationService.refreshToken(this.storageService.getTokenFromStorage());
               }
               else {
                  forkJoinSource[entityName] = of(1);
               }
               break;
            case enumIndexDB0[enumIndexDB0.versionning]:
               forkJoinSource[entityName] = this.versionningService.getAllVersions();
               break;
            case enumIndexDB1[enumIndexDB1.sportsclub]:
               forkJoinSource[entityName] = this.sportsClubService.getAllActiveWithoutIncluding([]);
               break;
            case enumIndexDB1[enumIndexDB1.todo]:
               forkJoinSource[entityName] = this.todoService.getAllTodos();
               break;
            case enumIndexDB1[enumIndexDB1.lastupdates]:
               forkJoinSource[entityName] = this.lastUpdatesService.getAllActiveWithoutIncluding([]);
               break;
            case enumIndexDB1[enumIndexDB1.championship]:
               forkJoinSource[entityName] = this.championshipService.getAllActiveWithoutIncluding([]);
               break;
            case enumIndexDB1[enumIndexDB1.workgroup]:
               forkJoinSource[entityName] = this.workgroupService.getAllActiveWithoutIncluding([]);
               break;
            case enumIndexDB1[enumIndexDB1.setting]:
               forkJoinSource[entityName] = this.settingService.getAllActiveWithoutIncluding([]);
               break;
            case enumIndexDB2[enumIndexDB2.buildingtrainingclosingday]:
               forkJoinSource[entityName] = this.buildingTrainingClosingDayService.getAllActiveWithoutIncluding(queryFiltersOnChampionship);
               break;
            case enumIndexDB2[enumIndexDB2.homenews]:
               forkJoinSource[entityName] = this.homeNewsService.getAllWithoutIncluding(queryFiltersOnChampionship);
               break;
            case enumIndexDB2[enumIndexDB2.team]:
               forkJoinSource[entityName] = this.teamService.getAllActiveWithoutIncluding(queryFiltersOnChampionship);
               break;
            case enumIndexDB2[enumIndexDB2.sponsor]:
               forkJoinSource[entityName] = this.sponsorService.getAllActiveWithoutIncluding(queryFiltersOnChampionship);
               break;
            case enumIndexDB2[enumIndexDB2.activity]:
               forkJoinSource[entityName] = this.activityService.getAllWithoutIncluding(queryFiltersOnChampionship);
               break;
            case enumIndexDB3[enumIndexDB3.user]:
               forkJoinSource[entityName] = this.userService.getAllWithoutIncluding(queryFiltersOnTeam);
               break;
            case enumIndexDB3[enumIndexDB3.ranking]:
               forkJoinSource[entityName] = this.rankingService.getAllActiveWithoutIncluding(queryFiltersOnTeam);
               break;
            case enumIndexDB3[enumIndexDB3.match]:
               forkJoinSource[entityName] = this.matchService.getAllActiveWithoutIncluding(queryFiltersOnChampionship);
               break;
            case enumIndexDB3[enumIndexDB3.trainingplayer]:
               forkJoinSource[entityName] = this.trainingPlayerService.getAllActiveWithoutIncluding(queryFiltersOnChampionship);
               break;
            case enumIndexDB3[enumIndexDB3.comiteemeeting]:
               forkJoinSource[entityName] = this.comiteeMeetingService.getAllActiveWithoutIncluding(queryFiltersOnChampionship);
               break;
         }
      }
   }

   public async cacheGetFromForkJoinSource(cacheType: enumCacheTypeLoad, entityName: string, data: any) {
      try {
         if (cacheType == enumCacheTypeLoad.fromIndexDB) {
            //Refresh token if indexDB is used and navigator is online (for future use of WEB API)
            if (navigator.onLine) {
               switch (entityName) {
                  case enumIndexDB0[enumIndexDB0.token]:
                     if (data[entityName] != null && data[entityName] != 1) {
                        //this.frameworkService.logInfo("saveToken", "CACHE / cacheGetFromForkJoinSource");
                        this.storageService.saveToken(data[entityName]);
                     }
                     return;
               }
            }
         }

         let resume = true;
         if (this.allEntitiesToUpdate != null)
            resume = this.allEntitiesToUpdate.map(i => i.entityName.toLowerCase()).includes(entityName.toLowerCase());

         if ((cacheType == enumCacheTypeLoad.fromWebAPI && navigator.onLine) ||
            (cacheType == enumCacheTypeLoad.fromUpdate && navigator.onLine && resume)) {

            switch (entityName) {
               case enumIndexDB0[enumIndexDB0.token]:
                  if (data[entityName] != null && data[entityName] != 1) {
                     this.storageService.saveToken(data[entityName]);
                  }
                  break;
               case enumIndexDB0[enumIndexDB0.versionning]:
                  this.allVersions = data[entityName].toString().replace(/\r\n/g, '\n').split('\n').map((x) => {
                     return { value: x };
                  });
                  break;
               case enumIndexDB1[enumIndexDB1.sportsclub]:
                  //console.log("DECRYPT 3A");
                  this.sportsClub = this.encryptionService.decryptObject(false, data[entityName].value)[0];
                  break;
               case enumIndexDB1[enumIndexDB1.todo]:
                  this.allTodos = data[entityName].toString().replace(/\r\n/g, '\n').split('\n').map((x) => {
                     return { value: x };
                  });
                  break;
               case enumIndexDB1[enumIndexDB1.lastupdates]:
                  //console.log("DECRYPT 3B");
                  this.allLastUpdates = this.encryptionService.decryptObject(false, data[entityName].value);
                  break;
               case enumIndexDB1[enumIndexDB1.championship]:
                  //this.allChampionships = data[entityName];
                  //console.log("DECRYPT 3C");
                  this.allChampionships = this.encryptionService.decryptObject(false, data[entityName].value);
                  break;
               case enumIndexDB1[enumIndexDB1.workgroup]:
                  //console.log("DECRYPT 3D");
                  this.allWorkgroups = this.encryptionService.decryptObject(false, data[entityName].value);
                  break;
               case enumIndexDB1[enumIndexDB1.setting]:
                  //console.log("DECRYPT 3E");
                  this.allSettings = this.encryptionService.decryptObject(false, data[entityName].value);
                  break;
               case enumIndexDB2[enumIndexDB2.buildingtrainingclosingday]:
                  //console.log("DECRYPT 3F");
                  this.allBuildingTrainingClosingDay = this.encryptionService.decryptObject(false, data[entityName].value);
                  break;
               case enumIndexDB2[enumIndexDB2.homenews]:
                  //console.log("DECRYPT 3G");
                  this.allHomeNews = this.encryptionService.decryptObject(false, data[entityName].value);
                  break;
               case enumIndexDB2[enumIndexDB2.team]:
                  //console.log("DECRYPT 3H");
                  this.allTeams = this.encryptionService.decryptObject(false, data[entityName].value);
                  break;
               case enumIndexDB2[enumIndexDB2.sponsor]:
                  //console.log("DECRYPT 3I");
                  this.allSponsors = (this.encryptionService.decryptObject(false, data[entityName].value)).OrderBy(i => i.order).ToArray();
                  break;
               case enumIndexDB2[enumIndexDB2.activity]:
                  //console.log("DECRYPT 3J");
                  this.allActivities = this.encryptionService.decryptObject(false, data[entityName].value);
                  break;
               case enumIndexDB3[enumIndexDB3.user]:
                  //console.log("DECRYPT 3K");
                  this.allUsers = this.encryptionService.decryptObject(false, data[entityName].value);
                  break;
               case enumIndexDB3[enumIndexDB3.ranking]:
                  //console.log("DECRYPT 3L");
                  this.allRanking = this.encryptionService.decryptObject(false, data[entityName].value);
                  break;
               case enumIndexDB3[enumIndexDB3.match]:
                  //console.log("DECRYPT 3M");
                  this.allMatchs = this.encryptionService.decryptObject(false, data[entityName].value);
                  break;
               case enumIndexDB3[enumIndexDB3.trainingplayer]:
                  if (this.getUserLogged() != null) {
                     //console.log("DECRYPT 3N");
                     this.allTrainingPlayers = this.encryptionService.decryptObject(false, data[entityName].value);
                  }

                  break;
               case enumIndexDB3[enumIndexDB3.comiteemeeting]:
                  if (this.getUserLogged() != null && this.frameworkService.isUserInComitee(this.getUserLogged())) {
                     //console.log("DECRYPT 3O");
                     this.allComiteeMeetings = this.encryptionService.decryptObject(false, data[entityName].value);
                  }

                  this.subjectService.IsComiteeMeetingCacheLoadedSubject.next(true);
                  break;
            }
         }
         else {
            if (cacheType != enumCacheTypeLoad.fromUpdate) {
               switch (entityName) {
                  case enumIndexDB0[enumIndexDB0.versionning]:
                     if (this.allVersions?.length == 0) this.allVersions = await db.versionningDB.toArray();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.allVersions == null) + " / " + this.allVersions?.length + "]");
                     break;
                  case enumIndexDB1[enumIndexDB1.sportsclub]:
                     if (this.sportsClub == null) this.sportsClub = (await db.sportsClubDB.toArray()).FirstOrDefault();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.sportsClub == null) + "]");
                     break;
                  case enumIndexDB1[enumIndexDB1.todo]:
                     if (this.allTodos?.length == 0) this.allTodos = await db.todoDB.toArray();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.allTodos == null) + " / " + this.allTodos?.length + "]");
                     break;
                  case enumIndexDB1[enumIndexDB1.lastupdates]:
                     if (this.allLastUpdates?.length == 0) this.allLastUpdates = await db.lastUpdatesDB.toArray();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.allLastUpdates == null) + " / " + this.allLastUpdates?.length + "]");
                     break;
                  case enumIndexDB1[enumIndexDB1.championship]:
                     if (this.allChampionships?.length == 0) this.allChampionships = await db.championshipsDB.toArray();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.allChampionships == null) + " / " + this.allChampionships?.length + "]");
                     break;
                  case enumIndexDB1[enumIndexDB1.workgroup]:
                     if (this.allWorkgroups?.length == 0) this.allWorkgroups = await db.workgroupsDB.toArray();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.allWorkgroups == null) + " / " + this.allWorkgroups?.length + "]");
                     break;
                  case enumIndexDB1[enumIndexDB1.setting]:
                     if (this.allSettings?.length == 0) this.allSettings = await db.settingsDB.toArray();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.allSettings == null) + " / " + this.allSettings?.length + "]");
                     break;
                  case enumIndexDB2[enumIndexDB2.buildingtrainingclosingday]:
                     if (this.allBuildingTrainingClosingDay?.length == 0) this.allBuildingTrainingClosingDay = await db.buildingTrainingClosingDayDB.toArray();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.allBuildingTrainingClosingDay == null) + " / " + this.allBuildingTrainingClosingDay?.length + "]");
                     break;
                  case enumIndexDB2[enumIndexDB2.homenews]:
                     if (this.allHomeNews?.length == 0) this.allHomeNews = await db.homeNewsDB.toArray();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.allHomeNews == null) + " / " + this.allHomeNews?.length + "]");
                     break;
                  case enumIndexDB2[enumIndexDB2.team]:
                     if (this.allTeams?.length == 0) this.allTeams = await db.teamsDB.toArray();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.allTeams == null) + " / " + this.allTeams?.length + "]");
                     break;
                  case enumIndexDB2[enumIndexDB2.sponsor]:
                     if (this.allSponsors?.length == 0) this.allSponsors = (await ((await db.sponsorsDB).toArray())).ToArray().OrderBy(i => i.order).ToArray();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.allSponsors == null) + " / " + this.allSponsors?.length + "]");
                     break;
                  case enumIndexDB2[enumIndexDB2.activity]:
                     if (this.allActivities?.length == 0) this.allActivities = await db.activitiesDB.toArray();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.allActivities == null) + " / " + this.allActivities?.length + "]");
                     break;
                  case enumIndexDB3[enumIndexDB3.user]:
                     if (this.allUsers?.length == 0) this.allUsers = await db.usersDB.toArray();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.allUsers == null) + " / " + this.allUsers?.length + "]");
                     break;
                  case enumIndexDB3[enumIndexDB3.ranking]:
                     if (this.allRanking?.length == 0) this.allRanking = await db.rankingsDB.toArray();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.allRanking == null) + " / " + this.allRanking?.length + "]");
                     break;
                  case enumIndexDB3[enumIndexDB3.match]:
                     if (this.allMatchs?.length == 0) this.allMatchs = await db.matchsDB.toArray();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.allMatchs == null) + " / " + this.allMatchs?.length + "]");
                     break;
                  case enumIndexDB3[enumIndexDB3.trainingplayer]:
                     if (this.allTrainingPlayers?.length == 0) this.allTrainingPlayers = await db.trainingPlayersDB.toArray();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.allTrainingPlayers == null) + " / " + this.allTrainingPlayers?.length + "]");
                     break;
                  case enumIndexDB3[enumIndexDB3.comiteemeeting]:
                     if (this.allComiteeMeetings?.length == 0) this.allComiteeMeetings = await db.comiteeMeetingsDB.toArray();
                     //console.log("-> " + entityName + " <= INDEX DB [" + (this.allComiteeMeetings == null) + " / " + this.allComiteeMeetings?.length + "]");
                     break;
               }
            }
         }
      }
      catch (error) {
         //this.frameworkService.logInfo("error = " + error, "CACHE / cacheGetFromForkJoinSource");
         this.setCacheEndOnError();
      }
   }

   public async cacheLoadPartToken(cacheType: number) {
      try {
         let forkJoinSource: Record<string, ObservableInput<any>> = {};
         forkJoinSource["None"] = of(1);
         await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB0[enumIndexDB0.token], null, null);

         forkJoin(forkJoinSource).subscribe({
            next: async data => {
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB0[enumIndexDB0.token], data);

               this.subjectService.IsPart1CacheLoadSubject.next(cacheType);
            },
            error: err => {
               //this.frameworkService.logInfo(err, "CACHE ERROR FJ / cacheLoadPartToken");
               this.setCacheEndOnError();
            }
         });
      }
      catch (error) {
         //this.frameworkService.logInfo("error = " + error, "CACHE ERROR / cacheLoadPartToken");
         this.setCacheEndOnError();
      }
   }

   public async cacheLoadPart1(cacheType: number) {
      try {
         //this.frameworkService.logInfo(cacheType, "cacheLoadPart1 cacheType");
         this.subjectService.StepNumberCacheSubject.next(33);
         if (cacheType == enumCacheTypeLoad.fromWebAPI || cacheType == enumCacheTypeLoad.fromUpdate) {
            this.subjectService.MessageCacheSubject.next("Chargement en ligne ... 1/3");
         }
         else {
            this.subjectService.MessageCacheSubject.next("Chargement hors ligne ... 1/3");
         }

         const queryFilters = [];
         let forkJoinSource: Record<string, ObservableInput<any>> = {};
         forkJoinSource["None"] = of(1);
         await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB1[enumIndexDB1.sportsclub], null, null);
         await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB1[enumIndexDB1.todo], null, null);
         await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB1[enumIndexDB1.lastupdates], null, null);
         await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB1[enumIndexDB1.championship], null, null);
         await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB1[enumIndexDB1.workgroup], null, null);
         await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB1[enumIndexDB1.setting], null, null);

         forkJoin(forkJoinSource).subscribe({
            next: async data => {
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB1[enumIndexDB1.sportsclub], data);
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB1[enumIndexDB1.todo], data);
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB1[enumIndexDB1.lastupdates], data);
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB1[enumIndexDB1.championship], data);
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB1[enumIndexDB1.workgroup], data);
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB1[enumIndexDB1.setting], data);

               this.cachePart1(cacheType);

               this.cacheSaveToIndexDB(cacheType, enumIndexDB1[enumIndexDB1.sportsclub]);
               this.cacheSaveToIndexDB(cacheType, enumIndexDB1[enumIndexDB1.todo]);
               this.cacheSaveToIndexDB(cacheType, enumIndexDB1[enumIndexDB1.lastupdates]);
               this.cacheSaveToIndexDB(cacheType, enumIndexDB1[enumIndexDB1.championship]);
               this.cacheSaveToIndexDB(cacheType, enumIndexDB1[enumIndexDB1.workgroup]);
               this.cacheSaveToIndexDB(cacheType, enumIndexDB1[enumIndexDB1.setting]);

               this.subjectService.IsPart2CacheLoadSubject.next(cacheType);
            },
            error: err => {
               //this.frameworkService.logInfo(err, "CACHE ERROR FJ / cacheLoadPart1");
               this.setCacheEndOnError();
            }
         });
      }
      catch (error) {
         //this.frameworkService.logInfo("error = " + error, "CACHE ERROR / cacheLoadPart1");
         this.setCacheEndOnError();
      }
   }

   public async cacheLoadPart2(cacheType: number) {
      try {
         this.subjectService.StepNumberCacheSubject.next(66);
         if (cacheType == enumCacheTypeLoad.fromWebAPI || cacheType == enumCacheTypeLoad.fromUpdate) {
            this.subjectService.MessageCacheSubject.next("Chargement en ligne ... 2/3");
         }
         else {
            this.subjectService.MessageCacheSubject.next("Chargement hors ligne ... 2/3");
         }

         let forkJoinSource: Record<string, ObservableInput<any>> = {};
         forkJoinSource["None"] = of(1);
         await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB2[enumIndexDB2.buildingtrainingclosingday], this.queryFiltersOnChampionship, null);
         await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB2[enumIndexDB2.homenews], this.queryFiltersOnChampionship, null);
         await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB2[enumIndexDB2.team], this.queryFiltersOnChampionship, null);
         await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB2[enumIndexDB2.sponsor], this.queryFiltersOnChampionship, null);
         await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB2[enumIndexDB2.activity], this.queryFiltersOnChampionship, null);

         forkJoin(forkJoinSource).subscribe({
            next: async data => {
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB2[enumIndexDB2.buildingtrainingclosingday], data);
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB2[enumIndexDB2.homenews], data);
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB2[enumIndexDB2.team], data);
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB2[enumIndexDB2.sponsor], data);
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB2[enumIndexDB2.activity], data);

               this.cachePart2(cacheType);

               this.cacheSaveToIndexDB(cacheType, enumIndexDB2[enumIndexDB2.buildingtrainingclosingday]);
               this.cacheSaveToIndexDB(cacheType, enumIndexDB2[enumIndexDB2.homenews]);
               this.cacheSaveToIndexDB(cacheType, enumIndexDB2[enumIndexDB2.team]);
               this.cacheSaveToIndexDB(cacheType, enumIndexDB2[enumIndexDB2.sponsor]);
               this.cacheSaveToIndexDB(cacheType, enumIndexDB2[enumIndexDB2.activity]);

               this.subjectService.IsPart3CacheLoadSubject.next(cacheType);
            },
            error: err => {
               //this.frameworkService.logInfo(err, "CACHE ERROR FJ / cacheLoadPart2");
               this.setCacheEndOnError();
            }
         });
      }
      catch (error) {
         //this.frameworkService.logInfo("error = " + error, "CACHE ERROR / cacheLoadPart2");
         this.setCacheEndOnError();
      }
   }

   public async cacheLoadPart3(cacheType: number) {
      try {
         this.subjectService.StepNumberCacheSubject.next(100);
         if (cacheType == enumCacheTypeLoad.fromWebAPI || cacheType == enumCacheTypeLoad.fromUpdate) {
            this.subjectService.MessageCacheSubject.next("Chargement en ligne ... 3/3");
         }
         else {
            this.subjectService.MessageCacheSubject.next("Chargement hors ligne ... 3/3");
         }

         let forkJoinSource: Record<string, ObservableInput<any>> = {};
         forkJoinSource["None"] = of(1);
         await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB3[enumIndexDB3.user], null, this.queryFiltersOnTeam);
         await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB3[enumIndexDB3.ranking], null, this.queryFiltersOnTeam);
         await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB3[enumIndexDB3.match], this.queryFiltersOnChampionship, null);

         if (this.getUserLogged() != null) {
            // (only for member logged) 
            await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB3[enumIndexDB3.trainingplayer], this.queryFiltersOnChampionship, null);
            if (this.frameworkService.isUserInComitee(this.getUserLogged())) {
               // (only for member logged and in comitee)
               await this.cacheAddToForkJoinSource(cacheType, forkJoinSource, enumIndexDB3[enumIndexDB3.comiteemeeting], this.queryFiltersOnChampionship, null);
            }
         }

         forkJoin(forkJoinSource).subscribe({
            next: async data => {
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB3[enumIndexDB3.user], data);
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB3[enumIndexDB3.ranking], data);
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB3[enumIndexDB3.match], data);
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB3[enumIndexDB3.trainingplayer], data);
               await this.cacheGetFromForkJoinSource(cacheType, enumIndexDB3[enumIndexDB3.comiteemeeting], data);

               this.cachePart3(cacheType);

               this.cacheSaveToIndexDB(cacheType, enumIndexDB3[enumIndexDB3.user]);
               this.cacheSaveToIndexDB(cacheType, enumIndexDB3[enumIndexDB3.ranking]);
               this.cacheSaveToIndexDB(cacheType, enumIndexDB3[enumIndexDB3.match]);
               this.cacheSaveToIndexDB(cacheType, enumIndexDB3[enumIndexDB3.trainingplayer]);
               this.cacheSaveToIndexDB(cacheType, enumIndexDB3[enumIndexDB3.comiteemeeting]);

               this.cacheEnd(cacheType);

               if (cacheType == enumCacheTypeLoad.fromWebAPI || cacheType == enumCacheTypeLoad.fromUpdate) {
                  //this.frameworkService.logInfo("Fin du chargement en ligne", "CACHE");
                  this.subjectService.MessageCacheSubject.next("Fin du chargement en ligne");
               }
               else {
                  //this.frameworkService.logInfo("Fin du chargement hors ligne", "CACHE");
                  this.subjectService.MessageCacheSubject.next("Fin du chargement hors ligne");
               }
            },
            error: err => {
               //this.frameworkService.logInfo(err, "CACHE ERROR FJ / cacheLoadPart3");
               this.setCacheEndOnError();
            }
         });
      }
      catch (error) {
         //this.frameworkService.logInfo("error = " + error, "CACHE ERROR / cacheLoadPart3");
         this.setCacheEndOnError();
      }
   }

   public async cachePart1(cacheType: number) {
      try {
         //-> Set filter to current championship
         //this.frameworkService.logInfo("LOAD CACHEPART1", "CACHE / cachePart1");
         this.currentChampionship = this.allChampionships.FirstOrDefault(i => i.isCurrent);
         this.queryFiltersOnChampionship = [];
         this.queryFiltersOnChampionship.push(new queryFilter({ propertyName: "ChampionshipSysId", operation: enumQueryFilterOp.Equals, value: this.currentChampionship.sysId }));
         //<- Set filter to current championship

         //-> (only for administrator or supervisor) Set filter to previous of current championship 
         if (this.accessRightsService.isUserAdministratorOrSupervisor()) {
            this.previousChampionship = this.allChampionships.FirstOrDefault(i => Number(this.currentChampionship.yearStarting) - 1 == Number(i.yearStarting));
         }
      }
      catch (error) {
         //this.frameworkService.logInfo("error = " + error, "CACHE ERROR / cachePart1");
         this.setCacheEndOnError();
      }

   }

   public async cachePart2(cacheType: number) {
      try {
         //this.frameworkService.logInfo("LOAD CACHEPART2", "CACHE / cachePart2");
         //-> Load foreign object (reload it if indexDB is used too)
         let teamSysIds = [];
         this.allTeamsWithoutPicture = [];
         this.allTeams.forEach(i => {
            teamSysIds.push(i.sysId);
            let t = new team();
            t.clone(i);
            t.picture = "";
            this.allTeamsWithoutPicture.push(t);
         });

         //TODO JD ICI check if all tables are manage
         if (this.allBuildingTrainingClosingDay != null) {
            this.loadlAllBuildingTrainingClosingDayForeignKeys(this.allBuildingTrainingClosingDay);
         }
         //-> Load foreign object (reload it if indexDB is used too)
         if (this.allHomeNews != null) {
            this.loadAllHomeNewsForeignKeys();
         }
         //-> Load foreign object (reload it if indexDB is used too)
         if (this.allSponsors != null) {
            this.loadAllSponsorsForeignKeys();
         }
         //-> Load foreign object (reload it if indexDB is used too)
         if (this.allActivities != null) {
            this.loadAllActivitiesForeignKeys();
         }

         this.queryFiltersOnTeam = [];
         if (teamSysIds.length > 0)
            this.queryFiltersOnTeam.push(new queryFilter({ propertyName: "TeamSysId", operation: enumQueryFilterOp.Equals, value: teamSysIds }));
         //<- Load foreign object
      }
      catch (error) {
         //this.frameworkService.logInfo("error = " + error, "CACHE ERROR / cachePart2");
         this.setCacheEndOnError();
      }

   }

   public async cachePart3(cacheType: number) {
      try {
         //this.frameworkService.logInfo("LOAD CACHEPART3", "CACHE / cachePart3");
         if (this.allMatchs != null) {
            this.allMatchs = this.allMatchs.sort(function (a, b) {
               if (a.day < b.day) {
                  return -1;
               }
               if (a.day > b.day) {
                  return 1;
               }
               return 0;
            });
         }

         //-> Load foreign object (reload it if indexDB is used too)
         if (this.allRanking != null) {
            this.loadAllRankingForeignKeys();
         }
         if (this.allUsers != null) {
            this.loadAllUserForeignKeys();
         }

         if (this.allTeams != null) {
            this.loadAllTeamForeignKeys(this.allTeams);
         }

         if (this.allMatchs != null) {
            this.loadAllMatchForeignKeys();
         }

         if (this.frameworkService.isUserInComitee(this.getUserLogged()) && this.allComiteeMeetings != null) this.loadAllComiteeMeetingsForeignKeys();
         //<- Load foreign object

         if (this.getUserLogged() != null) {
            //-> REFRESH the user already stored in localstorage if some information has changed in database [like team and workgroup]
            if (this.allUsers != null) {
               let newUser = this.allUsers.FirstOrDefault(i => i.sysId == this.getUserLogged().sysId);
               if (this.allEntitiesToUpdate != null) {
                  if (this.allEntitiesToUpdate.map(i => i.entityName.toLowerCase()).Contains("reset") && navigator.onLine) {
                     //-> REFRESH the user already stored in localstorage when a reset is done for new championship
                     this.frameworkService.logInfo("Reset user", "CACHE / cachePart3");
                     newUser = this.allUsers.FirstOrDefault(i => i.lastName == this.getUserLogged().lastName && i.firstName == this.getUserLogged().firstName && i.birthdayDate == this.getUserLogged().birthdayDate);
                  }
               }

               if (newUser != null) {
                  this.storageService.updateTokenUser(newUser);
               }
            }
            //<- REFRESH the user already stored in localstorage if some information has changed in database [like team and workgroup]
         }
         this.reloadRefreshData(this.getUserLogged());
      }
      catch (error) {
         //this.frameworkService.logInfo("error = " + error, "CACHE / cachePart3");
         this.setCacheEndOnError();
      }
   }

   public cacheSaveToIndexDB(cacheType: enumCacheTypeLoad, entityName: string) {
      if (!this.storageService.isDataPrivacyAccepted())
         return;

      let resume = true;
      if (this.allEntitiesToUpdate != null)
         resume = this.allEntitiesToUpdate.map(i => i.entityName.toLowerCase()).includes(entityName.toLowerCase());

      if ((cacheType == enumCacheTypeLoad.fromWebAPI && navigator.onLine) ||
         (cacheType == enumCacheTypeLoad.fromUpdate && navigator.onLine && resume)) {
         /*TODO JD manage error ?
         db.raindrops.bulkAdd(drops).then(function(lastKey) {
    console.log("Done adding 100,000 raindrops all over the place");
    console.log("Last raindrop's id was: " + lastKey); // Will be 100000.
}).catch(Dexie.BulkError, function (e) {
    // Explicitely catching the bulkAdd() operation makes those successful
    // additions commit despite that there were errors.
    console.error ("Some raindrops did not succeed. However, " +
       100000-e.failures.length + " raindrops was added successfully");
});
         */

         switch (entityName) {
            case enumIndexDB0[enumIndexDB0.versionning]:
               db.versionningDB.clear();
               if (this.allVersions) db.versionningDB.bulkAdd(this.allVersions);
               break;
            case enumIndexDB1[enumIndexDB1.sportsclub]:
               db.sportsClubDB.clear();
               if (this.sportsClub) db.sportsClubDB.add(this.sportsClub, this.sportsClub.sysId);
               break;
            case enumIndexDB1[enumIndexDB1.todo]:
               db.todoDB.clear();
               if (this.allTodos) db.todoDB.bulkAdd(this.allTodos);
               break;
            case enumIndexDB1[enumIndexDB1.lastupdates]:
               db.lastUpdatesDB.clear();
               if (this.allLastUpdates) db.lastUpdatesDB.bulkAdd(this.allLastUpdates);
               break;
            case enumIndexDB1[enumIndexDB1.championship]:
               db.championshipsDB.clear();
               if (this.allChampionships) db.championshipsDB.bulkAdd(this.allChampionships);
               break;
            case enumIndexDB1[enumIndexDB1.workgroup]:
               db.workgroupsDB.clear();
               if (this.allWorkgroups) db.workgroupsDB.bulkAdd(this.allWorkgroups);
               break;
            case enumIndexDB1[enumIndexDB1.setting]:
               db.settingsDB.clear();
               if (this.allSettings) db.settingsDB.bulkAdd(this.allSettings);
               break;
            case enumIndexDB2[enumIndexDB2.buildingtrainingclosingday]:
               db.buildingTrainingClosingDayDB.clear();
               if (this.allBuildingTrainingClosingDay) db.buildingTrainingClosingDayDB.bulkAdd(this.allBuildingTrainingClosingDay);
               break;
            case enumIndexDB2[enumIndexDB2.homenews]:
               db.homeNewsDB.clear();
               if (this.allHomeNews) db.homeNewsDB.bulkAdd(this.allHomeNews);
               break;
            case enumIndexDB2[enumIndexDB2.team]:
               db.teamsDB.clear();
               if (this.allTeams) db.teamsDB.bulkAdd(this.allTeams);
               break;
            case enumIndexDB2[enumIndexDB2.sponsor]:
               db.sponsorsDB.clear();
               if (this.allSponsors) db.sponsorsDB.bulkAdd(this.allSponsors);
               break;
            case enumIndexDB2[enumIndexDB2.activity]:
               db.activitiesDB.clear();
               if (this.allActivities) db.activitiesDB.bulkAdd(this.allActivities);
               break;
            case enumIndexDB3[enumIndexDB3.user]:
               db.usersDB.clear();
               if (this.allUsers) db.usersDB.bulkAdd(this.allUsers);
               break;
            case enumIndexDB3[enumIndexDB3.ranking]:
               db.rankingsDB.clear();
               if (this.allRanking) db.rankingsDB.bulkAdd(this.allRanking);
               break;
            case enumIndexDB3[enumIndexDB3.match]:
               db.matchsDB.clear();
               if (this.allMatchs) db.matchsDB.bulkAdd(this.allMatchs);
               break;
            case enumIndexDB3[enumIndexDB3.trainingplayer]:
               db.trainingPlayersDB.clear();
               if (this.allTrainingPlayers) db.trainingPlayersDB.bulkAdd(this.allTrainingPlayers, { allKeys: true });
               break;
            case enumIndexDB3[enumIndexDB3.comiteemeeting]:
               db.comiteeMeetingsDB.clear();
               if (this.allComiteeMeetings) db.comiteeMeetingsDB.bulkAdd(this.allComiteeMeetings);
               break;
         }
      }
   }

   public checkNetworkStatus() {
      this.networkStatus$ = merge(
         of(null),
         fromEvent(window, 'online'),
         fromEvent(window, 'offline')
      )
         .pipe(map(() => navigator.onLine))
         .subscribe(status => {
            this.subjectService.IsNetworkStatusOnlineSubject.next(status);
            //this.frameworkService.logInfo(status, "IsNetworkStatusOnlineSubject");
            if (status) {
               //this.frameworkService.logInfo("loadUpdatedData", "checkNetworkStatus");
               this.loadUpdatedData(false, true);
            }
            else {
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
         });
   }

   public getAllActiveActivities() {
      return this.allActivities == null ? [] : this.allActivities.filter(i => i.sysIsActive);
   }

   public getAllActiveHomeNews() {
      return this.allHomeNews == null ? [] : this.allHomeNews.filter(i => i.sysIsActive);
   }

   public getAllActivities() {
      return this.allActivities == null ? [] : this.allActivities;
   }

   public getAllBuildingTrainingClosingDay() {
      return this.allBuildingTrainingClosingDay == null ? [] : this.allBuildingTrainingClosingDay;
   }

   public getAllChampionships() {
      return this.allChampionships == null ? [] : this.allChampionships;
   }

   public getAllClubEvents() {
      return this.allClubEvents;
   }

   public getAllClubEventsOfUserAuthenticated() {
      return this.allClubEventsOfUserAuthenticated;
   }

   public getAllCoachsOfClub() {
      return this.allUsers == null ? [] : this.allUsers.filter(i => this.frameworkService.isMemberCoachOnly(i));
   }

   public getAllCoachsOfCurrentLogged() {
      return this.allCoachsOfCurrentLogged == null ? [] : this.allCoachsOfCurrentLogged;
   }

   public getAllComiteeMeetings() {
      return this.allComiteeMeetings == null ? [] : this.allComiteeMeetings;
   }

   public getAllHomeNews() {
      return this.allHomeNews == null ? [] : this.allHomeNews;
   }

   public getAllInactiveActivities() {
      return this.allActivities == null ? [] : this.allActivities.filter(i => !i.sysIsActive);
   }

   public getAllInactiveHomeNews() {
      return this.allHomeNews == null ? [] : this.allHomeNews.filter(i => !i.sysIsActive);
   }

   public getAllLastUpdates() {
      return this.allLastUpdates == null ? [] : this.allLastUpdates;
   }

   public getAllMatchs() {
      return this.allMatchs == null ? [] : this.allMatchs;
   }

   public getAllMatchsOfTeamAndClub() {
      return this.allMatchsOfTeamAndClub == null ? [] : this.allMatchsOfTeamAndClub;
   }

   public getAllMatchsOfUserTeamAndClub() {
      // Liste des matchs officiel, non officiel (amical + tournoi), jours de fermeture du club de l'équipe du joueur connecté"
      return this.allMatchsOfUserTeamAndClub == null ? [] : this.allMatchsOfUserTeamAndClub;
   }

   public getAllMatchsOfUserTeamAndClubAndOverCurrentDayCS() {
      // Liste des matchs officiel, non officiel (amical + tournoi), jours de fermeture FUTUR du club de l'équipe du joueur connecté"
      return this.allMatchsOfUserTeamAndClubAndOverCurrentDayCS == null ? [] : this.allMatchsOfUserTeamAndClubAndOverCurrentDayCS;
   }

   public getAllRanking() {
      return this.allRanking == null ? [] : this.allRanking;
   }

   public getAllSettings() {
      return this.allSettings == null ? [] : this.allSettings;
   }

   public getAllSponsors() {
      return this.allSponsors == null ? [] : this.allSponsors;
   }

   public getAllTeams() {
      return this.allTeams == null ? [] : this.allTeams;
   }

   public getAllTeamsWithoutPicture() {
      return this.allTeamsWithoutPicture == null ? [] : this.allTeamsWithoutPicture;
   }

   public getAllTodos() {
      return this.allTodos == null ? [] : this.allTodos;
   }

   public getAllTrainingDays() {
      return this.allTrainingDay == null ? [] : this.allTrainingDay;
   }

   public getAllTrainingPlayers() {
      return this.allTrainingPlayers == null ? [] : this.allTrainingPlayers;
   }

   public getAllTrainingPlayersOfCurrentLogged() {
      return this.allTrainingPlayersOfCurrentLogged == null ? [] : this.allTrainingPlayersOfCurrentLogged;
   }

   public getAllTrainingPlayersOfUserCoachConnected() {
      return this.allTrainingPlayersOfUserCoachConnected == null ? [] : this.allTrainingPlayersOfUserCoachConnected;
   }

   public getAllUsers() {
      return this.allUsers == null ? [] : this.allUsers;
   }

   public getAllUsersOfTeamOfCurrentUser() {
      return this.allUsersOfTeamOfCurrentUser == null ? [] : this.allUsersOfTeamOfCurrentUser;
   }

   public getAllUsersWithActiveLogin() {
      return this.allUsers == null ? [] : this.allUsers.filter(i => !i.isUserLoginInactive);
   }

   public getAllUsersWithAllTeams(isTeamSysInverted: boolean) {
      let temp = [];

      if (this.allUsers == null)
         return temp;

      for (let cpt = 0; cpt < this.allUsers.length; cpt++) {
         if (this.allUsers[cpt].team2 == null) {
            temp.push(Object.assign({}, this.allUsers[cpt]));
         }
         else {
            temp.push(Object.assign({}, this.allUsers[cpt]));

            if (isTeamSysInverted) {
               let p = Object.assign({}, this.allUsers[cpt]);

               let t1 = Object.assign({}, this.allUsers[cpt].team);
               let t2 = Object.assign({}, this.allUsers[cpt].team2);

               p.team = t2;
               p.teamSysId = this.allUsers[cpt].team2SysId;
               p.team2 = t1;
               p.team2SysId = this.allUsers[cpt].teamSysId;
               temp.push(Object.assign({}, p));
            }
         }
      }
      return temp;
   }

   public getAllVersions() {
      return this.allVersions == null ? [] : this.allVersions;
   }

   public getAllWorkgroups() {
      return this.allWorkgroups == null ? [] : this.allWorkgroups;
   }

   public getAvailabilityFromTrainingPlayer(tp: trainingPlayer) {
      if (tp == null)
         return enumAvailability.PRESENT;
      return this.allVersions == null ? enumAvailability.PRESENT : (tp.present == null) ? enumAvailability.GUARD : (tp.present >= 1 ? enumAvailability.PRESENT : enumAvailability.NOTPRESENT);
   }

   public getCoachOfCurrentLogged() {
      return this.coachOfCurrentLogged;
   }

   public getCurrentChampionship() {
      return this.currentChampionship;
   }

   public getEventClubWithTraining(allClubEventsOfUserAuthenticated, date: Date): eventClub {
      let ec = this.frameworkService.findFirstEventClub(allClubEventsOfUserAuthenticated, this.frameworkService.getDateFormatYyyyMmDd(date))?.eventClub;
      if (ec == null || ec.event == null) {
         // Ajout de l'event si c'est un jour d'entraînement avec update des présences sinon on update les présences sur l'event existant
         ec = this.getTrainingEventClubObject(ec, date);
      }
      return ec;
   }

   public getExtraTrainingOfCoachFromDate(date: Date): trainingPlayer {
      return this.allTrainingPlayersOfUserCoachConnected.FirstOrDefault(i =>
         this.frameworkService.getDateFormatYyyyMmDd(i.day) == this.frameworkService.getDateFormatYyyyMmDd(date) &&
         i.type != "" && i.type != null && i.playerSysId == this.frameworkService.getSysIdOfUser(this.getCoachOfCurrentLogged()) &&
         this.frameworkService.getMatchTrainingActivitiesType().FirstOrDefault(j => j.value === i.type));
   }

   public getLastVersionNumber() {
      return this.allVersions[0]?.value.split(',')[0].replace("#", "");
   }

   public getLastVersionUpate() {
      return this.allVersions[0]?.value.split(',')[1];
   }

   public getPreviousChampionship() {
      return this.previousChampionship;
   }

   public convertQueryControllerToPublicFiltersOnChampionship() {
      return this.queryFiltersOnChampionship;
   }

   public getSportClub() {
      return this.sportsClub;
   }

   public getTeamOfUserConnected() {
      return this.teamOfCurrentUser;
   }

   public getToday() {
      return this.frameworkService.getToday();
   }

   public getTrainingEventClubObject(ec, date): eventClub {
      let trainingDay = this.getAllTrainingDays().FirstOrDefault(i => i.day == new Date(date).getDay());
      let tp = this.getAllTrainingPlayersOfCurrentLogged().FirstOrDefault(i => this.frameworkService.getDateFormatYyyyMmDd(date) == this.frameworkService.getDateFormatYyyyMmDd(i.day));

      //If it is a training day, check if it is really one (no other event exists on this date)
      let tempEc = this.frameworkService.findFirstEventClub(this.allClubEventsOfUserAuthenticated, this.frameworkService.getDateFormatYyyyMmDd(date));
      let isAtrainingDay = trainingDay != null;
      if (isAtrainingDay) {
         if (tempEc != null && tempEc.eventClub != null && tempEc.eventClub.clubActivityType != enumClubActivityType.TRAINING_DAY)
            isAtrainingDay = false;
      }

      if (isAtrainingDay) {
         //It is a training day
         ec = new eventClub();
         ec.tpSysId = null;

         ec.clubActivityType = enumClubActivityType.TRAINING_DAY;
         ec.day = date;
         ec.event = new match();
         ec.event.sysId = null;
         ec.event.name = enumActivityType[enumActivityType.TRAINING_DAY];
         ec.event.day = date;
         ec.event.time = trainingDay.timeFrom + " à " + trainingDay.timeTo;
         ec.event.type = enumActivityType[enumActivityType.TRAINING_DAY];
         ec.event.buildingName = trainingDay.buildingTraining;

         if (tp != null) {
            let availability = this.getAvailabilityFromTrainingPlayer(tp);

            ec.tpSysId = ec.event.sysId = tp.sysId; // <-- Keep sysId of trainingPlayer for update
            if (availability == enumAvailability.GUARD) {
               ec.reason = enumAvailabilityReason[enumAvailabilityReason.GUARD];
               ec.availability = enumAvailability.GUARD;
            }
            else {
               if (availability == enumAvailability.PRESENT) {
                  ec.reason = "";
                  ec.availability = enumAvailability.PRESENT;
               }
               else {
                  ec.reason = tp.reason;
                  ec.availability = enumAvailability.NOTPRESENT;
               }
            }
         }
         else {
            ec.tpSysId = 0;
            ec.availability = enumAvailability.PRESENT;
         }
      }
      else {
         //It is NOT a training day
         if (ec != null) {
            ec.tpSysId = tp.sysId;
            ec.availability = enumAvailability.UNKNOWN;
         }
      }
      return ec;
   }

   public getUserLogged() {
      return this.storageService.getUserConnected();
   }

   public initializeAllTrainingPlayersList() {
      if (this.getUserLogged() != null) {
         this.allTrainingPlayersOfCurrentLogged = this.allTrainingPlayers.filter(i => i.playerSysId == this.getUserLogged().sysId);
         if (this.coachOfCurrentLogged != null)
            this.allTrainingPlayersOfUserCoachConnected = this.allTrainingPlayers.filter(i => i.playerSysId == this.coachOfCurrentLogged.sysId);
      }
      else {
         this.allTrainingPlayersOfCurrentLogged = [];
         this.allTrainingPlayersOfUserCoachConnected = [];
      }
      this.allTrainingPlayersOfCoachs = this.allTrainingPlayers.filter(i => this.getAllCoachsOfClub().map(u => u.sysId).includes(i.playerSysId));
      //this.frameworkService.logInfo(this.allTrainingPlayersOfCoachs, "allTrainingPlayersOfCoachs");
   }

   public loadAllTrainingPlayers() {
      this.initializeAllTrainingPlayersList();

      // PRE-LOAD ALL EVENTS OF THE CLUB <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
      //console.time();

      this.allClubEvents.clear();
      this.allClubEventsOfUserAuthenticated.clear();
      //-> Ajout des matchs officiels (CHAMPIONSHIP, HAINAUT_CUP, YOUNG) dans allClubEventsOfUserAuthenticated (pour trainingPLayer)
      if (this.allMatchs != null) {
         this.allMatchsOfTeamAndClub = this.allMatchs.filter(i => i.fromTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()) ||
            i.awayTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()));
         this.allMatchsOfUserTeamAndClub = this.allMatchsOfTeamAndClub.filter(i => i.teamSysId == this.teamOfCurrentUser?.sysId);
         this.allMatchsOfUserTeamAndClub.forEach(m => {
            // Mise à jour des absences et des raisons d'absence pour les matchs officiels,
            let tp = this.allTrainingPlayersOfCurrentLogged.FirstOrDefault(j => this.frameworkService.getDateFormatYyyyMmDd(j.day) == this.frameworkService.getDateFormatYyyyMmDd(m.day));

            if (tp != null) {
               let availability = this.getAvailabilityFromTrainingPlayer(tp);
               this.allClubEventsOfUserAuthenticated.set(this.frameworkService.getDateFormatYyyyMmDd(m.day) + m.type, new eventClub({ clubActivityType: this.frameworkService.getClubActivityType(m.type), tpSysId: tp.sysId, day: m.day, availability: availability, reason: tp.reason, event: m }));
            }
            else {
               this.allClubEventsOfUserAuthenticated.set(this.frameworkService.getDateFormatYyyyMmDd(m.day) + m.type, new eventClub({ clubActivityType: this.frameworkService.getClubActivityType(m.type), tpSysId: 0, day: m.day, availability: enumAvailability.PRESENT, reason: "", event: m }));
            }
         });
      }
      //<- Ajout des matchs officiels (CHAMPIONSHIP, HAINAUT_CUP, YOUNG) dans allClubEventsOfUserAuthenticated (pour trainingPLayer)

      //-> Ajout des matchs officiels (CHAMPIONSHIP, HAINAUT_CUP, YOUNG) dans allClubEvents (pour activités)
      let allTeamsTemp = this.getAllTeamsWithoutPicture().filter(i => i.division != "" && i.sysId > 1);
      allTeamsTemp.forEach(t => {
         let allMatchs = this.getAllMatchs().filter(m =>
            !m.fromTeam.includes(this.frameworkService.getByeStatus()) &&
            !m.awayTeam.includes(this.frameworkService.getByeStatus()) &&
            (m.fromTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()) ||
               m.awayTeam.toLowerCase().includes(this.frameworkService.getSportsClubName())) &&
            m.teamSysId == t.sysId);

         allMatchs.forEach(m => {
            this.allClubEvents.set(this.frameworkService.getDateFormatYyyyMmDd(m.day) + m.sysId + m.type, new eventClub({ clubActivityType: this.frameworkService.getClubActivityType(m.type), day: m.day, availability: enumAvailability.UNKNOWN, reason: "", event: m }));
         });
      });
      //<- Ajout des matchs officiels (CHAMPIONSHIP, HAINAUT_CUP, YOUNG) dans allClubEvents (pour activités)

      //-> Ajout des matchs NON officiels (FRIENDLY, TOURNAMENT, EXTRA_TRAINING) dans allClubEventsOfUserAuthenticated (pour trainingPlayer)
      if (this.allTrainingPlayersOfUserCoachConnected != null) {
         for (let cpt = 0; cpt < this.allTrainingPlayersOfUserCoachConnected?.length; cpt++) {
            if (this.allTrainingPlayersOfUserCoachConnected[cpt].type == enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY] ||
               this.allTrainingPlayersOfUserCoachConnected[cpt].type == enumActivityType[enumActivityType.EXTRA_TRAINING_DAY] ||
               this.allTrainingPlayersOfUserCoachConnected[cpt].type == enumActivityType[enumActivityType.TOURNAMENT_DAY]) {
               let m = new match();
               m.sysId = 0;
               if (this.allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingIsAtHome)
                  m.awayTeam = this.allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingTeam;
               else
                  m.fromTeam = this.allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingTeam;
               m.day = this.allTrainingPlayersOfUserCoachConnected[cpt].day;
               m.time = this.allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingTimeStarting;
               if (this.allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingTimeEnding != "00:00" && this.allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingTimeEnding != "")
                  m.time += " à " + this.allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingTimeEnding;
               m.isAtHome = this.allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingIsAtHome;
               m.type = this.allTrainingPlayersOfUserCoachConnected[cpt].type;
               let u = this.getAllUsers().FirstOrDefault(i => i.sysId == this.allTrainingPlayersOfUserCoachConnected[cpt].playerSysId);
               m.teamSysId = u.teamSysId;
               m.team = this.getAllTeamsWithoutPicture().FirstOrDefault(i => i.sysId == u.teamSysId);
               m.buildingName = this.allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingBuilding;
               m.status = "AJO";

               this.allMatchsOfUserTeamAndClub.push(m);

               // Mise à jour des absences et des raisons d'absence pour les matchs non officiels via les tp du joueurs (pas du coach) !
               let tp = this.allTrainingPlayersOfCurrentLogged.FirstOrDefault(j => this.frameworkService.getDateFormatYyyyMmDd(j.day) == this.frameworkService.getDateFormatYyyyMmDd(m.day));
               if (tp != null) {
                  m.sysId = tp.sysId;
                  let availability = this.getAvailabilityFromTrainingPlayer(tp);
                  //this.frameworkService.logInfo(tp, "availability:" + availability);
                  //this.frameworkService.logInfo(tp, "tp:" + this.frameworkService.getDateFormatYyyyMmDd(m.day) + m.type);
                  this.allClubEventsOfUserAuthenticated.set(this.frameworkService.getDateFormatYyyyMmDd(tp.day) + m.type, new eventClub({ clubActivityType: this.frameworkService.getClubActivityType(m.type), tpSysId: tp.sysId, day: m.day, availability: availability, reason: tp.reason, event: m }));
               }
               else {
                  m.sysId = this.allTrainingPlayersOfUserCoachConnected[cpt].sysId;
                  //this.frameworkService.logInfo(m, "m:" + this.frameworkService.getDateFormatYyyyMmDd(m.day) + m.type);
                  this.allClubEventsOfUserAuthenticated.set(this.frameworkService.getDateFormatYyyyMmDd(m.day) + m.type, new eventClub({ clubActivityType: this.frameworkService.getClubActivityType(m.type), tpSysId: m.sysId, day: m.day, availability: enumAvailability.PRESENT, reason: "", event: m }));
               }
            }
         }
      }
      //<- Ajout des matchs NON officiels (FRIENDLY, TOURNAMENT, EXTRA_TRAINING) dans allClubEventsOfUserAuthenticated (pour trainingPlayer)

      //-> Ajout des matchs NON officiels (FRIENDLY, TOURNAMENT, EXTRA_TRAINING) dans allClubEvents (pour trainingPlayer)
      for (let cpt = 0; cpt < this.allTrainingPlayersOfCoachs?.length; cpt++) {
         this.frameworkService.logInfo(this.allTrainingPlayersOfCoachs?.length);
         let m = new match();
         m.sysId = 0;
         if (this.allTrainingPlayersOfCoachs[cpt].extraTrainingIsAtHome)
            m.awayTeam = this.allTrainingPlayersOfCoachs[cpt].extraTrainingTeam;
         else
            m.fromTeam = this.allTrainingPlayersOfCoachs[cpt].extraTrainingTeam;
         m.day = this.allTrainingPlayersOfCoachs[cpt].day;
         m.time = this.allTrainingPlayersOfCoachs[cpt].extraTrainingTimeStarting;
         if (this.allTrainingPlayersOfCoachs[cpt].extraTrainingTimeEnding != "00:00" && this.allTrainingPlayersOfCoachs[cpt].extraTrainingTimeEnding != "")
            m.time += " à " + this.allTrainingPlayersOfCoachs[cpt].extraTrainingTimeEnding;
         m.isAtHome = this.allTrainingPlayersOfCoachs[cpt].extraTrainingIsAtHome;
         m.type = this.allTrainingPlayersOfCoachs[cpt].type;
         let u = this.getAllUsers().FirstOrDefault(i => i.sysId == this.allTrainingPlayersOfCoachs[cpt].playerSysId);
         m.teamSysId = u.teamSysId;
         m = this.loadMatchForeignKeys(m);
         m.buildingName = this.allTrainingPlayersOfCoachs[cpt].extraTrainingBuilding;
         m.status = "AJO";
         this.allClubEvents.set(this.frameworkService.getDateFormatYyyyMmDd(m.day) + m.teamSysId + m.type, new eventClub({ clubActivityType: this.frameworkService.getClubActivityType(m.type), tpSysId: m.sysId, day: m.day, availability: enumAvailability.PRESENT, reason: "", event: m }));
      }
      //<- Ajout des matchs NON officiels (FRIENDLY, TOURNAMENT, EXTRA_TRAINING) dans allClubEvents (pour trainingPlayer)

      //-> Ajout des jours de fermeture (CLOSING) dans allClubEvents (pour activités) et allClubEventsOfUserAuthenticated (pour trainingPlayer)
      let allClosingDay = this.getAllBuildingTrainingClosingDay();
      for (let cpt = 0; cpt < allClosingDay?.length; cpt++) {
         if (allClosingDay[cpt].teamSysId == 1 || (this.storageService.getUserConnected()?.teamSysId == allClosingDay[cpt].teamSysId || this.storageService.getUserConnected()?.team2SysId == allClosingDay[cpt].teamSysId)) {
            let m = new match();
            m.sysId = allClosingDay[cpt].sysId;
            m.day = allClosingDay[cpt].day;
            m.type = enumActivityType[enumActivityType.CLOSING_DAY];
            this.allMatchsOfUserTeamAndClub.push(m);
            this.allClubEventsOfUserAuthenticated.set(this.frameworkService.getDateFormatYyyyMmDd(m.day) + enumClubActivityType[enumClubActivityType.CLOSING], new eventClub({ clubActivityType: enumClubActivityType.CLOSING, tpSysId: 0, day: m.day, availability: enumAvailability.UNKNOWN, reason: allClosingDay[cpt].reason, event: m }));
            this.allClubEvents.set(this.frameworkService.getDateFormatYyyyMmDd(m.day) + m.sysId + enumClubActivityType[enumClubActivityType.CLOSING], new eventClub({ clubActivityType: enumClubActivityType.CLOSING, tpSysId: 0, day: m.day, availability: enumAvailability.UNKNOWN, reason: allClosingDay[cpt].reason, event: m }));
         }
      }
      //<- Ajout des jours de fermeture (CLOSING) dans allClubEvents (pour activités) et allClubEventsOfUserAuthenticated (pour trainingPlayer)

      //-> Ajout des réunions de comité (COMITEE_MEETING) dans allClubEvents (pour activités)
      let allComiteeMeeting = this.getAllComiteeMeetings().filter(i => this.frameworkService.isDayOverToday(i.date));
      if (allComiteeMeeting != null) {
         for (let cpt = 0; cpt < allComiteeMeeting.length; cpt++) {
            //TODO JD Ajouter le possibilité d'entrer ses présences pour les réunions
            this.allClubEvents.set(this.frameworkService.getDateFormatYyyyMmDd(allComiteeMeeting[cpt].date) + allComiteeMeeting[cpt].sysId + enumClubActivityType[enumClubActivityType.COMITEE_MEETING], new eventClub({ clubActivityType: enumClubActivityType.COMITEE_MEETING, day: allComiteeMeeting[cpt].date, availability: enumAvailability.UNKNOWN, reason: "", event: allComiteeMeeting[cpt] }));
         }
      }
      //<- Ajout des réunions de comité (COMITEE_MEETING) dans allClubEvents (pour activités)

      //-> Ajout des anniversaires (BIRTHDAY) dans allClubEvents (pour activités)
      let allBirthday = this.getAllUsersWithActiveLogin();
      if (allBirthday != null) {
         for (let cpt = 0; cpt < allBirthday.length; cpt++) {
            let birthdayDateEvent = this.frameworkService.getCurrentDateOfBirthdayDate(allBirthday[cpt].birthdayDate);
            this.allClubEvents.set(this.frameworkService.getDateFormatYyyyMmDd(birthdayDateEvent) + allBirthday[cpt].sysId + enumClubActivityType[enumClubActivityType.BIRTHDAY], new eventClub({ clubActivityType: enumClubActivityType.BIRTHDAY, day: birthdayDateEvent, availability: enumAvailability.UNKNOWN, reason: "", event: allBirthday[cpt] }));
         }
      }
      //<- Ajout des anniversaires (BIRTHDAY) dans allClubEvents (pour activités)

      //-> Ajout des activités (SUPPER, ACTIVITY) dans allClubEvents (pour activités)
      //  allActivitees[cpt] = 10 -> clubActivityType = enumClubActivityType.SUPPER
      //  allActivitees[cpt] = 12 -> clubActivityType = enumClubActivityType.AG_MEETING
      //  allActivitees[cpt] = 13 -> clubActivityType = enumClubActivityType.TOURNAMENT_INFO
      let allActivitees = this.getAllActiveActivities().filter(i => this.frameworkService.isDayOverToday(i.day));
      if (allActivitees != null) {
         for (let cpt = 0; cpt < allActivitees.length; cpt++) {
            let type = allActivitees[cpt].type;
            this.allClubEvents.set(this.frameworkService.getDateFormatYyyyMmDd(allActivitees[cpt].day) + allActivitees[cpt].sysId + enumClubActivityType[type], new eventClub({ clubActivityType: type, day: allActivitees[cpt].day, availability: enumAvailability.UNKNOWN, reason: "", event: allActivitees[cpt] }));
         }
      }
      //<- Ajout des activités (SUPPER, ACTIVITY) dans allClubEvents (pour activités)
   }





   public manageIUClubEventsOfUserAuthenticated(date: Date, tp, eventClubSource: any, insert: boolean, user: user): Map<string, eventClub> {
      let trainingDay = this.getAllTrainingDays().FirstOrDefault(i => i.day == new Date(date).getDay());
      let ec: any = new eventClub();

      //this.frameworkService.logInfo(insert, "manageIUClubEventsOfUserAuthenticated CALL");

      //If it is a training day, check if it is really one (no other event exists on this date)
      let tempEc = this.frameworkService.findFirstEventClub(this.allClubEventsOfUserAuthenticated, this.frameworkService.getDateFormatYyyyMmDd(date));
      //this.frameworkService.logInfo(tempEc?.eventClub, "tempEc manageIUClubEventsOfUserAuthenticated");
      //this.frameworkService.logInfo(tp, "tp manageIUClubEventsOfUserAuthenticated");
      let isAtrainingDay = trainingDay != null;
      if (isAtrainingDay) {
         if (tempEc != null && tempEc.eventClub != null && tempEc.eventClub.clubActivityType != enumClubActivityType.TRAINING_DAY)
            isAtrainingDay = false;
      }

      if (isAtrainingDay && tp.type != enumActivityType[enumActivityType.EXTRA_TRAINING_DAY] &&
         tp.type != enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY] &&
         tp.type != enumActivityType[enumActivityType.TOURNAMENT_DAY]) {
         //It is a training day
         ec.tpSysId = tp.sysId;
         ec.clubActivityType = enumClubActivityType.TRAINING_DAY;
         ec.day = date;
         ec.event = new match();
         ec.event.sysId = tp.sysId;
         ec.event.name = enumActivityType[enumActivityType.TRAINING_DAY];
         ec.event.day = date;
         ec.event.time = trainingDay.timeFrom + " à " + trainingDay.timeTo;
         ec.event.type = enumActivityType[enumActivityType.TRAINING_DAY];
         ec.event.buildingName = trainingDay.buildingTraining;
         ec.reason = tp.reason;
         ec.availability = (tp.present == null) ? enumAvailability.GUARD : (tp.present >= 1 ? enumAvailability.PRESENT : enumAvailability.NOTPRESENT);
         //this.frameworkService.logInfo(isAtrainingDay, "manageIUClubEventsOfUserAuthenticated/isAtrainingDay");
      }
      else {
         //It is NOT a training day
         if ((user.sysId == tp.playerSysId && eventClubSource != null) || user.sysId == this.getUserLogged().sysId) {
            ec = eventClubSource;
            ec.tpSysId = tp.sysId;

            //It is an EXTRA training day ?
            if (tp.type == enumActivityType[enumActivityType.EXTRA_TRAINING_DAY] ||
               tp.type == enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY] ||
               tp.type == enumActivityType[enumActivityType.TOURNAMENT_DAY]) {
               ec.reason = tp.reason;
               ec.availability = (tp.present == null) ? enumAvailability.GUARD : (tp.present >= 1 ? enumAvailability.PRESENT : enumAvailability.NOTPRESENT);
               //this.frameworkService.logInfo(tp.type, "manageIUClubEventsOfUserAuthenticated/EXTRA_TRAINING");
            }
            else {
               ec.reason = tp.reason;
               ec.availability = (tp.present == null) ? enumAvailability.GUARD : (tp.present >= 1 ? enumAvailability.PRESENT : enumAvailability.NOTPRESENT);
               //this.frameworkService.logInfo(tp.type, "manageIUClubEventsOfUserAuthenticated/not EXTRA_TRAINING");
               //ec.availability = enumAvailability.NOTPRESENT;
            }
         }
      }

      if (insert) {
         db.trainingPlayersDB.add(tp, tp.sysId);
         this.allTrainingPlayers.Add(tp);
         //this.frameworkService.logInfo(this.allTrainingPlayers.length);
         //this.frameworkService.logInfo(tp.type, "manageIUClubEventsOfUserAuthenticated/DB insert " + tp.sysId);
      }
      else {
         db.trainingPlayersDB.update(tp, tp.sysId);
         this.allTrainingPlayers[this.allTrainingPlayers.findIndex(i => i.sysId === tp.sysId)] = tp;
         //this.frameworkService.logInfo(this.allTrainingPlayers.length);
         //this.frameworkService.logInfo(tp.type, "manageIUClubEventsOfUserAuthenticated/DB update" + tp.sysId);
      }

      this.initializeAllTrainingPlayersList();
      //this.frameworkService.logInfo("", "initializeAllTrainingPlayersList");
      //this.frameworkService.logInfo(user.sysId, "user.sysId");
      //this.frameworkService.logInfo(tp.playerSysId, "tp.playerSysId");
      //this.frameworkService.logInfo(eventClubSource, "eventClubSource");

      //99=90
      //this.storageService.getUserConnected() == tp.playerSysId && has been removed from condition
      if ((eventClubSource != null)) {
         this.allClubEventsOfUserAuthenticated.set(this.frameworkService.getDateFormatYyyyMmDd(tp.day) + tp.type, ec);
         //this.frameworkService.logInfo(this.frameworkService.getDateFormatYyyyMmDd(tp.day) + tp.type, "allClubEventsOfUserAuthenticated SET");
      }
      return this.allClubEventsOfUserAuthenticated;
   }

   public manageRClubEventsOfUserAuthenticated(tp: any, user: user) {
      //this.frameworkService.logInfo("CALL", "manageRClubEventsOfUserAuthenticated");

      if (this.frameworkService.isMemberCoachOnly(user)) {
         //this.frameworkService.logInfo("", "initializeAllTrainingPlayersList/isCoach");
         //this.frameworkService.logInfo(tp.sysId, "tp.sysId");

         //Remove TP from cache
         this.allTrainingPlayers.Remove(tp);
         //Remove TP from IndexDB
         db.trainingPlayersDB.delete(tp.sysId);

         this.initializeAllTrainingPlayersList();
         //this.frameworkService.logInfo("", "initializeAllTrainingPlayersList");

         //Remove all eventClub
         this.allClubEventsOfUserAuthenticated.delete(this.frameworkService.getDateFormatYyyyMmDd(tp.day) + tp.type);
         //this.frameworkService.logInfo(this.allTrainingPlayers.length, "allTrainingPlayers length after");
         //this.frameworkService.logInfo(this.allClubEventsOfUserAuthenticated.size, "allClubEventsOfUserAuthenticated length after");
      }
      else {
         //this.frameworkService.logInfo("", "initializeAllTrainingPlayersList/NOT isCoach");
         this.allTrainingPlayers.Remove(tp);
         this.initializeAllTrainingPlayersList();
         //this.frameworkService.logInfo("", "initializeAllTrainingPlayersList");

         db.trainingPlayersDB.delete(tp.sysId);
         if (tp.type == enumClubActivityType[enumClubActivityType.TRAINING_DAY] || (this.frameworkService.isMemberCoachOnly(user)))
            this.allClubEventsOfUserAuthenticated.delete(this.frameworkService.getDateFormatYyyyMmDd(tp.day) + tp.type);
         else {
            let ec = this.frameworkService.findFirstEventClub(this.allClubEventsOfUserAuthenticated, this.frameworkService.getDateFormatYyyyMmDd(tp.day));
            ec.eventClub.tpSysId = 0;
            ec.eventClub.reason = "";
            ec.eventClub.availability = enumAvailability.PRESENT;
            this.allClubEventsOfUserAuthenticated.set(this.frameworkService.getDateFormatYyyyMmDd(tp.day) + tp.type, ec.eventClub);
         }
      }

      return this.allClubEventsOfUserAuthenticated;
   }

   public manageRIUComiteeMeeting(comiteeMeeting, operation?: boolean) {
      switch (operation) {
         //Delete
         case null:
            {
               db.comiteeMeetingsDB.delete(comiteeMeeting.sysId);
               this.allComiteeMeetings.Remove(comiteeMeeting);
               break;
            }
         //Insert
         case true:
            {
               comiteeMeeting = this.loadComiteeMeetingsForeignKeys(comiteeMeeting);
               db.comiteeMeetingsDB.add(comiteeMeeting, comiteeMeeting.sysId);
               this.allComiteeMeetings.Add(comiteeMeeting);
               break;
            }
         //Update
         case false:
            {
               db.comiteeMeetingsDB.update(comiteeMeeting, comiteeMeeting.sysId);
               this.allComiteeMeetings[this.allComiteeMeetings.findIndex(i => i.sysId === comiteeMeeting.sysId)] = comiteeMeeting;
               break;
            }
      }
   }

   public manageUUser(user) {
      //this.frameworkService.logInfo(user, "manageUUser/DB update");
      user = this.loadUserForeignKeys(user);
      if (db.usersDB != undefined)
         db.usersDB.update(user, user.sysId);
      this.allUsers[this.allUsers.findIndex(i => i.sysId === user.sysId)] = user;
   }

   public reloadRefreshData(userLogged: user) {
      //this.frameworkService.logInfo("-", "CACHE / reloadRefreshData");

      if (userLogged) {
         // Equipe Id du joueur connecté
         this.teamOfCurrentUser = this.allTeams.FirstOrDefault(i => i.sysId == userLogged.teamSysId);
      }
      else {
         this.teamOfCurrentUser = null;
      }

      if (this.getAllUsersWithActiveLogin() != null && userLogged != null) {
         // Liste des joueurs avec login actif de l'équipe du membre connecté
         this.allUsersOfTeamOfCurrentUser = this.getAllUsersWithActiveLogin().filter(i => i.teamSysId == userLogged.teamSysId);
      }
      if (this.allUsers != null && this.teamOfCurrentUser != null) {
         // Coach du joueur connecté
         this.coachOfCurrentLogged = this.allUsers.FirstOrDefault(i => i.teamSysId == this.teamOfCurrentUser?.sysId && this.frameworkService.isMemberCoachOnly(i));
         // Tous les coachs du club
         this.allCoachsOfCurrentLogged = this.allUsers.filter(i => i.teamSysId == this.teamOfCurrentUser?.sysId && (this.frameworkService.IsMemberCoachs(i)));
      }

      this.allTrainingDay = [];
      let teamOfUserConnected = this.getTeamOfUserConnected();
      if (teamOfUserConnected != null) { // If at least one team is selected ...
         //-> Add training days
         if (teamOfUserConnected.trainingDay1 != -1) this.allTrainingDay.push({ day: teamOfUserConnected.trainingDay1, timeFrom: teamOfUserConnected.trainingTimeFrom1, timeTo: teamOfUserConnected.trainingTimeTo1, buildingTraining: teamOfUserConnected.buildingTrainingName1 });
         if (teamOfUserConnected.trainingDay2 != -1) this.allTrainingDay.push({ day: teamOfUserConnected.trainingDay2, timeFrom: teamOfUserConnected.trainingTimeFrom2, timeTo: teamOfUserConnected.trainingTimeTo2, buildingTraining: teamOfUserConnected.buildingTrainingName2 });
         if (teamOfUserConnected.trainingDay3 != -1) this.allTrainingDay.push({ day: teamOfUserConnected.trainingDay3, timeFrom: teamOfUserConnected.trainingTimeFrom3, timeTo: teamOfUserConnected.trainingTimeTo3, buildingTraining: teamOfUserConnected.buildingTrainingName3 });
         //<- Add training days
      }

      this.loadAllTrainingPlayers();

      this.allMatchsOfUserTeamAndClub.sort((a, b) => a.day < b.day ? -1 : 1);

      // PRE-LOAD ALL EVENTS OF THE CLUB >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      //console.timeEnd();
   }

   public async resetData() {
      //Remove collection not used in cache on public mode
      this.resetUnloggedData();
      this.frameworkService.removeAllCookies();
      this.storageService.setDataPrivacyCookie(false);
      //this.frameworkService.logInfo("ResetData", "loadUpdatedData");
      this.loadUpdatedData(true, false);
   }

   public async resetDataForPrivacyOff() {
      //Remove collection not used in cache on public mode
      this.resetUnloggedData();
      this.storageService.setDataPrivacyCookie(false);
      //this.frameworkService.logInfo("ResetDataForPrivacyOff", "loadUpdatedData");
      this.loadUpdatedData(true, false);
   }

   public resetUnloggedData() {
      this.allComiteeMeetings = [];
      this.allTrainingPlayers = [];
   }

   public getIsAutomaticCheckCalled() {
      return this.isAutomaticCheckCalled;
   }
   public setIsAutomaticCheckCalled(value) {
      this.isAutomaticCheckCalled = value;
   }



   public loadlAllBuildingTrainingClosingDayForeignKeys(allBuildingTrainingClosingDay) {
      allBuildingTrainingClosingDay.forEach(b => b = this.loadBuildingTrainingClosingDayForeignKeys(b));
   }

   public loadAllActivitiesForeignKeys() {
      this.allActivities.forEach(r => r = this.loadActivityForeignKeys(r));
   }

   public loadAllTrainingPlayersForeignKeys() {
      this.allTrainingPlayers.forEach(r => r = this.loadTrainingPlayersForeignKeys(r));
   }

   public loadAllComiteeMeetingsForeignKeys() {
      this.allComiteeMeetings.forEach(c => c = this.loadComiteeMeetingsForeignKeys(c));
   }

   public loadAllHomeNewsForeignKeys() {
      this.allHomeNews.forEach(r => r = this.loadHomeNewsForeignKeys(r));
   }

   public loadAllMatchForeignKeys() {
      this.allMatchs.forEach(t => t = this.loadMatchForeignKeys(t));
   }

   public loadAllRankingForeignKeys() {
      this.allRanking.forEach(r => r = this.loadRankingForeignKeys(r));
   }

   public loadAllSponsorsForeignKeys() {
      this.allSponsors.forEach(r => r = this.loadSponsorForeignKeys(r));
   }

   public loadAllTeamForeignKeys(allTeams) {
      allTeams.forEach(t => t = this.loadTeamForeignKeys(t));
   }


   public loadAllUserForeignKeys() {
      this.allUsers.forEach(u => u = this.loadUserForeignKeys(u));
   }




   public loadActivityForeignKeys(activity) {
      activity.championship = this.allTeamsWithoutPicture.FirstOrDefault(t => t.sysId == activity.championshipSysId);
      return activity;
   }

   public loadBuildingTrainingClosingDayForeignKeys(buildingTrainingClosingDay) {
      buildingTrainingClosingDay.team = this.allTeamsWithoutPicture.FirstOrDefault(t => t.sysId == buildingTrainingClosingDay.teamSysId);
      buildingTrainingClosingDay.championship = this.allBuildingTrainingClosingDay.FirstOrDefault(c => c.sysId == buildingTrainingClosingDay.championshipSysId);
      return buildingTrainingClosingDay;
   }

   public loadTrainingPlayersForeignKeys(trainingPlayer: trainingPlayer) {
      trainingPlayer.championship = this.allChampionships.FirstOrDefault(p => p.sysId == trainingPlayer.championshipSysId);
      trainingPlayer.player = this.allUsers.FirstOrDefault(p => p.sysId == trainingPlayer.playerSysId);
      return trainingPlayer;
   }

   public loadTeamForeignKeys(team) {
      team.championship = this.allChampionships.FirstOrDefault(c => c.sysId == team.championshipSysId);
      return team;
   }

   public loadUserForeignKeys(user) {
      user.team = this.allTeamsWithoutPicture.FirstOrDefault(t => t.sysId == user.teamSysId);
      user.team2 = this.allTeams.FirstOrDefault(j => j.sysId == user.team2SysId);
      user.workgroup = this.allWorkgroups.FirstOrDefault(j => j.sysId == user.workgroupSysId);
      return user;
   }

   public loadComiteeMeetingsForeignKeys(comiteeMeetings) {
      comiteeMeetings.championship = this.allChampionships.FirstOrDefault(c => c.sysId == comiteeMeetings.championshipSysId);
      return comiteeMeetings;
   }

   public loadHomeNewsForeignKeys(homeNews) {
      homeNews.championship = this.allTeamsWithoutPicture.FirstOrDefault(t => t.sysId == homeNews.championshipSysId);
      return homeNews;
   }

   public loadMatchForeignKeys(match) {
      match.championship = this.allChampionships.FirstOrDefault(c => c.sysId == match.championshipSysId);
      match.team = this.allTeamsWithoutPicture.FirstOrDefault(t => t.sysId == match.teamSysId);
      return match;
   }

   public loadRankingForeignKeys(ranking) {
      ranking.team = this.allTeamsWithoutPicture.FirstOrDefault(t => t.sysId == ranking.teamSysId);
      return ranking;
   }

   public loadSponsorForeignKeys(sponsor) {
      sponsor.championship = this.allTeamsWithoutPicture.FirstOrDefault(t => t.sysId == sponsor.championshipSysId);
      return sponsor;
   }

   public getAllMembersByAllTeam() {
      return this._allMembersByAllTeam;
   }

   public getAllMembersByFirstname() {
      return this._allMembersByFirstname;
   }

   public getAllMembersByLastname() {
      return this._allMembersByLastname;
   }

   public getAllMembersByRole() {
      return this._allMembersByRole;
   }

   public getAllMembersByTeam() {
      return this._allMembersByTeam;
   }



   private _getAllMembersByAllTeam(isTeamSysInverted: boolean) {
      return this.getAllUsersWithAllTeams(isTeamSysInverted).filter(i => i.sysId != 1 && i.sysId != 2)
         .sort(function (a, b) {
            if ((a.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team?.teamCategory) + a.firstName + a.lastName < (b.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team?.teamCategory) + b.firstName + b.lastName) {
               return -1;
            }
            if ((a.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team?.teamCategory) + a.firstName + a.lastName > (b.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team?.teamCategory) + b.firstName + b.lastName) {
               return 1;
            }
            return 0;
         });
   }

   private _getAllMembersByFirstname() {
      let allPlayers = this.getAllUsers().filter(i => i.sysId != 1 && i.sysId != 2).sort(function (a, b) {
         if (a.firstName + a.lastName < b.firstName + b.lastName) {
            return -1;
         }
         if (a.firstName + a.lastName > b.firstName + b.lastName) {
            return 1;
         }
         return 0;
      });

      return allPlayers;
   }

   private _getAllMembersByLastname() {
      let allPlayers = this.getAllUsers().filter(i => i.sysId != 1 && i.sysId != 2).sort(function (a, b) {
         if (a.lastName + a.firstName < b.lastName + b.firstName) {
            return -1;
         }
         if (a.lastName + a.firstName > b.lastName + b.firstName) {
            return 1;
         }
         return 0;
      });

      return allPlayers;
   }

   private _getAllMembersByRole(): Map<string, object> {
      let list = new Map<string, object>();
      let allUsers = this.getAllUsers();
      if (allUsers != null)
         allUsers = allUsers.filter(i => i.sysId != 1 && i.sysId != 2).sort(function (a, b) {
            if (a.firstName + a.lastName < b.firstName + b.lastName) {
               return -1;
            }
            if (a.firstName + a.lastName > b.firstName + b.lastName) {
               return 1;
            }
            return 0;
         });
      allUsers.forEach(u => this.frameworkService.getProfileRoles(u, false, false).forEach(i => {
         let temp = [];
         temp = list.get(i) as user[];
         if (temp == undefined) {
            temp = [];
         }
         temp.push(u);
         list.set(i, temp);
      }
      ));

      return list;
   }
   private _getAllMembersByTeam() {

      let allPlayers = this.getAllUsers().filter(i => i.sysId != 1 && i.sysId != 2).sort(function (a, b) {
         if ((a.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team?.teamCategory) + a.lastName + a.firstName < (b.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team?.teamCategory) + b.lastName + b.firstName) {
            return -1;
         }
         if ((a.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team?.teamCategory) + a.lastName + a.firstName > (b.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team?.teamCategory) + b.lastName + b.firstName) {
            return 1;
         }
         return 0;
      });

      return allPlayers;
   }
}