import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AppConfigService } from './appconfig.service';
import { HttpTransportType, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';
import { SubjectService } from './subject.service';
import { FrameworkService } from './framework.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService implements OnDestroy {
  private hubConnection: signalR.HubConnection;
  public HubConnectionSubject: Subject<boolean> = new Subject<boolean>();
  private hubConnectionStatus: number = 0;

  constructor(
    private appConfigService: AppConfigService,
    private cacheService: CacheService,
    private frameworkService: FrameworkService,
    private subjectService: SubjectService) {
  }

  public startConnection(isHubConnectionRestarted: boolean, subjectService: SubjectService) {
    this.hubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect([10000, 30000])
      .withUrl(this.appConfigService.getApiUrl() + 'SignalHub/', {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();

    this.hubConnection.serverTimeoutInMilliseconds = 100000; // 100 seconds
    this.hubConnection.keepAliveIntervalInMilliseconds = 60000; // 60 seconds

    this.hubConnection.start().then(function () {
      //this.frameworkService.logInfo(isHubConnectionRestarted, "SignalR -> hubConnection.start() / isHubConnectionRestarted");
      //subjectService.IsQueryOnTimeoutSubject.next(isHubConnectionRestarted ? 3 : 0);
      subjectService.HubConnectionStatusSubject.next(2);
    }).catch(function (err) {
      subjectService.HubConnectionStatusSubject.next(0);
      return console.error("==> ERROR : " + err.toString());
    });

    this.hubConnection.onreconnecting(error => {
      subjectService.HubConnectionStatusSubject.next(1);
    });
    this.hubConnection.onreconnected(connectionId => {
      subjectService.HubConnectionStatusSubject.next(2);
    });
    this.hubConnection.onclose(error => {
      subjectService.HubConnectionStatusSubject.next(0);
    });

    this.hubConnection.on("SynchroniseAsync", () => {
      //this.frameworkService.logInfo("SignalR (SynchroniseAsync) -> CALL loadUpdatedData");
      this.cacheService.loadUpdatedData(false, true);
    });

    this.hubConnection.on("SendAsync", (operation, message) => {
      console.log(operation + "/" + message);
    });
  }

  //BOOKMARK : closeHubConnection
  public closeHubConnection() {
    //this.frameworkService.logInfo(this.hubConnection.state != HubConnectionState.Disconnected && this.hubConnection.state != HubConnectionState.Disconnecting, "closeHubConnection");

    if (this.hubConnection != null) {
      //this.frameworkService.logInfo(this.hubConnection.state, "closeHubConnection state 1");
      if (this.hubConnection.state != HubConnectionState.Disconnected && this.hubConnection.state != HubConnectionState.Disconnecting) {
        this.hubConnection.stop();
        //this.frameworkService.logInfo(this.hubConnection.state, "closeHubConnection state 2");
      }
    }
  }

  public ngOnDestroy(): void {
    this.HubConnectionSubject.unsubscribe();
  }

  //BOOKMARK : isHubConnectionClosed
  public isHubConnectionClosed(): boolean {
    return this.hubConnection.state != HubConnectionState.Connected;
  }
}
