<nav>
   <!-- ROW 1 -->
   <div class="navbarTop">
      <!-- LOGO -->
      <div [class]="getNavbarClass()" (click)="reloadWebsite()">
      </div>

      <div class="navbarVabourlettisLogo">
         <!-- TITLE -->
         <div class="navbarVabourlettisNormalFont contentTitle">
            <span class="navbarVabourlettisBigFont">V</span>olley&nbsp;
            <span class="navbarVabourlettisBigFont">A</span>nderlues
            <span class="navbarVabourlettisBigFont">B</span>ourlettis&nbsp;
         </div>
         <div class="navbarSeasonSeparator"></div>
         <!-- SEASON -->
         <div class="navbarSeason">Saison {{getCurrentChampionshipName()}}
            <!-- WIFI ON ICON -->
            <span *ngIf="isNetworkStatusOnline" class="navbarMenuAlignment wifi"><span [class]="getWorkerServiceClass()">wifi</span>
            </span>
            <!-- WIFI OFF ICON -->
            <span *ngIf="!isNetworkStatusOnline" class="navbarMenuAlignment wifi"><span [class]="getWorkerServiceClass()">wifi_off</span>
            </span>
            <!-- MENU -->
            <span *ngIf="isInformationEditable() && !isTurnAdminButtonsOff"
               class="material-icons editButton animatedButton"
               [routerLink]="['/adminchampionshiplist']">more_horiz</span>
         </div>
      </div>

      <div class="navbarVabourlettis" *ngIf="isUserAuthenticated()">
         <div class="navbarSeason">
            <!-- PROFIL -->
            <span class="navbarMenuAlignment">
               <app-buttonmenu [isBackgroundOpaque]="true" icon="person" [routerLink]="['/profile']"
                  [queryParams]="{id: '-1'}"></app-buttonmenu>
            </span>
            <!-- LOG OUT -->
            <span class="navbarMenuAlignment">
               <app-buttonmenu [isBackgroundOpaque]="true" icon="power_settings_new" routerLink="/loginout"
                  (click)="logout()"></app-buttonmenu>
            </span>
         </div>
         <!-- USER CONNECTED -->
         <div class="navbarSeason contentUserTitle">
            {{userInitialDisplay}}
         </div>
      </div>

      <!-- LOG IN -->
      <div *ngIf="!isUserAuthenticated()">
         <div class="navbarLinkLogOut">
            <div class="marginLeftAuto">
               <app-buttonmenu [isBackgroundOpaque]="true" icon="account_circle" routerLink="/loginout"
                  (click)="login()"></app-buttonmenu>
            </div>
         </div>
      </div>
   </div>

   <!--  ROW 2 COMPUTER -->
   <div [class]="backColorEnv()" *ngIf="!isDisplayOnAMobile">
      <div>
         <span *ngIf="isCacheInLoading" class="cache navbarMenuAlignment">
            <img class="cacheImage rotate" src="../../../assets/images/system/spin.png">
         </span>
         <button *ngIf="!isCacheInLoading" class="navbarToggler" type="button" data-toggle="minimize"
            (click)="toggleSidebar()">
            <span class="material-icons-bigger">toggle_off</span>
         </button>
      </div>

      <app-buttonmenu icon="home" routerLink="/home"></app-buttonmenu>
      <app-buttonmenu icon="campaign" routerLink="/activity"></app-buttonmenu>
      <app-buttonmenu icon="groups" routerLink="/team"></app-buttonmenu>
      <app-buttonmenu icon="date_range" routerLink="/diary"></app-buttonmenu>
      <app-buttonmenu icon="emoji_events" routerLink="/ranking"></app-buttonmenu>
      <app-buttonmenu icon="event_note" routerLink="/comiteeMeeting" [visible]="isComiteeAuthenticated()"></app-buttonmenu>

      <app-buttonmenu icon="phone" routerLink="/contactus" [visible]="!isUserAuthenticated()"></app-buttonmenu>
      <app-buttonmenu icon="directions_run" routerLink="/trainingInfo"
         [visible]="!isUserAuthenticated()"></app-buttonmenu>
      <app-buttonmenu icon="festival" routerLink="/club" [visible]="!isUserAuthenticated()"></app-buttonmenu>

      <app-buttonmenu icon="thumbs_up_down" routerLink="/trainingPlayer"
         [visible]="isUserAuthenticated() && isUserInTeam()"></app-buttonmenu>
      <app-buttonmenu icon="people" routerLink="/member" [visible]="isUserAuthenticated()"></app-buttonmenu>
      <app-buttonmenu icon="../../../sites/{{site()}}/images/content/facebook.png" href="https://www.facebook.com/vabourlettis"
         alt="VAB facebook"></app-buttonmenu>
      <app-buttonmenu icon="../../../sites/{{site()}}/images/content/facebookGroup.png" href="https://www.facebook.com/groups/2385128264896160"
      [visible]="isUserAuthenticated()" alt="VAB facebook group"></app-buttonmenu>

      <app-buttonmenu icon="volunteer_activism" routerLink="/sponsor"
         [visible]="!isUserAuthenticated()"></app-buttonmenu>
      <app-buttonmenu icon="../../../sites/{{site()}}/images/content/Fvwb.jpg" href="https://www.portailfvwb.be/" alt="Fvwb"
         [visible]="isUserAuthenticated()"></app-buttonmenu>
      <app-buttonmenu icon="../../../sites/{{site()}}/images/content/Achvb.jpg" href="https://www.achvb.be/" alt="Achvb"
         [visible]="isUserAuthenticated()"></app-buttonmenu>

      <app-buttonmenu [isFlicked]="isSynchronisationRunning" icon="change_circle" routerLink="/fvwbSynchronize" (click)="fvwbSynchronize()"
         [visible]="isUserSupervisor()"></app-buttonmenu>
      <app-buttonmenu icon="cleaning_services" href="onclick" (onClick)="setReset()"
         [visible]="isUserSupervisor()"></app-buttonmenu>
      <app-buttonmenu [icon]="isTurnAdminButtonsOff ? 'edit' : 'edit_off'" href="onclick" (onClick)="turnOnOffAdminButtons()"
         [visible]="isUserSupervisor()"></app-buttonmenu>

      <div class="navbarLoginTitle" *ngIf="!isUserAuthenticated()">
         <div class="welcome">
            <span class="material-icons">privacy_tip</span> Veuillez vous connecter si vous êtes membre.
         </div>
      </div>
   </div>


   <!--  ROW 2 MOBILE -->
   <div [class]="backColorEnv()" *ngIf="isDisplayOnAMobile">
      <app-buttonmenu icon="home" routerLink="/home"></app-buttonmenu>
      <app-buttonmenu icon="campaign" routerLink="/activity"></app-buttonmenu>
      <app-buttonmenu icon="groups" routerLink="/team"></app-buttonmenu>
      <app-buttonmenu icon="date_range" routerLink="/diary"></app-buttonmenu>
      <app-buttonmenu icon="emoji_events" routerLink="/ranking"></app-buttonmenu>
      <app-buttonmenu icon="event_note" routerLink="/comiteeMeeting" [visible]="isComiteeAuthenticated()"></app-buttonmenu>
      <app-buttonmenu icon="phone" routerLink="/contactus" [visible]="!isUserAuthenticated()"></app-buttonmenu>
      <app-buttonmenu icon="directions_run" routerLink="/trainingInfo"
         [visible]="!isUserAuthenticated()"></app-buttonmenu>
      <app-buttonmenu icon="festival" routerLink="/club" [visible]="!isUserAuthenticated()"></app-buttonmenu>

      <app-buttonmenu icon="thumbs_up_down" routerLink="/trainingPlayer"
         [visible]="isUserAuthenticated() && isUserInTeam()"></app-buttonmenu>
      <app-buttonmenu icon="people" routerLink="/member" [visible]="isUserAuthenticated()"></app-buttonmenu>
   </div>
   <!-- ROW 3 MOBILE -->
   <div [class]="backColorEnv()" *ngIf="isDisplayOnAMobile">
      <app-buttonmenu icon="volunteer_activism" routerLink="/sponsor"
         [visible]="!isUserAuthenticated()"></app-buttonmenu>
      <app-buttonmenu icon="../../../sites/{{site()}}/images/content/facebook.png" href="https://www.facebook.com/vabourlettis"
         alt="VAB facebook"></app-buttonmenu>
      <app-buttonmenu icon="../../../sites/{{site()}}/images/content/facebookGroup.png" href="https://www.facebook.com/groups/2385128264896160"
         [visible]="isUserAuthenticated()" alt="VAB facebook group"></app-buttonmenu>
      <app-buttonmenu icon="../../../sites/{{site()}}/images/content/Fvwb.jpg" href="https://www.portailfvwb.be/" alt="Fvwb"
         [visible]="isUserAuthenticated()"></app-buttonmenu>
      <app-buttonmenu icon="../../../sites/{{site()}}/images/content/Achvb.jpg" href="https://www.achvb.be/" alt="Achvb"
         [visible]="isUserAuthenticated()"></app-buttonmenu>

      <app-buttonmenu [isFlicked]="isSynchronisationRunning" icon="change_circle" routerLink="/fvwbSynchronize" (click)="fvwbSynchronize()"
         [visible]="isUserSupervisor()"></app-buttonmenu>
      <app-buttonmenu icon="cleaning_services" href="onclick" (onClick)="setReset()"
         [visible]="isUserSupervisor()"></app-buttonmenu>
      <app-buttonmenu [icon]="isTurnAdminButtonsOff ? 'edit' : 'edit_off'" href="onclick" (onClick)="turnOnOffAdminButtons()"
         [visible]="isUserSupervisor()"></app-buttonmenu>

      <div class="navbarLoginTitle" *ngIf="!isUserAuthenticated()">
         <div class="welcome">
            <span class="material-icons">privacy_tip</span> Veuillez vous connecter si vous êtes membre.
         </div>
      </div>

      <div class="navbarLogAlignment">
         <div class="spinMargin navbarMenuAlignment">
            <span *ngIf="isCacheInLoading" class="cache navbarMenuAlignment">
               <img class="cacheImage rotate" src="../../../assets/images/system/spin.png">
            </span>
            <div *ngIf="!isCacheInLoading" class="navbarLink animatedButton">
               <a class="nav-link"><span class="material-icons-bigger" id="toggleMenu"
                     (click)="toggleOffcanvas()">menu</span></a>
            </div>
         </div>
      </div>
   </div>
</nav>