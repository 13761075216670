<div [hidden]="subjectCacheIsLoaded >= 2">
   <app-spinner></app-spinner>
</div>

<form [formGroup]="form">
   <div [hidden]="!subjectCacheIsLoaded">
      <mat-card>
         <mat-card-header class="mat-card-header-color">
            <mat-card-title>
               <div class="flexStart">
                  <div>
                     <span class="material-icons matcard-title-icon">thumbs_up_down</span>&nbsp;{{titleDescriptionDisplay}}&nbsp;
                  </div>
                  <div class="alignmentRight padding">
                     <span  *ngIf="isCoachOrAssistantOrSupervisorConnected && selectedCalendarDate != null">
                        <app-button icon="date_range" [isBackgroundOpaque]="false" [text]="''"
                           (onClick)="onTrainingPlayerEdition()"></app-button>
                     </span>
                     <span  *ngIf="selectedCalendarDate != null">
                        <app-button icon="pie_chart" [isBackgroundOpaque]="false" [text]="''"
                           (onClick)="onTrainingPlayersStatistics()"></app-button>
                  </span>
                     <span>
                        <app-button icon="refresh" [isBackgroundOpaque]="false"
                           (onClick)="onRefreshTrainingPlayerInformation()"></app-button>
                     </span>
                  </div>
               </div>
            </mat-card-title>
         </mat-card-header>
         <mat-card-content>
            <mat-card class="matCardContent">
               <mat-card-content>
                  <div class="flexAlignment" *ngIf="!isLoading">
                     <mat-card class="matcard">
                        <mat-card-content>
                           <!-- Statut -->
                           <ms [allClubEventsOfUserAuthenticated]="allClubEventsOfUserAuthenticatedForCalendar"
                              [date]="selectedCalendarDate" (OnEditTeamAvailability)="onTrainingPlayersManager()"></ms>
                           <!-- Calendrier -->
                           <mc [minDate]="minDate" [maxDate]="maxDate"
                              [allClubEventsOfUserAuthenticated]="allClubEventsOfUserAuthenticatedForCalendar"
                              (onSelected)="onCalendarDateSelect($event)"></mc>

                        </mat-card-content>
                     </mat-card>
                  </div>

                  <!-- Liste des matchs (à jouer) -->
                  <div class="matcard matcardFloat">
                     <app-panel [widthPanel]="'500px'">
                        <span panel-title>Liste des matchs (à jouer)</span>
                        <div panel-content>
                           <span *ngFor="let clubActivityCalendar of allClubEventsOfUserAuthenticated | keyvalue">
                              <md [eventClub]="clubActivityCalendar.value"
                                 [matchDisplayType]="EnumMatchDisplayType.STATUS" [isPresentVisible]="true"
                                 [isClickable]="true" (onClick)="onMatchClick(clubActivityCalendar.value.event.day)">
                              </md>
                           </span>
                        </div>
                     </app-panel>
                  </div>
               </mat-card-content>
            </mat-card>
         </mat-card-content>
      </mat-card>
   </div>
</form>