import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService } from '../../core/services/account.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private accountService: AccountService) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      next.handle(req).pipe(catchError(error => {
         if (error instanceof HttpErrorResponse && error.message === "Unauthorised") {
            // auto logout if "Unauthorised" response returned from api
            this.accountService.logout();
         }
         return throwError(() => error);
      }));
      return next.handle(req).pipe();
   }
}
