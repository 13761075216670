import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-buttonmenu',
   templateUrl: './buttonMenu.component.html',
   styleUrls: ['./buttonMenu.component.scss']
})
export class ButtonMenuComponent implements OnInit, OnChanges {
   @Input() icon: string;
   @Input() visible: boolean = true;
   @Input() routerLink: string;
   @Input() href: string;
   @Input() disabled: boolean = false;
   @Input() class: string;
   @Input() alt: string;
   @Input() isBackgroundOpaque: boolean = false;
   @Input() isFlicked: boolean = false;
   @Input() widthButton: string;

   @Output() onClick = new EventEmitter<string>();

   public isNetworkStatusOnline: boolean = false;
   public isNetworkLoading: boolean = false;
   public internalClass: string = "";
   public classBackup: string = "";
   public internalWidth: string = "42px";
   public isDisplayOnAMobile = false;

   bgColor: string = "#3d3d3d79 !important";

   constructor (
      private subjectService: SubjectService) {
      this.subjectService.IsWindowResizingSubject.subscribe(value => { this.isDisplayOnAMobile = value; this.resetWidth(); });
   } 
   ngOnChanges(changes: SimpleChanges): void {
      if (changes['isFlicked']) {
         this.class = "nav-link navbarLink " + (this.isBackgroundOpaque ? "navbarLinkBackgroundOpaque" : "navbarLinkBackground") + " animatedButton " + this.classBackup;
         if (this.isFlicked) this.class += " animate-flicker";
      }
   }

   ngOnInit() {
      this.classBackup = this.class;
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
      this.subjectService.IsButtonInLoadingSubject.subscribe(value => { this.isNetworkLoading = value; });

      this.class = "nav-link navbarLink " + (this.isBackgroundOpaque ? "navbarLinkBackgroundOpaque" : "navbarLinkBackground") + " animatedButton " + this.classBackup;
      
      if(this.isFlicked) this.class += " animate-flicker";
      this.internalClass = "nav-link " + ((this.href != undefined && this.href != "onclick") ? " nav-linkImage" : "");

      this.resetWidth();
   }

   resetWidth() {
      if (this.isDisplayOnAMobile)
         this.internalWidth = "42px";
      else
         this.internalWidth = (this.widthButton != "") ? this.widthButton : "60px";
   }


   openLink() {
      window.open(this.href, '_blank');
   }

   onButtonClick() {
      this.bgColor = "#f44b4b !important";
      setInterval(() => {
         this.bgColor = "#3d3d3d79 !important";
      }, 1000);
      this.onClick.emit();
   }
}
