import { formatDate } from '@angular/common';
import { Component, HostListener, Inject, LOCALE_ID, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { enumClubActivityType, enumMatchDisplayType, enumTimelineType } from 'src/sites/core/enum/Enum';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { eventClub } from '../../models/eventClub';
import { CacheService } from '../../services/cache.service';
import { StorageService } from '../../authentication/storage.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-activity',
   templateUrl: './activity.component.html',
   styleUrls: ['./activity.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class ActivityComponent implements OnInit {
   public EnumClubActivityType = enumClubActivityType;
   public EnumMatchDisplayType = enumMatchDisplayType;
   public EnumTimelineType = enumTimelineType;
   public allClubEvents = new Map<string, eventClub>();
   public allMatchNext = new Map<string, eventClub>();
   public allMatchResultsOfWeekend = new Map<string, eventClub>();
   public allMatchsOfWeekend = new Map<string, eventClub>();

   public allMatchNextArray: [string, eventClub][] = null;
   public allMatchResultsOfWeekendArray: [string, eventClub][] = null;
   public allMatchsOfWeekendArray: [string, eventClub][] = null;

   public championshipFilterDisplay: string = "";
   public isCoachOrAssistantOrSupervisorConnected: boolean = false;
   public isDisplayOnAMobile: boolean = false;
   public isLoading: boolean = false;
   public isNetworkStatusOnline: boolean = false;
   public isTurnAdminButtonsOff: boolean = true;
   public memberPresenceCountActivity = new Map<string, string>();
   public subjectCacheIsLoaded: number = null;
   public titleDescriptionDisplay: string = "";
   public isBirthdayDisplayed: boolean = false;

   constructor(
      @Inject(LOCALE_ID) private locale: string,
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      private accessRightsService: AccessRightsService,
      private storageService: StorageService,
      public sanitizer: DomSanitizer
   ) {
      monkeyPatchChartJsTooltip();
      monkeyPatchChartJsLegend();
   }

   @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

   public turnOnOffBirthdayVisibility() {
      this.isBirthdayDisplayed = !this.isBirthdayDisplayed;
      if (this.isBirthdayDisplayed) {
         this.allMatchNext = this.frameworkService.findEventClubsNext(this.allClubEvents);
      }
      else {
         this.allMatchNext.forEach((value, key) => {
            if ((value as eventClub).clubActivityType == enumClubActivityType.BIRTHDAY) {
               this.allMatchNext.delete(key);
            }
         });
      }
      this.allMatchNextArray = Array.from(this.allMatchNext.entries());
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

   public getBackgroundOfMatch(match) {
      if (match != null) {
         return "timeline-panel " + match.team.class ?? "";
      }
      return "timeline-panel";
   }

   public getEventTitle(isMatchPlay, allMatchsOfWeekendSize, allMatchResultsOfWeekendSize) {
      if (!isMatchPlay && allMatchsOfWeekendSize > 0 && allMatchResultsOfWeekendSize == 0)
         return " Il n'y a aucune rencontre cette semaine";
      else {
         if (!isMatchPlay && allMatchsOfWeekendSize == 0)
            return " Il n'y a aucun événement cette semaine";
         else
            return " Evénements de la semaine";
      }
   }

   public getNextEventTitle(allMatchNextSize) {
      if (allMatchNextSize == 0)
         return " Il n'y a aucun événement prochainement";
      else
         return " Prochains événements";
   }

   public getBorderOfMatch(match) {
      if (match != null) {
         return "border" + match.team.class ?? "";
      }
      return "";
   }

   public getCalendarActivityBirthdayDate(birthdayDate: Date) {
      return this.frameworkService.getCalendarActivityBirthdayDate(birthdayDate);
   }

   public getCalendarActivityDate(date: Date) {
      return this.frameworkService.getCalendarActivityDate(date);
   }

   public getCalendarActivityDateByMonth(date: Date) {
      return this.frameworkService.uppercaseFirstLetterAndLowerOther(this.frameworkService.getCalendarActivityDateByMonth(date));
   }

   public getCalendarActivityDateByMonthAndYear(date: Date) {
      return this.frameworkService.uppercaseFirstLetterAndLowerOther(this.frameworkService.getCalendarActivityDateByMonthAndYear(date));
   }

   public getClosingDayInfo(closingDayReason) {
      return this.frameworkService.getClosingDayInfo(closingDayReason);
   }

   public getComiteeMeetingInfo(meeting) {
      return this.frameworkService.getComiteeMeetingInfo(meeting);
   }

   public getDayNumberOfDate(value: Date): string {
      return this.frameworkService.getDayNumberOfDate(value);
   }

   public getDayOfWeek(value: string) {
      return this.frameworkService.getDayOfWeekFromString(value);
   }

   public getDayOfWeekFromDate(date: Date) {
      return this.translateService.instant(this.frameworkService.getDayOfWeekFromDate(date)).toLowerCase();
   }

   public getEventInfo(event) {
      return this.frameworkService.getEventInfo(event);
   }

   public getHtml(value: string) {
      return this.frameworkService.replaceCrLfByBr(value ?? "");
   }

   public getIconTimeLine(type: enumClubActivityType) {
      if (type == enumClubActivityType.BIRTHDAY)
         return "cake";
      if (type == enumClubActivityType.COMITEE_MEETING || type == enumClubActivityType.AG_MEETING)
         return "event_note";
      if (type == enumClubActivityType.SUPPER)
         return "event";
      if (type == enumClubActivityType.CLOSING)
         return "door_front";
      if (type == enumClubActivityType.TOURNAMENT_DAY)
         return "sports_volleyball";
      return "sports_volleyball";
   }

   public getImgURL(value: string) {
      if (value == null || value == "")
         return null;
      else
         return this.sanitizer.bypassSecurityTrustUrl(JSON.parse(value));
   }

   public getInfoBirthday(birthday, isNext) {
      return this.frameworkService.getInfoBirthday(birthday, isNext);
   }

   // public getMatchBackground(match: match): string {
   //    if (match != null) {
   //       if (match.team.teamCategory.endsWith("M"))
   //          return "matchMan removePadding";
   //       else {
   //          if (match.team.teamCategory.endsWith("D"))
   //             return "matchWoman removePadding";
   //          else
   //             return "matchYoung removePadding";
   //       }
   //    }
   // }

   public getMonthStringOfDate(value: Date): string {
      return this.frameworkService.getMonthStringOfDate(value);
   }

   public getMonthStringOfDateTranslated(value: Date, toLowerCase: boolean): string {
      let temp = this.frameworkService.uppercaseFirstLetter(this.translateService.instant(this.frameworkService.getMonthStringOfDate(value)));
      if (toLowerCase) return temp.toLowerCase(); else return temp;
   }

   public getNextMonthStringOfDate(value: Date): string {
      return this.frameworkService.getNextMonthStringOfDate(value);
   }

   public getSaturdaySundayWeek(value: Date) {
      return "Weekend du " + formatDate(this.frameworkService.getSaturdayFromDateOnSameWeek(value), "dd", this.locale) + "-" + formatDate(this.frameworkService.getSundayFromDateOnSameWeek(value), "dd", this.locale);
   }

   public getToday() {
      return this.frameworkService.getToday();
   }

   public getYearOfDate(value: Date): string {
      return this.frameworkService.getYearOfDate(value);
   }

   public initialize() {
      this.isCoachOrAssistantOrSupervisorConnected = this.frameworkService.IsMemberCoachs(this.storageService.getUserConnected()) || this.accessRightsService.isUserSupervisor();

      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

   public isComiteeAuthenticated() {
      if (this.storageService.getUserConnected() == null)
         return false;
      else
         return this.frameworkService.isUserInComitee(this.storageService.getUserConnected());
   }

   public isInformationEditable() {
      return this.accessRightsService.isInformationEditable();
   }

   public isMatchPlay() {
      let temp = this.frameworkService.isMatchPlay(this.allMatchsOfWeekend);
      return temp;
   }

   public isUserAuthenticated(): boolean {
      return this.storageService.getUserConnected() != null;
   }

   public ngOnInit() {
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });

      //Souscription à l'event lorsque la cache globale a terminé son chargement
      this.subjectService.IsGlobalCacheLoadedSubject.subscribe(value => { this.subjectCacheIsLoaded = value; if (value == 2) this.initialize(); });

      this.subjectService.IsTurnOffAdminButtonSubject.subscribe(value => { this.isTurnAdminButtonsOff = value; });

      this.getScreenSize();
   }

   public onFilterChange(): void {
      this.refresh();
   }

   public refresh() {
      this.isLoading = true;
      this.allClubEvents = this.cacheService.getAllClubEvents();
      this.allMatchsOfWeekend = this.frameworkService.findEventClubsOfWeekend(this.allClubEvents);
      this.allMatchResultsOfWeekend = this.frameworkService.findEventClubResultsOfWeekend(this.allClubEvents);
      this.allMatchNext = this.frameworkService.findEventClubsNext(this.allClubEvents);
      this.allMatchNext.forEach((value, key) => {
         if ((value as eventClub).clubActivityType == enumClubActivityType.BIRTHDAY) {
            this.allMatchNext.delete(key);
         }
      });
      this.allMatchsOfWeekendArray = Array.from(this.allMatchsOfWeekend.entries());
      this.allMatchResultsOfWeekendArray = Array.from(this.allMatchResultsOfWeekend.entries());
      this.allMatchNextArray = Array.from(this.allMatchNext.entries());
      this.isLoading = false;
   }

   public getTiPaddingClass(i, array, isTimelineInverted) {
      return this._getTiPaddingClass(this.getTiPaddingClassCondition(i, array), isTimelineInverted);
   }
   public getTiPaddingClassCondition(i, array) {
      let isHidden = false;
      if (i - 1 < 0 || array.length <= 0)
         isHidden = false;
      else {
         if (array[i][1].clubActivityType == enumClubActivityType.BIRTHDAY)
         {  // If it is a birthday, Check if previous activity is a birthday
            if (array[i - 1][1].clubActivityType == enumClubActivityType.BIRTHDAY)
            {
               // Yes, previous is a birthday,
               // if month not equal, no space
               // if month equal, space
               if (this.getCalendarActivityDateByMonth(array[i][1].day) != this.getCalendarActivityDateByMonth(array[i - 1][1].day))
                  isHidden = false;
               else    
                  isHidden = true;
            }
            else
            {
               // No, previous is not a birthday, space
               isHidden = true; //this.getCalendarActivityDate(this.array[i][1].day) == this.getCalendarActivityDate(this.array[i - 1][1].day);
            }
         }
         else
         {
            // If it is not a birthday, space
            if (this.getCalendarActivityDate(array[i][1].day) == this.getCalendarActivityDate(array[i - 1][1].day))
            {
               isHidden = true;
               if (array[i - 1][1].clubActivityType == enumClubActivityType.BIRTHDAY)
                  isHidden = false;
            }
         }
      }
      return isHidden;
   }


   private _getTiPaddingClass(isHidden, isTimelineInverted) {
      if (isTimelineInverted)
         return (isHidden) ? "timeline-inverted tiSpaceNormal" : "timeline-inverted tiSpace";
      else
         return (isHidden) ? "tiSpaceNormal" : "tiSpace";
   }

   public translatePage() {
      //ActivityTitle
      this.translateService.get(['ActivityTitle'])
         .subscribe(translations => {
            this.titleDescriptionDisplay = translations['ActivityTitle'];
         });
   }
}

export class Event {
   public date: Date;
   public object: any;
   public team: string;
   public type: string;

   constructor(init?: Partial<Event>) {
      Object.assign(this, init);
   }
}
