import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';
import { DataPrivacyDialogComponent, DataPrivacyDialogModel } from './dataPrivacyDialog.component';
import { GDPRDialogComponent, GDPRDialogModel } from './gDPRDialog.component';
import { VersionDialogComponent, VersionDialogModel } from './versionDialog.component';
import { enumCacheTypeLoad } from 'src/sites/core/enum/Enum';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-footer',
   templateUrl: './footer.component.html',
   styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
   public currentApplicationLastVersionUpdate: string = "";
   public currentDataLastVersionUpdate: string = "";
   public currentApplicationVersionNumber: string = "";
   public isDisplayOnAMobile: boolean = false;
   public subjectCacheIsLoaded: number = null;

   constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private frameworkService: FrameworkService,
      private storageService: StorageService,
      public dialog: MatDialog) {
      this.getScreenSize();
   }

   @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

   public getDataPrivacyPopup() {
      const dialogData = new DataPrivacyDialogModel("Protection de vos données");

      //this.frameworkService.logInfo("dialog open ","DataPrivacy (footer)");
      const dialogRef = this.dialog.open(DataPrivacyDialogComponent, {
         maxWidth: "100%",
         width: "100%",
         maxHeight: "100%",
         height: "100%",
         data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
         if (dialogResult) {
            if (!(this.storageService.isDataPrivacyAccepted())) {
               //Set privacy cookie
               this.storageService.setDataPrivacyCookie(true);
               //this.frameworkService.logInfo("CALL cacheLoadStart","Popup data privacy (app)");
               this.cacheService.cacheLoadStart(enumCacheTypeLoad.fromWebAPI);
            }
            else
               this.subjectService.IsButtonInLoadingSubject.next(false);
         }
         else {
            if (this.storageService.isDataPrivacyAccepted())
               this.cacheService.resetDataForPrivacyOff();
            else
               this.subjectService.IsButtonInLoadingSubject.next(false);
         }
      });
   }

   public getGDPR() {
      this.dialog.open(GDPRDialogComponent, {
         position: { top: '0px' },
         maxWidth: "100%",
         width: "100%",
         data: new GDPRDialogModel("Règles GDPR")
      });
   }

   public getHistoryVersion() {
      this.dialog.open(VersionDialogComponent, {
         position: { top: '0px' },
         maxWidth: "100%",
         width: "100%",
         data: new VersionDialogModel("Version du site")
      });
   }

   public initialize() {
      this.currentApplicationVersionNumber = this.cacheService.getLastVersionNumber().replace("SITE ", "");
      this.currentApplicationLastVersionUpdate = this.cacheService.getLastVersionUpate();
      this.currentDataLastVersionUpdate = this.frameworkService.convertDateTimeToNowDDMMYYYYHHMMSSFromString(this.storageService.getLastUpdateCookie());
   }

   public ngOnInit(): void {
      //Souscription à l'event lorsque la cache globale a terminé son chargement
      this.subjectService.IsGlobalCacheLoadedSubject.subscribe(value => { this.subjectCacheIsLoaded = value; if (value == 2) this.initialize(); });
   }
}
