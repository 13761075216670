import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { enumActivityType, enumAvailability, enumAvailabilityReason, enumClubActivityType } from 'src/sites/core/enum/Enum';
import { user } from 'src/sites/core/models/user/user';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { eventClub } from '../../models/eventClub';
import { trainingPlayer } from '../../models/trainingPlayer';
import { CacheService } from '../../services/cache.service';
import { TrainingPlayerService } from '../../services/trainingPlayer.service';
import { Color } from 'ng2-charts/lib/color';
import { StorageService } from '../../authentication/storage.service';
import { encryptedValue } from '../../authentication/encryptedValue';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'tpm',
   templateUrl: './trainingPlayersManager.component.html',
   styleUrls: ['./trainingPlayersManager.component.scss']
})
export class TrainingPlayersManagerComponent implements OnInit {
  public EnumAvailability = enumAvailability;
  public EnumAvailabilityReason = enumAvailabilityReason;
  public allButtonClass: string[] = [];
  public allClubEventsOfUserAuthenticated: Map<string, eventClub>;
  public allCoachsOfTeamOfCurrentUser: user[] = [];
  public allReasons: any[] = [];
  public allUsersOfTeamOfCurrentUser: user[] = [];
  public chartColors: Color[] = [{ backgroundColor: ["#4caf4c", "#ddbb25", "#c03030"] }];
  public date: Date;
  public editionIndexVisible = -1;
  public eventClub: eventClub = null;
  public form: FormGroup = new FormGroup({});
  public isCoachOnlyConnected: boolean = false;
  public isCoachOrAssistantOrSupervisorConnected: boolean = false;
  public pieChartData: SingleDataSet;
  public pieChartLabels: Label[];
  public pieChartLegend: boolean = true;
  public pieChartOptions: ChartOptions = {
      responsive: true
   };
  public pieChartPlugins = [];
  public pieChartType: ChartType = 'doughnut';
  public playersNotPresentTotal = 0;
  public playersOnGuardTotal = 0;
  public playersPresentTotal = 0;
  public subTitle: string;
  public title: string;

  constructor(public dialogRef: MatDialogRef<TrainingPlayersManagerComponent>,
      @Inject(MAT_DIALOG_DATA) public data: TrainingPlayersManagerModel,
      private accessRightsService: AccessRightsService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      private trainingPlayerService: TrainingPlayerService,
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private storageService: StorageService,
      private encryptionService: EncryptionService) {
      this.subjectService.IsButtonInLoadingSubject.next(false);

      this.isCoachOrAssistantOrSupervisorConnected = this.frameworkService.IsMemberCoachs(this.storageService.getUserConnected()) || this.accessRightsService.isUserSupervisor();
      this.isCoachOnlyConnected = this.frameworkService.isMemberCoachOnly(this.storageService.getUserConnected());
      this.title = "Disponibilités au " + this.frameworkService.getCalendarActivityDate(data.date, true);
      this.date = data.date;

      this.initialize();

      this.subjectService.IsTrainingPlayerCacheLoadedSubject.subscribe(value => {
         if (value) {
            this.initialize();
         }
      });

      this.form.addControl("allReasons", new FormControl(''));
      this.allReasons = this.frameworkService.getNonPresenceReason();
   }

  public getAllAvailability(user: user) {
      return this.cacheService.getAllTrainingPlayers().filter(i => i.playerSysId == user.sysId && this.frameworkService.getDateFormatYyyyMmDd(i.day) >= this.frameworkService.getDateFormatYyyyMmDd(this.date)).OrderBy(i => i.day).ToArray();
   }

  public getAllAvailabilityOfCoach(user: user) {
      return this.cacheService.getAllTrainingPlayers().filter(i => i.playerSysId == user.sysId && i.present == 0 && this.frameworkService.getDateFormatYyyyMmDd(i.day) >= this.frameworkService.getDateFormatYyyyMmDd(this.date)).OrderBy(i => i.day).ToArray();
   }

  public getAvailability(user: user) {
      let tp = this.cacheService.getAllTrainingPlayers().FirstOrDefault(i => i.playerSysId == user.sysId && this.frameworkService.getDateFormatYyyyMmDd(i.day) == this.frameworkService.getDateFormatYyyyMmDd(this.date));
      return this.cacheService.getAvailabilityFromTrainingPlayer(tp);
   }

  public getAvailabilityClass(user: user): string {
      if (user != null) {
         let tp = this.cacheService.getAllTrainingPlayers().FirstOrDefault(i => i.playerSysId == user.sysId && this.frameworkService.getDateFormatYyyyMmDd(i.day) == this.frameworkService.getDateFormatYyyyMmDd(this.date));
         if (tp != null)
            return (tp.present == null) ? "availabilitySize guard" : (tp.present >=1 ? "availabilitySize present" : "availabilitySize notPresent");
         else
            return "availabilitySize present";
      }
      else
         return "availabilitySize";
   }

  public getCalendarActivityDate(date) {
      return this.frameworkService.getCalendarActivityDate(date, true) 
   }

  public getJacketNumber(user: user) {
      return this.frameworkService.getJacketNumber(user);
   }

  public getPresenceIcon(user: user) {
      let tp = this.cacheService.getAllTrainingPlayers().FirstOrDefault(i => i.playerSysId == user.sysId && this.frameworkService.getDateFormatYyyyMmDd(i.day) == this.frameworkService.getDateFormatYyyyMmDd(this.date));
      if (tp != null) {
         return (tp.present == null) ? "thumbs_up_down" : (tp.present >= 1 ? "thumb_up" : "thumb_down");
      }
      else
         return "thumb_up";
   }

  public getReason(user: user) {
      let tp = this.cacheService.getAllTrainingPlayers().FirstOrDefault(i => i.playerSysId == user.sysId && this.frameworkService.getDateFormatYyyyMmDd(i.day) == this.frameworkService.getDateFormatYyyyMmDd(this.date));
      if (tp != null)
         return tp.reason;
      else
         return "";
   }

  public getReasonTranslated(user: user) {
      let tp = this.cacheService.getAllTrainingPlayers().FirstOrDefault(i => i.playerSysId == user.sysId && this.frameworkService.getDateFormatYyyyMmDd(i.day) == this.frameworkService.getDateFormatYyyyMmDd(this.date));
      if (tp != null) {
         return tp.reason != "" ? this.translateService.instant("REASON_" + tp.reason) : "";
      }
      else
         return "";
   }

  public initialize() {
      this.playersOnGuardTotal = 0;
      this.playersNotPresentTotal = 0;
      this.playersPresentTotal = 0;

      this.allClubEventsOfUserAuthenticated = this.cacheService.getAllClubEventsOfUserAuthenticated();
      this.allCoachsOfTeamOfCurrentUser = this.cacheService.getAllCoachsOfCurrentLogged().sort((a, b) => a.firstName + a.lastName < b.firstName + b.lastName ? -1 : 1);
      let allCoachs = this.allCoachsOfTeamOfCurrentUser.map(function (user) {
         return user.sysId;
      });
      this.allUsersOfTeamOfCurrentUser = this.cacheService.getAllUsersOfTeamOfCurrentUser().filter(i => i.firstName != "VAB" && !allCoachs.includes(i.sysId)).sort((a, b) => a.firstName + a.lastName < b.firstName + b.lastName ? -1 : 1);

      this.allButtonClass = [];

      this.allCoachsOfTeamOfCurrentUser.forEach(u => {
         this.allButtonClass.push(this.getAvailabilityClass(u));
      });

      this.allUsersOfTeamOfCurrentUser.forEach(u => {
         let availability = this.getAvailability(u);  
         if (availability == enumAvailability.GUARD)
            this.playersOnGuardTotal++;
         if (availability == enumAvailability.PRESENT)
            this.playersPresentTotal++;
         if (availability == enumAvailability.NOTPRESENT)
            this.playersNotPresentTotal++;

         this.allButtonClass.push(this.getAvailabilityClass(u));
      });

      let presencePercent = Math.round((Number(this.playersPresentTotal) / Number(this.allUsersOfTeamOfCurrentUser.Count())) * 100);
      let notPresencePercent = Math.round((Number(this.playersNotPresentTotal) / Number(this.allUsersOfTeamOfCurrentUser.Count())) * 100);
      let guardPercent = Math.round((Number(this.playersOnGuardTotal) / Number(this.allUsersOfTeamOfCurrentUser.Count())) * 100);

      this.pieChartLabels = [
         [this.frameworkService.formatString("{0}% présent", [presencePercent.toString()])],
         [this.frameworkService.formatString("{0}% garde", [guardPercent.toString()])],
         [this.frameworkService.formatString("{0}% absent", [notPresencePercent.toString()])]
      ];
      this.pieChartData = [presencePercent, guardPercent, notPresencePercent];
   }

  public isATrainingDay() {
      return this.cacheService.getAllTrainingDays().FirstOrDefault(i => i.day == new Date(this.date).getDay()) != null;
   }

  public isAnExtraDay(type: string) {
      return (type == enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY] ||
         type == enumActivityType[enumActivityType.TOURNAMENT_DAY] ||
         type == enumActivityType[enumActivityType.EXTRA_TRAINING_DAY]);
   }

  public ngOnInit() {
   }

  public onCancel() {
      this.dialogRef.close(false);
   }

  public async onChangeToGuard(user: user) {
      // try {
      let type = "";
      let isShouldBeInserted = false;
      let tp: trainingPlayer;
      let tpOfCoach = this.cacheService.getExtraTrainingOfCoachFromDate(this.date);

      if (this.cacheService.getUserLogged().sysId == user.sysId)
      {
         this.eventClub = this.cacheService.getEventClubWithTraining(this.allClubEventsOfUserAuthenticated, this.date);
         tp = this.cacheService.getAllTrainingPlayersOfCurrentLogged().FirstOrDefault(i => i.sysId == this.eventClub.tpSysId);
         type = this.eventClub.event.type;
      }
      else {
         tp = this.cacheService.getAllTrainingPlayers().FirstOrDefault(i => i.playerSysId == user.sysId && this.frameworkService.getDateFormatYyyyMmDd(i.day) == this.frameworkService.getDateFormatYyyyMmDd(this.date));
         if (tp == null)
         {
            if (this.isATrainingDay()) 
               type = enumClubActivityType[enumClubActivityType.TRAINING_DAY];
            else
               type = tpOfCoach != null ? tpOfCoach.type : "";
         }
         else {
            if (this.isATrainingDay()) 
               type = enumClubActivityType[enumClubActivityType.TRAINING_DAY];
            else
               type = tpOfCoach != null ? tpOfCoach.type : "";
         }
      }

      if (tp == null) {
         isShouldBeInserted = true;
         tp = new trainingPlayer();
         tp.day = this.frameworkService.getDateOnlyFormatDatabase(this.date);
         tp.type = type;
         tp.championshipSysId = this.cacheService.getCurrentChampionship().sysId;
         tp.playerSysId = (this.cacheService.getUserLogged().sysId == user.sysId) ? this.storageService.getUserConnected().sysId : user.sysId;
         tp.championship = null;
         tp.player = null;
         tp.present = null;

         if (this.isAnExtraDay(tp.type)) {
            tp.extraTrainingBuilding = tpOfCoach.extraTrainingBuilding;
            tp.extraTrainingIsAtHome = tpOfCoach.extraTrainingIsAtHome;
            tp.extraTrainingTeam = tpOfCoach.extraTrainingTeam;
            tp.extraTrainingTimeStarting = tpOfCoach.extraTrainingTimeStarting;
            tp.extraTrainingTimeEnding = tpOfCoach.extraTrainingTimeEnding;
            tp.trainingPlayerSysId = tpOfCoach.sysId;
            tp.isPresenceNotCounted = tpOfCoach.isPresenceNotCounted;
         }

         if (this.isATrainingDay()) {
            tp.extraTrainingIsAtHome = true;
            tp.extraTrainingTimeStarting = this.cacheService.getAllTrainingDays().FirstOrDefault(i => i.day == new Date(this.date).getDay()).timeFrom;
            tp.extraTrainingTimeEnding = this.cacheService.getAllTrainingDays().FirstOrDefault(i => i.day == new Date(this.date).getDay()).timeTo;
         }
      }
      else
         tp.present = null;

      //this.frameworkService.logInfo(tp, "onChangeToGuard(tp)");
      this.saveNotPresent(user, isShouldBeInserted, tp, enumAvailabilityReason[enumAvailabilityReason.GUARD]);
      // }
      // catch (err) {
      //    this.subjectService.IsButtonInLoadingSubject.next(false);
      // }
   }

  public async onChangeToNotPresent(user: user) {
       try {
      let type = "";
      let isShouldBeInserted = false;
      let tp: trainingPlayer;
      let tpOfCoach = this.cacheService.getExtraTrainingOfCoachFromDate(this.date);

      this.eventClub = this.cacheService.getEventClubWithTraining(this.allClubEventsOfUserAuthenticated, this.date);

      if (this.cacheService.getUserLogged().sysId == user.sysId)
      {
         tp = this.cacheService.getAllTrainingPlayersOfCurrentLogged().FirstOrDefault(i => i.sysId == this.eventClub.tpSysId);
         type = this.eventClub.event.type;
      }
      else {
         tp = this.cacheService.getAllTrainingPlayers().FirstOrDefault(i => i.playerSysId == user.sysId && this.frameworkService.getDateFormatYyyyMmDd(i.day) == this.frameworkService.getDateFormatYyyyMmDd(this.date));
       
         if (tp == null)
         {
            if (this.isATrainingDay()) 
               type = enumClubActivityType[enumClubActivityType.TRAINING_DAY];
            else
               type = tpOfCoach != null ? tpOfCoach.type : this.eventClub.event.type;
         }
         else {
            if (this.isATrainingDay()) 
               type = enumClubActivityType[enumClubActivityType.TRAINING_DAY];
            else
               type = tpOfCoach != null ? tpOfCoach.type : this.eventClub.event.type;
         }
      }

      if (tp == null) {
         isShouldBeInserted = true;
         tp = new trainingPlayer();
         tp.day = this.frameworkService.getDateOnlyFormatDatabase(this.date);
         tp.type = type;
         tp.championshipSysId = this.cacheService.getCurrentChampionship().sysId;
         tp.playerSysId = (this.cacheService.getUserLogged().sysId == user.sysId) ? this.storageService.getUserConnected().sysId : user.sysId;
         tp.championship = null;
         tp.player = null;

         tp.present = 0;
         if (this.isAnExtraDay(tp.type)) {
            //this.frameworkService.logInfo("true", "isAnExtraDay");
            tp.extraTrainingBuilding = tpOfCoach.extraTrainingBuilding;
            tp.extraTrainingIsAtHome = tpOfCoach.extraTrainingIsAtHome;
            tp.extraTrainingTeam = tpOfCoach.extraTrainingTeam;
            tp.extraTrainingTimeStarting = tpOfCoach.extraTrainingTimeStarting;
            tp.extraTrainingTimeEnding = tpOfCoach.extraTrainingTimeEnding;
            tp.trainingPlayerSysId = tpOfCoach.sysId;
            tp.isPresenceNotCounted = tpOfCoach.isPresenceNotCounted;
         }
      }
      else {
         tp.present = 0;
      }

      this.saveNotPresent(user, isShouldBeInserted, tp, this.form.get("allReasons").value);
      }
      catch (err) {
         this.subjectService.IsButtonInLoadingSubject.next(false);
      }
   }

  public async onChangeToPresent(user: user) {
      // try {
      if (this.cacheService.getUserLogged().sysId == user.sysId) {
         this.eventClub = this.cacheService.getEventClubWithTraining(this.allClubEventsOfUserAuthenticated, this.date);
         //this.frameworkService.logInfo(this.eventClub, "onChangeToPresent this.eventClub");
      }
      //this.frameworkService.logInfo(this.cacheService.getUserLogged().sysId + " == " + user.sysId, "onChangeToPresent");

      let tp = this.cacheService.getAllTrainingPlayers().FirstOrDefault(i => i.playerSysId == user.sysId && this.frameworkService.getDateFormatYyyyMmDd(i.day) == this.frameworkService.getDateFormatYyyyMmDd(this.date));
      if (tp != null) {
         //this.frameworkService.logInfo(tp, "onChangeToPresent(tp)");
         tp.present = 1;
         tp.reason = "";
         this.savePresent(user, this.eventClub, tp);
      }
      this.subjectService.IsButtonInLoadingSubject.next(false);
      // }
      // catch (err) {
      //    this.subjectService.IsButtonInLoadingSubject.next(false);
      // }
   }

  public onEditPresence(user: user, id: number) {
      this.editionIndexVisible = id;
      this.form.get("allReasons").setValue(this.getReason(user));
      //this.frameworkService.logInfo(this.getReason(user), "TPM reason");
      //this.frameworkService.logInfo(user, "TPM user");
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

  public saveNotPresent(user: user, isShouldBeInserted: boolean, tp: trainingPlayer, reason: string) {
      tp.reason = reason == "" ? enumAvailabilityReason[enumAvailabilityReason.OTHER] : reason;

      //this.frameworkService.logInfo(tp, "saveNotPresent(tp)");

      if (isShouldBeInserted)
      {
         this.trainingPlayerService.insertWithServerDateCheck(tp, false).subscribe({
            next: data => {
               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

               if (dataDecrypted != null) {
                  this.allClubEventsOfUserAuthenticated = this.cacheService.manageIUClubEventsOfUserAuthenticated(this.date, dataDecrypted, this.eventClub, true, user);

                  this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
                  this.frameworkService.displayAlert(true);
               }
               else
                  this.frameworkService.displayAlert(false);
               this.subjectService.IsButtonInLoadingSubject.next(false);
            },
            error: err => {
               this.frameworkService.displayAlert(null, err);
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
         });
      }
      else
         this.trainingPlayerService.updateWithServerDateCheck(tp, false).subscribe({
            next: data => {
               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

               if (dataDecrypted != null) {
                  this.allClubEventsOfUserAuthenticated = this.cacheService.manageIUClubEventsOfUserAuthenticated(this.date, dataDecrypted, this.eventClub, false, user);

                  this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
                  this.frameworkService.displayAlert(true);
               }
               else
                  this.frameworkService.displayAlert(false);
               this.subjectService.IsButtonInLoadingSubject.next(false);
            },
            error: err => {
               this.frameworkService.displayAlert(null, err);
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
         });
   }

  public savePresent(user: user, ec: eventClub, tp: trainingPlayer) {
      //this.frameworkService.logInfo(tp, "savePresent(tp)");

      if (this.frameworkService.isMemberCoachOnly(user) && this.isAnExtraDay(tp.type)) {
         //If it is the coach, update the TP if it is an extra day. 
         this.trainingPlayerService.updateWithServerDateCheck(tp, false).subscribe({
            next: data => {
               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

               if (dataDecrypted != null) {
                  this.allClubEventsOfUserAuthenticated = this.cacheService.manageIUClubEventsOfUserAuthenticated(this.date, dataDecrypted, this.eventClub, false, user);

                  this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
                  this.frameworkService.displayAlert(true);
               }
               else
                  this.frameworkService.displayAlert(false);
               this.subjectService.IsButtonInLoadingSubject.next(false);
            },
            error: err => {
               this.frameworkService.displayAlert(null, err);
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
         });
      }
      else {
         this.trainingPlayerService.deleteWithServerDateCheck(tp.sysId, tp.day, tp.extraTrainingTimeStarting, false).subscribe({
            next: data => {
               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

               if (dataDecrypted != null) {
                  this.allClubEventsOfUserAuthenticated = this.cacheService.manageRClubEventsOfUserAuthenticated(tp, user);

                  this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
                  this.frameworkService.displayAlert(true);
               }
               else {
                  this.frameworkService.displayAlert(false);
               }
               this.subjectService.IsButtonInLoadingSubject.next(false);
            },
            error: err => {
               this.frameworkService.displayAlert(null, err);
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
         });
      }
   }
}

export class TrainingPlayersManagerModel {
  constructor(public date: Date) {
   }
}