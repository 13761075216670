<div [hidden]="subjectCacheIsLoaded < 2">
   <div>
      <mat-card class="mat-card-center-95P">
         <mat-card-header class="mat-card-header-color">
            <mat-card-title>
               <span class="material-icons matcard-title-icon"
                  style="margin-left:auto">groups</span>{{titleDescriptionDisplay}}&nbsp;<span
                  *ngIf="isInformationEditable() && !isTurnAdminButtonsOff"
                  class="material-icons editButton animatedButton" [routerLink]="['/adminteamlist']">more_horiz</span>
            </mat-card-title>
         </mat-card-header>
         <mat-card-content>
            <div class="matcard matcardFloat">
               <app-panel [widthPanel]="'500px'" *ngFor="let team of allTeamsFiltered">
                  <span panel-title>{{getNameOfTeam(team)}}</span>
                  <div panel-content>
                     <div>
                        <div class="flexCenter"><img *ngIf="team.picture != null && team.picture != ''"
                              [src]="getImgURL(team.picture)" loading="lazy" class="groupPicture" alt="Team">
                        </div>
                     </div>
                     <div [innerHtml]="getPlayersOfTeam(team) | safe: 'html'">
                     </div>
                  </div>
               </app-panel>
            </div>
         </mat-card-content>
      </mat-card>
   </div>
</div>



<!-- 
<div [hidden]="subjectCacheIsLoaded < 2">
   <div>
      <mat-card class="mat-card-center-95P">
         <mat-card-content>
            <mat-card>
               <mat-card-header class="mat-card-header-color">
                  <mat-card-title>
                     <span class="material-icons matcard-title-icon"
                        style="margin-left:auto">groups</span>{{titleDescriptionDisplay}}&nbsp;<span *ngIf="isInformationEditable() && !isTurnAdminButtonsOff"
                        class="material-icons editButton animatedButton"
                        [routerLink]="['/adminteamlist']">more_horiz</span>
                  </mat-card-title>
               </mat-card-header>
               <mat-card-content>
                  <div class="mainmatcard">
                     <div class="matcard" *ngFor="let team of allTeamsFiltered">
                        <mat-card>
                           <mat-card-header class="mat-card-header-color">
                              <mat-card-title>{{getNameOfTeam(team)}}</mat-card-title>
                           </mat-card-header>
                           <mat-card-content>
                              <div>
                                 <div class="flexCenter"><img *ngIf="team.picture != null && team.picture != ''"
                                       [src]="getImgURL(team.picture)" loading="lazy" class="groupPicture" alt="Team">
                                 </div>
                              </div>
                              <div>
                                 {{ getPlayersOfTeam(team) }}
                              </div>
                           </mat-card-content>
                        </mat-card>
                     </div>
                  </div>
               </mat-card-content>
            </mat-card>
         </mat-card-content>
      </mat-card>
   </div>
</div> -->