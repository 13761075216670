<ng-container class="noPrint">
   <div *ngIf="isLoading || subjectCacheIsLoaded < 2" style="width: 100%;padding: 0px;margin: 0px;">
      <div class="container-fluid load-page-body-wrapper" style="width: 100%;padding: 0px;margin: 0px;">
         <div class="main-panel" [ngClass]="'main-panel-only'" style="width: 100%;padding: 0px;margin: 0px;">
            <div class="content-wrapper mainBackground" style="width: 100%;padding: 0px;margin: 0px;">
               <app-spinner>
               </app-spinner>
            </div>
         </div>
      </div>
   </div>

   <div class="container-scroller" *ngIf="!isLoading && subjectCacheIsLoaded >= 2"  >
      <app-navbar *ngIf="showNavbar" class="noPrint"></app-navbar>
      <div class="container-fluid page-body-wrapper">
         <app-sidebar *ngIf="showSidebar" class="noPrint"></app-sidebar>
         <div class="main-panel" [ngClass]="{ 'main-panel-only' : !showSidebar }">
            <div class="content-wrapper">
               <app-spinner *ngIf="isLoading"></app-spinner>
               <div contentAnimate *ngIf="!isLoading" class="mainBackground">
                  <router-outlet></router-outlet>
               </div>
            </div>
            <div>
               <app-footer *ngIf="showFooter" class="noPrint"></app-footer>
            </div>
            <!-- partial -->
         </div>
         <!-- main-panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
   </div>
</ng-container>