import { Component, OnInit } from '@angular/core';
import { CacheService } from '../../services/cache.service';
import { user } from 'src/sites/core/models/user/user';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/sites/core/services/user.service';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { encryptedValue } from '../../authentication/encryptedValue';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-adminMember-list',
   templateUrl: './adminMember-list.component.html',
   styleUrls: ['./adminMember-list.component.scss']
})
export class AdminMemberListComponent implements OnInit {
   public selectedMemberIds: number[] = []
   public form: FormGroup;
   
   constructor(
      private cacheService : CacheService,
      private formBuilder: FormBuilder,
      private userService: UserService,
      private encryptionService: EncryptionService,
      private frameworkService : FrameworkService,
      private subjectService : SubjectService) {
   }

   public ngOnInit() {
      this.form = this.formBuilder.group(
      {
         teamSelected: ['']
      });
   }

   getAllMembers() {
      return this.cacheService.getAllUsers().filter(i=>i.team == null || i.team?.teamDisplay == "");
   }

   getAllTeams() {
      return this.cacheService.getAllTeamsWithoutPicture().filter(i=> i.teamDisplay != "");
   }
   
   onMovePlayer_click() {
      this.userService.assignPlayersToTeam(this.selectedMemberIds, this.form.get("teamSelected").value.sysId).subscribe({
         next: data => {
            let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

            if (dataDecrypted != null) {
               this.frameworkService.displayAlert(true);
            }
            else
               this.frameworkService.displayAlert(false);
            this.subjectService.IsButtonInLoadingSubject.next(false);
         },
         error: err => {
            this.frameworkService.displayAlert(null, err);
            this.subjectService.IsButtonInLoadingSubject.next(false);
         }
      });
   }
}
