import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';
import { Md5 } from 'ts-md5/dist/md5';
import { AccountService } from "../../services/account.service";
import { AlertShowService } from "../../services/alertShow.service";
import { AppConfigService } from '../../services/appconfig.service';
import { FrameworkService } from '../../services/framework.service';
import { ConfirmedValidator } from './validators/confirmed.validator';
import { StrengthPasswordValidator } from './validators/strengthPassword.validator';
import { encryptedValue } from 'src/sites/vabourlettis/authentication/encryptedValue';
import { EncryptionService } from '../../services/encryption.service';
import { SubjectService } from '../../services/subject.service';

@Component({
   selector: 'app-changePassword',
   templateUrl: './changePassword.component.html',
   styleUrls: ['./changePassword.component.scss']
})

export class ChangePasswordComponent implements OnInit {
  public barLabel: string;
  public form: FormGroup;
  public hideConfirmationPassword : boolean = true;
  public hidePassword : boolean = true;
  public isChangeSuccessfull: boolean = false;
  public isNetworkStatusOnline: boolean = false;
  public queryParams: any;
  public submitted: boolean = false;

  constructor(
      private appConfigService : AppConfigService,
      private subjectService: SubjectService,
      private router: ActivatedRoute,
      private translateService: TranslateService,
      private formBuilder: FormBuilder,
      private accountService: AccountService,
      private alertShowService: AlertShowService,
      private frameworkService: FrameworkService,
      private encryptionService: EncryptionService
   ) { }

  public getConfirmationPassword() {
      return this.form.controls["confirmPassword"].value;
   }

  public getPassword() {
      return this.form.controls["password"].value;
   }

  public ngOnInit() {
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });

      this.router.queryParams.subscribe(params => {
         this.queryParams = params;
      });

      this.form = this.formBuilder.group(
         {
            accountSecurityCode: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
         },
         {
            validators:
               [
                  ConfirmedValidator('password', 'confirmPassword'),
                  StrengthPasswordValidator('password', this.frameworkService)
               ]
         }
      );

      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });
   }

  public onConfirmChangePasswordCommand() {
      this.submitted = true;

      // reset alerts on submit
      this.alertShowService.clear();
      // stop here if form is invalid
      if (this.form.invalid) {
         this.subjectService.IsButtonInLoadingSubject.next(false);
         return;
      }

      const md5 = new Md5();

      this.accountService.changeAccountPassword(this.queryParams["username"], md5.appendStr(this.form.controls.password.value).end().toString(), this.form.controls.accountSecurityCode.value).subscribe(
         {
            next: data => {
               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

               this.isChangeSuccessfull = false;
               if (dataDecrypted != null) {
                  this.alertShowService.success(this.translateService.instant('ChangePasswordSuccessfull'), { keepAfterRouteChange: true });
                  timer(this.frameworkService.saveStatusResetTimeOnSuccess()).subscribe(x => { this.alertShowService.clear() });
                  this.isChangeSuccessfull = true;
               }
               else {
                  this.alertShowService.error(this.translateService.instant('ChangePasswordFailed'));
                  timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
               }
               this.subjectService.IsButtonInLoadingSubject.next(false);
            },
            error: err => {
               this.alertShowService.error(err.mes);
               timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
         });
   }

  public site() {
      return this.appConfigService.getSiteName();
   }

  public translatePage() {
      this.translateService.get(['ChangePasswordStrengthDescription'])
         .subscribe(translations => {
            this.barLabel = translations['ChangePasswordStrengthDescription'];
         });
   }
}