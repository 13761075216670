export enum enumDocumentType {
   None,
   SimpleMailing,
   ComplexMailing
}

export enum enumDatagridHeaderOrder {
   ApiByDefault,
   AngularModel,
   KeyAsc,
   KeyDesc
}

export enum enumCRUDType {
   Create,
   Read,
   Update,
   Delete
}

export enum enumFormValidator {
   None,

   DecimalNumberWithComa,  //editStickyValidator
   Email,                  //editStickyValidator
   MinLength,              //editVariableValidator
   MaxLength,              //editVariableValidator
   Required,               //editStickyValidator
   LettersOnly
}

export enum enumRoles {
   President,
   Secretary,
   VicePresident,
   Treasurer,
   MarkerDelegate,
   CateringManager,
   MaterialManager,
   EquipmentManager,
   SponsorShipManager,
   TeamDelegate,
   Coach,
   AssistantCoach,
   TeamContact
}

export enum enumControlType {
   Bool2States, //DONE
   Bool3States, //DONE
   DateOnly, //DONE
   DateTime, //TODO
   Decimal, //DONE
   Dropdown, //DONE
   Email, //DONE
   Html,
   Integer, //DONE
   LetterLowerCaseOnly, //DONE
   LetterUpperCaseOnly, //DONE
   Letter1CharUpperCase, //DONE
   Password, //DONE
   PhoneOffice, //DONE
   PhoneMobile, //DONE
   Picture, //DONE
   File, //TODO
   Text, //DONE
   Textarea, //DONE
   TextAllToUpperCase, //DONE
   TextAllToLowerCase, //DONE
   Text1CharUpperCase, //DONE
   TimeOnly //DONE
}

export enum enumFormAction {
   All, //Used for CRUD menu edition
   Add,
   Edit,
   Delete,
   Archive,
   Unarchive
}

//Workgroup type for the user
export enum enumWorkgroup {
   None = 0,               //The user has no privileges at all
   Supervisor = 1,         //The user is supervisor and have all privileges
   Administrator = 2,      //The user is administrator and have all privileges of administration
   UserFullEditSoft = 3,   //The user has edit (Add, Edit, Delete, Soft Delete) privileges (no administration)
   UserFullEdit = 4,       //The user has edit (Add, Edit, Delete) privileges (no administration)
   UserAddEdit = 5,        //The user has edit (Add, Edit) privileges (no delete and no administration)
   UserEditOnly = 6,       //The user has edit (Edit) privileges (no add, delete and no administration)
   UserReadOnly = 7        //The user has no edit privileges (only read information, no administration)
}

export enum enumQueryFilterOp {
   Equals,
   GreaterThan,
   LessThan,
   GreaterThanOrEqual,
   LessThanOrEqual,
   Contains,
   StartsWith,
   EndsWith
}

export enum enumCompanyType {
   Asbl,
   SA,
   Sprl,
   Scrl
}

export enum enumActivityType {
   SUPPER = 0,
   COMITEE_MEETING = 1,
   TOURNAMENT_DAY = 2,
   CHAMPIONSHIP,
   FRIENDLY_MATCH_DAY,
   EXTRA_TRAINING_DAY,
   HAINAUT_CUP,
   YOUNG,
   ACTIVITY,
   BIRTHDAY,
   CLOSING_DAY,
   TRAINING_DAY,
   NONE
}

export enum enumTimelineType {
   NONE,
   NORMAL,
   CLOSING,
   ACTIVITY,
   FULL,
   TOURNAMENT,
   RESULT,
   BIRTHDAY,
   SUPPER,
   COMITEE
}

export enum enumClubActivityType {
   NONE,
   CHAMPIONSHIP,
   YOUNG,
   HAINAUT_CUP,

   FRIENDLY_MATCH_DAY,
   TOURNAMENT_DAY,
   EXTRA_TRAINING_DAY,
   TRAINING_DAY,
   
   BIRTHDAY,
   COMITEE_MEETING,
   SUPPER,
   CLOSING,
   AG_MEETING,
   TOURNAMENT_INFO
}

export enum enumMatchDisplayType {
   NORMAL,
   FULL,
   RESULT,
   STATUS_DETAIL,
   STATUS,
   NONE
}

export enum enumMatchDisplayStatus {
   JOU,
   AJO
}

export enum enumAvailability {
   NULL,
   PRESENT,
   NOTPRESENT,
   UNKNOWN,
   GUARD
}

export enum enumAvailabilityReason {
   NONE,
   INJURY,
   WORK,
   SICK,
   HOLIDAY,
   GUARD,
   OTHER
}

export enum enumIndexDB0 {
   versionning,
   token
}

export enum enumIndexDB1 {
   sportsclub,
   todo,
   lastupdates,
   championship,
   workgroup,
   setting
}

export enum enumIndexDB2 {
   buildingtrainingclosingday,
   homenews,
   team,
   sponsor,
   activity
}

export enum enumIndexDB3 {
   user,
   ranking,
   match,
   trainingplayer,
   comiteemeeting
}

export enum enumCacheTypeLoad {
   fromIndexDB,
   fromWebAPI,
   fromUpdate
}

export enum enumBlobType {
   Unknown,
   Pdf,
   Word,
   Image
}

export enum enumPrivacyLevel {
   None,
   Team,
   Club,
   Public
}

export enum enumPrivacyControlType {
   Email,
   Phone1,
   Phone2,
   Address,
   BirthdayDate
}

export enum enumHRefType {
   Phone,
   Email,
   GoogleLocation,
   HttpNewWindow,
   HttpRouter
}