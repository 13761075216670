//This file is autogenerated by C# GeneratedServices/Angular/ServicesAngularGenerationTool

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jsonIgnoreReplacer } from 'json-ignore';
import { catchError, retry, throwError } from 'rxjs';					   
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { LogService } from 'src/sites/core/services/log.service';
import { queryFilter } from 'src/sites/vabourlettis/models/queryFilter';
import { AppConfigService } from 'src/sites/core/services/appconfig.service';
import { encryptedValue } from 'src/sites/vabourlettis/authentication/encryptedValue';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { lastUpdates } from '../models/lastupdates/lastUpdates';

@Injectable()
export class LastUpdatesBaseService {

  constructor(
    private appConfigService : AppConfigService,
    private logger: LogService, 
    private httpClient: HttpClient, 
    private frameworkService: FrameworkService, 
    private accessRightsService: AccessRightsService,
    private encryptionService : EncryptionService,
    private storageService: StorageService) {
  }

  getAllWithIncluding(queryFilters: queryFilter[])
  {
    let url = "/" + this.frameworkService.convertQueryControllerToPublic("QX001") + "?withIncluding=True";
    return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + "api/" + this.frameworkService.convertQueryControllerToPublic("CX014") + url, queryFilters)
    .pipe(retry(1),catchError(this.handleError));
  }
  
  //getAllWithoutIncluding(queryFilters: queryFilter[])
  //{
  //  let url = "/" + this.frameworkService.convertQueryControllerToPublic("QX001") + "?withIncluding=False";
  //  return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + "api/" + this.frameworkService.convertQueryControllerToPublic("CX014") + url, queryFilters)
  //  .pipe(retry(1),catchError(this.handleError));
  //}
  
  //getAllActiveWithIncluding(queryFilters: queryFilter[])
  //{
  //  let url = "/" + this.frameworkService.convertQueryControllerToPublic("QX001") + "?withIncluding=True";
  //  url += "&isActive=True";
  //  return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + "api/" + this.frameworkService.convertQueryControllerToPublic("CX014") + url, queryFilters)
  //  .pipe(retry(1),catchError(this.handleError));
  //}
  
  getAllActiveWithoutIncluding(queryFilters: queryFilter[])
  {
    let url = "/" + this.frameworkService.convertQueryControllerToPublic("QX001") + "?withIncluding=False";
    url += "&isActive=True";
  
    return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + "api/" + this.frameworkService.convertQueryControllerToPublic("CX014") + url, queryFilters)
    .pipe(retry(1),catchError(this.handleError));
  }

  //getAllInactiveWithIncluding(queryFilters: queryFilter[])
  //{
  //  let url = "/" + this.frameworkService.convertQueryControllerToPublic("QX001") + "?withIncluding=True";
  //  url += "&isActive=False";
  //  return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + "api/" + this.frameworkService.convertQueryControllerToPublic("CX014") + url, queryFilters)
  //  .pipe(retry(1),catchError(this.handleError));
  //}

  //getAllInactiveWithoutIncluding(queryFilters: queryFilter[])
  //{
  //  let url = "/" + this.frameworkService.convertQueryControllerToPublic("QX001") + "?withIncluding=False";
  //  url += "&isActive=False";
  //  return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + "api/" + this.frameworkService.convertQueryControllerToPublic("CX014") + url, queryFilters)
  //  .pipe(retry(1),catchError(this.handleError));
  //}

  insert(lastUpdatesToInsert: lastUpdates)
  {
    let username = this.storageService.getUserConnected() == null ? "UNKNOWN" : this.storageService.getUserConnected().username;

    let encryptedData = new encryptedValue();
    encryptedData.value = this.encryptionService.encryptObject(true, false, lastUpdatesToInsert);
      
    let url = "/Q1004?username=" + username;
    return this.httpClient.post(this.appConfigService.getApiUrl() + "api/" + this.frameworkService.convertQueryControllerToPublic("CX014") + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
    .pipe(retry(1),catchError(this.handleError));
  }

  insertAll(lastUpdatesToInsertAll: lastUpdates[])
  {  
    let username = this.storageService.getUserConnected() == null ? "UNKNOWN" : this.storageService.getUserConnected().username;

    let encryptedData = new encryptedValue();
    encryptedData.value = this.encryptionService.encryptObject(true, false, lastUpdatesToInsertAll);

    let url = "/Q1005?username=" + username;
    return this.httpClient.post(this.appConfigService.getApiUrl() + "api/" + this.frameworkService.convertQueryControllerToPublic("CX014") + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
    .pipe(retry(1),catchError(this.handleError));
  }

  update(lastUpdatesToUpdate: lastUpdates)
  {
    let username = this.storageService.getUserConnected() == null ? "UNKNOWN" : this.storageService.getUserConnected().username;

    let encryptedData = new encryptedValue();
    encryptedData.value = this.encryptionService.encryptObject(true, false, lastUpdatesToUpdate);

    let url = "/Q1006?username=" + username;
    return this.httpClient.put(this.appConfigService.getApiUrl() + "api/" + this.frameworkService.convertQueryControllerToPublic("CX014") + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
    .pipe(retry(1),catchError(this.handleError));
  }

  updateAll(lastUpdatesToUpdateAll: lastUpdates[])
  {
    let username = this.storageService.getUserConnected() == null ? "UNKNOWN" : this.storageService.getUserConnected().username;

    let encryptedData = new encryptedValue();
    encryptedData.value = this.encryptionService.encryptObject(true, false, lastUpdatesToUpdateAll);

    let url = "/Q1007?username=" + username;
    return this.httpClient.put(this.appConfigService.getApiUrl() + "api/" + this.frameworkService.convertQueryControllerToPublic("CX014") + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
    .pipe(retry(1),catchError(this.handleError));
  }

  delete(sysId: number)
  {
    let username = this.storageService.getUserConnected() == null ? "UNKNOWN" : this.storageService.getUserConnected().username;
    
    let encryptedData = new encryptedValue();
    encryptedData.value = this.encryptionService.encryptObject(true, false, sysId);

    let url = "/Q1010?username=" + username;
    return this.httpClient.post(this.appConfigService.getApiUrl() + "api/" + this.frameworkService.convertQueryControllerToPublic("CX014") + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
    .pipe(retry(1),catchError(this.handleError));
  }

  deleteAll(lastUpdatesToDeleteAll: lastUpdates[])
  {
    let username = this.storageService.getUserConnected() == null ? "UNKNOWN" : this.storageService.getUserConnected().username;

    var sysIds: string[] = [];
    for(var cpt = 0;cpt < lastUpdatesToDeleteAll.length; cpt++ )
        sysIds.push(lastUpdatesToDeleteAll[cpt].sysId.toString());

    let encryptedData = new encryptedValue();
    encryptedData.value = this.encryptionService.encryptObject(true, false, sysIds);

    let url = "/Q1011?username=" + username;
    return this.httpClient.post(this.appConfigService.getApiUrl() + "api/" + this.frameworkService.convertQueryControllerToPublic("CX014") + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
    .pipe(retry(1),catchError(this.handleError));
  }

  // Error handling 
  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => errorMessage);
 }
}