import { DatePipe, KeyValue, formatDate } from '@angular/common';
import { Inject, Injectable, Injector, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { subtract } from 'add-subtract-date';
import 'linq-typed';
import * as moment from 'moment';
import 'reflect-metadata';
import { infoRoleOfUser } from 'src/sites/vabourlettis/models/infoRoleOfUser';
import { SaveStatusComponent } from '../components/saveStatus/saveStatus.component';
import { enumControlType, enumFormValidator, enumRoles, enumWorkgroup, enumActivityType, enumAvailabilityReason, enumClubActivityType, enumMatchDisplayStatus } from '../enum/Enum';
import { user } from '../models/user/user';
import { metadataModel } from "../models/system/metadataModel";
import { metadataModelBase } from "../models/system/metadataModelBase";
import { AccessRightsService } from './accessRights.service';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';
import { eventClubKeyValue } from 'src/sites/vabourlettis/models/eventClubKeyValue';
import { eventClub } from 'src/sites/vabourlettis/models/eventClub';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { BehaviorSubject } from 'rxjs';
import { SubjectService } from './subject.service';

@Injectable({
   providedIn: 'root'
})
//-- Framework service
export class FrameworkService implements OnInit, OnDestroy {


   public convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
      const reader = new FileReader;
      reader.onerror = reject;
      reader.onload = () => {
         resolve(reader.result);
      };
      reader.readAsDataURL(blob);
   });

   constructor(
      @Inject(LOCALE_ID) private locale: string,
      private subjectService: SubjectService,
      private translateService: TranslateService,
      private accessRightsService: AccessRightsService,
      private storageService: StorageService,
      private snackBar: MatSnackBar,
      public datepipe: DatePipe) {
   }

   ngOnDestroy(): void {

   }

   public IsMemberAssistantCoachOnly(member: user) {
      if (member == null)
         return false;

      if (this.getRolesOfMembers().filter(i => i.name == this.translateService.instant(enumRoles[enumRoles.AssistantCoach])) != null)
         return this.getAllRoles(member).includes(this.getRolesOfMembers().FirstOrDefault(i => i.name == this.translateService.instant(enumRoles[enumRoles.AssistantCoach]))?.name);
      else
         return false;
   }

   public IsMemberCoachs(member: user) {
      if (member == null)
         return false;

      if (this.getRolesOfMembers().filter(i => i.name == this.translateService.instant(enumRoles[enumRoles.Coach]) != null && i.name == this.translateService.instant(enumRoles[enumRoles.AssistantCoach]) != null))
         return this.getAllRoles(member).includes(this.getRolesOfMembers().FirstOrDefault(i => i.name == this.translateService.instant(enumRoles[enumRoles.Coach]))?.name) ||
            this.getAllRoles(member).includes(this.getRolesOfMembers().FirstOrDefault(i => i.name == this.translateService.instant(enumRoles[enumRoles.AssistantCoach]))?.name);
      else
         return false;
   }

   public addDays(date: Date, days: number): Date {
      return new Date(new Date(date).setDate(date.getDate() + days));
   }

   public addMinutesToDate(date: Date, minutes: number): Date {
      let min = new Date(date).getMinutes();
      new Date(date).setMinutes(min + minutes);
      return new Date(date);
   }

   public buildEntityFromFormGroup(formGroup: FormGroup): any {
      let entity = {};

      for (const field in formGroup.controls)
         entity[field] = formGroup.controls[field].value;

      return entity;
   }

   public buildFormGroupFromEntity<T>(entity: T): FormGroup {
      {
         const properties = Object.getOwnPropertyNames(entity) as Array<keyof T>; //Get all properties of entity

         let form = new FormGroup({});

         for (let property of properties) {
            const control: FormControl = new FormControl(property?.toString()); //Add field to form

            control.setValue(""); //Set value

            const metadataModelBase = Reflect.getMetadata(property?.toString(), entity, property?.toString()); //Get metadata of entity (+ entitybase)

            control.clearValidators();

            //Add sticky validators
            if (metadataModelBase != null && metadataModelBase.editStickyValidator != null) {
               metadataModelBase.editStickyValidator.forEach(function (value) {
                  switch (value) {
                     case enumFormValidator.Required: //Add validator isRequired
                        control.addValidators([Validators.required]);
                        break;
                     case enumFormValidator.DecimalNumberWithComa:
                        control.addValidators([Validators.pattern("^[-]?([1-9]+\.?[0-9]*|\.[0-9])")]);
                        break;
                  }
               });
            }

            //Add variable validators
            if (metadataModelBase != null && metadataModelBase.editVariableValidator != null) {
               for (const name in metadataModelBase.editVariableValidator) {
                  switch (name) {
                     case enumFormValidator[enumFormValidator.MinLength]: //Add validator minlength
                        control.addValidators([Validators.minLength(metadataModelBase.editVariableValidator[name])]);
                        break;
                     case enumFormValidator[enumFormValidator.MaxLength]: //Add validator maxlength
                        control.addValidators([Validators.maxLength(metadataModelBase.editVariableValidator[name])]);
                        break;
                  }
               }
            }
            form.addControl(property?.toString(), control);
         }
         return form;
      }
   }

   public buildUsername(firstName: string, lastName: string) {
      return this.uppercaseFirstLetterAndLowerOtherAndRemoveBlank(firstName) + "." + this.uppercaseFirstLetterAndLowerOtherAndRemoveBlank(lastName);
   }

   public convertObjectToArray<T>(data): any[] {
      let arr = [];
      Object.keys(data).map(function (key) {
         arr.push({ [key]: data[key] as T });
         return arr;
      });
      return arr;
   }

   public completeNumberWithZeros(number: number, length: number): string {
      return (Array(length).join('0') + number).slice(-length);
   }



   public convertQueryControllerToPublic(query: string) {
      var type = "2";
      if (this.storageService.getUserConnected() != null)
         type = "1";

      return query.replace("X", type);
   }

   public getFormController(entity: string) {
      var type = "2";
      if (this.storageService.getUserConnected() != null)
         type = "1";

      var allEntities = [
         "ActivityByMember",
         "Activity",
         "Appointment",
         "Auth",
         "AuthToken",
         "Blob",
         "BuildingTrainingClosingDay",
         "BuildingTraining",
         "Championship",
         "ComiteeMeeting",
         "ComiteeTodo",
         "Finances",
         "Fvwb",
         "HomeNews",
         "LastUpdates",
         "Email",
         "Match",
         "Newspaper",
         "Ranking",
         "Setting",
         "Sponsor",
         "SportsClub",
         "System",
         "Team",
         "Token",
         "Training",
         "TrainingPlayer",
         "UpdatedMatch",
         "User",
         "Workgroup"];

      var entityIndex = -1;

      for (var i = 0; i < allEntities.length; i++) {
         if ((allEntities[i] + "Public").toLocaleLowerCase() == entity.toLocaleLowerCase()) {
            entityIndex = i;
            break;
         }
      }

      if (entityIndex == -1) {
         for (var i = 0; i < allEntities.length; i++) {
            if (allEntities[i].toLocaleLowerCase() == entity.toLocaleLowerCase()) {
               entityIndex = i;
               break;
            }
         }
      }

      if (entityIndex == -1)
         return "CX";

      var controller = this.completeNumberWithZeros(i, 3);

      return "CX".replace("X", type) + controller;
   }

   public displayAlert(success: boolean, message?: string) {
      const config: MatSnackBarConfig = {
         data: [success, message],
         duration: success ? this.saveStatusResetTimeOnSuccess() : this.saveStatusResetTimeOnFailed()
      }
      if (success)
         config['panelClass'] = ['snackbar', 'success'];
      else
         config['panelClass'] = ['snackbar', 'error'];
      this.snackBar.openFromComponent(SaveStatusComponent, config);
   }

   public displayAlertReservation(success?: boolean, error?: string) {
      if (success == null)
         this.displayAlert(false, "Votre réservation n'a pas été enregistrée en raison d'une erreur technique : " + error);
      else {
         if (success)
            this.displayAlert(true, "Votre réservation a bien été enregistrée");
         else
            this.displayAlert(false, "Votre réservation n'a pas été enregistrée");
      }
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }
   public displayAlertNoReservation(success?: boolean, error?: string) {
      this.displayAlert(true, "Votre désinscription a bien été enregistrée");
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }
   public displayAlertSendReportSupper(success?: boolean, error?: string) {
      if (success == null)
         this.displayAlert(false, "Le rapport n'a pas pu être envoyé sur votre email en raison d'une erreur technique : " + error);
      else {
         if (success)
            this.displayAlert(true, "Le rapport vous a été envoyé sur votre email");
         else
            this.displayAlert(false, "Le rapport n'a pas pu être envoyé sur votre email");
      }
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

   public findAllEventClubsOverToday(dictionary: Map<string, eventClub>): Map<string, eventClub> {
      let temp = new Map<string, eventClub>();
      let occurence: string = "";

      Object.assign({}, [...dictionary.entries()].sort((a, b) => a[0] > b[0] ? 1 : -1).filter(([key, value]) => {
         if (this.isAMatch(value)) {
            if (this.getDateFormatYyyyMmDd(value.day) >= this.getDateFormatYyyyMmDd(this.getToday())) {
               return { key, value };
            }
         }
      }
      ).map(([key, value]) => { temp.set(key, value); }));

      return temp;
   }

   public findEventClubResultsOfWeekend(dictionary: Map<string, eventClub>): Map<string, eventClub> {
      let temp = new Map<string, eventClub>();
      Object.assign({}, [...dictionary.entries()].sort((a, b) => a[0] > b[0] ? 1 : -1).filter(([key, value]) => {
         let condition: boolean = true;
         //Si le jour courant est un jour de weekend, prendre le weekend courant sinon prendre le weekend prédédent
         if (this.getDateFormatYyyyMmDd(this.getToday()) == this.getDateFormatYyyyMmDd(this.getSaturdayFromDateOnSameWeek(this.getToday())) ||
            this.getDateFormatYyyyMmDd(this.getToday()) == this.getDateFormatYyyyMmDd(this.getSundayFromDateOnSameWeek(this.getToday()))) {
            condition =
               (this.getDateFormatYyyyMmDd(value.day) > this.getDateFormatYyyyMmDd(this.getSundayFromDateOfPreviousWeek(this.getToday()))) &&
               (this.getDateFormatYyyyMmDd(value.day) <= this.getDateFormatYyyyMmDd(this.getSundayFromDateOnSameWeek(this.getToday())))
         }
         else {
            condition =
               (this.getDateFormatYyyyMmDd(value.day) >= this.getDateFormatYyyyMmDd(this.getSaturdayFromDateOfPreviousWeek(this.getToday()))) &&
               (this.getDateFormatYyyyMmDd(value.day) < this.getDateFormatYyyyMmDd(this.getSaturdayFromDateOnSameWeek(this.getToday())))
         }

         if (condition) {
            if (
               !value.event?.fromTeam?.includes(this.getByeStatus()) &&
               !value.event?.awayTeam?.includes(this.getByeStatus()) &&
               value.event?.status == enumMatchDisplayStatus[enumMatchDisplayStatus.JOU]) { return { key, value }; }
         }
      }).map(([key, value]) => { temp.set(key, value); }));
      return temp;
   }

   public findEventClubsNext(dictionary: Map<string, eventClub>): Map<string, eventClub> {
      let temp = new Map<string, eventClub>();
      let occurence: string = "";

      Object.assign({}, [...dictionary.entries()].sort((a, b) => a[0] > b[0] ? 1 : -1).filter(([key, value]) => {
         if (value.clubActivityType == enumClubActivityType.BIRTHDAY) {
            if (this.isCompleteDayInOverWeekAsTodayWeek(value.day)) //&& this.isBirthdayOnCurrentAndNextMonth(value.day)
               return { key, value };
         }
         else {
            //Display all next event for SUPPER, CLOSING, COMITEE_MEETING, ...
            if (!this.isAMatch(value)) {
               if (this.isCompleteDayInOverWeekAsTodayWeek(value.day) && !this.isCompleteDayInSameWeekAsTodayWeek(value.day)) {
                  return { key, value };
               }
            }
            else {
               if (!occurence.includes(value.event.teamSysId) || 
                  value.clubActivityType == enumClubActivityType.TOURNAMENT_DAY ||
                  value.clubActivityType == enumClubActivityType.EXTRA_TRAINING_DAY ||
                  value.clubActivityType == enumClubActivityType.FRIENDLY_MATCH_DAY
               ) {
                  if (this.isCompleteDayInOverWeekAsTodayWeek(value.day) && !this.isCompleteDayInSameWeekAsTodayWeek(value.day)) {
                     occurence += ";" + value.event.teamSysId;
                     return { key, value };
                  }
               }
            }
         }
      }
      ).map(([key, value]) => { temp.set(key, value); }));

      return temp;
   }

   public findEventClubsOfWeekend(dictionary: Map<string, eventClub>): Map<string, eventClub> {
      // Tous les matchs () dont le statut est à AJO
      // Tous les autres événements
      let temp = new Map<string, eventClub>();
      Object.assign({}, [...dictionary.entries()].sort((a, b) => a[0] > b[0] ? 1 : -1).filter(([key, value]) => {
         if (this.isCompleteDayInSameWeekAsTodayWeek(value.day) &&
            ((this.isAMatch(value) && value.event.status == enumMatchDisplayStatus[enumMatchDisplayStatus.AJO]) ||
               (!this.isAMatch(value)))) { return { key, value }; }
      }).map(([key, value]) => { temp.set(key, value); }));
      return temp;
   }

   //date format to given : getDateFormatYyyyMmDd(tp.day)
   public findFirstEventClub(dictionary: Map<string, eventClub>, date: string): eventClubKeyValue {
      return [...dictionary.entries()].sort((a, b) => a[0] > b[0] ? 1 : -1).filter(([key, value]) => key.startsWith(date)).map(function ([key, value]) {
         return new eventClubKeyValue({ eventClub: value, clubActivityType: key.slice(8), date: key.substring(0, 8) });
      })[0];
   }

   public format(format: string, fieldArgs: string, rowItem) {
      if (fieldArgs == null)
         return format;

      const splitArgs = fieldArgs.split(';');
      for (let i = 0; i < splitArgs.length; i++) {
         const regEx = new RegExp("\\{" + i + "\\}", "gm");

         format = format.replace(regEx, rowItem[splitArgs[i]]);
      }
      return format;
   }

   public formatString(format: string, fieldArgs: string[]) {
      if (fieldArgs == null)
         return format;

      for (let i = 0; i < fieldArgs.length; i++) {
         const regEx = new RegExp("\\{" + i + "\\}", "gm");
         format = format.replace(regEx, fieldArgs[i]);
      }
      return format;
   }

   public formatWithTranslation(format: string, fieldArgs: string, rowItem, translateService: TranslateService) {
      if (fieldArgs == null)
         return format;

      const splitArgs = fieldArgs.split(';');
      for (let i = 0; i < splitArgs.length; i++) {
         const regEx = new RegExp("\\{" + i + "\\}", "gm");

         format = format.replace(regEx, translateService.instant("Dropdown" + rowItem[splitArgs[i]]));
      }
      return format;
   }

   public getActivityType(value: string) {
      switch (value) {
         case enumActivityType[enumActivityType.ACTIVITY]:
            return enumActivityType.ACTIVITY;
         case enumActivityType[enumActivityType.BIRTHDAY]:
            return enumActivityType.BIRTHDAY;
         case enumActivityType[enumActivityType.CHAMPIONSHIP]:
            return enumActivityType.CHAMPIONSHIP;
         case enumActivityType[enumActivityType.COMITEE_MEETING]:
            return enumActivityType.COMITEE_MEETING;
         case enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY]:
            return enumActivityType.FRIENDLY_MATCH_DAY;
         case enumActivityType[enumActivityType.EXTRA_TRAINING_DAY]:
            return enumActivityType.EXTRA_TRAINING_DAY;
         case enumActivityType[enumActivityType.HAINAUT_CUP]:
            return enumActivityType.HAINAUT_CUP;
         case enumActivityType[enumActivityType.NONE]:
            return enumActivityType.NONE;
         case enumActivityType[enumActivityType.SUPPER]:
            return enumActivityType.SUPPER;
         case enumActivityType[enumActivityType.TOURNAMENT_DAY]:
            return enumActivityType.TOURNAMENT_DAY;
         case enumActivityType[enumActivityType.YOUNG]:
            return enumActivityType.YOUNG;
         case enumActivityType[enumActivityType.CLOSING_DAY]:
            return enumActivityType.CLOSING_DAY;
         case enumActivityType[enumActivityType.TRAINING_DAY]:
            return enumActivityType.TRAINING_DAY;
      }
   }

   public getAllRolesOfContacts(): infoRoleOfUser[] {
      let info: infoRoleOfUser[] = [];

      info.push(new infoRoleOfUser({ code: enumRoles.President.toString(), name: this.translateService.instant(enumRoles[enumRoles.President]), priority: 10 }));
      info.push(new infoRoleOfUser({ code: enumRoles.VicePresident.toString(), name: this.translateService.instant(enumRoles[enumRoles.VicePresident]), priority: 10 }));
      info.push(new infoRoleOfUser({ code: enumRoles.Secretary.toString(), name: this.translateService.instant(enumRoles[enumRoles.Secretary]), priority: 30 }));

      info.push(new infoRoleOfUser({ code: enumRoles.Treasurer.toString(), name: this.translateService.instant(enumRoles[enumRoles.Treasurer]), priority: 50 }));
      info.push(new infoRoleOfUser({ code: enumRoles.SponsorShipManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.SponsorShipManager]), priority: 100 }));
      return info;
   }

   public getAllRolesOfMembers(): infoRoleOfUser[] {
      let info: infoRoleOfUser[] = [];

      info.push(new infoRoleOfUser({ code: enumRoles.President.toString(), name: this.translateService.instant(enumRoles[enumRoles.President]), priority: 10 }));
      info.push(new infoRoleOfUser({ code: enumRoles.VicePresident.toString(), name: this.translateService.instant(enumRoles[enumRoles.VicePresident]), priority: 10 }));
      info.push(new infoRoleOfUser({ code: enumRoles.Secretary.toString(), name: this.translateService.instant(enumRoles[enumRoles.Secretary]), priority: 30 }));

      info.push(new infoRoleOfUser({ code: enumRoles.Treasurer.toString(), name: this.translateService.instant(enumRoles[enumRoles.Treasurer]), priority: 50 }));

      info.push(new infoRoleOfUser({ code: enumRoles.TeamContact.toString(), name: this.translateService.instant(enumRoles[enumRoles.TeamContact]), priority: 110 }));
      info.push(new infoRoleOfUser({ code: enumRoles.TeamDelegate.toString(), name: this.translateService.instant(enumRoles[enumRoles.TeamDelegate]), priority: 60 }));
      info.push(new infoRoleOfUser({ code: enumRoles.CateringManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.CateringManager]), priority: 70 }));
      info.push(new infoRoleOfUser({ code: enumRoles.MaterialManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.MaterialManager]), priority: 80 }));
      info.push(new infoRoleOfUser({ code: enumRoles.EquipmentManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.EquipmentManager]), priority: 90 }));
      info.push(new infoRoleOfUser({ code: enumRoles.SponsorShipManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.SponsorShipManager]), priority: 100 }));
      info.push(new infoRoleOfUser({ code: enumRoles.MarkerDelegate.toString(), name: this.translateService.instant(enumRoles[enumRoles.MarkerDelegate]), priority: 100 }));

      info.push(new infoRoleOfUser({ code: enumRoles.Coach.toString(), name: this.translateService.instant(enumRoles[enumRoles.Coach]), priority: 40 }));
      info.push(new infoRoleOfUser({ code: enumRoles.AssistantCoach.toString(), name: this.translateService.instant(enumRoles[enumRoles.AssistantCoach]), priority: 40 }));

      return info;
   }

   public getByeStatus() {
      return "BYE";
   }

   public getCalendarActivityBirthdayDate(birthdayDate: Date) {
      return this.getDayOfWeekFromDate(birthdayDate) + " " + this.getDayNumberOfDate(birthdayDate) + " " + this.getMonthStringOfDateTranslated(birthdayDate, true);
   }

   public getCalendarActivityDate(date: Date, isFirstCharLowerCase: boolean = false) {
      return ((isFirstCharLowerCase) ? this.lowercaseFirstLetter(this.getDayOfWeekFromDate(date)) : this.getDayOfWeekFromDate(date)) + " " + this.getDayNumberOfDate(date) + " " + this.getMonthStringOfDateTranslated(date, true);
   }

   public getCalendarActivityDateByMonth(date: Date) {
      return this.getMonthStringOfDateTranslated(date, true);
   }

   public getCalendarActivityDateByMonthAndYear(date: Date) {
      let today = new Date(this.getToday().setHours(0, 0, 0, 0));
      if (new Date(date).getMonth() < new Date(today).getMonth()) {
         return this.getMonthStringOfDateTranslated(date, true) + " " + (today.getFullYear() + 1);
      }
      else {
         return this.getMonthStringOfDateTranslated(date, true);
      }
   }

   public getClosingDayInfo(closingDayReason) {
      return "Fermeture de salle - " + closingDayReason;
   }

   public getClubActivityType(value: string) {
      switch (value) {
         case enumClubActivityType[enumClubActivityType.BIRTHDAY]:
            return enumClubActivityType.BIRTHDAY;
         case enumClubActivityType[enumClubActivityType.CHAMPIONSHIP]:
            return enumClubActivityType.CHAMPIONSHIP;
         case enumClubActivityType[enumClubActivityType.CLOSING]:
            return enumClubActivityType.CLOSING;
         case enumClubActivityType[enumClubActivityType.COMITEE_MEETING]:
            return enumClubActivityType.COMITEE_MEETING;
         case enumClubActivityType[enumClubActivityType.EXTRA_TRAINING_DAY]:
            return enumClubActivityType.EXTRA_TRAINING_DAY;
         case enumClubActivityType[enumClubActivityType.FRIENDLY_MATCH_DAY]:
            return enumClubActivityType.FRIENDLY_MATCH_DAY;
         case enumClubActivityType[enumClubActivityType.HAINAUT_CUP]:
            return enumClubActivityType.HAINAUT_CUP;
         case enumClubActivityType[enumClubActivityType.NONE]:
            return enumClubActivityType.NONE;
         case enumClubActivityType[enumClubActivityType.SUPPER]:
            return enumClubActivityType.SUPPER;
         case enumClubActivityType[enumClubActivityType.TOURNAMENT_DAY]:
            return enumClubActivityType.TOURNAMENT_DAY;
         case enumClubActivityType[enumClubActivityType.YOUNG]:
            return enumClubActivityType.YOUNG;
      }
   }

   public getClubDelegateTeamTitle(): string {
      return this.getExtraComiteeMembers().FirstOrDefault(i => i.name == enumRoles[enumRoles.TeamDelegate]).name;
   }

   public getClubEquipmentResponsibleTitle(): string {
      return this.getExtraComiteeMembers().FirstOrDefault(i => i.name == enumRoles[enumRoles.EquipmentManager]).name;
   }

   public getClubMarqueurTitle(): string {
      return this.getExtraComiteeMembers().FirstOrDefault(i => i.name == enumRoles[enumRoles.MarkerDelegate]).name;
   }

   public getClubMaterialResponsibleTitle(): string {
      return this.getExtraComiteeMembers().FirstOrDefault(i => i.name == enumRoles[enumRoles.MaterialManager]).name;
   }

   public getClubRestaurationResponsibleTitle(): string {
      return this.getExtraComiteeMembers().FirstOrDefault(i => i.name == enumRoles[enumRoles.CateringManager]).name;
   }

   public getClubSponsoringResponsibleTitle(): string {
      return this.getExtraComiteeMembers().FirstOrDefault(i => i.name == enumRoles[enumRoles.SponsorShipManager]).name;
   }

   public getComiteeMeetingInfo(meeting) {
      let temp = "Réunion de comité à <span style='font-size:medium'>" + this.getTimeFormatHHmm(meeting.date).replace(":", "H") + "<br><span style='font-size:xx-small'>" + meeting.location + "</span>";
      return temp;
   }

   public getComiteePresidentTitle(): string {
      return this.getOfficalComiteeMembers().FirstOrDefault(i => i.name == enumRoles[enumRoles.President]).name;
   }

   public getComiteeSecretaryTitle(): string {
      return this.getOfficalComiteeMembers().FirstOrDefault(i => i.name == enumRoles[enumRoles.Secretary]).name;
   }

   public getComiteeTreasurerTitle(): string {
      return this.getOfficalComiteeMembers().FirstOrDefault(i => i.name == enumRoles[enumRoles.Treasurer]).name;
   }

   public getComiteeVicePresidentTitle(): string {
      return this.getOfficalComiteeMembers().FirstOrDefault(i => i.name == enumRoles[enumRoles.VicePresident]).name;
   }

   //Get "editControlType" from metadata column
   public getControlType(column: metadataModel): string {
      return enumControlType[column.editControlType];
   }

   public getCurrentDateOfBirthdayDate(birthdayDate: Date): Date {
      //Replace birthday year by current year
      let today = new Date(this.getToday().setHours(0, 0, 0, 0));
      if (new Date(birthdayDate).getMonth() < new Date(today).getMonth()) {
         return new Date(new Date(birthdayDate).setFullYear(today.getFullYear() + 1));
      }
      else {
         return new Date(new Date(birthdayDate).setFullYear(today.getFullYear()));
      }
   }



   public getDateAndTimeFormatDatabase(date: Date, time: string) {
      let t = time.split(':');
      return new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate(), Number(t[0]), Number(t[1]), 0);
   }

   public getDateCalendarDisplay(day) {
      return this.translateService.instant(this.getDayOfWeekFromString(day)) + " " + this.getDayNumberOfDate(day) + " " + this.translateService.instant(this.getMonthStringOfDate(day)) + " " + this.getYearOfDate(day);
   }

   public getDateFormatYyyyMmDd(date: Date) {
      return this.datepipe.transform(date, 'yyyyMMdd');
   }

   public getDateFormatYyyyMmDdHhMm(date: Date) {
      return this.datepipe.transform(date, 'yyyyMMddHHmm');
   }

   public getDateFormatYyyyMmDdHhMmSs(date: Date) {
      return this.datepipe.transform(date, 'yyyyMMddHHmmss');
   }

   public getDateOnlyFormatDatabase(date: Date) {
      return new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate(), 0, 0, 0);
   }

   public getDateOnlyFormatDatabaseFromJJMMAAAAA(date: string) {
      let d = this.datepipe.transform(date, 'dd/MM/yyyy');
      let day = this.left(d, 2);
      let month = this.mid(d, 4, 2);
      let year = this.mid(d, 7, 4);
      return new Date(Number(year), Number(month) - 1, Number(day), 0, 0, 0);
   }

   public getDateOnlyFormatDatabaseFromJJMMAAAAADate(date: string) {
      let day = this.left(date, 2);
      let month = this.mid(date, 4, 2);
      let year = this.mid(date, 7, 4);
      return new Date(Number(year), Number(month) - 1, Number(day), 0, 0, 0);
   }

   public getDateOnlyFormatForScreen(date: string) {
      return this.datepipe.transform(date, 'dd/MM/yy');
   }

   public getDateTimeFormatDatabase(date: Date) {
      return new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate(), new Date(date).getHours(), new Date(date).getMinutes(), new Date(date).getSeconds());
   }

   public getDateTimeFromDateTimeString(dateTimeString: string): Date {
      //26/09/2023 12:02:35
      // Split the date and time parts
      const [datePart, timePart] = dateTimeString.split(' ');

      // Split the date part into an array of [year, month, day]
      const dateParts: number[] = datePart.split('/').map(Number);

      // Split the time part into an array of [hour, minute, second]
      const timeParts: number[] = timePart.split(':').map(Number);

      const year: number = dateParts[2];
      const month: number = dateParts[1] - 1;
      const day: number = dateParts[0];
      const hour: number = timeParts[0];
      const minute: number = timeParts[1];
      const second: number = timeParts[2];

      return new Date(year, month, day, hour, minute, second);
   }

   public getDateWithDayFormatForScreen(date: string) {
      return this.left(this.translateService.instant(this.getDayOfWeekFromDate(new Date(date))), 2) + " " + this.datepipe.transform(date, 'dd/MM/yy');
   }

   public getDayNumberOfDate(value: Date): string {
      let newDate = new Date(value);
      return moment(newDate).format('DD');
   }

   public getDayOfDate(value: Date): any {
      return new Date(value).getDay();
   }

   public getDayOfWeekFromDate(value: Date) {
      try {
         let day = new Date(value).getDay();
         return this.translateService.instant("DayOfWeek" + day);
      }
      catch
      {
         return this.translateService.instant("DayOfWeekUnknown");
      }
   }

   public getDayOfWeekFromString(value: string) {
      try {
         let day = new Date(value)?.getDay();
         return "DayOfWeek" + day;
      }
      catch
      {
         return "DayOfWeekUnknown";
      }
   }

   public getDaysBetweenDates(start: Date, end: Date, day: Number): Date[] {
      let result = [];
      // Copy start date
      const current = this.getDateOnlyFormatDatabase(start);

      // Shift to next of required days
      current.setDate(current.getDate() + (Number(day) - Number(current.getDay()) + 7) % 7);
      // While less than end date, add dates to result array
      while (current <= end) {
         result.push(new Date(+current));
         current.setDate(current.getDate() + 7);
      }
      return result;
   }

   public getEventInfo(event) {
      let temp = event.name + " à <span style='font-size:medium'>" + event.time.replace(":", "H");
      if (event.description != undefined)
         temp += "<br><span style='font-size:x-small'>" + event.description + "</span></span>";
      return temp;
   }

   public getExtraComiteeMembers(): infoRoleOfUser[] {
      let info: infoRoleOfUser[] = [];

      info.push(new infoRoleOfUser({ code: enumRoles.TeamDelegate.toString(), name: this.translateService.instant(enumRoles[enumRoles.TeamDelegate]), priority: 60 }));
      info.push(new infoRoleOfUser({ code: enumRoles.CateringManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.CateringManager]), priority: 70 }));
      info.push(new infoRoleOfUser({ code: enumRoles.MaterialManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.MaterialManager]), priority: 80 }));
      info.push(new infoRoleOfUser({ code: enumRoles.EquipmentManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.EquipmentManager]), priority: 90 }));
      info.push(new infoRoleOfUser({ code: enumRoles.SponsorShipManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.SponsorShipManager]), priority: 100 }));

      return info; //see portal fvwb
   }

   public getExtraComiteeMembersWithoutTeamDelegate(): infoRoleOfUser[] {
      let info: infoRoleOfUser[] = [];

      info.push(new infoRoleOfUser({ code: enumRoles.CateringManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.CateringManager]), priority: 70 }));
      info.push(new infoRoleOfUser({ code: enumRoles.MaterialManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.MaterialManager]), priority: 80 }));
      info.push(new infoRoleOfUser({ code: enumRoles.EquipmentManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.EquipmentManager]), priority: 90 }));
      info.push(new infoRoleOfUser({ code: enumRoles.SponsorShipManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.SponsorShipManager]), priority: 100 }));

      return info; //see portal fvwb
   }

   //Get "Form validator" for "editControlType"
   public getFormValidator(formValidator: enumFormValidator): string {
      switch (formValidator) {
         case enumFormValidator.Required:
            return "required";
         case enumFormValidator.DecimalNumberWithComa:
            return "pattern";
         case enumFormValidator.MinLength:
            return "minlength";
         case enumFormValidator.MaxLength:
            return "maxlength";
         case enumFormValidator.Email:
            return "email";
         default:
            return "";
      }
   }

   public getHainautCupShortcut(name: string) {
      if (name.trim().toUpperCase() == "COUPE HT M")
         return "CHM";
      if (name.trim().toUpperCase() == "COUPE HT D")
         return "CHD";
      return "?";
   }

   public getInfoBirthday(birthday, isNext) {
      let temp = "<div class='flexSpaceBetween'><div style='font-size:12px'>" + this.getCalendarActivityBirthdayDate(this.getCurrentDateOfBirthdayDate(birthday.birthdayDate)) + "</div> <div style='font-size:16px'>" + birthday.firstName + " " + birthday.lastName + "</div></div>";
      temp += (birthday.team?.teamDisplay != null) ? "<div style='font-size:xx-small'>" + (birthday.team?.teamDisplay + "</div>") : "";
      return temp;
   }

   public getIsExpandedRowExist(entity: any): boolean {
      let resultReturn = false;
      if (entity != null) {
         const allEntries = Object.entries(entity);
         allEntries.forEach(([key, value]) => {
            const result = this.getMetadataOfPropertyName(entity, key);
            if (result != undefined && result.grisIsInDetailExpanded) {
               resultReturn = true;
            }
         });
      }
      return resultReturn;
   }

   public getJacketNumber(user: user) {
      let jacketNumber = "";
      if (user.jacketNumber > 0)
         jacketNumber = user.jacketNumber.toString();
      if (this.isMemberCoachOnly(user))
         jacketNumber += " (COACH)";
      if (this.IsMemberAssistantCoachOnly(user))
         jacketNumber += " (ASSISTANT)";
      return jacketNumber;
   }

   public getMatchActivitiesType(): any[] {
      // value is limited at 5 chars in database !!!

      let activity: any[] = [];
      activity.push({ value: enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY], viewValue: this.translateService.instant(enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY]) });
      activity.push({ value: enumActivityType[enumActivityType.TOURNAMENT_DAY], viewValue: this.translateService.instant(enumActivityType[enumActivityType.TOURNAMENT_DAY]) });
      return activity;
   }

   public getMatchTimeOfReserve(match): string {
      if (match.number.startsWith("CL")) {
         return "";
      }
      else {
         let time = match.time;
         if (time != null && time != "") {
            let temp = time.split(':');
            if (temp.length == 2) {
               let hourToReserveTime = 90;
               let tempDate = this.getToday().setHours(Number(temp[0]), Number(temp[1]), 0, 0);
               tempDate = subtract(new Date(tempDate), hourToReserveTime, "minutes");
               return moment(new Date(tempDate), "DD/MM/YYYY HH:mm").format("HH:mm").replace(":", "H");
            }
            return "";
         }
         else
            return "";
      }
   }

   public getMatchTimeOnSite(match): string {
      if (match == null)
         return "";
      let time = match.time;
      if (time != null && time != "") {
         let temp = time.split(':');
         if (temp.length == 2) {
            let hourToReserveTime = 90;
            let hourPresenceBefore = 0;
            if (match.team == null) {
               hourToReserveTime = 0;
               hourPresenceBefore = 0;
            }
            else
               hourPresenceBefore = match.team.hourPresenceBeforeNotAtHome;

            if (match.number.startsWith("CL") || match.type == enumActivityType[enumActivityType.YOUNG] || match.type == enumActivityType[enumActivityType.HAINAUT_CUP]) {
               hourToReserveTime = 0;
               hourPresenceBefore = 45;
            }
            else {
               if (match.isAtHome) {
                  if (match.team == null)
                     hourPresenceBefore = 60;
                  else
                     hourPresenceBefore = match.team.hourPresenceBeforeAtHome;
               }
               else
                  hourPresenceBefore = match.team.hourPresenceBeforeNotAtHome;
            }
            let tempDate = this.getToday().setHours(Number(temp[0]), Number(temp[1]), 0, 0);
            tempDate = subtract(new Date(tempDate), hourToReserveTime + hourPresenceBefore, "minutes");
            return moment(new Date(tempDate), "DD/MM/YYYY HH:mm").format("HH:mm").replace(":", "H");
         }
         return "";
      }
      else
         return "";
   }

   public getMatchTimeOnSiteTraining(time: string, isAtHome: Boolean, team): string {
      if (time != null && time != "") {
         let temp = time.split(':');
         if (temp.length == 2) {
            let hourPresenceBefore = team.hourPresenceBeforeNotAtHome2;
            if (isAtHome)
               hourPresenceBefore = team.hourPresenceBeforeAtHome2;
            let tempDate = this.getToday().setHours(Number(temp[0]), Number(temp[1]), 0, 0);
            tempDate = subtract(new Date(tempDate), hourPresenceBefore, "minutes");
            return moment(new Date(tempDate), "DD/MM/YYYY HH:mm").format("HH:mm").replace(":", "H");
         }
         return "";
      }
      else
         return "";
   }

   public getMatchTrainingActivitiesType(): any[] {
      // value is limited at 5 chars in database !!!

      let activity: any[] = [];
      activity.push({ value: enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY], viewValue: this.translateService.instant(enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY]) });
      activity.push({ value: enumActivityType[enumActivityType.EXTRA_TRAINING_DAY], viewValue: this.translateService.instant(enumActivityType[enumActivityType.EXTRA_TRAINING_DAY]) });
      activity.push({ value: enumActivityType[enumActivityType.TOURNAMENT_DAY], viewValue: this.translateService.instant(enumActivityType[enumActivityType.TOURNAMENT_DAY]) });
      return activity;
   }

   public getMaxAccountLoginTries() {
      return 5; //5 tries failed maximum at login. If more the account is locked
   }

   public getMemberIsAssistantCoach(member: user) {
      if (this.getExtraComiteeMembers().filter(i => i.name == this.translateService.instant(enumRoles[enumRoles.AssistantCoach])) != null)
         return member.roleInClubCustom?.includes(this.getExtraComiteeMembers().FirstOrDefault(i => i.name == this.translateService.instant(enumRoles[enumRoles.AssistantCoach]))?.name);
      else
         return false;
   }

   public getMemberIsManager(member: user) {
      if (this.getExtraComiteeMembers().filter(i => i.name.includes("Responsable")) != null)
         return member.roleInClubCustom?.includes(this.getExtraComiteeMembers().FirstOrDefault(i => i.name.includes("Responsable"))?.name);
      else
         return false;
   }

   public getMemberIsPresident(member: user) {
      if (member == null)
         return false;

      if (this.getOfficalComiteeMembers().filter(i => i.name == this.translateService.instant(enumRoles[enumRoles.President])) != null)
         return ((member.roleInComity ?? "") + ", " + (member.roleInClubCustom ?? "")).includes(this.translateService.instant(enumRoles[enumRoles.President]));
      else
         return false;
   }

   public getMemberIsSecretary(member: user) {
      if (member == null)
         return false;

      if (this.getOfficalComiteeMembers().filter(i => i.name == this.translateService.instant(enumRoles[enumRoles.Secretary])) != null)
         return ((member.roleInComity ?? "") + ", " + (member.roleInClubCustom ?? "")).includes(this.translateService.instant(enumRoles[enumRoles.Secretary]));
      else
         return false;
   }

   public getMemberIsTeamDelegateManager(member: user) {
      if (this.getExtraComiteeMembers().filter(i => i.name == this.translateService.instant(enumRoles[enumRoles.TeamDelegate])) != null)
         return member.roleInClubCustom?.includes(this.getExtraComiteeMembers().FirstOrDefault(i => i.name == this.translateService.instant(enumRoles[enumRoles.TeamDelegate]))?.name);
      else
         return false;
   }

   public getMemberIsTreasurer(member: user) {
      if (member == null)
         return false;

      if (this.getOfficalComiteeMembers().filter(i => i.name == this.translateService.instant(enumRoles[enumRoles.Treasurer])) != null)
         return ((member.roleInComity ?? "") + ", " + (member.roleInClubCustom ?? "")).includes(this.translateService.instant(enumRoles[enumRoles.Treasurer]));
      else
         return false;
   }

   public getMemberIsVicePresident(member: user) {
      if (member == null)
         return false;

      if (this.getOfficalComiteeMembers().filter(i => i.name == this.translateService.instant(enumRoles[enumRoles.VicePresident])) != null) {
         return ((member.roleInComity ?? "") + ", " + (member.roleInClubCustom ?? "")).includes(this.translateService.instant(enumRoles[enumRoles.VicePresident]));
      }
      else
         return false;
   }

   public getMetadataGridIsInDetailExpanded(entity: any) {
      const listOfAllEntriesMetadata = new Map<string, string>();
      if (entity != null) {
         const allEntries = Object.entries(entity);
         allEntries.forEach(([key, value]) => {
            const result = this.getMetadataOfPropertyName(entity, key);
            if (result != null) {
               if (result.grisIsInDetailExpanded)
                  listOfAllEntriesMetadata[key] = result;
            }
         });
      }
      return Object.entries(listOfAllEntriesMetadata).filter(x => this.accessRightsService.getVisibilityOfColumn(x[1].editColumnIsVisible));
   }

   public getMetadataGridIsVisible(entity: any) {
      const listOfAllEntriesMetadata = new Map<string, string>();
      if (entity != null) {
         const allEntries = Object.entries(entity);
         allEntries.forEach(([key, value]) => {
            const result = this.getMetadataOfPropertyName(entity, key);
            if (result != null) {
               if (this.accessRightsService.getVisibilityOfColumn(result.gridColumnIsVisible)) {
                  listOfAllEntriesMetadata[key] = result;
               }
            }
         });
      }
      return Object.entries(listOfAllEntriesMetadata);
   }

   public getMetadataGridIsVisibleFromName(entity: any, property: string): enumControlType {
      return this.getMetadataGridIsVisible(entity).filter(x => x[1].baseName == property).map(function (x) { return this.accessRightsService.getVisibilityOfColumn(x[1].editControlType) })[0];
   }

   public getMetadataModalFormIsVisible(entity: any) {
      const listOfAllEntriesMetadata = new Map<string, string>();
      if (entity != null) {
         const allEntries = Object.entries(entity);
         allEntries.forEach(([key, value]) => {
            const result = this.getMetadataOfPropertyName(entity, key);
            if (result != null) {
               if (this.accessRightsService.getVisibilityOfColumn(result.editColumnIsVisible)) {
                  listOfAllEntriesMetadata[key] = result;
               }
            }
         });
      }
      return Object.entries(listOfAllEntriesMetadata);
   }

   public getMetadataOfClass<T, U>(type: T, entity: U): string {
      return Reflect.getMetadata(entity.constructor.name, type);
   }

   public getMetadataOfEntities<T>(entity: T[]): metadataModel[] {
      {
         let metadataModelList: metadataModel[] = [];
         let index = 0;

         for (let e = 0; e < entity.length; e++) {
            let properties = this.getNamesOfAllProperties(entity);
            let data = {};

            for (let i = 0, len = properties.length; i < len; i++) {
               let metadataModelBase: metadataModelBase = Reflect.getMetadata(properties[i]?.toString(), entity[e], properties[i]?.toString())

               let type: string = "";
               if (typeof new Date() == typeof entity[e][properties[i]])
                  type = "Date";
               else
                  type = typeof entity[e][properties[i]];

               metadataModelList[i] = this.createMetadataModel(metadataModelBase, type, properties[i]?.toString());

               index++;
            }
         };

         return metadataModelList;
      }
   }

   public getMetadataOfEntity<T>(entity: T): metadataModel[] {
      {
         let metadataModelList: metadataModel[] = [];

         let properties = Object.keys(entity) as Array<keyof T>;

         let data = {};

         for (let i = 0, len = properties.length; i < len; i++) {
            let metadataModelBase: metadataModelBase = Reflect.getMetadata(properties[i]?.toString(), entity, properties[i].toString())

            let type: string = "";
            if (Object.prototype?.toString.call(entity[properties[i]]) === '[object Date]')
               type = "Date";
            else {
               if (Object.prototype?.toString.call(entity[properties[i]]) === '[object Object]') {
                  type = "object";
               }
               else
                  type = typeof entity[properties[i]];
            }
            metadataModelList[i] = this.createMetadataModel(metadataModelBase, type, properties[i]?.toString());
         }
         return metadataModelList;
      }
   }

   public getMetadataOfPropertyName<T>(entity: T, propertyName: string): metadataModel {
      return this.getMetadataOfEntity(entity).FirstOrDefault(item => item.baseName === propertyName);
   }

   public getMondayFromDateOfPreviousWeek(date: Date): Date {
      return this.addDays(this.getSundayFromDateOfPreviousWeek(date), -6);
   }

   public getMondayFromDateOnSameWeek(date: Date): Date {
      return this.addDays(this.getSundayFromDateOnSameWeek(date), -6);
   }

   public getMonthOfDate(value: Date): string {
      let newDate = new Date(value);
      return moment(newDate).format('MM');
   }

   public getMonthStringOfDate(value: Date): string {
      let newDate = new Date(value);
      return "Month" + moment(newDate).format('MM');
   }

   public getMonthStringOfDateTranslated(value: Date, toLowerCase: boolean): string {
      let temp = this.uppercaseFirstLetter(this.translateService.instant(this.getMonthStringOfDate(value)));
      if (toLowerCase) return temp.toLowerCase(); else return temp;
   }

   public getNameOfClass(object: any): string {
      return object.constructor.name;
   }

   public getNamesOfAllProperties<T>(entity: T): any {
      return Object.keys(entity) as Array<keyof T>;
   }

   public getNextMonthStringOfDate(value: Date): string {
      let newDate = new Date(new Date(value).setMonth(new Date(value).getMonth() + 1));
      return "Month" + moment(newDate).format('MM');
   }

   public getNonPresenceReason(): any[] {
      // value is limited at 5 chars in database !!!

      let reason: any[] = [];
      reason.push({ value: enumAvailabilityReason[enumAvailabilityReason.NONE], viewValue: "" });
      reason.push({ value: enumAvailabilityReason[enumAvailabilityReason.INJURY], viewValue: this.translateService.instant("REASON_INJURY") });
      reason.push({ value: enumAvailabilityReason[enumAvailabilityReason.WORK], viewValue: this.translateService.instant("REASON_WORK") });
      reason.push({ value: enumAvailabilityReason[enumAvailabilityReason.SICK], viewValue: this.translateService.instant("REASON_SICK") });
      reason.push({ value: enumAvailabilityReason[enumAvailabilityReason.HOLIDAY], viewValue: this.translateService.instant("REASON_HOLIDAY") });
      reason.push({ value: enumAvailabilityReason[enumAvailabilityReason.GUARD], viewValue: this.translateService.instant("REASON_GUARD") });
      reason.push({ value: enumAvailabilityReason[enumAvailabilityReason.OTHER], viewValue: this.translateService.instant("REASON_OTHER") });
      return reason;
   }

   public getOfficalComiteeMembers(): infoRoleOfUser[] {
      let info: infoRoleOfUser[] = [];

      info.push(new infoRoleOfUser({ code: enumRoles.President.toString(), name: this.translateService.instant(enumRoles[enumRoles.President]), priority: 10 }));
      info.push(new infoRoleOfUser({ code: enumRoles.VicePresident.toString(), name: this.translateService.instant(enumRoles[enumRoles.VicePresident]), priority: 10 }));
      info.push(new infoRoleOfUser({ code: enumRoles.Secretary.toString(), name: this.translateService.instant(enumRoles[enumRoles.Secretary]), priority: 30 }));

      info.push(new infoRoleOfUser({ code: enumRoles.Treasurer.toString(), name: this.translateService.instant(enumRoles[enumRoles.Treasurer]), priority: 50 }));

      return info; //see portal fvwb
   }

   public getOfficialMeetingManagerComiteeMembers(): infoRoleOfUser[] {
      let info: infoRoleOfUser[] = [];

      info.push(new infoRoleOfUser({ code: enumRoles.President.toString(), name: this.translateService.instant(enumRoles[enumRoles.President]), priority: 10 }));
      info.push(new infoRoleOfUser({ code: enumRoles.VicePresident.toString(), name: this.translateService.instant(enumRoles[enumRoles.VicePresident]), priority: 10 }));
      info.push(new infoRoleOfUser({ code: enumRoles.Secretary.toString(), name: this.translateService.instant(enumRoles[enumRoles.Secretary]), priority: 30 }));

      return info;
   }

   public getPaginatorItemsCount() {
      return [2, 5, 10, 15, 20, 30, 50, 100, 150, 200, 250, 300];
   }

   //Get "parameters" for "editControlType"
   public getParamFromControlType(column: metadataModel): string {
      switch (column.editControlType) {
         case enumControlType.Bool2States:
            return "";
         case enumControlType.Bool3States:
            return "";
         case enumControlType.DateOnly:
            return "";
         case enumControlType.DateTime:
            return "";
         case enumControlType.Decimal:
            return "";
         case enumControlType.Dropdown:
            return "";
         case enumControlType.Email:
            return "";
         case enumControlType.Password:
            return "";
         case enumControlType.PhoneMobile:
            return "(0000) 00.00.00"; //Input mask
         case enumControlType.PhoneOffice:
            return "(000) 00.00.00";  //Input mask
         case enumControlType.Picture:
            return "";
         case enumControlType.Text:
            return "";
         case enumControlType.Textarea:
            return "";
         case enumControlType.TimeOnly:
            return "";
      }
   }

   public getPasswordMeasureStrength(pass: string) {
      let score = 0;
      // award every unique letter until 5 repetitions  
      let letters = {};
      for (let i = 0; i < pass.length; i++) {
         letters[pass[i]] = (letters[pass[i]] || 0) + 1;
         score += 5.0 / letters[pass[i]];
      }
      // bonus points for mixing it up  
      let variations = {
         digits: /\d/.test(pass),
         lower: /[a-z]/.test(pass),
         upper: /[A-Z]/.test(pass),
         nonWords: /\W/.test(pass),
      };

      let variationCount = 0;
      for (let check in variations) {
         variationCount += (variations[check]) ? 1 : 0;
      }
      score += (variationCount - 1) * 10;
      return Math.trunc(score);
   }

   public getProfileRoles(profile, isCoachRemoved: boolean, isDelegateMarkerRemoved: boolean): string[] {
      if (profile == null || profile.roleInClub == null)
         return [];

      let roleList = [];
      let allRole = profile.roleInTeam?.toString() + "," +
         profile.roleInComity?.toString() + "," +
         profile.roleInClub?.toString() + "," +
         profile.roleInClubCustom?.toString();
         roleList = allRole.split(",");
         roleList = roleList.map(r => r.trim());

      if (this.isMemberCoachOnly(profile) && isCoachRemoved) {
         this.removeItemFromArray(roleList, this.translateService.instant(enumRoles[enumRoles.Coach]));
      }

      if (roleList.includes(this.translateService.instant(enumRoles[enumRoles.AssistantCoach])))
         this.removeItemFromArray(roleList, this.translateService.instant(enumRoles[enumRoles.Coach]));

      if (isDelegateMarkerRemoved) {
         this.removeItemFromArray(roleList, this.translateService.instant(enumRoles[enumRoles.MarkerDelegate]));
      }

      roleList = this.removeDuplicateItemFromArray(roleList);
      this.removeItemFromArray(roleList, "undefined");
      this.removeItemFromArray(roleList, "");

      if (roleList.length == 0)
         return [];

      return roleList;
   }

   public getWebPushCategories(profile): string[] {
      if (profile == null || profile.subscriptionCategories == null)
         return [];

      let categoryList = [];
      let allCategory = profile.subscriptionCategories?.toString();
      categoryList = allCategory.split(",");
      categoryList = categoryList.map(r => r.trim());

      if (categoryList.length == 0)
         return [];

      return categoryList;
   }

   public getRolesOfMembers(): infoRoleOfUser[] {
      let info: infoRoleOfUser[] = [];

      info.push(new infoRoleOfUser({ code: enumRoles.VicePresident.toString(), name: this.translateService.instant(enumRoles[enumRoles.VicePresident]), priority: 10 }));

      info.push(new infoRoleOfUser({ code: enumRoles.TeamContact.toString(), name: this.translateService.instant(enumRoles[enumRoles.TeamContact]), priority: 110 }));
      info.push(new infoRoleOfUser({ code: enumRoles.TeamDelegate.toString(), name: this.translateService.instant(enumRoles[enumRoles.TeamDelegate]), priority: 60 }));
      info.push(new infoRoleOfUser({ code: enumRoles.CateringManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.CateringManager]), priority: 70 }));
      info.push(new infoRoleOfUser({ code: enumRoles.MaterialManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.MaterialManager]), priority: 80 }));
      info.push(new infoRoleOfUser({ code: enumRoles.EquipmentManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.EquipmentManager]), priority: 90 }));
      info.push(new infoRoleOfUser({ code: enumRoles.SponsorShipManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.SponsorShipManager]), priority: 100 }));

      info.push(new infoRoleOfUser({ code: enumRoles.Coach.toString(), name: this.translateService.instant(enumRoles[enumRoles.Coach]), priority: 40 }));
      info.push(new infoRoleOfUser({ code: enumRoles.AssistantCoach.toString(), name: this.translateService.instant(enumRoles[enumRoles.AssistantCoach]), priority: 40 }));

      return info;
   }

   public getSaturdayFromDateOfNextWeek(date: Date): Date {
      return this.addDays(this.getSundayFromDateOfNextWeek(date), -1);
   }

   public getSaturdayFromDateOfPreviousWeek(date: Date): Date {
      return this.addDays(this.getSundayFromDateOfPreviousWeek(date), -1);
   }

   public getSaturdayFromDateOnSameWeek(date: Date): Date {
      return this.addDays(this.getSundayFromDateOnSameWeek(date), -1);
   }

   public getSaturdaySundayWeek(value: Date) {
      return "Weekend du " + formatDate(this.getSaturdayFromDateOnSameWeek(value), "dd", this.locale) + "-" + formatDate(this.getSundayFromDateOnSameWeek(value), "dd", this.locale);
   }

   public getSportsClubName() {
      return "bourlettis";
   }

   public getSportsClubNameOld() {
      return "anderlues";
   }

   public getSportsClubNameToUse() {
      return "VA Bourlettis";
   }

   public getSportsClubNameToUseOnDisplay(isDisplayOnAMobile: boolean) {
      return (isDisplayOnAMobile) ? "VAB" : this.getSportsClubNameToUse();
   }

   public getSundayFromDateOfNextWeek(date: Date): Date {
      return this.addDays(this.getSundayFromDateOnSameWeek(date), 7);
   }

   public getSundayFromDateOfPreviousWeek(date: Date): Date {
      return this.addDays(this.getSundayFromDateOnSameWeek(date), -7);
   }

   public getSundayFromDateOnSameWeek(date: Date): Date {
      if (date.getDay() == 0)
         return date;
      else
         return this.addDays(date, 7 - date.getDay());
   }

   // public getValidatorDictionnary(validator: Validator): ValidatorInfo {
   //   return this.LoadValidatorDictionnary[Validator[validator]];
   // }

   // ValidatorInfo[enumFormValidator.DecimalNumberWithPoint] = { value: "^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$", description: " : value should be like '1.23', '0.2'", errorDescription: "You entered wrong value. Only decimal number are accepted" };

   // ValidatorInfo[enumFormValidator.LettersOnly] = { value: "[a-zA-Z]*", description: " : value should be like 'XyZ'", errorDescription: "You entered wrong value. Please look at the rule" };
   // ValidatorInfo[enumFormValidator.LettersLowerCaseOnly] = { value: "[a-z]*", description: " : value should be like 'xyz'", errorDescription: "You entered wrong value. Please look at the rule" };
   // ValidatorInfo[enumFormValidator.LettersUpperCaseOnly] = { value: "[A-Z]*", description: " : value should be like 'XYZ'", errorDescription: "You entered wrong value. Please look at the rule" };

   // ValidatorInfo[enumFormValidator.Passwor63to18] = { value: "[a-z0-9_-]{6,18}", description: " : 6 to 18 chars. Can contains a-z, 0-9, _ or -", errorDescription: "You entered wrong value. Please look at the rule" };
   // ValidatorInfo[enumFormValidator.Username3to16] = { value: "[a-z0-9_-]{3,16}", description: " : 3 to 16 chars. Can contains a-z, 0-9, _ or -", errorDescription: "You entered wrong value. Please look at the rule" };

   // ValidatorInfo[enumFormValidator.Email] = { value: "([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})", description: " : value should be like t.x@y.z", errorDescription: "You entered wrong value. Please look at the rule" };
   // ValidatorInfo[enumFormValidator.HtmlHexValue] = { value: "^#[a-f0-9]{1,6}|^#[a-f0-9]{1,2}", description: " : value should be like '#00ff00'", errorDescription: "You entered wrong value. Please look at the rule" };
   // ValidatorInfo[enumFormValidator.IpAddress] = { value: "(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)", description: " : value should be like 't.x.y.z'", errorDescription: "You entered wrong value. Please look at the rule" };

   // ValidatorInfo[enumFormValidator.LanguagesList] = { value: "(?:(?:^|, )(FR|NL))+$", description: " : value should be 'FR' or 'NL'", errorDescription: "You entered wrong value. Please look at the rule" };

   // //ValidatorInfo[""] = { value: "", description: "", errorDescription: "You entered wrong value. Please look at the rule" };
   public getSysIdOfUser(user: user): number {
      if (user == null)
         return -1;
      else {
         return user.sysId;
      }
   }

   public getTimeFormatExcel(time: string) {
      return moment(time, "DD/MM/YYYY HH:mm").format("HH:mm");
   }

   public getTimeFormatHHmm(date: Date) {
      return this.datepipe.transform(date, 'HH:mm');
   }

   public getToday() {
      let now = new Date();
      //return new Date("2023/09/15"); //SA 04/11/18/25 (02/2023)
      if (this.accessRightsService.isUserSupervisor()) {
         //now = new Date("2024/03/13");
         return now;
      }
      return now;
   }

   public getTodayString() {
      return this.datepipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss');
   }

   public convertDateToNowDDMMYYYYFromDate(date: Date) {
      if (date == new Date())
         return "Aujourd'hui";
      else
         return this.datepipe.transform(date, 'dd/MM/yyyy');
   }

   public convertDateToNowDDMMYYYYFromString(date: string) {
      if (date == this.datepipe.transform(new Date(), 'dd/MM/yyyy'))
         return "Aujourd'hui";
      else
         return date;
   }

   public convertDateTimeToNowDDMMYYYYHHMMSSFromDate(dateTime: Date) {
      if (this.datepipe.transform(dateTime, 'dd/MM/yyyy') == this.datepipe.transform(new Date(), 'dd/MM/yyyy'))
         return "Aujourd'hui à " + this.datepipe.transform(dateTime, 'HH:mm:ss');
      else
         return this.datepipe.transform(dateTime, 'dd/MM/yyyy HH:mm:ss');
   }

   public convertDateTimeToNowDDMMYYYYHHMMSSFromString(dateTime: string) {
      if (dateTime.split(" ")[0] == this.datepipe.transform(new Date(), 'dd/MM/yyyy'))
         return "Aujourd'hui à " + dateTime.split(" ")[1];
      else
         return dateTime;
   }

   public getTrainingActivitiesType(): any[] {
      // value is limited at 5 chars in database !!!

      let activity: any[] = [];
      activity.push({ value: enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY], viewValue: this.translateService.instant(enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY]) });
      return activity;
   }

   public getTrainingInfo(training, team) {
      let temp: string = "";
      if (training != null) {
         let _extraTrainingTimeEnding = training.extraTrainingTimeEnding??"";
         if (training.extraTrainingIsAtHome)
            return "<div class=\"gridMatch\"><div><b>" + training.extraTrainingTeam + "</b></div><div><b>à domicile</b></div></div>" +
               "<div class=\"gridMatch\"><div><b>" + training.extraTrainingTimeStarting?.replace(":", "H") + ((_extraTrainingTimeEnding != "" && _extraTrainingTimeEnding != "00:00") ? (" à " + training.extraTrainingTimeEnding?.replace(":", "H")) : "") + "</b></div><div>" + temp + "</div></div>";
         else
            return "<div class=\"gridMatch\"><div><b>en déplacement</b></div><div><b>" + training.extraTrainingTeam + "</b></div></div>" +
               "<div class=\"gridMatch\"><div><b>" + training.extraTrainingTimeStarting?.replace(":", "H") + ((_extraTrainingTimeEnding != "" && _extraTrainingTimeEnding != "00:00") ? (" à " + training.extraTrainingTimeEnding?.replace(":", "H")) : "") + "</div></div>";
      }
   }

   public getYearOfDate(value: Date): string {
      let newDate = new Date(value);
      return moment(newDate).format('YYYY');
   }

   public groupBy(data: any, key: string) {
      let result = [];
      for (let i = 0; i < data.length; i++) {
         const value = data[i][key];
         if (result.indexOf(value) == -1) {
            result.push(value);
         }
      }
      return result;
   }

   public isAMatch(eventClub: eventClub): boolean {
      return eventClub.clubActivityType == enumClubActivityType.CHAMPIONSHIP ||
         eventClub.clubActivityType == enumClubActivityType.HAINAUT_CUP ||
         eventClub.clubActivityType == enumClubActivityType.TOURNAMENT_DAY ||
         eventClub.clubActivityType == enumClubActivityType.EXTRA_TRAINING_DAY ||
         eventClub.clubActivityType == enumClubActivityType.FRIENDLY_MATCH_DAY;
   }

   public isBirthdayOnCurrentAndNextMonth(birthdayDate: Date): boolean {
      let todayMonth = new Date(this.getToday().setHours(0, 0, 0, 0)).getMonth();
      let tempBirthdayMonth = new Date(birthdayDate.setHours(0, 0, 0, 0)).getMonth();
      let todayMonthPlus1 = todayMonth == 11 ? 0 : (todayMonth + 1);
      return (tempBirthdayMonth == todayMonth) || (tempBirthdayMonth == todayMonthPlus1);
   }

   public isCompleteDayInOverWeekAsTodayWeek(date: Date): boolean {
      let today = new Date(this.getToday().setHours(0, 0, 0, 0));
      let tempDate = new Date(date);
      return tempDate > this.getSundayFromDateOnSameWeek(today);
   }

   public isCompleteDayInSameWeekAsTodayWeek(date: Date): boolean {
      let today = new Date(this.getToday().setHours(0, 0, 0, 0));
      let tempDate = new Date(date);
      //this.log(this.getDateFormatYyyyMmDd(this.getMondayFromDateOnSameWeek(today)) + ">" + this.getDateFormatYyyyMmDd(tempDate) + "<" + this.getDateFormatYyyyMmDd(this.getSundayFromDateOnSameWeek(today)),"isCompleteDayInSameWeekAsTodayWeek" + ((tempDate >= this.getMondayFromDateOnSameWeek(today) && tempDate <= this.getSundayFromDateOnSameWeek(today)) ? " <- OK" : ""));
      return tempDate >= this.getMondayFromDateOnSameWeek(today) && tempDate <= this.getSundayFromDateOnSameWeek(today);
   }

   public isDayMonthInSameWeekAsTodayWeek(date: Date): boolean {
      let today = new Date(this.getToday().setHours(0, 0, 0, 0));
      let tempDate = new Date(new Date(date).setFullYear(today.getFullYear()));
      return tempDate >= this.getMondayFromDateOnSameWeek(today) && tempDate <= this.getSundayFromDateOnSameWeek(today);
   }

   public isDayOverToday(date: Date): boolean {
      return this.getDateFormatYyyyMmDd(date) >= this.getDateFormatYyyyMmDd(this.getToday());
   }

   public isMatchPlay(dictionary: Map<string, eventClub>): boolean {
      let temp = [...dictionary.entries()].sort((a, b) => a[0] > b[0] ? 1 : -1).find(([key, value]) => {
         if ((value.event.type == enumActivityType[enumActivityType.CHAMPIONSHIP] ||
            value.event.type == enumActivityType[enumActivityType.HAINAUT_CUP] ||
            value.event.type == enumActivityType[enumActivityType.TOURNAMENT_DAY] ||
            value.event.type == enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY]) && value.event.status != enumMatchDisplayStatus[enumMatchDisplayStatus.JOU]) { return 1; }
      });
      return temp != undefined;
   }

   public isMemberCoachOnly(member: user) {
      if (member == null)
         return false;

      if (this.getRolesOfMembers().filter(i => i.name == this.translateService.instant(enumRoles[enumRoles.Coach])) != null) {
         return this.getAllRoles(member).includes(this.getRolesOfMembers().FirstOrDefault(i => i.name == this.translateService.instant(enumRoles[enumRoles.Coach]))?.name) &&
            !this.getAllRoles(member).includes(this.getRolesOfMembers().FirstOrDefault(i => i.name == this.translateService.instant(enumRoles[enumRoles.AssistantCoach]))?.name);
      }
      else
         return false;
   }

   public isNotNullOrEmpty(value: string): boolean {
      return !this.isNullOrEmpty(value);
   }

   public isNullOrEmpty(value: string): boolean {
      return !value;
   }

   public isRoleAssistantCoach(role) {
      return role == this.translateService.instant(enumRoles[enumRoles.AssistantCoach]);
   }

   public isRoleCoach(role) {
      return role == this.translateService.instant(enumRoles[enumRoles.Coach]);
   }

   public isRoleCoachOrAssistant(role) {
      return role == this.translateService.instant(enumRoles[enumRoles.Coach]) || role == this.translateService.instant(enumRoles[enumRoles.AssistantCoach]);
   }

   public isRoleTeamContact(role) {
      return role == this.translateService.instant(enumRoles[enumRoles.TeamContact]) || role == this.translateService.instant(enumRoles[enumRoles.TeamContact]);
   }

   public isRoleTeamDelegate(role) {
      return role == this.translateService.instant(enumRoles[enumRoles.TeamDelegate]);
   }

   public isUserCanManageMeeting(user: user): boolean {
      let temp = this.getOfficialMeetingManagerComiteeMembers();
      let found = false;

      temp.forEach(r => {
         if (user.roleInComity?.includes(r.name) || user.roleInClub?.includes(r.name) || user.roleInClubCustom?.includes(r.name)) {
            found = true;
            return;
         }
      });
      return found;
   }

   public isUserInComitee(user: user): boolean {
      if (user == null)
         return false;
      else {
         let temp = this.getOfficalComiteeMembers().concat(this.getExtraComiteeMembers());
         let found = false;

         temp.forEach(r => {
            if (user.roleInComity?.includes(r.name) || user.roleInClub?.includes(r.name) || user.roleInClubCustom?.includes(r.name)) {
               found = true;
               return;
            }
         });
         return found;
      }
   }

   public isUserSecretary(user: user): boolean {
      if (user == null)
         return false;
      else {
         return (user.roleInComity?.includes(this.translateService.instant(enumRoles[enumRoles.Secretary])) || 
            user.roleInClubCustom?.includes(this.translateService.instant(enumRoles[enumRoles.Secretary]))
         );
      }
   }

   public left(value: string, length: number) {
      return value.substring(0, length);
   }

   public logCompare(obj1: any, obj2: any, message: string = "") {
      console.log("-> " + (message != "" ? (message + " : ") : "") + (JSON.stringify(obj1) == JSON.stringify(obj2)));
   }

   public logInfo(object: any, message: string = "") {
      console.log("-> " + (message != "" ? (message + " : ") : "") + JSON.stringify(object));
   }

   public logInfoA(object: any, message: string = "") {
      console.log("-> " + (message != "" ? (message + " : ") : "") + this.simpleStringify(object));
   }

   public logInfoDic(object: any, message: string = "") {
      console.log("-> " + (message != "" ? (message + " : ") : "") + JSON.stringify([...object].join(";")));
   }

   public lowercaseFirstLetter(value: string) {
      return value.charAt(0).toLowerCase() + value.slice(1);
   }

   public mapSort(unsortedList: any): any {
      const keys: string[] = Object.keys(unsortedList);
      const sortedKeys = keys.sort();
      const sortedList: any = {};
      sortedKeys.forEach((x) => {
         sortedList[x] = unsortedList[x];
      });
      return sortedList;
   }

   public mid(value: string, pos: number, length: number) {
      return value.substring(pos - 1, pos + length - 1);
   }

   public ngOnInit() {
   }

   public phoneFormat(phone: string) {
      if (phone == null)
         return "";

      let temp = phone.replace("+32", "");

      if (temp.length == 8) {
         return "+32/" + this.mid(temp, 1, 2) + "." + this.mid(temp, 3, 2) + "." + this.mid(temp, 5, 2) + "." + this.mid(temp, 7, 2);
      }
      if (temp.length == 9) {
         return "+32/" + this.mid(temp, 1, 3) + "." + this.mid(temp, 4, 3) + "." + this.mid(temp, 7, 3)
      }
      return phone;
   }

   public removeAllCookies() {
      //this.logInfo("removeDataPrivacyCookie", "FW / removeAllCookies");
      this.storageService.removeDataPrivacyCookie();
      //this.logInfo("removeLastUpdateCookie", "FW / removeAllCookies");
      this.storageService.removeLastUpdateCookie();
      //this.logInfo("removeLastResetCookie", "FW / removeAllCookies");
      this.storageService.removeLastResetCookie();
      //this.logInfo("signOut", "FW / removeAllCookies");
      this.storageService.signOut();
   }

   public removeBlank(value: string): string {
      return value.split(' ').join('');
   }

   public removeChars(value: string, chars: string[]): string {
      let result = value;
      for (let cpt = 0; cpt < chars.length; cpt++) {
         result = result.split(chars[cpt]).join('');
      }
      return result;
   }

   public removeDuplicateItemFromArray(array) {
      const result = [];
      array.forEach((item) => {
         if (!result.includes(item)) {
            result.push(item);
         }
      })
      return result;
   }

   public removeItemFromArray(array, element: string) {
      return array.forEach((value, index) => {
         if (value == element) array.splice(index, 1);
      });
   }

   public replaceCrLfByBr(value: string) {
      return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
   }

   public right(value: string, length: number) {
      return value.slice(value.length - length);
   }

   public saveStatusResetTimeOnFailed() {
      return 6000;
   }

   public saveStatusResetTimeOnSuccess() {
      return 2000;
   }



   //TODO (Framework) : Adapt sort for all columns (actually set on day column)
   public sort(list: any[], descending: boolean) {
      if (descending) {
         return list.sort(function (a, b) {
            if (a.day > b.day) {
               return -1;
            }
            if (a.day < b.day) {
               return 1;
            }
            return 0;
         });
      }
      else {
         return list.sort(function (a, b) {
            if (a.day < b.day) {
               return -1;
            }
            if (a.day > b.day) {
               return 1;
            }
            return 0;
         });
      }
   }

   public sortEventClubs(dictionary: Map<string, eventClub>): Map<string, eventClub> {
      let temp = new Map<string, eventClub>();
      Object.assign({}, [...dictionary.entries()].sort().filter(([key, value]) => {
         { return { key, value }; }
      }).map(([key, value]) => { temp.set(key, value); }));
      return temp;
   }

   public sortOnKey<T>(obj: Object) {
      return Object.keys(obj).sort().reduce((acc, c) => { acc[c] = obj[c]; return acc }, {}) as T
   }

   public stringFormat(str: string, args: string[]): string {
      return str.replace(/{(\d+)}/g, (match, index) => args[index] || '');
   }

   public unFormatPhone(value: string) {
      if (value == null)
         return "";

      let temp = value.split('+').join('').split('/').join('').split('.').join('').split('-').join('').split(' ').join('').trim();
      if (temp[0] == '0') {
         return "+32" + temp.slice(1);
      }
      return "+" + temp;
   }

   public uppercaseFirstLetter(value: string): string {
      return value.charAt(0).toUpperCase() + value.slice(1);
   }

   public uppercaseFirstLetterAndLowerOther(value: string): string {
      return (value.charAt(0).toUpperCase() + value.slice(1).toLowerCase());
   }

   public uppercaseFirstLetterAndLowerOtherAndRemoveBlank(value: string): string {
      return (value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()).split(' ').join('');
   }

   private createMetadataModel(metadataModelBase: any, type: string, property: string): metadataModel {
      return <metadataModel>{
         //Set metadataModelBase properties
         baseName: property,
         orderIndex: metadataModelBase.orderIndex,

         gridColumnIsVisible: metadataModelBase.gridColumnIsVisible == null ? enumWorkgroup[enumWorkgroup.UserReadOnly] : metadataModelBase.gridColumnIsVisible,
         grisIsInDetailExpanded: metadataModelBase.grisIsInDetailExpanded,

         editColumnIsVisible: metadataModelBase.editColumnIsVisible == null ? enumWorkgroup[enumWorkgroup.UserReadOnly] : metadataModelBase.editColumnIsVisible,
         editDropdownEntityMapping: metadataModelBase.editDropdownEntityMapping == null ? "" : metadataModelBase.editDropdownEntityMapping,

         editDropdownPropertiesForDisplay: metadataModelBase.editDropdownPropertiesForDisplay == null ? "" : metadataModelBase.editDropdownPropertiesForDisplay,
         editDropdownFormatForDisplay: metadataModelBase.editDropdownFormatForDisplay == null ? "" : metadataModelBase.editDropdownFormatForDisplay,
         editDropdownIsTranslateForDisplay: metadataModelBase.editDropdownIsTranslateForDisplay == null ? false : metadataModelBase.editDropdownIsTranslateForDisplay,

         editControlType: metadataModelBase.editControlType == null ? enumControlType.Text : metadataModelBase.editControlType,
         editStickyValidator: metadataModelBase.editStickyValidator == null ? [] : metadataModelBase.editStickyValidator,
         editVariableValidator: metadataModelBase.editVariableValidator == null ? [] : metadataModelBase.editVariableValidator,
         editDropdownStickyValues: metadataModelBase.editDropdownStickyValues == null ? [] : metadataModelBase.editDropdownStickyValues,
      };
   }

   private getAllRoles(profile) {
      return (profile.rolhelloeInTeam ?? "") + "," + (profile.roleInClub ?? "") + "," + (profile.roleInClubCustom ?? "");
   }

   private simpleStringify(object) {
      const simpleObject = {};
      for (let prop in object) {
         if (!object.hasOwnProperty(prop)) {
            continue;
         }
         if (typeof (object[prop]) == 'object') {
            continue;
         }
         if (typeof (object[prop]) == 'function') {
            continue;
         }
         simpleObject[prop] = object[prop];
      }
      return JSON.stringify(simpleObject); // returns cleaned up JSON
   }
}
