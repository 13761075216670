import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { enumCacheTypeLoad } from 'src/sites/core/enum/Enum';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { SignalRService } from 'src/sites/core/services/signalR.service';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { db } from 'src/sites/vabourlettis/services/appDB';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';

@Component({
   selector: 'app-spinner',
   templateUrl: './spinner.component.html',
   styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
   public isVersionCacheLoadedSubject: boolean = false;
   public currentApplicationLastVersionUpdate: string = "";
   public currentApplicationVersionNumber: string = "";
   public message: string = "";
   public isCannotBeLoaded: boolean = false;
   public isQueryOnTimeoutSubject: boolean = false;
   public isNetworkStatusOnline: boolean = false;
   public stepNumber: number = 0;

   constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private storageService: StorageService,
      private frameworkService: FrameworkService,
      private signalRService: SignalRService) {
      this.isCannotBeLoaded = false;
      this.isQueryOnTimeoutSubject = false;
   }

   public initializeVersion() {
      this.currentApplicationVersionNumber = this.cacheService.getLastVersionNumber();
      this.currentApplicationLastVersionUpdate = this.cacheService.getLastVersionUpate();
   }

   public async ngOnInit() {
      //Souscription à l'event lorsque la cache globale a terminé son chargement
      this.subjectService.IsVersionCacheLoadedSubject.subscribe(bool => { this.isVersionCacheLoadedSubject = bool; if (bool) this.initializeVersion(); });
      this.subjectService.MessageCacheSubject.subscribe(message => { this.setMessage(message); });
      this.subjectService.StepNumberCacheSubject.subscribe(stepNumber => { this.stepNumber = stepNumber; });
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => {
         if (value && !this.isNetworkStatusOnline && !this.storageService.isDataPrivacyAccepted() && !this.storageService.isDataPrivacyIsNull()) {
            this.isNetworkStatusOnline = value;
            this.isCannotBeLoaded = !this.storageService.isDataPrivacyAccepted() && !this.isNetworkStatusOnline;
            this.reloadWebsite();
         }
         else {
            this.isNetworkStatusOnline = value;
            this.isCannotBeLoaded = !this.storageService.isDataPrivacyAccepted() && !this.isNetworkStatusOnline;
         }
      });

      //BOOKMARK : IsQueryOnTimeoutSubject subscribe
      // this.subjectService.IsQueryOnTimeoutSubject.subscribe(async value => {
      //    if (value == 1 || value == 2) {
      //       let isDbEmpty = true;
      //       if (db.sportsClubDB == undefined)
      //          isDbEmpty = true;
      //       else
      //          isDbEmpty = (await db.sportsClubDB.count() == 0);

      //       if ((isDbEmpty && this.storageService.isDataPrivacyAccepted()) || this.storageService.isDataPrivacyIsNull() || !this.storageService.isDataPrivacyAccepted())
      //       {
      //          this.message = "Chargement interrompu !";
      //          this.stepNumber = 0;
      //          this.isQueryOnTimeoutSubject = true;  
      //       }
      //       else
      //       {
      //          this.isQueryOnTimeoutSubject = false;  
      //       }
      //    }
      //    else
      //    {
      //       this.isQueryOnTimeoutSubject = (value == 0 || value == null) ? false : true;  
      //    }



      //    //BOOKMARK : autoReloadWebsiteManually
      //    if (value == 3) {
      //       this.autoReloadWebsiteManually();
      //    }
      // });
   }

   public autoReloadWebsiteManually() {
      //this.frameworkService.logInfo(this.signalRService.isHubConnectionClosed(), "autoReloadWebsiteManually");
      if (!this.signalRService.isHubConnectionClosed()) {
         this.cacheService.cacheLoadStart(enumCacheTypeLoad.fromWebAPI);
      }
   }

   public reloadWebsite() {
      //this.frameworkService.logInfo("CALL cacheLoadStart","reloadWebsite (spinner)");
      this.cacheService.cacheLoadStart(enumCacheTypeLoad.fromWebAPI);
   }

   public setMessage(message: string) {
      this.message = message;
   }
}
