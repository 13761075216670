//This file is autogenerated by C# GeneratedServices/Angular/ServicesAngularGenerationTool

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { LogService } from 'src/sites/core/services/log.service';
import { AppConfigService } from 'src/sites/core/services/appconfig.service';
import { TrainingPlayerBaseService } from './trainingPlayerBase.service';
import { StorageService } from '../authentication/storage.service';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { trainingPlayer } from '../models/trainingPlayer';
import { jsonIgnoreReplacer } from 'json-ignore';
import { encryptedValue } from '../authentication/encryptedValue';

@Injectable()
export class TrainingPlayerService extends TrainingPlayerBaseService {
  private _logger: LogService;
  private _appConfigService: AppConfigService;
  private _httpClient: HttpClient;
  private _frameworkService: FrameworkService;
  private _accessRightsService: AccessRightsService;
  private _encryptionService: EncryptionService;
  private _tokenStorageService: StorageService;

  username = "UNKNOWN";

  constructor(
    appConfigService: AppConfigService,
    logger: LogService,
    httpClient: HttpClient,
    frameworkService: FrameworkService,
    accessRightsService: AccessRightsService,
    encryptionService: EncryptionService,
    storageService: StorageService
  ) {
    super(appConfigService, logger, httpClient, frameworkService, accessRightsService, encryptionService, storageService);
    this._appConfigService = appConfigService;
    this._logger = logger;
    this._httpClient = httpClient;
    this._frameworkService = frameworkService;
    this._accessRightsService = accessRightsService;
    this._encryptionService = encryptionService;
    this._tokenStorageService = storageService;
    this.username = this._tokenStorageService.getUserConnected() == null ? "UNKNOWN" : this._tokenStorageService.getUserConnected().username;
  }

  public deleteAllTrainingPlayerOfCoach(trainingPlayerToDelete: trainingPlayer): Observable<encryptedValue> {
    //console.log("TRACE -> deleteAllTrainingPlayerOfCoach");
    const username = this._tokenStorageService.getUserConnected() == null ? "UNKNOWN" : this._tokenStorageService.getUserConnected().username;

    let encryptedData = new encryptedValue();
    encryptedData.value = this._encryptionService.encryptObject(true, false, trainingPlayerToDelete);

    let url = "/Q102600001?id=" + trainingPlayerToDelete.sysId + "&username=" + username;
    return this._httpClient.post<encryptedValue>(this._appConfigService.getApiUrl() + 'api/C1026' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
      .pipe(retry(1), catchError(this.handleError));
  }

  public deleteWithServerDateCheck(sysId: number, date: Date, time: string, isShouldBeChecked: boolean): Observable<encryptedValue> {
    //console.log("TRACE -> deleteWithServerDateCheck");
    const username = this._tokenStorageService.getUserConnected() == null ? "UNKNOWN" : this._tokenStorageService.getUserConnected().username;
    
    let encryptedData = new encryptedValue();
    encryptedData.value = this._encryptionService.encryptObject(true, false, sysId);
      
    let url = encodeURI("/Q102600002?datetime=" + this._frameworkService.getDateFormatYyyyMmDd(date) + time.replace(":", "") + "&isShouldBeChecked=" + isShouldBeChecked + "&username=" + username);
    return this._httpClient.post<encryptedValue>(this._appConfigService.getApiUrl() + 'api/C1026' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
      .pipe(retry(1), catchError(this.handleError));
  }

  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => errorMessage);
  }

  public insertWithServerDateCheck(trainingPlayerToInsert: trainingPlayer, isShouldBeChecked: boolean) {
    //console.log("TRACE -> insertWithServerDateCheck " + JSON.stringify(trainingPlayerToInsert));
    let encryptedData = new encryptedValue();
    encryptedData.value = this._encryptionService.encryptObject(true, false, trainingPlayerToInsert);
      
    const username = this._tokenStorageService.getUserConnected() == null ? "UNKNOWN" : this._tokenStorageService.getUserConnected().username;
    let url = "/Q102600003?isShouldBeChecked=" + isShouldBeChecked + "&username=" + username;
    return this._httpClient.post<encryptedValue>(this._appConfigService.getApiUrl() + 'api/C1026' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
      .pipe(retry(1), catchError(this.handleError));
  }

  public updateAllTrainingPlayerOfCoach(trainingPlayerToUpdate: trainingPlayer) {
    //console.log("TRACE -> updateAllTrainingPlayerOfCoach");
    let encryptedData = new encryptedValue();
    encryptedData.value = this._encryptionService.encryptObject(true, false, trainingPlayerToUpdate);
      
    const username = this._tokenStorageService.getUserConnected() == null ? "UNKNOWN" : this._tokenStorageService.getUserConnected().username;
    let url = "/Q102600004?username=" + username;
    return this._httpClient.put<encryptedValue>(this._appConfigService.getApiUrl() + 'api/C1026' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
      .pipe(retry(1), catchError(this.handleError));
  }

  public updateWithServerDateCheck(trainingPlayerToUpdate: trainingPlayer, isShouldBeChecked: boolean) {
    //console.log("TRACE -> updateWithServerDateCheck");
    let encryptedData = new encryptedValue();
    encryptedData.value = this._encryptionService.encryptObject(true, false, trainingPlayerToUpdate);
      
    const username = this._tokenStorageService.getUserConnected() == null ? "UNKNOWN" : this._tokenStorageService.getUserConnected().username;
    let url = "/Q102600005?isShouldBeChecked=" + isShouldBeChecked + "&username=" + username;
    return this._httpClient.put<encryptedValue>(this._appConfigService.getApiUrl() + 'api/C1026' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
      .pipe(retry(1), catchError(this.handleError));
  }
}