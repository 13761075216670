<form [formGroup]="form">
    <ng-container *ngIf="visible && eventClub != null">
        <div>
            <md [eventClub]="eventClub" [matchDisplayType]="EnumMatchDisplayType.STATUS_DETAIL" [isClickable]="false"
                [isPresentVisible]="false"></md>
        </div>
        <div *ngIf="this.eventClub.availability == EnumAvailability.PRESENT"
            class="editionPanel">
            <app-button [icon]="'thumb_down'" [class]="'notPresent space'" [isBackgroundOpaque]="true" [text]="''"
                (onClick)="onChangeToNotPresent()"></app-button>
            <mat-select placeholder="(motif)" formControlName="allReasons" class="dropDownMatchStatusWidth space">
                <mat-option *ngFor="let item of allReasons" [value]="item.value">
                    <ng-container>{{item.viewValue}}</ng-container>
                </mat-option>
            </mat-select>
            <app-button [icon]="'thumbs_up_down'" [class]="'guard space'" [isBackgroundOpaque]="true" [text]="''"
                (onClick)="onChangeToGuard()"></app-button>
            <ng-container><app-button [icon]="'groups'" [color]="'neutral'" [isBackgroundOpaque]="true" [text]=""
            (onClick)="onTrainingPlayersManager()"></app-button></ng-container>
        </div>
        <div *ngIf="this.eventClub.availability == EnumAvailability.NOTPRESENT"
            class="editionPanel">
            <div>
                <mat-select placeholder="(motif)" formControlName="allReasons" class="dropDownMatchStatusWidth space">
                    <mat-option *ngFor="let item of allReasons" [value]="item.value">
                        <ng-container>{{item.viewValue}}</ng-container>
                    </mat-option>
                </mat-select>
                <app-button [icon]="'thumb_down'" [class]="'notPresent space'" [isBackgroundOpaque]="true" [text]="''"
                    (onClick)="onChangeToNotPresent()"></app-button>
                <app-button [icon]="'thumbs_up_down'" [class]="'guard space'" [isBackgroundOpaque]="true" [text]="''"
                    (onClick)="onChangeToGuard()"></app-button>
                <app-button [icon]="'thumb_up'" [class]="'present space'" [isBackgroundOpaque]="true" [text]="''"
                    (onClick)="onChangeToPresent()"></app-button>
                <ng-container><app-button [icon]="'groups'" [color]="'neutral'" [isBackgroundOpaque]="true" [text]="''"
                    (onClick)="onTrainingPlayersManager()"></app-button></ng-container>
            </div>
        </div>
        <div *ngIf="this.eventClub.availability == EnumAvailability.GUARD"
            class="editionPanel">
            <div>
                <mat-select placeholder="(motif)" formControlName="allReasons" class="dropDownMatchStatusWidth space">
                    <mat-option *ngFor="let item of allReasons" [value]="item.value">
                        <ng-container>{{item.viewValue}}</ng-container>
                    </mat-option>
                </mat-select>
                <app-button [icon]="'thumb_down'" [class]="'notPresent space'" [isBackgroundOpaque]="true" [text]="''"
                    (onClick)="onChangeToNotPresent()"></app-button>
                <app-button [icon]="'thumb_up'" [class]="'present space'" [isBackgroundOpaque]="true" [text]="''"
                    (onClick)="onChangeToPresent()"></app-button>
                <ng-container><app-button icon="groups" [color]="'neutral'" [isBackgroundOpaque]="true" [text]=""
                    (onClick)="onTrainingPlayersManager()"></app-button></ng-container>
            </div>
        </div>
        <div>
            <div class="br1"></div>
        </div>
    </ng-container>
</form>