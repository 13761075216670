<div [hidden]="subjectCacheIsLoaded >= 2">
   <app-spinner></app-spinner>
</div>

<form [formGroup]="form">
   <div [hidden]="subjectCacheIsLoaded < 2">
      <mat-card class="mat-card-center-95P">
         <mat-card-content>
            <mat-card>
               <mat-card-header class="mat-card-header-color">
                  <mat-card-title>
                     <div class="flexStart">
                        <div>
                           <span
                              class="material-icons matcard-title-icon">person</span>&nbsp;{{getProfileTitle()}}&nbsp;
                        </div>
                        <div class="alignmentRight" style="padding-right:20px">

                           <span *ngIf="profileId != '-1'">
                              <app-button icon="people" class="spanButtonSeparator buttonWidth animatedButton"
                                 [isAlwaysEnabled]="true" (onClick)="onReturnToMember()"></app-button>
                           </span>
                        </div>
                     </div>
                  </mat-card-title>
               </mat-card-header>
               <mat-card-content>
                  <div class="mainmatcard" *ngIf="profile == null">
                     <div class="container d-flex justify-content-center">
                        Le profil demandé est inconnu.
                     </div>
                  </div>
                  <div class="mainmatcard" *ngIf="profile != null">
                     <div class="container d-flex justify-content-center">
                        <div [class]="getIsInactiveBackgroundClass(profile)">
                           <div class="text-center">
                              <div style="font-size:x-large;padding-top:5px;padding-bottom:5px;"
                                 *ngIf="profile.nickName == ''">{{profile.firstName}} {{profile.lastName}}</div>
                              <div style="font-size:x-large;padding-top:5px;padding-bottom:5px;"
                                 *ngIf="profile.nickName != ''"><span>{{profile.firstName}} {{profile.lastName}} <span
                                       style="font-size:large">alias {{profile.nickName}}</span></span></div>

                              <div style="font-size:large;padding-top:5px;padding-bottom:5px;"
                                 *ngIf="profile.team?.teamCategory != ''">
                                 {{profile.team?.teamDisplay}}<div *ngIf="profile.team2 != null">et
                                    {{profile.team2?.teamDisplay}}</div>
                              </div>
                           </div>

                           <div class="upper text-center">
                              <img src="../../../../sites/vabourlettis/images/content/Profile.jpg" class="img-fluid"
                                 alt="Profile">
                           </div>

                           <div class="br1"></div>

                           <div class="user text-center">
                              <div class="pictureProfile"
                                 *ngIf="getImgURL('memberPicture') != null && getImgURL('memberPicture') != ''">
                                 <img [src]="getImgURL('memberPicture')" loading="lazy" alt="Profile icon"
                                    (click)="onClickUpdatePicture('memberPicture');">
                              </div>
                              <div class="pictureNoProfile"
                                 *ngIf="getImgURL('memberPicture') == null || getImgURL('memberPicture') == ''">
                                 <img [src]="getUserIcon(profile)" alt="Profile icon"
                                    (click)="onClickUpdatePicture('memberPicture');">
                              </div>
                           </div>

                           <div class="br2"></div>

                           <div class="flexSpaceBetween">
                              <div style="font-size:x-large"
                                 *ngIf="!isMemberCoachOnly(profile) && profile.jacketNumber > 0">N°
                                 {{profile.jacketNumber}}</div>
                              <div style="font-size:x-large" *ngIf="isMemberCoachOnly(profile)">COACH</div>
                              <div>
                                 <div>N° Licence</div>
                                 <div style="font-size:x-large">{{profile.licenceNumber}}</div>
                              </div>
                           </div>

                           <div class="br1"></div>

                           <div class="card-block">
                              <h5 class="m-b-20 p-b-5 b-b-default f-w-600">Information personnelle</h5>
                              <div class="flexStart" *ngIf="getProfileRoles(profile) != ''">
                                 <div class="text-muted text-mutedSize">Rôle(s)</div>
                                 <div class="alignmentRight">&nbsp;&nbsp;{{getProfileRoles(profile)}}</div>
                              </div>
                              <div class="flexStart" *ngIf="profile.licenceType != null && profile.licenceType != ''">
                                 <div class="text-muted text-mutedSize">Licence</div>
                                 <div class="alignmentRight">&nbsp;&nbsp;{{profile.licenceType}} {{profile.category}}
                                 </div>
                              </div>
                              <div class="flexStart" *ngIf="profile.startAffiliate != null">
                                 <div class="text-muted text-mutedSize">Date d'affiliation</div>
                                 <div class="alignmentRight">&nbsp;&nbsp;{{profile.startAffiliate | date:'dd/MM/yyyy'}}
                                 </div>
                              </div>
                              <div class="flexStart" *ngIf="profile.email != '' && isProfileInfoVisible(0)">
                                 <div class="text-muted text-mutedSize">Email</div>
                                 <div class="alignmentRight">&nbsp;&nbsp;<a href="mailto:{{profile.email}}"
                                       class="auth-link">{{profile.email}}</a></div>
                              </div>
                              <div class="flexStart"
                                 *ngIf="profile.address+profile.zipCode+profile.locality != '' && isProfileInfoVisible(3)">
                                 <div class="text-muted text-mutedSize">Adresse</div>
                                 <div class="alignmentRight">&nbsp;&nbsp;

                                    <span class="material-icons"
                                       (click)="onEventCopyToClipboard(profile)">copy_all</span>
                                    <a href="https://maps.google.com/?q={{profile.address}} {{profile.zipCode}} {{profile.locality}}"
                                       target="_blank" rel="noopener" class="auth-link aFontSize"> {{profile.address}} -
                                       {{profile.zipCode}}
                                       {{profile.locality}}</a>
                                 </div>
                              </div>
                              <div class="flexStart"
                                 *ngIf="profile.phone1 != null && profile.phone1 != '' && isProfileInfoVisible(1)">
                                 <div class="text-muted text-mutedSize">Téléphone</div>
                                 <div class="alignmentRight">&nbsp;&nbsp;<a
                                       href="tel:{{profile.phone1}}">{{getPhoneFormat(profile.phone1)}}</a></div>
                              </div>
                              <div class="flexStart"
                                 *ngIf="profile.phone2 != null && profile.phone2 != '' && isProfileInfoVisible(2)">
                                 <div class="text-muted text-mutedSize">Téléphone</div>
                                 <div class="alignmentRight">&nbsp;&nbsp;<a
                                       href="tel:{{profile.phone2}}">{{getPhoneFormat(profile.phone2)}}</a></div>
                              </div>
                              <div class="flexStart" *ngIf="profile.jacketSize != null && profile.jacketSize != ''">
                                 <div class="text-muted text-mutedSize">Taille maillot</div>
                                 <div class="alignmentRight" *ngIf="profile.jacketSize == ''">&nbsp;&nbsp;Non renseigné
                                 </div>
                                 <div class="alignmentRight" *ngIf="profile.jacketSize != ''">
                                    &nbsp;&nbsp;{{profile.jacketSize}}</div>
                              </div>
                              <div class="flexStart" *ngIf="profile.shortsSize != null && profile.shortsSize != ''">
                                 <div class="text-muted text-mutedSize">Taille short</div>
                                 <div class="alignmentRight" *ngIf="profile.shortsSize == ''">&nbsp;&nbsp;Non renseigné
                                 </div>
                                 <div class="alignmentRight" *ngIf="profile.shortsSize != ''">
                                    &nbsp;&nbsp;{{profile.shortsSize}}</div>
                              </div>
                              <div class="flexStart" *ngIf="profile.birthdayDate != null && isProfileInfoVisible(4)">
                                 <div class="text-muted text-mutedSize">Anniversaire</div>
                                 <div class="alignmentRight">&nbsp;&nbsp;{{profile.birthdayDate | date:'dd/MM/yyyy'}}
                                 </div>
                              </div>
                              <div class="flexStart"
                                 *ngIf="profile.username != '' && (isUserProfile() && !isSupervisorConnected)">
                                 <div class="text-muted text-mutedSize">Nom d'utilisateur</div>
                                 <div class="alignmentRight">&nbsp;&nbsp;{{profile.username}}</div>
                              </div>
                           </div>

                           <div class="card-block" *ngIf="isProfileOfUserForUpdate()">
                              <h5 class="m-b-20 p-b-5 b-b-default f-w-600">
                                 <div class="flexStart" *ngIf="profile.username != ''">
                                    Gestion de vos données
                                    <div class="alignmentRight">&nbsp;&nbsp;
                                       <app-button icon="save" [class]="'spanButtonSeparator'" [text]="'Sauver'"
                                          [disabled]="!isDataModified" [isBackgroundOpaque]="true"
                                          (onClick)="onSaveProfile()"></app-button>
                                    </div>
                                 </div>
                              </h5>
                              <div class="flexStart">
                                 <div class="text-muted text-mutedSize">Votre photo</div>
                                 <div class="alignmentRight">
                                    <app-button icon="photo" [class]="'spanButtonSeparator'" [isBackgroundOpaque]="true"
                                       (onClick)="onClickUpdatePicture('memberPicture');"></app-button>
                                    <app-button icon="delete" [class]="'spanButtonSeparator'"
                                       [disabled]="isButtonPictureDeleteDisabled()" [isBackgroundOpaque]="true"
                                       (onClick)="onDeletePictureProfile()"></app-button>
                                    <input matInput formControlName="memberPicture" hidden="true" />
                                 </div>
                              </div>
                           </div>

                           <div class="card-block" *ngIf="isSupervisorConnected || isUserProfile()">
                              <h5 class="m-b-20 p-b-5 b-b-default f-w-600">
                                 <div class="flexStart" *ngIf="profile.username != ''">
                                    <div [class]="getNotificationClass(0, profile.isNotificationByEmailActivate)">
                                       <app-tooltip [isTooltip]="true" title="Notifications par email" message="Activer ou désactiver toutes les notifications par email.<br><br>Le système ne tiendra pas compte des autres options. Ces options vous permettrons d'être tenu au courant par mail des événements comme les rencontres de votre équipe, 
                                 les disponibilités de votre équipe et les réunions de comité (si vous en faite partie).<br><br> 
                                 Vous pouvez activer ou désactiver chaque option séparément."></app-tooltip>
                                       Notifications par email
                                    </div>
                                    <div class="alignmentRight">&nbsp;&nbsp;
                                       <app-button icon="visibility_off" [class]="'spanButtonSeparator buttonNo'"
                                          [text]="'Masquer'" [isBackgroundOpaque]="true"
                                          (onClick)="updateNotificationVisibility(0, 0)"
                                          [visible]="isButtonNotificationVisible(profile.isNotificationByEmailActivate, false)"></app-button>
                                       <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                          [text]="'Activer'" [isBackgroundOpaque]="true"
                                          (onClick)="updateNotificationVisibility(0, 1)"
                                          [visible]="isButtonNotificationVisible(profile.isNotificationByEmailActivate, true)"></app-button>
                                    </div>
                                 </div>
                              </h5>

                              <div class="br0" *ngIf="profile.username != '' && profile.isNotificationByEmailActivate">
                              </div>
                              <div class="flexStart"
                                 *ngIf="profile.username != '' && profile.isNotificationByEmailActivate">
                                 <div [class]="getNotificationClass(1, profile.isNotificationMatchByEmailActivate)">
                                    <app-tooltip [isTooltip]="true" title=""
                                       message="Activer ou désactiver les notifications au niveau des changements des rencontres de son équipe (rencontres officielles, recontres amicales, tournois, entrainements)"></app-tooltip>
                                    Recontres de son équipe (matchs, tournois)
                                 </div>
                                 <div class="alignmentRight">&nbsp;&nbsp;
                                    <app-button icon="visibility_off" [class]="'spanButtonSeparator buttonNo'"
                                       [text]="'Masquer'" [isBackgroundOpaque]="true"
                                       (onClick)="updateNotificationVisibility(1, 0)"
                                       [visible]="isButtonNotificationVisible(profile.isNotificationMatchByEmailActivate, false)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Activer'" [isBackgroundOpaque]="true"
                                       (onClick)="updateNotificationVisibility(1, 1)"
                                       [visible]="isButtonNotificationVisible(profile.isNotificationMatchByEmailActivate, true)"></app-button>
                                 </div>
                              </div>

                              <div class="br0"
                                 *ngIf="profile.username != '' && profile.isNotificationByEmailActivate && (isSupervisorConnected || isComiteeAuthenticated())">
                              </div>
                              <div class="flexStart"
                                 *ngIf="profile.username != '' && profile.isNotificationByEmailActivate && (isSupervisorConnected || isComiteeAuthenticated())">
                                 <div
                                    [class]="getNotificationClass(1, profile.isNotificationActivityReportByEmailActivate)">
                                    <app-tooltip [isTooltip]="true" title=""
                                       message="Activer ou désactiver l'envoi par mail du rapport de partiicpation aux soupers. (Uniquement pour administrateur et comité)"></app-tooltip>
                                    Rapport de participation aux soupers
                                 </div>
                                 <div class="alignmentRight">&nbsp;&nbsp;
                                    <app-button icon="visibility_off" [class]="'spanButtonSeparator buttonNo'"
                                       [text]="'Masquer'" [isBackgroundOpaque]="true"
                                       (onClick)="updateNotificationVisibility(7, 0)"
                                       [visible]="isButtonNotificationVisible(profile.isNotificationActivityReportByEmailActivate, false)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Activer'" [isBackgroundOpaque]="true"
                                       (onClick)="updateNotificationVisibility(7, 1)"
                                       [visible]="isButtonNotificationVisible(profile.isNotificationActivityReportByEmailActivate, true)"></app-button>
                                 </div>
                              </div>

                              <div class="br0" *ngIf="profile.username != '' && profile.isNotificationByEmailActivate">
                              </div>
                              <div class="flexStart"
                                 *ngIf="profile.username != '' && profile.isNotificationByEmailActivate">
                                 <div
                                    [class]="getNotificationClass(1, profile.isNotificationMatchOfClubByEmailActivate)">
                                    <app-tooltip [isTooltip]="true" title=""
                                       message="Activer ou désactiver les notifications au niveau des changements des rencontres pour toutes les autres équipes (rencontres officielles, recontres amicales, tournois, entrainements)"></app-tooltip>
                                    Recontres des autres équipes (matchs, tournois)
                                 </div>
                                 <div class="alignmentRight">&nbsp;&nbsp;
                                    <app-button icon="visibility_off" [class]="'spanButtonSeparator buttonNo'"
                                       [text]="'Masquer'" [isBackgroundOpaque]="true"
                                       (onClick)="updateNotificationVisibility(5, 0)"
                                       [visible]="isButtonNotificationVisible(profile.isNotificationMatchOfClubByEmailActivate, false)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Activer'" [isBackgroundOpaque]="true"
                                       (onClick)="updateNotificationVisibility(5, 1)"
                                       [visible]="isButtonNotificationVisible(profile.isNotificationMatchOfClubByEmailActivate, true)"></app-button>
                                 </div>
                              </div>
                              <div class="br0"
                                 *ngIf="profile.username != '' && profile.isNotificationByEmailActivate && (isSupervisorConnected || isComiteeAuthenticated())">
                              </div>
                              <div class="flexStart"
                                 *ngIf="profile.username != '' && profile.isNotificationByEmailActivate && (isSupervisorConnected || isComiteeAuthenticated())">
                                 <div
                                    [class]="getNotificationClass(2, profile.isNotificationComiteeMeetingByEmailActivate)">
                                    <app-tooltip [isTooltip]="true" title=""
                                       message="Activer ou désactiver les notifications au niveau des réunions de comité. Nouvelles, supprimées, annulées. (Uniquement pour administrateur et comité)"></app-tooltip>
                                    Réunions de comité
                                 </div>
                                 <!-- TODO uniquement pour membre de comité -->
                                 <div class="alignmentRight">&nbsp;&nbsp;
                                    <app-button icon="visibility_off" [class]="'spanButtonSeparator buttonNo'"
                                       [text]="'Masquer'" [isBackgroundOpaque]="true"
                                       (onClick)="updateNotificationVisibility(2, 0)"
                                       [visible]="isButtonNotificationVisible(profile.isNotificationComiteeMeetingByEmailActivate, false)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Activer'" [isBackgroundOpaque]="true"
                                       (onClick)="updateNotificationVisibility(2, 1)"
                                       [visible]="isButtonNotificationVisible(profile.isNotificationComiteeMeetingByEmailActivate, true)"></app-button>
                                 </div>
                              </div>
                              <div class="br0" *ngIf="profile.username != '' && profile.isNotificationByEmailActivate">
                              </div>
                              <div class="flexStart"
                                 *ngIf="profile.username != '' && profile.isNotificationByEmailActivate">
                                 <div
                                    [class]="getNotificationClass(3, profile.isNotificationAvailabilityByEmailActivate)">
                                    <app-tooltip [isTooltip]="true" title=""
                                       message="Activer ou désactiver les notifications au niveau des disponibilités de son équipe (présences, absences, de garde, ...)"></app-tooltip>
                                    Disponibilités (présences)
                                 </div>
                                 <div class="alignmentRight">&nbsp;&nbsp;
                                    <app-button icon="visibility_off" [class]="'spanButtonSeparator buttonNo'"
                                       [text]="'Masquer'" [isBackgroundOpaque]="true"
                                       (onClick)="updateNotificationVisibility(3, 0)"
                                       [visible]="isButtonNotificationVisible(profile.isNotificationAvailabilityByEmailActivate, false)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Activer'" [isBackgroundOpaque]="true"
                                       (onClick)="updateNotificationVisibility(3, 1)"
                                       [visible]="isButtonNotificationVisible(profile.isNotificationAvailabilityByEmailActivate, true)"></app-button>
                                 </div>
                              </div>
                              <div class="br0" *ngIf="profile.username != '' && profile.isNotificationByEmailActivate">
                              </div>
                              <div class="flexStart"
                                 *ngIf="profile.username != '' && profile.isNotificationByEmailActivate">
                                 <div
                                    [class]="getNotificationClass(4, profile.isNotificationAvailabilityReportByEmailActivate)">
                                    <app-tooltip [isTooltip]="true" title=""
                                       message="Activer ou désactiver les notifications au niveau des rapport de disponibilité d'équipe pour le coach (présences, absences, de garde pour les rencontres officielles, recontres amicales, tournois, entrainements)"></app-tooltip>
                                    Rapports de disponibilités (présences)
                                 </div>
                                 <div class="alignmentRight">&nbsp;&nbsp;
                                    <app-button icon="visibility_off" [class]="'spanButtonSeparator buttonNo'"
                                       [text]="'Masquer'" [isBackgroundOpaque]="true"
                                       (onClick)="updateNotificationVisibility(4, 0)"
                                       [visible]="isButtonNotificationVisible(profile.isNotificationAvailabilityReportByEmailActivate, false)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Activer'" [isBackgroundOpaque]="true"
                                       (onClick)="updateNotificationVisibility(4, 1)"
                                       [visible]="isButtonNotificationVisible(profile.isNotificationAvailabilityReportByEmailActivate, true)"></app-button>
                                 </div>
                              </div>
                              <div class="br0"
                                 *ngIf="profile.username != '' && profile.isNotificationByEmailActivate && (isSupervisorConnected || isPresidentAuthenticated())">
                              </div>
                              <div class="flexStart"
                                 *ngIf="profile.username != '' && profile.isNotificationByEmailActivate && (isSupervisorConnected || isPresidentAuthenticated())">
                                 <div
                                    [class]="getNotificationClass(5, !!profile.isNotificationAvailabilityForTeamByEmailActivate)">
                                    <app-tooltip [isTooltip]="true" title=""
                                       message="Activer ou désactiver pour les équipes données, les notifications au niveau des rapport de disponibilité pour le président (présences, absences, de garde pour les rencontres officielles, recontres amicales, tournois, entrainements)"></app-tooltip>
                                    Disponibilités (présences) envoyées au président
                                 </div>
                              </div>
                              <div
                                 *ngIf="profile.username != '' && profile.isNotificationByEmailActivate && (isSupervisorConnected || isPresidentAuthenticated())">
                                 <mat-button-toggle-group class="buttonToggle" #toggleBtn="matButtonToggleGroup"
                                    multiple [(value)]="selectedTeams" (change)="onTeamsChecked($event)">
                                    <mat-button-toggle [value]="team" (change)="onTeamClick()"
                                       *ngFor="let team of allTeamsWithoutPicture">{{team.teamCategory}}
                                    </mat-button-toggle>
                                 </mat-button-toggle-group>
                              </div>
                           </div>

                           <div class="card-block" *ngIf="isSupervisorConnected || isUserProfile()">
                              <h5 class="m-b-20 p-b-5 b-b-default f-w-600">
                                 <div class="flexStart" *ngIf="profile.username != ''">
                                    <div [class]="getNotificationClass(6, profile.isNotificationByPushActivate)">
                                       <app-tooltip [isTooltip]="true" title="Notifications par alerte Web push"
                                          message="Activer ou désactiver les notifications qui apparaitront dans le système de notification de l'appareil sur lequel l'application est exécutée."></app-tooltip>
                                       Notifications par alerte "Web push"
                                    </div>
                                    <div class="alignmentRight">&nbsp;&nbsp;
                                       <app-button icon="visibility_off" [class]="'spanButtonSeparator buttonNo'"
                                          [text]="'Masquer'" [isBackgroundOpaque]="true"
                                          (onClick)="updateNotificationVisibility(6, 0)"
                                          [visible]="isButtonNotificationVisible(profile.isNotificationByPushActivate, false)"></app-button>
                                       <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                          [text]="'Activer'" [isBackgroundOpaque]="true"
                                          (onClick)="updateNotificationVisibility(6, 1)"
                                          [visible]="isButtonNotificationVisible(profile.isNotificationByPushActivate, true)"></app-button>
                                    </div>
                                 </div>
                              </h5>

                              <div class="br0" *ngIf="profile.isNotificationByPushActivate">
                              </div>
                              <div class="flexStart" *ngIf="profile.isNotificationByPushActivate">
                                 <div [class]="getNotificationClass(1, profile.isNotificationMatchByEmailActivate)">
                                    <app-tooltip [isTooltip]="true" title="Autorisation web push"
                                       message="Affiche la fenêtre d'autorisation des notifications web push"></app-tooltip>
                                    Requête d'autorisation
                                 </div>
                                 <div class="br0"
                                    *ngIf="profile.isNotificationByPushActivate && (isSupervisorConnected || isPresidentAuthenticated())">
                                 </div>
                                 <div class="alignmentRight">&nbsp;&nbsp;
                                    <app-button icon="notification_add" [class]="'spanButtonSeparator'"
                                       [text]="'Soumettre'" [isBackgroundOpaque]="true"
                                       (onClick)="acceptWebPush()"></app-button>
                                 </div>
                              </div>
                              <div class="br0" *ngIf="profile.isNotificationByPushActivate">
                              </div>
                              <div class="flexStart" *ngIf="profile.isNotificationByPushActivate">
                                 <div [class]="getNotificationClass(1, profile.isNotificationMatchByEmailActivate)">
                                    <app-tooltip [isTooltip]="true" title="Souscription web push"
                                       message="Souscrire aux notifications web push"></app-tooltip>
                                    Souscription
                                 </div>
                                 <div class="alignmentRight">&nbsp;&nbsp;
                                    <app-button icon="subscriptions" [class]="'spanButtonSeparator'"
                                       [text]="'S\'abonner'" [isBackgroundOpaque]="true"
                                       (onClick)="subscribeWebPush()"></app-button>
                                    <app-button icon="unsubscribe" [class]="'spanButtonSeparator'" [text]="'Désabonner'"
                                       [isBackgroundOpaque]="true" (onClick)="unsubscribeWebPush()"></app-button>
                                 </div>
                              </div>
                              <div class="br0" *ngIf="profile.isNotificationByPushActivate">
                              </div>
                              <div class="flexStart" *ngIf="profile.isNotificationByPushActivate">
                                 <div [class]="getNotificationClass(1, profile.isNotificationMatchByEmailActivate)">
                                    <app-tooltip [isTooltip]="true" title="Message"
                                       message="Envoi d'un message de test"></app-tooltip>
                                    Message
                                 </div>
                                 <div class="alignmentRight">&nbsp;&nbsp;
                                    <app-button icon="send_to_mobile" [class]="'spanButtonSeparator'" [text]="'Envoyer'"
                                       [isBackgroundOpaque]="true" (onClick)="SendMessageWebPush()"></app-button>
                                 </div>
                              </div>
                              <div class="br0" *ngIf="profile.isNotificationByPushActivate">
                              </div>
                              <div class="flexStart">
                                 <div class="text-muted text-mutedSize">Catégories Web Push</div>
                              </div>
                              <div class="flexStart">
                                 <mat-button-toggle-group class="buttonToggle" #toggleBtn="matButtonToggleGroup"
                                    multiple [(value)]="selectedWebPushCategory"
                                    (change)="onWebPushCategoryChecked($event)">
                                    <mat-button-toggle [value]="category"
                                       *ngFor="let category of getAllWebPushCategory()">{{category}}
                                    </mat-button-toggle>
                                 </mat-button-toggle-group>
                              </div>
                           </div>

                           <div class="card-block" *ngIf="isSupervisorConnected || isUserProfile()">
                              <h5 class="m-b-20 p-b-5 b-b-default f-w-600">
                                 <app-tooltip [isTooltip]="true" title=""
                                    message='Vous pouvez rendre visible ou non individuellement certaines données personnelles en utilisant les boutons de la façon suivante.<br>
                                    1. "Equipe" : visible uniquement pour son équipe.<br>
                                    2. "Club" : visible pour tout les membres du club.<br>
                                    3. "Masquer" : masquer pour tout les membres du club (y compris son équipe).<br>
                                    Ces informations sont indisponibles pour les personnes non authentifiées (publique)'></app-tooltip>
                                 Confidentialité
                              </h5>
                              <div class="flexStart" *ngIf="profile.username != ''">
                                 <div [class]="getPrivacyClass(profile.emailPrivacyLevel)">
                                    "Email" {{getPrivacyVisiblity(profile.emailPrivacyLevel)}}</div>
                                 <div class="alignmentRight">
                                    <app-button icon="visibility_off" [class]="'spanButtonSeparator buttonNo'"
                                       [text]="'Masquer'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(EnumPrivacyLevel.None, EnumPrivacyLevel.None)"
                                       [visible]="isButtonPrivacyVisible(profile.emailPrivacyLevel, EnumPrivacyLevel.None)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Equipe'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(EnumPrivacyLevel.None, EnumPrivacyLevel.Team)"
                                       [visible]="isButtonPrivacyVisible(profile.emailPrivacyLevel, EnumPrivacyLevel.Team)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Club'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(EnumPrivacyLevel.None, EnumPrivacyLevel.Club)"
                                       [visible]="isButtonPrivacyVisible(profile.emailPrivacyLevel, EnumPrivacyLevel.Club)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Public'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(EnumPrivacyLevel.None, EnumPrivacyLevel.Public)"
                                       [visible]="isButtonPrivacyVisible(profile.emailPrivacyLevel, EnumPrivacyLevel.Public)"></app-button>
                                 </div>
                              </div>
                              <div class="br0"></div>
                              <div class="flexStart" *ngIf="profile.username != ''">
                                 <div [class]="getPrivacyClass(profile.phone1PrivacyLevel)">
                                    "Téléphone (1)" {{getPrivacyVisiblity(profile.phone1PrivacyLevel)}}</div>
                                 <div class="alignmentRight">
                                    <app-button icon="visibility_off" [class]="'spanButtonSeparator buttonNo'"
                                       [text]="'Masquer'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(1, EnumPrivacyLevel.None)"
                                       [visible]="isButtonPrivacyVisible(profile.phone1PrivacyLevel, EnumPrivacyLevel.None)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Equipe'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(1, EnumPrivacyLevel.Team)"
                                       [visible]="isButtonPrivacyVisible(profile.phone1PrivacyLevel, EnumPrivacyLevel.Team)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Club'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(1, EnumPrivacyLevel.Club)"
                                       [visible]="isButtonPrivacyVisible(profile.phone1PrivacyLevel, EnumPrivacyLevel.Club)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Public'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(1, EnumPrivacyLevel.Public)"
                                       [visible]="isButtonPrivacyVisible(profile.phone1PrivacyLevel, EnumPrivacyLevel.Public)"></app-button>
                                 </div>
                              </div>
                              <div class="br0"></div>
                              <div class="flexStart" *ngIf="profile.username != ''">
                                 <div [class]="getPrivacyClass(profile.phone2PrivacyLevel)">
                                    "Téléphone (2)" {{getPrivacyVisiblity(profile.phone2PrivacyLevel)}}</div>
                                 <div class="alignmentRight">
                                    <app-button icon="visibility_off" [class]="'spanButtonSeparator buttonNo'"
                                       [text]="'Masquer'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(2, EnumPrivacyLevel.None)"
                                       [visible]="isButtonPrivacyVisible(profile.phone2PrivacyLevel, EnumPrivacyLevel.None)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Equipe'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(2, EnumPrivacyLevel.Team)"
                                       [visible]="isButtonPrivacyVisible(profile.phone2PrivacyLevel, EnumPrivacyLevel.Team)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Club'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(2, EnumPrivacyLevel.Club)"
                                       [visible]="isButtonPrivacyVisible(profile.phone2PrivacyLevel, EnumPrivacyLevel.Club)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Public'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(2, EnumPrivacyLevel.Public)"
                                       [visible]="isButtonPrivacyVisible(profile.phone2PrivacyLevel, EnumPrivacyLevel.Public)"></app-button>
                                 </div>
                              </div>
                              <div class="br0"></div>
                              <div class="flexStart" *ngIf="profile.username != ''">
                                 <div [class]="getPrivacyClass(profile.addressPrivacyLevel)">
                                    "Adresse" {{getPrivacyVisiblity(profile.addressPrivacyLevel)}}</div>
                                 <div class="alignmentRight">
                                    <app-button icon="visibility_off" [class]="'spanButtonSeparator buttonNo'"
                                       [text]="'Masquer'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(3, EnumPrivacyLevel.None)"
                                       [visible]="isButtonPrivacyVisible(profile.addressPrivacyLevel, EnumPrivacyLevel.None)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Equipe'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(3, EnumPrivacyLevel.Team)"
                                       [visible]="isButtonPrivacyVisible(profile.addressPrivacyLevel, EnumPrivacyLevel.Team)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Club'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(3, EnumPrivacyLevel.Club)"
                                       [visible]="isButtonPrivacyVisible(profile.addressPrivacyLevel, EnumPrivacyLevel.Club)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Public'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(3, EnumPrivacyLevel.Public)"
                                       [visible]="isButtonPrivacyVisible(profile.addressPrivacyLevel, EnumPrivacyLevel.Public)"></app-button>
                                 </div>
                              </div>
                              <div class="br0"></div>
                              <div class="flexStart" *ngIf="profile.username != ''">
                                 <div [class]="getPrivacyClass(profile.birthdayDatePrivacyLevel)">
                                    "Anniversaire" {{getPrivacyVisiblity(profile.birthdayDatePrivacyLevel)}}</div>
                                 <div class="alignmentRight">
                                    <app-button icon="visibility_off" [class]="'spanButtonSeparator buttonNo'"
                                       [text]="'Masquer'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(4, EnumPrivacyLevel.None)"
                                       [visible]="isButtonPrivacyVisible(profile.birthdayDatePrivacyLevel, EnumPrivacyLevel.None)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Equipe'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(4, EnumPrivacyLevel.Team)"
                                       [visible]="isButtonPrivacyVisible(profile.birthdayDatePrivacyLevel, EnumPrivacyLevel.Team)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Club'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(4, EnumPrivacyLevel.Club)"
                                       [visible]="isButtonPrivacyVisible(profile.birthdayDatePrivacyLevel, EnumPrivacyLevel.Club)"></app-button>
                                    <app-button icon="visibility" [class]="'spanButtonSeparator buttonYes'"
                                       [text]="'Public'" [isBackgroundOpaque]="true"
                                       (onClick)="updatePrivacyVisibility(4, EnumPrivacyLevel.Public)"
                                       [visible]="isButtonPrivacyVisible(profile.birthdayDatePrivacyLevel, EnumPrivacyLevel.Public)"></app-button>
                                 </div>
                              </div>
                              <!-- TODO add pictureDatePrivacyLevel -->
                           </div>

                           <div class="card-block" *ngIf="isSupervisorConnected">
                              <h5 class="m-b-20 p-b-5 b-b-default f-w-600">Information technique</h5>

                              <div class="flexStart">
                                 <div class="text-muted text-mutedSize">Simuler ce profil</div>
                                 <div class="alignmentRight">&nbsp;&nbsp;
                                    <app-button [icon]="'manage_accounts'" [class]="'spanButtonSeparator'"
                                       [disabled]="isButtonLockUserDisabled" [isBackgroundOpaque]="true"
                                       (onClick)="simulateProfile(profile);"></app-button>
                                 </div>
                              </div>
                              <div class="flexStart" *ngIf="profile.username != ''">
                                 <div class="text-muted text-mutedSize">Nom d'utilisateur</div>
                                 <div class="alignmentRight">&nbsp;&nbsp;{{profile.username}}</div>
                              </div>
                              <div class="flexStart" *ngIf="profile.workgroup?.name != ''">
                                 <div class="text-muted text-mutedSize">Groupe de travail</div>
                                 <div class="alignmentRight">&nbsp;&nbsp;{{'Dropdown' + profile.workgroup?.name |
                                    translate}}</div>
                              </div>
                              <div class="flexStart" *ngIf="profile.isNotificationByEmailActivate">
                                 <div class="text-muted text-mutedSize">Notification</div>
                                 <div class="alignmentRight">&nbsp;&nbsp;Activée</div>
                              </div>
                              <div class="flexStart" *ngIf="!profile.isNotificationByEmailActivate">
                                 <div class="text-muted text-mutedSize">Notification</div>
                                 <div class="alignmentRight">&nbsp;&nbsp;Non activée</div>
                              </div>
                              <div class="flexStart" *ngIf="isSupervisor()">
                                 <div class="text-muted text-mutedSize">Total membres</div>
                                 <div class="alignmentRight">&nbsp;&nbsp;{{getUserCountTitle()}}</div>
                              </div>
                              <div class="flexStart" *ngIf="isSupervisor()">
                                 <div class="text-muted text-mutedSize">Web push catégories</div>
                                 <div class="alignmentRight">
                                    &nbsp;&nbsp;{{profile.subscriptionCategories}}
                                 </div>
                              </div>
                              <div class="flexStart" *ngIf="isSupervisor()">
                                 <div class="text-muted text-mutedSize">Web push subscription</div>
                                 <div class="alignmentRight" *ngIf="profile.subscriptionEndpoint == ''">
                                    &nbsp;&nbsp;Inactive
                                 </div>
                                 <div class="alignmentRight" *ngIf="profile.subscriptionEndpoint != ''">
                                    &nbsp;&nbsp;Activée
                                 </div>
                              </div>
                           </div>

                           <div class="card-block" *ngIf="isSupervisor()">
                              <h5 class="m-b-20 p-b-5 b-b-default f-w-600">Opérations administratives</h5>

                              <div class="flexStart">
                                 <div class="text-muted text-mutedSize" *ngIf="!profile.isUserLoginInactive">Désactiver
                                    le membre</div>
                                 <div class="text-muted text-mutedSize" *ngIf="profile.isUserLoginInactive">Activer le
                                    membre</div>
                                 <div class="alignmentRight">&nbsp;&nbsp;
                                    <app-button [icon]="getIsUserLoginInactiveClass()" [class]="'spanButtonSeparator'"
                                       [disabled]="isButtonLockUserDisabled" [isBackgroundOpaque]="true"
                                       (onClick)="lockOrUnlockUser(profile);"></app-button>
                                 </div>
                              </div>
                              <div class="br1"></div>
                              <div class="flexStart">
                                 <div class="text-muted text-mutedSize" *ngIf="profile.sysIsActive">Désaffilier le
                                    membre</div>
                                 <div class="text-muted text-mutedSize" *ngIf="!profile.sysIsActive">Affilier le membre
                                 </div>
                                 <div class="alignmentRight">&nbsp;&nbsp;
                                    <app-button [icon]="getIsUserInactiveClass()" [class]="'spanButtonSeparator'"
                                       [disabled]="isButtonLockUserDisabled" [isBackgroundOpaque]="true"
                                       (onClick)="enablekOrDisableUser(profile);"></app-button>
                                 </div>
                              </div>
                              <div class="br1"></div>

                              <div class="flexStart">
                                 <div class="text-muted text-mutedSize">Réinitialisation du nombre d'essais du login
                                 </div>
                                 <div class="alignmentRight">&nbsp;&nbsp;
                                    <app-button icon="restart_alt" [class]="'spanButtonSeparator'"
                                       [disabled]="isButtonResetTryPasswordDisabled" [isBackgroundOpaque]="true"
                                       (onClick)="resetTryPasswordUser(profile);"></app-button>
                                 </div>
                              </div>

                              <div class="br1"></div>
                              <div class="flexStart">
                                 <div class="text-muted text-mutedSize">Equipe</div>
                                 <div class="alignmentRight">
                                    <mat-select class="teamSelect" formControlName="allTeam"
                                       (selectionChange)="onTeamChange()" style="width:200px" placeholder="Choisir ...">
                                       <mat-option *ngFor="let item of allTeams" [value]="item">
                                          <ng-container>{{item.teamCategory}}</ng-container>
                                       </mat-option>
                                    </mat-select>
                                 </div>
                              </div>

                              <div class="flexStart">
                                 <div class="text-muted text-mutedSize">Rôles</div>
                                 <div class="alignmentRight">
                                    <mat-button-toggle-group class="buttonToggle" #toggleBtn="matButtonToggleGroup"
                                       vertical multiple [(value)]="selectedRoles" (change)="onRolesChecked($event)">
                                       <mat-button-toggle [disabled]="getMatButtonToggleClass(role)" [value]="role"
                                          (change)="onRoleClick(profile, role)"
                                          *ngFor="let role of getAllRolesOfMembers()">{{role}}
                                       </mat-button-toggle>
                                    </mat-button-toggle-group>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </mat-card-content>
            </mat-card>
         </mat-card-content>
      </mat-card>
   </div>
</form>