<mat-card class="popupDimensionDataPrivacyDialog">
   <mat-card-header class="mat-card-header-color">
      <mat-card-title>{{title}}
      </mat-card-title>
   </mat-card-header>
   <mat-card-content class="mat-card-content-padding">
      <!-- <div mat-dialog-content [innerHTML]="dataPrivacy"></div> -->
      <div mat-dialog-content>
         <h2><b>Nous utilisons des Cookies afin que vous puissiez utiliser plus rapidement 
            et efficacement notre site.</b></h2>
         <div class="br1"></div>
         Pour vous offrir la meilleure expérience de navigation possible, 
         nous utilisons des cookies et d'autres technologies (que nous désignons sous le terme générique de : « cookies ») 
         dans le but de stocker des informations sur les appareils et des données à caractère personnel (par ex. votre nom d'utilisateur) 
         et d'y accéder.
         <div class="br1"></div>
         Ils sont indispensables au fonctionnement du site et ne sont, ni utilisés à des fins de ciblage publicitaire, 
         ni à des fins de commercialisation.
         <div class="br1"></div>
         Veuillez cliquer sur le bouton en bas à droite "Accepter" pour donner votre accord à la mise en oeuvre de cookies soumis 
         à consentement et au traitement des données à caractère personnel y afférent ou sur le bouton en bas à gauche.
         <div class="br1"></div> 
         Si vous souhaitez avoir plus de renseignements à propos des cookies utilisés ou signaler un problème, vous pouvez toujours envoyer un mail à l'administrateur du site : <a
         href="mailto:support@vabourlettis.be">support@vabourlettis.be</a>.
         <div class="br1"></div>
         Il vous est toujours possible de changer, par la suite, votre consentement en cliquant tout en bas du site sur le lien "Protection de vos données".
         <div class="br1"></div>
         Si vous ne voulez pas donner votre consentement, veuillez cliquer sur le bouton en bas à gauche "Refuser".
      </div>
      <!-- <div *ngIf="!isNetworkStatusOnline">(Connexion à internet requise)</div> -->
      <div class="br1"></div>
      <div *ngIf="isAccepted && !isAcceptedIsnull" class="accepted">Vous avez accepté l'utilisation des cookies.</div>
      <div *ngIf="!isAccepted && !isAcceptedIsnull" class="refused">Vous avez refusé l'utilisation des cookies.</div>
      <div class="br1"></div>
      <div mat-dialog-actions class="mat-dialogActions">
         <div>
            <app-button icon="clear" [class]="'spanButtonSeparator buttonNo'" [text]="'Refuser'" [isBackgroundOpaque]="true" (onClick)="onDismiss()"></app-button>
         </div>
         <div>
            <app-button icon="done" [class]="'spanButtonSeparator buttonYes'" [text]="'Accepter'" [isBackgroundOpaque]="true" (onClick)="onConfirm()"></app-button>
         </div>
      </div>
   </mat-card-content>
</mat-card>