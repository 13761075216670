import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { enumDatagridHeaderOrder, enumQueryFilterOp } from 'src/sites/core/enum/Enum';
import { datagridParam } from 'src/sites/core/models/system/datagridParam';
import { DatagridService } from 'src/sites/core/services/datagrid.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { homeNews } from '../../models/homeNews';
import { queryFilter } from '../../models/queryFilter';
import { CacheService } from '../../services/cache.service';
import { HomeNewsService } from '../../services/homeNews.service';
import { encryptedValue } from '../../authentication/encryptedValue';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-adminHomeNews-list',
   templateUrl: './adminHomeNews-list.component.html',
   styleUrls: ['./adminHomeNews-list.component.scss']
})
export class AdminHomeNewsListComponent implements OnInit {
  public championshipNameDisplay: string = "";
  public datagridParam: datagridParam<homeNews>;
  public importDataDescriptionDisplay: string = "";
  public importDataTitleDisplay: string = "";
  public isLoading: boolean = false;
  public queryParams: any;
  public subjectCacheIsLoaded: number = null;

  constructor(
      private activeRouter: ActivatedRoute,
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private datagridService: DatagridService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      private homeNewsService: HomeNewsService,
      private encryptionService: EncryptionService) {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.subscribe(value => {
      }
      );
   }

  public getDatagridFilters($event) {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.next(true);
   }

  public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

  public ngOnInit() {
      //Souscription à l'event lorsque la cache globale a terminé son chargement
      this.subjectService.IsGlobalCacheLoadedSubject.subscribe(value => { this.subjectCacheIsLoaded = value; if (value == 2) this.initialize(); });
   }

  public refresh() {
      this.isLoading = false;
      let sysId = -1;
    
      this.activeRouter.queryParams.subscribe(params => {
         this.queryParams = params;

         if (this.queryParams["sysId"] != undefined) {
            sysId = this.queryParams["sysId"];
         }
      });

      const queryFilters = [];

      if (sysId != -1)
         queryFilters.push(new queryFilter({ propertyName: "sysId", operation: enumQueryFilterOp.Equals, value: sysId }));
      else
         queryFilters.push(new queryFilter({ propertyName: "championshipSysId", operation: enumQueryFilterOp.Equals, value: this.cacheService.getCurrentChampionship().sysId }));
      
      this.homeNewsService.getAllWithIncluding(queryFilters).subscribe(
         (data: encryptedValue) => {
            this.datagridParam = new datagridParam(
               {
                  hidden: !this.isLoading,
                  dataSource: this.encryptionService.decryptObject(false, data.value),
                  filters: null, //filters
                  mainTitle: "AdminHomeNewsListTitle",
                  headerOrder: enumDatagridHeaderOrder.ApiByDefault,
                  entity: new homeNews(),
                  urlApi: "C1013",
                  paginatorItemsCount: this.frameworkService.getPaginatorItemsCount(),
                  formTitle: "HomenewsTitle"
               });
            this.isLoading = true;
         }
      );
   }

  public translatePage() {
      //Championship
      this.translateService.get(['Championship'])
         .subscribe(translations => {
            this.championshipNameDisplay = translations['Championship'];
         });
   }
}
