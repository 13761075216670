import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Md5 } from 'ts-md5';
import { strings } from '../../models/strings';
import { CacheService } from '../../services/cache.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-adminDevelopment',
   templateUrl: './adminDevelopment.component.html',
   styleUrls: ['./adminDevelopment.component.scss']
})
export class AdminDevelopmentComponent implements OnInit {
  public allTodos: strings[] = [];
  public subjectCacheIsLoaded: number = null;
  public titleDescriptionDisplay: string = "";

  constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private translateService: TranslateService,
   ) {
   }

  public getPasswordMD5() {
      const md5 = new Md5();
      md5.appendStr("VAnderluesB0urlettis.be")
      return md5.end().toString();
   }

  public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });
      
      this.allTodos = this.cacheService.getAllTodos().map((x) => {
         return x;
       });

      this.refresh();
   }

   
  public isSeparator(todo: strings) {
   return todo.value.startsWith("#");
}

public isSeparatorLi(todo: strings) {
   return todo.value.startsWith("*");
}

  public ngOnInit() {
      //Souscription à l'event lorsque la cache globale a terminé son chargement
      this.subjectService.IsGlobalCacheLoadedSubject.subscribe(value => { this.subjectCacheIsLoaded = value; if (value == 2) this.initialize(); });
   }

  public onFilterChange(): void {
      this.refresh();
   }

  public refresh() {
   }

  public translatePage() {
   }
}
