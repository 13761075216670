import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { enumHRefType } from 'src/sites/core/enum/Enum';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-buttonhref',
   templateUrl: './buttonHRef.component.html',
   styleUrls: ['./buttonHRef.component.scss']
})
export class ButtonHRefComponent implements OnInit, OnChanges {
   @Input() icon: string;
   @Input() visible: boolean = true;
   @Input() routerLink: string;
   @Input() href: string;
   @Input() disabled: boolean = false;
   @Input() class: string;
   @Input() alt: string;
   @Input() text: string;
   @Input() type: enumHRefType;
   @Input() isBackgroundOpaque: boolean = false;
   @Input() widthButton: string = "auto";
   
   public isNetworkStatusOnline: boolean = false;
   public isNetworkLoading: boolean = false;
   public internalClass: string = "";
   public hrefType: string = "";
   public internalWidth: string = "600px";
   public isDisplayOnAMobile = false;

   constructor(
      private router: Router,
      private subjectService: SubjectService) {
      this.subjectService.IsWindowResizingSubject.subscribe(value => { this.isDisplayOnAMobile = value; this.resetWidth(this.widthButton); });
   }

   ngOnInit() {
      this.href = this.href.trim();
      this.text = this.text.trim();

      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
      this.subjectService.IsButtonInLoadingSubject.subscribe(value => { this.isNetworkLoading = value; });

      this.class = "nav-link navbarLink nav-link-behaviour " + (this.isBackgroundOpaque ? "navbarLinkBackgroundOpaque" : "navbarLinkBackground") + " animatedButton " + this.class;
      this.internalClass = "nav-link nav-link-behaviour";

      switch (this.type) {
         case enumHRefType.Phone:
            this.hrefType = "tel:" + this.href;
            break;
         case enumHRefType.Email:
            this.hrefType = "mailto:" + this.href;
            break;
         case enumHRefType.GoogleLocation:
            this.hrefType = "https://maps.google.com/?q=" + this.href;
            this.class += " ";
            break;
         case enumHRefType.HttpNewWindow:
         default:
            this.hrefType = this.href;
            break;
      }
      //TODO Ajouter les autres types

      this.resetWidth(this.widthButton);
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes.width) {
         this.resetWidth(changes.width.currentValue);
      }
    }

   resetWidth(widthButton) {
      this.internalWidth = (widthButton != "") ? widthButton : "auto";
   }

   openLink() {
      if (this.type == enumHRefType.HttpRouter)
         this.router.navigateByUrl(this.hrefType, { skipLocationChange: false });
      else
         window.open(this.hrefType, '_blank');
   }
}
