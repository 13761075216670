import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FrameworkService } from "../services/framework.service";
import { SubjectService } from './subject.service';

@Injectable({
   providedIn: 'root'
})
//-- Datagrid service
export class DatagridService implements OnInit, OnDestroy {

   constructor(private subjectService: SubjectService) {
   }

   ngOnInit() {  
   }

   ngOnDestroy(): void {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.unsubscribe();
   }

}
