//This file is autogenerated by C# GeneratedServices/Angular/ServicesAngularGenerationTool

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry, throwError } from 'rxjs';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { LogService } from 'src/sites/core/services/log.service';
import { AppConfigService } from 'src/sites/core/services/appconfig.service';
import { LastUpdatesBaseService } from './lastUpdatesBase.service';
import { entityUpdatedRequest } from "../../vabourlettis/models/entityUpdateRequest";

import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { encryptedValue } from 'src/sites/vabourlettis/authentication/encryptedValue';

@Injectable()
export class LastUpdatesService extends LastUpdatesBaseService {
   private _logger: LogService;
   private _appConfigService: AppConfigService;
   private _httpClient: HttpClient;
   private _frameworkService: FrameworkService;
   private _accessRightsService: AccessRightsService;
   private _encryptionService: EncryptionService;
   private _tokenStorageService: StorageService;

   username = "UNKNOWN";

   constructor(
      appConfigService: AppConfigService,
      logger: LogService,
      httpClient: HttpClient,
      frameworkService: FrameworkService,
      accessRightsService: AccessRightsService,
      encryptionService: EncryptionService,
      storageService: StorageService
   ) {
      super(appConfigService, logger, httpClient, frameworkService, accessRightsService, encryptionService, storageService);
      this._appConfigService = appConfigService;
      this._logger = logger;
      this._httpClient = httpClient;
      this._frameworkService = frameworkService;
      this._accessRightsService = accessRightsService;
      this._encryptionService = encryptionService;
      this._tokenStorageService = storageService;
      this.username = this._tokenStorageService.getUserConnected() == null ? "UNKNOWN" : this._tokenStorageService.getUserConnected().username;
   }


   getEntityUpdated(entityUpdatedRequest: entityUpdatedRequest) {
      let url = "/Q201400001";
      return this._httpClient.post<encryptedValue>(this._appConfigService.getApiUrl() + 'api/C2014' + url, entityUpdatedRequest)
         .pipe(retry(3), catchError((error: HttpErrorResponse) => {
            if (error.status !== 0) {
               return throwError(() => error);
            }
         }));
   }

   setReset() {
      let url = "/Q101400002";
      return this._httpClient.get<boolean>(this._appConfigService.getApiUrl() + 'api/C1014' + url)
         .pipe(retry(3), catchError((error: HttpErrorResponse) => {
            if (error.status !== 0) {
               return throwError(() => error);
            }
         }));
   }
}