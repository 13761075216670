import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';
import { FormService } from 'src/sites/vabourlettis/services/form.service';
import { enumFormAction, enumFormValidator, enumWorkgroup, enumCacheTypeLoad } from '../../enum/Enum';
import { metadataModel } from '../../models/system/metadataModel';
import { ModalFormParam } from '../../models/system/modalFormParam';
import { AccessRightsService } from '../../services/accessRights.service';
import { AppConfigService } from '../../services/appconfig.service';
import { DatagridService } from '../../services/datagrid.service';
import { FrameworkService } from '../../services/framework.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirmDialog/confirmDialog.component';
import { ImageCropMainComponent, ImageCropModel } from '../imageCrop/imageCropMain.component';
import { encryptedValue } from 'src/sites/vabourlettis/authentication/encryptedValue';
import { EncryptionService } from '../../services/encryption.service';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { jsonIgnoreReplacer } from 'json-ignore';
import { SubjectService } from '../../services/subject.service';

@Component({
   selector: 'app-modalForm',
   templateUrl: './modalForm.component.html',
   styleUrls: ['./modalForm.component.scss']
})

//-- ModalFormComponent
export class ModalFormComponent implements OnInit {
  public columnNamesList: string[];
  public editDropdownDatabaseValues = new Map<string, any>();
  public form: FormGroup;
  public hidePassword = true;
  public isButtonDisabled = false;
  public isCancelled = false;
  public isDisplayOnAMobile = (window.innerWidth <= 960);
  public isFormShown = false;
  public isOnError = false;
  public isSaveDisabled = false;
  public isScrollTopArrowShow: boolean = false;
  public listOfColumnMetadata;
  public mdParam: ModalFormParam;

  constructor(
      @Inject(DOCUMENT) private document: Document,
      private cacheService: CacheService,
      private appConfigService: AppConfigService,
      private dateAdapter: DateAdapter<any>,
      private translateService: TranslateService,
      private frameworkService: FrameworkService,
      private datagridService: DatagridService,
      private subjectService: SubjectService,
      private storageService: StorageService,
      private formService: FormService,
      private httpClient: HttpClient,
      private sanitizer: DomSanitizer,
      private encryptionService: EncryptionService,
      @Optional() @Inject(MAT_DIALOG_DATA) public modalFormParam: ModalFormParam,
      @Optional() public dialog: MatDialog) {
   }

  @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

  public checkIsDecimal(column: string) {
      return this.form.get(column).hasError(this.frameworkService.getFormValidator(enumFormValidator.DecimalNumberWithComa));
   }

  public checkIsEmail(column: string) {
      return this.form.get(column).hasError(this.frameworkService.getFormValidator(enumFormValidator.Email));
   }

  //-> Validators
  public checkIsRequired(column: string) {
      return this.form.get(column).hasError(this.frameworkService.getFormValidator(enumFormValidator.Required));
   }

  public checkMaxLength(column: string) {
      return this.form.get(column).hasError(this.frameworkService.getFormValidator(enumFormValidator.MaxLength));
   }

  public checkMinLength(column: string) {
      return this.form.get(column).hasError(this.frameworkService.getFormValidator(enumFormValidator.MinLength));
   }
  public getControlType(column: metadataModel): string {
      return this.frameworkService.getControlType(column);
   }

  public getImgURL(column: string) {
      if (this.form.controls[column].value == null || this.form.controls[column].value == "")
         return null;
      else
         return this.sanitizer.bypassSecurityTrustUrl(JSON.parse(this.form.controls[column].value));
   }

  public getMaxLength(column: any): string {
      if (column[enumFormValidator[enumFormValidator.MaxLength]] !== undefined)
         return column[enumFormValidator[enumFormValidator.MaxLength]];
      else
         return "65535";
   }

  public getParamFromControlType(column: metadataModel): string {
      return this.frameworkService.getParamFromControlType(column);
   }

  public isArchiveOrUnarchive() {
      return this.modalFormParam.action == enumFormAction.Archive || this.modalFormParam.action == enumFormAction.Unarchive;
   }

  public isColumnHidden(isVisibleProperty: string) {
      return isVisibleProperty == enumWorkgroup[enumWorkgroup.None];
   }

  public isFieldNotFilled(column: string): boolean {
      return this.form.get(column).value === "";
   }

  public isFormActionDelete(): boolean {
      return this.mdParam.action == enumFormAction.Delete;
   }

  public ngOnInit(): void {
      this.isFormShown = false;

      this.getScreenSize();

      this.mdParam = this.modalFormParam; //Retrieve parameters given by datagrid

      this.form = this.frameworkService.buildFormGroupFromEntity(this.mdParam.entity); //Build the form
      this.form.patchValue(this.mdParam.element); //Fill the form with element

      if (this.isArchiveOrUnarchive() || this.modalFormParam.action == enumFormAction.Delete) {
         this.onSaveDialog(null);
      }
      else {
         this.isFormShown = true;
         
         //Reset the form if add (fill before else an error occurs at form load)
         if (this.mdParam.action == enumFormAction.Add)
            this.form.reset();

         this.columnNamesList = this.frameworkService.getNamesOfAllProperties(this.mdParam.entity);

         //-> Get metadata
         this.listOfColumnMetadata = this.frameworkService.getMetadataModalFormIsVisible(this.mdParam.entity);
         //<- Get metadata

         //-> Fill items values of each dropdown
         for (let metadata of this.listOfColumnMetadata) {
            if (this.getControlType(metadata[1]) == 'Dropdown') {
               let editDropdownEntityMapping = metadata[1].editDropdownEntityMapping;
               let editDropdownFormatForDisplay = metadata[1].editDropdownFormatForDisplay;
               let editDropdownPropertiesForDisplay = metadata[1].editDropdownPropertiesForDisplay;

               if (editDropdownEntityMapping != "") {
                  //TODO : Exemple pour les jours de fermeture, la liste déroulante des équipes contient toutes les équipes de TOUTES les saisons !!!
                  // ----> Limiter la liste aux équipes de la saison.
                  // ----> Plus, par défaut se position sur le championnat courant !!! Inutile de le sélectionner à chaque fois.
                  this.formService.getAllActiveWithoutIncluding(editDropdownEntityMapping).subscribe(
                     (data: encryptedValue) => {
                        this.editDropdownDatabaseValues.set(editDropdownEntityMapping, this.encryptionService.decryptObject(false, data.value).map(x => (
                           {
                              value: x.sysId,
                              viewValue: this.frameworkService.format(editDropdownFormatForDisplay, editDropdownPropertiesForDisplay, x)
                           })));
                     }
                  );
               }
            }
         }

         if (this.isFormActionDelete()) {
            for (const field in this.form.controls) {
               const control = this.form.get(field);
               control.disable({ onlySelf: true });
            }
         }

         this.dateAdapter.setLocale(this.translateService.currentLang);

         this.translatePage();
         this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translatePage();
         });
      }
   }

  public onCancelDialog(): void {
      this.subjectService.IsButtonInLoadingSubject.next(false);
      this.dialog.closeAll();
   }

  public onClosedDialog(refresh: boolean): void {
      if (refresh)
      {
         //this.frameworkService.logInfo("CALL cacheLoadStart","onClosedDialog (modalForm)");
         this.cacheService.cacheLoadStart(enumCacheTypeLoad.fromUpdate);
      }
      this.subjectService.IsButtonInLoadingSubject.next(false);
      this.dialog.closeAll();
   }

  public onNoClick(): void {
      this.subjectService.IsButtonInLoadingSubject.next(false);
      this.dialog.closeAll();
   }

  public onSaveDialog(event): void {
      this.isSaveDisabled = true;

      //Prevent multiple click
      if (event != null)
      {
         const button = (event.srcElement.disabled === undefined) ? event.srcElement.parentElement : event.srcElement;
         button.setAttribute('disabled', true);
         setTimeout(function () {
            button.removeAttribute('disabled');
         }, 2000);
         //Prevent multiple click

         if (this.form.invalid) {
            this.subjectService.IsButtonInLoadingSubject.next(false);
            return;
         }
      }

      if (this.modalFormParam.action == enumFormAction.Add) {
         this.mdParam.element = this.mdParam.entity;

         for (const field in this.form.controls) {
            const control = this.form.get(field);
            if (control.dirty) {
               Object.defineProperty(this.mdParam.element, field, { value: control.value });
            }
         }

         switch(this.mdParam.urlApi)
         {
            //TODO (site) : Manage other tables
            case "sponsor":
               this.mdParam.element = this.cacheService.loadSponsorForeignKeys(this.mdParam.element);
            break;
         }

         let encryptedData = new encryptedValue();
         encryptedData.value = this.encryptionService.encryptObject(true, false, this.mdParam.element);

         let url = "/Q1004?username=" + this.storageService.getUserConnected().username;
         this.httpClient.post(this.appConfigService.getApiUrl() + 'api/' + this.mdParam.urlApi + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer))).subscribe(
            (data) => {
               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);
     
               if (dataDecrypted != null) {
                  this.isButtonDisabled = true;
                  this.frameworkService.displayAlert(true, this.translateService.instant("MessageDataSavedSuccessfully"));
                  this.onClosedDialog(true);
               }
               else {
                  this.isOnError = true;
                  this.frameworkService.displayAlert(false, this.translateService.instant("MessageDataNotSavedDueToAnError"));
               }
               this.isSaveDisabled = true;
               this.onClosedDialog(false);
            },
            (err) => {
               this.isOnError = true;
               this.frameworkService.displayAlert(false, this.translateService.instant("MessageDataNotSavedDueToAnError"));
               this.isSaveDisabled = true;
               this.onClosedDialog(false);
            }
         );
      }

      if (this.modalFormParam.action == enumFormAction.Edit) {
         for (const field in this.form.controls) {
            const control = this.form.get(field);
            Object.defineProperty(this.mdParam.element, "sysId", { value: this.form.get("sysId").value });
            if (this.form.get(field).dirty) {
               Object.defineProperty(this.mdParam.element, field, { value: this.form.get(field).value });
            }
         }

         let encryptedData = new encryptedValue();
         encryptedData.value = this.encryptionService.encryptObject(true, false, this.mdParam.element);

         let url = "/Q1006?isToBeAdded=false&username=" + this.storageService.getUserConnected().username;
         this.httpClient.put(this.appConfigService.getApiUrl() + 'api/' + this.mdParam.urlApi + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer))).subscribe(
            (data) => {
               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);
     
               if (dataDecrypted != null) {
                  this.isButtonDisabled = true;
                  this.frameworkService.displayAlert(true, this.translateService.instant("MessageDataSavedSuccessfully"));
                  this.onClosedDialog(true);
               }
               else {
                  this.isOnError = true;
                  this.frameworkService.displayAlert(false, this.translateService.instant("MessageDataNotSavedDueToAnError"));
                  this.onClosedDialog(false);
               }
             
            },
            (err) => {
               this.isOnError = true;
               this.frameworkService.displayAlert(false, this.translateService.instant("MessageDataNotSavedDueToAnError"));
               this.onClosedDialog(false);
            }
         );
      }

      if (this.modalFormParam.action == enumFormAction.Delete) {
         const message1 : string = "Voulez-vous vraiment supprimer définitivement cette information ?";
         const message2 : string = "";
   
         const dialogRefData = new ConfirmDialogModel("Suppression !", message1, message2);
   
         const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            position: { top: '0px' },
            maxWidth: "100%",
            width: "100%",
            data: dialogRefData
         });
   
         dialogRef.afterClosed().subscribe(dialogRefResult => {
            if (dialogRefResult) {
               let encryptedData = new encryptedValue();
               encryptedData.value = this.encryptionService.encryptObject(true, false, this.form.get("sysId").value);
      
               let url = "/Q1010";
               this.httpClient.post(this.appConfigService.getApiUrl() + 'api/' + this.mdParam.urlApi + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer))).subscribe(
                  (data) => {
                     this.isButtonDisabled = true;
                     this.frameworkService.displayAlert(true, this.translateService.instant('MessageDataDeletedSuccessfully'));
                     this.onClosedDialog(true);
                  },
                  (err) => {
                     this.isOnError = true;
                     this.frameworkService.displayAlert(false, this.translateService.instant('MessageDataNotDeletedDueToAnError'));
                     this.onClosedDialog(false);
                  }
               );
            }
            this.onCancelDialog();
         });
      }

      //TODO : Archive/unarchive desactivated -> Update the httpClient.put with the encryption if you reenable it

      // if (this.modalFormParam.action == enumFormAction.Archive) {
      //    let url = "/Q1008?id=" + this.form.get("sysId").value + "&activate=false&username=" + this.storageService.getUserConnected().username;
      //    this.httpClient.put(this.appConfigService.getApiUrl() + 'api/' + this.mdParam.urlApi + url, this.mdParam.element).subscribe(
      //       (res) => {
      //          if (res != 0) {
      //             this.isButtonDisabled = true;
      //             this.frameworkService.displayAlert(true, this.translateService.instant("MessageDataSavedSuccessfully"));
      //             this.onClosedDialog(true);
      //          }
      //          else {
      //             this.isOnError = true;
      //             this.frameworkService.displayAlert(false, this.translateService.instant("MessageDataNotSavedDueToAnError"));
      //             this.onClosedDialog(false);
      //          }
         
      //       },
      //       (err) => {
      //          this.isOnError = true;
      //          this.frameworkService.displayAlert(false, this.translateService.instant("MessageDataNotSavedDueToAnError"));
      //          this.onClosedDialog(false);
      //       }
      //    );
      // }

      // if (this.modalFormParam.action == enumFormAction.Unarchive) {
      //    let url = "/Q1008?id=" + this.form.get("sysId").value + "&activate=true&username=" + this.storageService.getUserConnected().username;
      //    this.httpClient.put(this.appConfigService.getApiUrl() + 'api/' + this.mdParam.urlApi + url, this.mdParam.element).subscribe(
      //       (res) => {
      //           if (res != 0) {
      //             this.isButtonDisabled = true;
      //             this.frameworkService.displayAlert(true, this.translateService.instant("MessageDataSavedSuccessfully"));
      //             this.onClosedDialog(true);
      //          }
      //          else {
      //             this.isOnError = true;
      //             this.frameworkService.displayAlert(false, this.translateService.instant("MessageDataNotSavedDueToAnError"));
      //             this.onClosedDialog(false);
      //          }

      //       },
      //       (err) => {
      //          this.isOnError = true;
      //          this.frameworkService.displayAlert(false, this.translateService.instant("MessageDataNotSavedDueToAnError"));
      //          this.onClosedDialog(false);
      //       }
      //    );
      // }
   }

  public onScroll(event: any) {
      const number = event.srcElement.scrollTop;
      this.isScrollTopArrowShow = true;
      if (event.target.scrollTop == 0) {
         this.isScrollTopArrowShow = false;
      }
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

  public resetPicture(column: string) {
      this.form.controls[column].setValue(null);
      return false;
   }

  //TODO (ModalForm) : Add others validators
  //<- Validators
  public scrollToElement($element): void {
      $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

   //Used for radiobutton (inactive)
   // getCheckedValue(column: string) {
   //   if (this.form.get(column).value == null)
   //     return 'null';
   //   else
   //     return this.form.get(column).value?.toString();
   // }
  public setIndeterminate(column: string) {
      this.form.get(column).setValue(null);
   }

   // uploadFile(imgFile: any, column: string) {
   //   this.openImageCropPopupClick();
   // }

   // uploadFile(imgFile: any, column: string) {
   //   this.openImageCropPopupClick();

   //   if (imgFile.target.files.length === 0)
   //     return;

   //   let reader = new FileReader();
   //   reader.onload = (e: any) => {
   //     let image = new Image();
   //     image.src = e.target.result;
   //     image.onload = rs => {
   //       this.form.controls[column].setValue(JSON.stringify(e.target.result));
   //       this.form.controls[column].markAsDirty();
   //     };
   //   };
   //   reader.readAsDataURL(imgFile.target.files[0]);
   // }
  public showImageCropPopup(column: string) {
      const dialogRefData = new ImageCropModel(column, "550", "jpeg", "99");

      const dialogRef = this.dialog.open(ImageCropMainComponent, {
         data: dialogRefData,
         position: { top: '0px' },
         maxWidth: "100%",
         width: "100%",
         disableClose: true
      }
      );

      dialogRef.afterClosed().subscribe(result => {
         if (result.image != undefined && result.image != "") {
            this.form.controls[result.column].setValue(JSON.stringify(result.image));
            this.form.controls[result.column].markAsDirty();
         }
         this.subjectService.IsDatagridShouldbeRefreshedSubject.next(true);
      });
   }

  public translatePage() {
   }
} 